import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Platform } from '@angular/cdk/platform';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { PromptComponent } from '../../component/prompt/prompt.component';
import { DialogServiceService } from '../dialog/dialog-service.service';
import { TranslateService } from '@ngx-translate/core';
import { communityConfig } from 'src/app/config/app';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;

  constructor(
    //private bottomSheet: MatBottomSheet,
    private transev: TranslateService,
    private alertLib: DialogServiceService,
    private platform: Platform
  ) { }

  public initPwaPrompt() {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        this.openPromptComponent('android');
      });
    }
    if (this.platform.IOS) {
      // tslint:disable-next-line: no-string-literal
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode && this.platform.SAFARI &&
        (window.navigator.userAgent.indexOf('CriOS') < 0)) {
        this.openPromptComponent('ios');
      }
    }
  }

  private openPromptComponent(mobileType: 'ios' | 'android') {

    timer(10)
      .pipe(take(1))
      .subscribe(() => {
        if (communityConfig.grpc.server.dev) return;
        this.alertLib.installPwa(mobileType, this.promptEvent, (r) => { 

        });
      });

  }
}
