<app-sidenav page="product" menuType>

<div class="form-list" [ngClass]="{tablet: menuType==2}">

<div class="product-page">
<mat-card class="product-card">
  <mat-card-header>
    <mat-card-title>{{ product?.getName() }}</mat-card-title>
  </mat-card-header>
  <mat-card-footer [class]="'product-price'">
    <span *ngIf="product_qty">{{ 'product.qty' | translate }} {{ product_qty }}</span>
    {{ ((product?.getPrice() | number:'1.2-2') || '').replace(',',' ').replace('.',',') }}{{ currencysymbol }}          </mat-card-footer>
</mat-card>

<ng-image-slider
  class="img-slider"
  [imageSize]="imageSize"
  [manageImageRatio]="true"
  [images]="imageObject" #nav>
</ng-image-slider>

<div class="body"
  [ngClass]="{'three': hasColorSize == 3, 'one': hasColorSize == 1, 'zero': hasColorSize == 0}">
  <p
    [innerHTML]="product?.getDescription() | pipeDescription">
  </p>
</div>

<div
  *ngIf="product?.getUrl()"
  class="link">
  <a class="pl-0" mat-flat-button target="_blank"
    href="{{ product?.getUrl() }}">
    {{ 'product.more_info' | translate }}
  </a>
</div>

<div #boxOption class="box-options" *ngIf="colorList(product) || product?.getSizesList().join(',')">
  <div class="row" *ngIf="colorList(product)">
    <div class="col1">{{ 'product.color_label' | translate }}</div>
    <div class="col2">{{ colorList(product) }}</div>
  </div>
  <div class="row" *ngIf="product?.getSizesList().join(',')">
    <div class="col1">{{ 'product.size_label' | translate }}</div>
    <div class="col2">{{ product?.getSizesList().join(',') }}</div>
  </div>
</div>
</div>
<div class="bottom">
  <button
    *ngIf="(product.getQtyn() && product.getQt() > 0) || !product.getQtyn() || (product.getQt() === -1)"
    (click)="clickButton()"
    mat-raised-button
    [class]="'button-buy'">
    {{ 'product.button' | translate }}
  </button>
</div>

</div>

</app-sidenav>