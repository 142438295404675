import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { UserLibService } from '../../user/user-lib.service';
import { Empty, RequestID } from '../../../libs/proto/commUnity_pb';
import { CartConversionService } from '../../conversion/cart/cart-conversion.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { DeliveryCost, ProductToRemove, ProductToRemoveFromMeal } from '../../../libs/proto/mobile_pb';
import { RestaurantSettingsConversionService } from '../../conversion/restaurant/resto-settings-conversion.service';
import { CheckOutMeal, Meal, RestaurantOrder, RestaurantOrderInfo, RestaurantOrderLine, RestaurantProduct, RestaurantSettings, Table } from '../../../libs/proto/restaurant_pb';
import { RestaurantProductConversionService } from '../../conversion/restaurant/resto-product-conversion-service';
import { RestaurantTableConversionService } from '../../conversion/restaurant/resto-table-conversion-service';
import { Cart } from '../../../libs/proto/shop_pb';
import { RestaurantMealConversionService } from '../../conversion/restaurant/resto-meal-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcRestaurantLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private convProdLib: RestaurantProductConversionService,
    private convTableLib: RestaurantTableConversionService,
    private convSetLib: RestaurantSettingsConversionService,
    private convMealLib: RestaurantMealConversionService,
    private storeLib: StorageLibService
  ) { }

  getRestaurantSettings(option?: GrpcLibServiceOption): Promise<RestaurantSettings>{
    return new Observable<RestaurantSettings>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getRestaurantSettingsOffline(opt);
      } else {
        this._getRestaurantSettingsOnline(opt);
      }
    }).toPromise();
  }

  private _getRestaurantSettingsOffline(option: GrpcLibServiceOption){
    this.convSetLib.FromStorage(
      this.storeLib.get('resto-setting'), (ns, e) => {
        option.call.subscribe.next(ns);
        option.call.subscribe.complete();
    });
  }

  private _getRestaurantSettingsOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getRestaurantSettings(new Empty(), {
        token: this.userLib.Data.token?.getToken(),
      }, (e, r) => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getRestaurantSettingsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getRestaurantSettingsOnlineStatus(s, option);
      })
      .on('data', (r: RestaurantSettings) => {
        this._getRestaurantSettingsOnlineData(r, option);
      })
      .on('end', () => {
        this._getRestaurantSettingsOnlineEnd(option);
      });
  }

  private _getRestaurantSettingsOnlineData(ret: RestaurantSettings, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getRestaurantSettingsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getRestaurantSettingsOnline(option);
    }, option);
  }

  private _getRestaurantSettingsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getRestaurantSettingsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storeLib.cache.restaurantSettings = true;
      if (option.KeepInCache || false) {
        this.storeLib.set(
          'resto-setting', this.convSetLib.ToStorage(option.call.data[0])
        );
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {

      this.grpcLib.treatStatus(s, () => {
        this._getRestaurantSettingsOnline(option);
      }, option);
    }
  }

  getRestaurantProducts(option?: GrpcLibServiceOption): Promise<RestaurantProduct[]>{
    return new Observable<RestaurantProduct[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getRestaurantProductsOffline(opt);
      } else {
        this._getRestaurantProductsOnline(opt);
      }
    }).toPromise();
  }

  private _getRestaurantProductsOffline(option: GrpcLibServiceOption){
    this.convProdLib.FromStorages(
      (this.storeLib.get('rproduct-s') || this.storeLib.get('rproduct')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getRestaurantProductsOnline(option: GrpcLibServiceOption){
    const r = new RequestID();
    r.setId(option.call.req);
    this.grpcLib.MobileClient.getRestaurantProducts(r, {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getRestaurantProductsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getRestaurantProductsOnlineStatus(s, option);
      })
      .on('data', (rr: RestaurantProduct) => {
        this._getRestaurantProductsOnlineData(rr, option);
      })
      .on('end', () => {
        this._getRestaurantProductsOnlineEnd(option);
      });
  }

  private _getRestaurantProductsOnlineData(ret: RestaurantProduct, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getRestaurantProductsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getRestaurantProductsOnline(option);
    }, option);
  }

  private _getRestaurantProductsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getRestaurantProductsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storeLib.cache.restaurantProduct = true;
      if (option.KeepInCache || false) {
        this.storeLib.set(
          'rproduct', this.convProdLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getRestaurantProductsOnline(option);
      }, option);
    }
  }

  getTable(option?: GrpcLibServiceOption): Promise<Table[]>{
    return new Observable<Table[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getTableOffline(opt);
      } else {
        this._getTableOnline(opt);
      }
    }).toPromise();
  }

  private _getTableOffline(option: GrpcLibServiceOption){
    this.convTableLib.FromStorages(
      (this.storeLib.get('rtable-s') || this.storeLib.get('rtable')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getTableOnline(option: GrpcLibServiceOption){
    const r = new RequestID();
    r.setId(option.call.req);
    this.grpcLib.MobileClient.getTable(r, {
        token: this.userLib.Data.token?.getToken(),
      }, () => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getTableOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getTableOnlineStatus(s, option);
      })
      .on('data', (rr: Table) => {
        this._getTableOnlineData(rr, option);
      })
      .on('end', () => {
        this._getTableOnlineEnd(option);
      });
  }

  private _getTableOnlineData(ret: Table, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getTableOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getTableOnline(option);
    }, option);
  }

  private _getTableOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getTableOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storeLib.cache.restaurantTable = true;
      if (option.KeepInCache || false) {
        this.storeLib.set(
          'rtable', this.convTableLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getTableOnline(option);
      }, option);
    }
  }

  addProductToMeal(req: RestaurantOrderLine): Promise<Meal>{
    return new Observable<Meal>(obs => {
      const opt = this.grpcLib.getOption({});
      opt.call.subscribe = obs;
      opt.call.req = req;

      this._addProductToMeal(opt);

    }).toPromise();
  }

  private _addProductToMeal(option: GrpcLibServiceOption){
      this.grpcLib.MobileClient.addProductToMeal(option.call.req, {
        token: this.userLib.Data.token?.getToken(),
      }, (err: any, resp: Meal) => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._addProductToMealError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._addProductToMealStatus(s, option);
      })
      .on('data', (r: Meal) => {
        this._addProductToMealData(r, option);
      })
      .on('end', () => {
        this._addProductToMealEnd(option);
      });
  }

  private _addProductToMealData(ret: Meal, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _addProductToMealError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._addProductToMeal(option);
    }, option);
  }

  private _addProductToMealEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _addProductToMealStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._addProductToMeal(option);
      }, option);
    }
  }

  getMeal(option?: GrpcLibServiceOption): Promise<Meal>{
    return new Observable<Meal>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getMealOffline(opt);
      } else {
        this._getMealOnline(opt);
      }
    }).toPromise();
  }

  private _getMealOffline(option: GrpcLibServiceOption){
    this.convMealLib.FromStorage(
      this.storeLib.get('meal'), (ns, e) => {
        option.call.subscribe.next(ns);
        option.call.subscribe.complete();
    });
  }

  private _getMealOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);
    this.grpcLib.MobileClient.getMeal(req, {
        token: this.userLib.Data.token?.getToken(),
      }, () => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getMealOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getMealOnlineStatus(s, option);
      })
      .on('data', (rr: Meal) => {
        this._getMealOnlineData(rr, option);
      })
      .on('end', () => {
        this._getMealOnlineEnd(option);
      });
  }

  private _getMealOnlineData(ret: Meal, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getMealOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getMealOnline(option);
    }, option);
  }

  private _getMealOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getMealOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storeLib.cache.restaurantMeal = true;
      if (option.KeepInCache || false) {
        this.storeLib.set(
          'meal', this.convMealLib.ToStorage(option.call.data[0])
        );
        this.storeLib.set('meal-badge',
          (option.call.data[0] as Meal).getNbitems());
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getMealOnline(option);
      }, option);
    }
  }

  dropProductFromMeal(req: ProductToRemoveFromMeal): Promise<Meal>{
    return new Observable<Meal>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req
        }
      });
      opt.call.subscribe = obs;

      this._dropProductFromMealOnline(opt);

    }).toPromise();
  }

  private _dropProductFromMealOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.dropProductFromMeal(option.call.req, {
      token: this.userLib.Data.token?.getToken(),
    }, (err: any, resp: Meal) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._dropProductFromMealOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }

  restaurantCheckOut(req: CheckOutMeal): Promise<RestaurantOrderInfo>{

    return new Observable<RestaurantOrderInfo>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req
        }
      });
      opt.call.subscribe = obs;

      this._restaurantCheckOutOnline(opt);

    }).toPromise();
  }

  private _restaurantCheckOutOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.restaurantCheckOut(option.call.req, {
      token: this.userLib.Data.token?.getToken(),
    }, (err: any, resp: RestaurantOrderInfo) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._restaurantCheckOutOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }
  callWaiter(req: string): Promise<Empty>{

    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req
        }
      });
      opt.call.subscribe = obs;

      this._callWaiterOnline(opt);

    }).toPromise();
  }

  private _callWaiterOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);
    this.grpcLib.MobileClient.callWaiter(req, {
      token: this.userLib.Data.token?.getToken(),
    }, (err: any, resp: Empty) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._callWaiterOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }

  getRestaurantOrder(option?: GrpcLibServiceOption): Promise<RestaurantOrder[]>{
    return new Observable<RestaurantOrder[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      this._getRestaurantOrderOnline(opt);
    }).toPromise();
  }

  private _getRestaurantOrderOnline(option: GrpcLibServiceOption){
      const req = new RequestID();
      req.setId(option.call.req);
      this.grpcLib.MobileClient.getRestaurantOrder(req, {
        token: this.userLib.Data.token?.getToken(),
      }, () => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getRestaurantOrderError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getRestaurantOrderStatus(s, option);
      })
      .on('data', (r: RestaurantOrder) => {
        this._getRestaurantOrderData(r, option);
      })
      .on('end', () => {
        this._getRestaurantOrderEnd(option);
      });
  }

  private _getRestaurantOrderData(ret: RestaurantOrder, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getRestaurantOrderError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getRestaurantOrderOnline(option);
    }, option);
  }

  private _getRestaurantOrderEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getRestaurantOrderStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getRestaurantOrderOnline(option);
      }, option);
    }
  }
}
