import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Empty, Disclaimer } from '../../../libs/proto/commUnity_pb';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GrpcDisclaimerLibService {

  constructor(
    private grpcLib: GrpcLibService,
  ) { }

  getDisclaimer(t: string): Promise<Disclaimer>{

    return new Observable<Disclaimer>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: t
        }
      });
      opt.call.subscribe = obs;

      this._getDisclaimerOnline(opt);

    }).toPromise();
  }

  private _getDisclaimerOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getDisclaimer(new Empty(), {
      token: option.call.req,
    }, (err: any, resp: Disclaimer) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._getDisclaimerOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }

  approveDisclaimer(t: string): Promise<Empty>{

    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: t
        }
      });
      opt.call.subscribe = obs;

      this._approveDisclaimerOnline(opt);

    }).toPromise();
  }

  private _approveDisclaimerOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.approveDisclaimer(new Empty(), {
      token: option.call.req,
    }, (err: any, resp: Empty) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._approveDisclaimerOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }
}
