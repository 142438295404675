// source: forum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.commUnity.NewPost', null, global);
goog.exportSymbol('proto.commUnity.Post', null, global);
goog.exportSymbol('proto.commUnity.PostType', null, global);
goog.exportSymbol('proto.commUnity.ReplyType', null, global);
goog.exportSymbol('proto.commUnity.Topic', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Topic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Topic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Topic.displayName = 'proto.commUnity.Topic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Post = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Post.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Post, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Post.displayName = 'proto.commUnity.Post';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.NewPost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.NewPost.repeatedFields_, null);
};
goog.inherits(proto.commUnity.NewPost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.NewPost.displayName = 'proto.commUnity.NewPost';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Topic.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Topic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Topic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Topic.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Topic}
 */
proto.commUnity.Topic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Topic;
  return proto.commUnity.Topic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Topic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Topic}
 */
proto.commUnity.Topic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Topic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Topic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Topic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Topic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Topic.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Topic} returns this
 */
proto.commUnity.Topic.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.commUnity.Topic.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Topic} returns this
 */
proto.commUnity.Topic.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Post.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Post.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Post.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Post} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Post.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    image: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    replytypesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    publishdate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    memberid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    membername: jspb.Message.getFieldWithDefault(msg, 7, ""),
    memberphone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    memberemail: jspb.Message.getFieldWithDefault(msg, 9, ""),
    topicid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    topicname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    posttype: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Post}
 */
proto.commUnity.Post.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Post;
  return proto.commUnity.Post.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Post} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Post}
 */
proto.commUnity.Post.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.commUnity.ReplyType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReplytypes(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPublishdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembername(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberphone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberemail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopicid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopicname(value);
      break;
    case 12:
      var value = /** @type {!proto.commUnity.PostType} */ (reader.readEnum());
      msg.setPosttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Post.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Post.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Post} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Post.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReplytypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getPublishdate();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMembername();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMemberphone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMemberemail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTopicid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTopicname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPosttype();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Post.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image = 2;
 * @return {string}
 */
proto.commUnity.Post.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.commUnity.Post.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ReplyType replyTypes = 4;
 * @return {!Array<!proto.commUnity.ReplyType>}
 */
proto.commUnity.Post.prototype.getReplytypesList = function() {
  return /** @type {!Array<!proto.commUnity.ReplyType>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.commUnity.ReplyType>} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setReplytypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.commUnity.ReplyType} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.addReplytypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.clearReplytypesList = function() {
  return this.setReplytypesList([]);
};


/**
 * optional uint32 publishDate = 5;
 * @return {number}
 */
proto.commUnity.Post.prototype.getPublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setPublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string memberID = 6;
 * @return {string}
 */
proto.commUnity.Post.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string memberName = 7;
 * @return {string}
 */
proto.commUnity.Post.prototype.getMembername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setMembername = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string memberPhone = 8;
 * @return {string}
 */
proto.commUnity.Post.prototype.getMemberphone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setMemberphone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string memberEmail = 9;
 * @return {string}
 */
proto.commUnity.Post.prototype.getMemberemail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setMemberemail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string topicID = 10;
 * @return {string}
 */
proto.commUnity.Post.prototype.getTopicid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setTopicid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string topicName = 11;
 * @return {string}
 */
proto.commUnity.Post.prototype.getTopicname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setTopicname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional PostType PostType = 12;
 * @return {!proto.commUnity.PostType}
 */
proto.commUnity.Post.prototype.getPosttype = function() {
  return /** @type {!proto.commUnity.PostType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.commUnity.PostType} value
 * @return {!proto.commUnity.Post} returns this
 */
proto.commUnity.Post.prototype.setPosttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.NewPost.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.NewPost.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.NewPost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.NewPost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.NewPost.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    replytypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    topicid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    posttype: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.NewPost}
 */
proto.commUnity.NewPost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.NewPost;
  return proto.commUnity.NewPost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.NewPost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.NewPost}
 */
proto.commUnity.NewPost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.commUnity.ReplyType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReplytypes(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopicid(value);
      break;
    case 12:
      var value = /** @type {!proto.commUnity.PostType} */ (reader.readEnum());
      msg.setPosttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.NewPost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.NewPost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.NewPost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.NewPost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReplytypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getTopicid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPosttype();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.commUnity.NewPost.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.NewPost} returns this
 */
proto.commUnity.NewPost.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ReplyType replyTypes = 2;
 * @return {!Array<!proto.commUnity.ReplyType>}
 */
proto.commUnity.NewPost.prototype.getReplytypesList = function() {
  return /** @type {!Array<!proto.commUnity.ReplyType>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.commUnity.ReplyType>} value
 * @return {!proto.commUnity.NewPost} returns this
 */
proto.commUnity.NewPost.prototype.setReplytypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.commUnity.ReplyType} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.NewPost} returns this
 */
proto.commUnity.NewPost.prototype.addReplytypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.NewPost} returns this
 */
proto.commUnity.NewPost.prototype.clearReplytypesList = function() {
  return this.setReplytypesList([]);
};


/**
 * optional string topicID = 3;
 * @return {string}
 */
proto.commUnity.NewPost.prototype.getTopicid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.NewPost} returns this
 */
proto.commUnity.NewPost.prototype.setTopicid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PostType PostType = 12;
 * @return {!proto.commUnity.PostType}
 */
proto.commUnity.NewPost.prototype.getPosttype = function() {
  return /** @type {!proto.commUnity.PostType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.commUnity.PostType} value
 * @return {!proto.commUnity.NewPost} returns this
 */
proto.commUnity.NewPost.prototype.setPosttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * @enum {number}
 */
proto.commUnity.ReplyType = {
  PHONE_CALL: 0,
  SMS: 1,
  EMAIL: 2
};

/**
 * @enum {number}
 */
proto.commUnity.PostType = {
  PUBLIC: 0,
  PRIVATE: 1
};

goog.object.extend(exports, proto.commUnity);
