<app-sidenav page="new" menuType>

<div class="form-list" *ngIf="menuType!=2">

<mat-card class="news-card">
  <div class="top-image"
    [ngStyle]="{'background-image': 'url(' + news?.getImage() +')'}">
  </div>

  <mat-card-header class="header">
    <mat-card-title>{{ news?.getTitle() }}</mat-card-title>
    <mat-card-subtitle class="news-date">{{ news?.getPublishdate()+'' | amParse: 'YYYYMMDD' | amDateFormat: dateFormat }}</mat-card-subtitle>
  </mat-card-header>
</mat-card>
<div class="body">
  <p [innerHTML]="news?.getDescription() | pipeDescription">
  </p>

  <a class="news-link" mat-flat-button target="_blank" *ngIf="news?.getUrl() || news?.getYoutube()" href="{{ news?.getUrl() || news?.getYoutube()}}">
    <mat-icon>language</mat-icon>
    {{ 'new.more_info' | translate }}
  </a>
  <br />
  <a class="news-link" *ngIf="hasplace" mat-flat-button [routerLink]="[ '/place/new' + newindex, news?.getId(), news?.getPlaceid() ]" routerLinkActive="active">
    <mat-icon>place</mat-icon>
    {{ ('new.info_place' | translate).replace('%s', news.getPlacename()) }}
  </a>
</div>

</div>

<div class="mode-tablet" *ngIf="menuType==2">
  <div class="left">
    <mat-card class="news-card">
      <div class="top-image"
        [ngStyle]="{'background-image': 'url(' + news?.getImage() +')'}">
      </div>
    
      <mat-card-header class="header">
        <mat-card-title>{{ news?.getTitle() }}</mat-card-title>
        <mat-card-subtitle class="news-date">{{ news?.getPublishdate()+'' | amParse: 'YYYYMMDD' | amDateFormat: dateFormat }}</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>

<div class="right">
  <div class="body">
    <p [innerHTML]="news?.getDescription() | pipeDescription">
    </p>
  
    <a class="news-link" mat-flat-button target="_blank" *ngIf="news?.getUrl() || news?.getYoutube()" href="{{ news?.getUrl() || news?.getYoutube()}}">
      <mat-icon>language</mat-icon>
      {{ 'new.more_info' | translate }}
    </a>
    <br />
    <a class="news-link" *ngIf="hasplace" mat-flat-button [routerLink]="[ '/place/new' + newindex, news?.getId(), news?.getPlaceid() ]" routerLinkActive="active">
      <mat-icon>place</mat-icon>
      {{ ('new.info_place' | translate).replace('%s', news.getPlacename()) }}
    </a>
  </div>
</div>

</div>

</app-sidenav>
