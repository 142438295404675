<app-sidenav page="guest-bill" [data]="resaid" menuType>

<ng-container [ngTemplateOutlet]="loading" *ngIf="waiting">
</ng-container>  

<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="bills$ | async as bills ">

  <mat-action-list class="pt-0"  *ngIf="menuType!=2">

    <mat-card class="p-0" *ngIf="!(err || nobill) && !waiting">
      <mat-toolbar>
        <div class="w-100 top-title">
          <span>{{ 'guest.bill-no' | translate }}{{ bill_id }}</span>
          <span>{{ bill_total.toFixed(2) }} {{ bill_cur }}</span>
        </div>
      </mat-toolbar>

      <mat-list class="w-100">

        <mat-card 
          *ngFor="let bill of bills">

          <div class="line">
            <span>{{ bill.getDate() | amParse: 'YYYY-MM-DD' | amDateFormat: 'DD/MM/YYYY'  }} {{ bill.getHour() }}</span>
            <br/>
            <span class="line-date">
              <span>{{ bill.getQuantity() }} x {{ bill.getDescription() }}</span>
              <span>{{ (bill.getPrice() * bill.getQuantity()).toFixed(2)  }} {{ bill_cur }}</span>
            </span>
            <i>{{ bill.getComment() }}</i>
            </div>
        </mat-card>
      </mat-list>  
    </mat-card>
    
    <div class="w-100" *ngIf="(err || nobill) && !waiting">
    <mat-card>
      <mat-card-header class="header">
        <mat-card-subtitle *ngIf="!nobill">{{ 'guest.bill-err' | translate }}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="nobill">{{ 'guest.bill-no-data' | translate }}</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
    </div>

  </mat-action-list>

  <div class="mode-tablet" *ngIf="menuType==2">

    <div class="left" *ngIf="!(err || nobill) && !waiting">
      <mat-card>
      <mat-card-header class="header">
        <mat-card-title>{{ 'guest.bill-no' | translate }}{{ bill_id }}</mat-card-title>
        <mat-card-subtitle ><BR/>{{ bill_total.toFixed(2) }} {{ bill_cur }}</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
    </div>
    
    <div class="right">
      <div class="body">
        <mat-card *ngIf="(err || nobill) && !waiting">
          <mat-card-header class="header">
            <mat-card-subtitle *ngIf="!nobill">{{ 'guest.bill-err' | translate }}</mat-card-subtitle>
            <mat-card-subtitle *ngIf="nobill">{{ 'guest.bill-no-data' | translate }}</mat-card-subtitle>
              </mat-card-header>
        </mat-card>

        <mat-list class="w-100" *ngIf="!(err || nobill) && !waiting">
          <mat-card 
            *ngFor="let bill of bills" >
  
            <div class="line">
              <span>{{ bill.getDate() | amParse: 'YYYY-MM-DD' | amDateFormat: 'DD/MM/YYYY'  }} {{ bill.getHour() }}</span>
              <br/>
              <span class="line-date">
                <span>{{ bill.getQuantity() }} x {{ bill.getDescription() }}</span>
                <span>{{ bill.getPrice().toFixed(2)  }} {{ bill_cur }}</span>
              </span>
              <i>{{ bill.getComment() }}</i>
              </div>
          </mat-card>
        </mat-list>  

      </div>
    </div>
  </div>            
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

</app-sidenav>