import { Injectable } from '@angular/core';
import { GolfTheme, Place } from '../../../libs/proto/commUnity_pb';
import { BinaryWriter } from 'google-protobuf';

@Injectable({
  providedIn: 'root'
})
export class GolfThemeConversionService {

  constructor() { }

  ToStorage(req: GolfTheme) {
    const w = new BinaryWriter();
    GolfTheme.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (GolfTheme: GolfTheme, errorConvert: any) => void) {
    try {
      callback( GolfTheme.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }
  ToStorages(news: GolfTheme[]) {
    const rets = new Array<string>();

    news.forEach(element => {
      rets.push(
        btoa(String.fromCharCode.apply(null,this.ToStorage(element)))
      );
    });

    return {data: rets};
  }

  FromStorages(data: any, callback: (GolfThemees: GolfTheme[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const golfThemees = new Array<GolfTheme>();
    const golfThemejs: {data: string[]} = data;
    try {

      golfThemejs.data.forEach(element => {
        const golfTheme = GolfTheme.deserializeBinary( new Uint8Array([...atob(element)].map(char => char.charCodeAt(0)))  );
        golfThemees.push ( golfTheme );
      });

      callback( golfThemees, null);
    } catch (error) {
      callback( null, error);
    }
  }
}
