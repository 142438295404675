import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { desktopMode, desktopModeURL } from '../../config/type';
import { DesktopMenuType } from '../../libs/proto/commUnity_pb';

@Injectable()
export class MobileGuard implements CanActivate{

  constructor(
    private route: Router,
    private detector: DeviceLibService) {}

  /**
   * validate mobile
   * @param route active route
   * @param state route state
   */
  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.detector.isMobile() && desktopMode === DesktopMenuType.NODESKTOP.valueOf()) {
      if ((desktopModeURL + '') !== '') {
        location.href = desktopModeURL;
        return;
      }
      this.route.navigate(['/not-mobile'], {skipLocationChange: true});
      return false;
    }

    return true;
  }
}
