import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Observable, Subscriber } from 'rxjs';
import { News, Empty } from '../../../libs/proto/commUnity_pb';
import * as grpcWeb from 'grpc-web';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { NewsConversionService } from '../../conversion/news/news-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcNewsLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: NewsConversionService,
  ) { }

  getNews(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNewsOffline(opt);
      } else {
        this._getNewsOnline(opt);
      }
    }).toPromise();
  }

  private _getNewsOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s') || this.storLib.get('news')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNewsOnline(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNewsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNewsOnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNewsOnlineData(r, option);
      })
      .on('end', () => {
        this._getNewsOnlineEnd(option);
      });
  }

  private _getNewsOnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNewsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNewsOnline(option);
    }, option);
  }

  private _getNewsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNewsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNewsOnline(option);
      }, option);
    }
  }

  getNews2(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews2Offline(opt);
      } else {
        this._getNews2Online(opt);
      }
    }).toPromise();
  }

  private _getNews2Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s2') || this.storLib.get('news2')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews2Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews2(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews2OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews2OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews2OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews2OnlineEnd(option);
      });
  }

  private _getNews2OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews2OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews2Online(option);
    }, option);
  }

  private _getNews2OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews2OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news2 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news2', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews2Online(option);
      }, option);
    }
  }

  getNews3(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews3Offline(opt);
      } else {
        this._getNews3Online(opt);
      }
    }).toPromise();
  }

  private _getNews3Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s3') || this.storLib.get('news3')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews3Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews3(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews3OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews3OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews3OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews3OnlineEnd(option);
      });
  }

  private _getNews3OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews3OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews3Online(option);
    }, option);
  }

  private _getNews3OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews3OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news3 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news3', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews3Online(option);
      }, option);
    }
  }

  getNews4(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews4Offline(opt);
      } else {
        this._getNews4Online(opt);
      }
    }).toPromise();
  }

  private _getNews4Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s4') || this.storLib.get('news4')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews4Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews4(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews4OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews4OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews4OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews4OnlineEnd(option);
      });
  }

  private _getNews4OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews4OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews4Online(option);
    }, option);
  }

  private _getNews4OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews4OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news4 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news4', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews4Online(option);
      }, option);
    }
  }

  getNews5(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews5Offline(opt);
      } else {
        this._getNews5Online(opt);
      }
    }).toPromise();
  }

  private _getNews5Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s5') || this.storLib.get('news5')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews5Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews5(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews5OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews5OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews5OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews5OnlineEnd(option);
      });
  }

  private _getNews5OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews5OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews5Online(option);
    }, option);
  }

  private _getNews5OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews5OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news5 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news5', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews5Online(option);
      }, option);
    }
  }

  getNews6(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews6Offline(opt);
      } else {
        this._getNews6Online(opt);
      }
    }).toPromise();
  }

  private _getNews6Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s6') || this.storLib.get('news6')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews6Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews6(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews6OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews6OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews6OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews6OnlineEnd(option);
      });
  }

  private _getNews6OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews6OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews6Online(option);
    }, option);
  }

  private _getNews6OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews6OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news6 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news6', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews6Online(option);
      }, option);
    }
  }

  getNews7(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews7Offline(opt);
      } else {
        this._getNews7Online(opt);
      }
    }).toPromise();
  }

  private _getNews7Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s7') || this.storLib.get('news7')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews7Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews7(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews7OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews7OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews7OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews7OnlineEnd(option);
      });
  }

  private _getNews7OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews7OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews7Online(option);
    }, option);
  }

  private _getNews7OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews7OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news7 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news7', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews7Online(option);
      }, option);
    }
  }

  getNews8(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews8Offline(opt);
      } else {
        this._getNews8Online(opt);
      }
    }).toPromise();
  }

  private _getNews8Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s8') || this.storLib.get('news8')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews8Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews8(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews8OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews8OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews8OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews8OnlineEnd(option);
      });
  }

  private _getNews8OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews8OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews8Online(option);
    }, option);
  }

  private _getNews8OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews8OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news8 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news8', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews8Online(option);
      }, option);
    }
  }

  getNews9(option?: GrpcLibServiceOption): Promise<News[]>{
    return new Observable<News[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getNews9Offline(opt);
      } else {
        this._getNews9Online(opt);
      }
    }).toPromise();
  }

  private _getNews9Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('news-s9') || this.storLib.get('news9')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getNews9Online(option: GrpcLibServiceOption){
    let t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getNews9(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getNews9OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getNews9OnlineStatus(s, option);
      })
      .on('data', (r: News) => {
        this._getNews9OnlineData(r, option);
      })
      .on('end', () => {
        this._getNews9OnlineEnd(option);
      });
  }

  private _getNews9OnlineData(ret: News, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getNews9OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getNews9Online(option);
    }, option);
  }

  private _getNews9OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getNews9OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.news9 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'news9', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getNews9Online(option);
      }, option);
    }
  }
}
