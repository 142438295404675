import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { desktopMode } from '../config/type';
import { DesktopMenuType, MenuName } from '../libs/proto/commUnity_pb';
import { UserLibService } from '../service/user/user-lib.service';
import { ToolbarComponent } from '../component/toolbar/toolbar.component';
import { GrpcNewsLibService } from '../service/grpc/news/grpc-news-lib.service';
import { NewsPathConverter } from '../service/conversion/news/news-path-converter';
import { StreamPathConverter } from '../service/conversion/stream/stream-path-converter';
import { LinksPathConverter } from '../service/conversion/links/links-path-converter';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.sass']
})
export class SidenavComponent implements OnInit {

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  isMobile = true;
  canOpen = true;

  @Input() page: string;  
  @Input() pageTitle: string;
  @Input() menuType: number;
  @Input() data: string;
  @Input() noBack: boolean;
  @Input() source: any;
  @Input() noCart: string;

  @Output() closePay = new EventEmitter();
  @Output() eventsType = new EventEmitter();
  @Output() evtsortgolf = new EventEmitter();
  @Output() evtaddprecheckin = new EventEmitter();
  @Output() closePayment = new EventEmitter();

  constructor(
    private observer: BreakpointObserver,
    private userlib: UserLibService,
    private newsLib: NewsPathConverter,
    private streaLib: StreamPathConverter,
    private linkLib: LinksPathConverter,
  ) {}

  get menu():MenuName | null {
    return ToolbarComponent.getMenuItem(this.page, 
        this.newsLib.newsIndex, this.streaLib.StreamIndex,
        this.linkLib.linksIndex);
  }

  ngOnInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((screenSize) => {
      if(screenSize.matches || (desktopMode.toString() == DesktopMenuType.DEFAULTDESKTOP.toString())){
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

      this.userlib.loadMenuData();
    });
  }
  eventChange(sender: any) {
    this.eventsType.emit(sender);
  }  
  toggle() {
    this.sidenav.toggle();
  }
  closepaymenttoolbar(){
    this.closePayment.emit();
  }
  eventsortgolf(type: any) {
    this.evtsortgolf.emit(type);
  }

  get show_burger_menu() {
    return this.page != 'disclaimer' && this.userlib.Data.token?.getCustomer().getShowburgermenu();
  }
}
