import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { Router } from '@angular/router';
import { menu_type_data, menu_type_def } from './menu_type_def';


@Component({
  selector: 'app-menu-type-1',
  templateUrl: 'menu_type_1.html',
  styleUrls: ['./menu_type_1.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class menu_type_1 extends menu_type_def { 

  constructor(
    private _userLib: UserLibService,    
    private _storageLib: StorageLibService,
    private _route: Router,
  ){
    super(_userLib, _storageLib, _route);
    
  }
}
