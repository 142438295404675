import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductOrderLine, ProductOrder, PaymentStatus } from '../../../libs/proto/shop_pb';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLibService } from '../../../service/user/user-lib.service';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { GrpcOrderLibService } from '../../../service/grpc/order/grpc-order-lib.service';
import { Color } from '../../../libs/proto/commUnity_pb';
import { ProductToRemove } from '../../../libs/proto/mobile_pb';
import { Platform } from '@angular/cdk/platform';
import { MealForm } from '../confirm/menu-confirm.component';
import { RestaurantMealConversionService } from '../../../service/conversion/restaurant/resto-meal-conversion.service';
import { Meal, RestaurantOrderLine, RestaurantProduct } from '../../../libs/proto/restaurant_pb';
import { GrpcRestaurantLibService } from '../../../service/grpc/restaurant/grpc-resto-lib.service';
import { GrpcLibService } from '../../../service/grpc/grpc-lib.service';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-alcohol',
  templateUrl: './alcohol.component.html',
  styleUrls: ['./alcohol.component.sass']
})
export class AlcoholComponent implements OnInit {

  @Output() closeBox = new EventEmitter();

  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private storeLib: StorageLibService,
    private userLib: UserLibService,
    private convLib: RestaurantMealConversionService,
    private grpcOrerLib: GrpcOrderLibService,
    private grpcLib: GrpcRestaurantLibService,
    private dlgLib: DialogServiceService,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private platform: Platform
  ) { }

  ngOnInit(): void {}

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }
  get isSafari() {
    return this.platform.SAFARI;
  }

  close(ok?: boolean) {
    this.closeBox.emit(ok);
  }
}
