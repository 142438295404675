import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pipeTableName' })
export class TableNamePipe implements PipeTransform {

  transform(value: string, tname: string): string {
    if (value && tname) {
      const newValue = value.replace(/%1/g, tname);
      return ` - ${newValue}`;
    }
    return '';
  }
}
