<app-sidenav page="competitions" menuType>

<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="competitions$ | async as competitions ">
  <mat-action-list *ngFor="let competition of competitions" >
    <mat-card>
      <div (click)="open(competition)"  >

        <mat-card-header>
          <div mat-card-avatar class="img"
            [ngStyle]="{'background-image': 'url(' + competition.getImage() + ')'}" >
          </div>
          <mat-card-title> {{ competition.getTitle() }}</mat-card-title>
          <mat-card-subtitle>{{ competition.getShortdescription() }}</mat-card-subtitle>
          <mat-card-subtitle>{{ competition.getDate() | amParse: 'YYYYMMDD' | amDateFormat: dateFormat}}</mat-card-subtitle>
        </mat-card-header>
      </div>
    </mat-card>

  </mat-action-list>

  </div>

</app-sidenav>