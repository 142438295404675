<app-sidenav page="events" menuType (eventsType)="eventChange($event)">

<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="events$ | async as events ">
  <mat-action-list *ngIf="isEventList" class="pt-0">

    <mat-card class="p-0"
      (click)="open(event)"
      *ngFor="let event of events; let i = index;">
      <div *ngIf="event?.getEvent().getDate()" class="table" [class]="i % 2 ? ' dark' : ''" (click)="open(event)"  >
        <div class="row" *ngIf="!isPast( event?.getEvent().getDate())">
          <div class="col col1">
            <div class="table">
              <div class="row inner">
                <div class="col date month">{{ getMonth( event?.getEvent().getDate()) }}</div>
              </div>
              <div class="row inner">
                  <div class="col date day">
                    <span>
                      {{ event?.getEvent().getDate() | amParse: 'YYYYMMDD' | amLocale: userLocale |amDateFormat: 'dd DD'}}
                    </span>
                  </div>
              </div>
              <mat-icon *ngIf="!isPast( event?.getEvent().getDate()) && event?.getEvent().getQt() != -1 && event?.getEvent().getQt() != -2"
              [class]="statusEventClass( event?.getAttending())"
              class="icon">{{ statusEventIcon( event?.getAttending()) }}</mat-icon>
        </div>
          </div>
          <div class="col border">
            <div>
              <mat-card-title class="title"> {{ event?.getEvent().getTitle() }}</mat-card-title>
              <mat-card-subtitle>{{ event?.getEvent().getShortdescription() }}</mat-card-subtitle>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

  </mat-action-list>

  <div *ngIf="!isEventList">
    <div class="table">
      <div class="row">
        <button class="col" (click)="prevMonth()" mat-button>
          <mat-icon>chevron_left</mat-icon>
        </button>
        <h2 class="top col">
          {{ calendarDate | calendarDate:(calendarView + 'ViewTitle'): userLocale }}
        </h2>
        <button class="col" (click)="nextMonth()" mat-button>
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div [ngSwitch]="calendarView">
      <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [cellTemplate]="cellTemplate"
      [headerTemplate]="headTemplate"
      [locale]="userLocale"
      [viewDate]="calendarDate"
      [events]="calendarEvents"
      [refresh]="calendarRefresh"
      [activeDayIsOpen]="calendarActiveDayIsOpen"
      weekStartsOn="1"
      (dayClicked)="calendarDayClicked($event.day)">
    </mwl-calendar-month-view>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #cellTemplate let-day="day" let-locale="locale" >
  <div class="col-day" [ngClass]="{'cal-selected': selectedEvent(day.date |amDateFormat: 'YYYYMMDD')}">
      <span>
        {{ day.date | calendarDate:'monthViewDayNumber':locale }}
      </span>
  </div>
  <span class="evt-block" *ngIf="countEvent(day.date) > 0">
    <span class="evt evt1"></span>
    <span class="evt evt2" *ngIf="countEvent(day.date) > 1"></span>
  </span>
</ng-template>

<ng-template #headTemplate let-days="days" let-locale="locale">
  <span class="col-weekday" *ngFor="let d of days">
    {{ (d.date | amLocale: locale | amDateFormat: 'dd') | uppercase }}
  </span>

</ng-template>

</app-sidenav>