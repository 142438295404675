import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { PostConversionService } from '../../conversion/post/post-conversion-service';
import { Post, NewPost } from 'src/app/libs/proto/forum_pb';
import { Observable } from 'rxjs';
import { RequestID, Empty } from 'src/app/libs/proto/commUnity_pb';
import * as grpcWeb from 'grpc-web';

import { ConfigLibService } from '../../config/config-lib.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcPostLibService {


  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: PostConversionService,
    private configService: ConfigLibService,

  ) {


  }

  getPosts(option?: GrpcLibServiceOption): Promise<Post[]>{
    return new Observable<Post[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getPostsOffline(opt);
      } else {
        this._getPostsOnline(opt);
      }
    }).toPromise();
  }

  private _getPostsOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      this.storLib.get('post'), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getPostsOnline(option: GrpcLibServiceOption){
    const r = new RequestID();
    r.setId(option.call.req);
    this.grpcLib.MobileClient.getPosts(r, {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getPostsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getPostsOnlineStatus(s, option);
      })
      .on('data', (r: Post) => {
        this._getPostsOnlineData(r, option);
      })
      .on('end', () => {
        this._getPostsOnlineEnd(option);
      });
  }
  private _getPostsOnlineData(ret: Post, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getPostsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getPostsOnline(option);
    }, option);
  }

  private _getPostsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getPostsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.post = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'post', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    }
  }

  addPost(req: NewPost): Observable<NewPost[]>{
    const rets = new Array<NewPost>();
    return new Observable<NewPost[]>(obs => {
      const call= this.grpcLib.MobileClient.addPost(
        req,
        {token: this.userLib.Data.token?.getToken()},
        (err: grpcWeb.RpcError, empty: Empty)=>{}
      );
      call
      .on('error', (err:grpcWeb.RpcError)=>{
        err.message = 'error addNewPost';
        obs.error(err);
      })
      .on('status', (status: grpcWeb.Status) => {})
        .on('data', (response: Empty) => {
          obs.next(rets);
          obs.complete();
        })
        .on('end', () => {
          obs.complete();
        });
    });
  }}
