import { Component, OnInit } from '@angular/core';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { Router } from '@angular/router';
import { GrpcAuthenLibService } from '../../service/grpc/authen/grpc-authen-lib.service';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Loginform } from '../../class/login/loginform';
import { UserLibService } from '../../service/user/user-lib.service';
import { GrpcAdsLibService } from '../../service/grpc/ads/grpc-ads-lib.service';
import { MemberToken, GroupCustomers, CustomerMobile, SignInMobileRequest } from '../../libs/proto/mobile_pb';
import { GrpcCustomerLibService } from '../../service/grpc/customer/grpc-customer-lib.service';
import { ConfigLibService } from '../../service/config/config-lib.service';
import { MobileApp, Customer, Group, LoginTemplate } from '../../libs/proto/commUnity_pb';
import { DialogServiceService } from '../../service/dialog/dialog-service.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PromptComponent } from '../prompt/prompt.component';
import { Mobile } from '../../libs/proto/mobile_pb_service';
import { GrpcGroupLibService } from '../../service/grpc/customer/grpc-group-lib.service';
import { GrpcProductLibService } from '../../service/grpc/product/grpc-product-lib.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GrpcEventLibService } from 'src/app/service/grpc/event/grpc-event-lib.service';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { Platform } from '@angular/cdk/platform';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { GrpcNewsLibService } from 'src/app/service/grpc/news/grpc-news-lib.service';
import { GrpcMemberLibService } from 'src/app/service/grpc/member/grpc-member-lib.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  ismobile = false;

  hideLogin = true;
  hideGroup = true;

  loginForm: Loginform;
  logoURL: string;
  bgURL: string;
  bgSize: string;
  showPassword: boolean;
  waiting: boolean;

  customerLoading = true;
  appStopped = false;

  customer?: Customer;
  customers: CustomerMobile[] = [];
  customerGroup?: Group;

  constructor(
    private translateService: TranslateService,
    private userLib: UserLibService,
    private grpcAuthLib: GrpcAuthenLibService,
    private grpcGrpLib: GrpcGroupLibService,
    private grpcCusLib: GrpcCustomerLibService,
    private grpcProdLib: GrpcProductLibService,
    private grpcNewsLib: GrpcNewsLibService,
    private grpcMemberLib: GrpcMemberLibService,
    private configLib: ConfigLibService,
    private grpcEventLib: GrpcEventLibService,
    private dialogLib: DialogServiceService,
    private bottomSheet: MatBottomSheet,
    private detector: DeviceLibService,
    private storeLib: StorageLibService,
    private route: Router) {
      this.loginForm = new Loginform();
      this.loginForm.email = userLib.Data.login?.getUsername();
      this.loginForm.password = userLib.Data.login?.getPassword();
    }
  
  // manage redirect link after guest / normal signin
  checkredirectlink() {
    var link = location.href.match(/\/event\/[a-z0-9]{5,}/g);
    if (link) return link;

    link = location.href.match(/\/golf_class\/[a-z0-9]{5,}/g);
    if (link) return link;

    link = location.href.match(/\/new[0-9]?\/[a-z0-9]{5,}/g);
    if (link) return link;

    link = location.href.match(/\/benefit[0-9]?\/[a-z0-9]{5,}/g);
    if (link) return link;

    link = location.href.match(/\/album[0-9]?\/[a-z0-9]{5,}/g);
    if (link) return link;

    return '';
  }
    
  ngOnInit(): void {

    this.logoURL = '/assets/icons/icon-128x128.png';
    this.bgURL = '/assets/images/login_bg.webp';

    const relink = this.checkredirectlink();  
    if (relink){
      this.storeLib.set('f-redirect', relink);
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let lg = this.detector.lang(location.href);
    this.translateService.use(lg);

    // reset
    this.userLib.clearTemporary();
    if (this.storeLib.get('f-signout') === 'Y') {
      this.userLib.Data.signOut = true;
      this.storeLib.set('f-signout', '');
   }

    if (this.userLib.userApp === MobileApp.COMMUNITYLIGHT) {
      this.showLogin();
      return;
    }

    if (this.userLib.Data.selectedCustomer) {
      this.customer = this.userLib.Data.selectedCustomer;
      this.storeLib.set('s-cus', this.customer?.getId());
      this.autoSigninOrShowLogin();
      return;
    }

    this.getGroup().then( g => {
      this.customerGroup = g;
      this.userLib.Data.selectedGroup = g;

      this.getCustomers(g.getId()).then( cs => {

        if (cs.length === 0) {

          this.getDefaultCustomer().then( c => {
            this.customer = c;
            this.autoSigninOrShowLogin();
          });

        } else {
          this.customers = cs;
          this.showGroup();
        }

      }).catch( e => {
          this.showError(e);
      });

    }).catch( e => {
      this.showError(e);
    });
  }

  private autoSigninOrShowLogin() {
    // if just signout, show login.
    if (this.userLib.Data.signOut) {
      this.showLogin();
      return;
    }

    // check if already login?
    this.userLib.getUserCustomer( l => {

        if (l.getUsername() && l.getPassword()) {
          this.signinAuto(l);
          return;
        }

        this.checkAutoGuest().then( auto => {

          if (auto) {
            this.signinGuest();
            return;
          }

          this.showLogin();
        });
    });
  }

  private showError(e: any) {
    this.translateService.get([
      'Login.err_load',
      'Login.err_refer'
    ]).toPromise().then( t => {
      let tt = t['Login.err_load'];
      if (e.refer === 'refer-fail') {
        tt = t['Login.err_refer'].replace('%s', e.u);
      }
      this.dialogLib.show(
        tt, null, r => {
        this.appStopped = true;
        this.customerLoading = false;
      });
    });
  }
  private showGroup() {
    this.hideGroup = false;
    this.customerLoading = false;
  }
  private showLogin() {
    this.setupCustomerImages();

    this.hideLogin = false;
    this.hideGroup = true;
    this.customerLoading = false;

    this.isLandscape = this.detector.orientation === 'landscape';
    this.ismobile = this.getViewType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.ismobile = this.getViewType();
    });
  }
  getViewType() {
    const ismobile = this.detector.isMobile();
    if (ismobile && this.isLandscape) { return false; }
    return ismobile;
  }
  private get loginBg() {
    if (!this.detector.isMobile()) {
      return this.customer?.getLogindesktopbackgroundphoto();
    }
    return this.customer?.getLoginbackgroundphoto();
  }
  private setupCustomerImages() {
    if (this.loginBg || '') {
        this.bgURL = this.loginBg;
    }
    if (this.customer?.getLogo() || '') {
      this.logoURL = this.customer?.getLogo();
    } else {
      this.logoURL = '';
    }

    if (!this.detector.isMobile()) {
      this.bgSize = (this.customer && this.customer.getLoginbackgrounddesktopphotoskretch() || false) ? 'cover' : '';
    } else {
      this.bgSize = (this.customer && this.customer.getLoginbackgroundphotoskretch() || false) ? 'cover' : '';
    }
  }

  private getGroup(): Promise<Group> {
    return new Promise<Group>( (ok, ko) => {
      this.grpcGrpLib.getGroup({
        Offline: this.storeLib.cache.group || false,
      }).then( g => {
        // TODO:
        if (environment.production) {
          if ((g.getRefererurl() || this.hostname)  !== this.hostname) {
            ko({refer: 'refer-fail', u: g.getRefererurl()});
            return;
          }
        }
        ok(g);
      }).catch( e => {
        ok(null);
      });
    });
  }

  private get hostname(): string {
    return [location.protocol, '//', location.host].join('');
  }

  private getCustomers(id: string): Promise<CustomerMobile[]> {
    const thise = this;
    return new Promise<CustomerMobile[]>( (ok, ko) => {

      if (id === '') {
        ok([]);
        return;
      }

      thise.grpcCusLib.getCustomers({
        Offline: this.storeLib.cache.customer || false,
        call: {
          req: id
        }
      }).then( c => {

        if (c.getCustomersList().length === 0) {

          ok([]);

        } else {
          ok(c.getCustomersList());
        }
      }).catch( e => {
          ko(e);
      });
    });
  }

  private getDefaultCustomer(): Promise<Customer> {
    return new Promise<Customer>( (ok, ko) => {

      this.grpcCusLib.getCustomer().then( c => {
        ok(c);

      }).catch( e => {
        ok(null);
      });
    });
  }

  /**
   *
   * @param c customer
   * @returns true if autoguest active
   */
  checkAutoGuest(): Promise<boolean> {
    return new Promise<boolean>( (ok, ko) => {
      if (this.canSignInGuest)  {
        if (this.customer?.getAutoguest() || false) {
          ok(true);
          return;
        }
      }
      ok(false);
    });
  }

  get canSignInGuest() {
    return ((this.customer?.getGuestusername() || '') !== '') &&
           ((this.customer?.getGuestuserpwd() || '') !== '');
  }

  get showSignInGuest() {
    return this.canSignInGuest && !(this.customer?.getAutoguest() || false);
  }

  get canSignUp() {
    return this.customer?.getCansignup() || false;
  }

  getProducts(v: MemberToken) {
    if (this.userLib.Data.producstPage?.id) {

      this.grpcProdLib.getProducts().then( p => {
        this.getNews(v);
      });

    } else {
      this.getNews(v);
    }
  }

  getNews(v: MemberToken) {
    if (this.userLib.Data.newsPage?.id) {

      this.grpcNewsLib.getNews().then( p => {
        this.getNews2(v);
      });

    } else {
      this.getNews2(v);
    }
  }
  getNews2(v: MemberToken) {
    if (this.userLib.Data.news2Page?.id) {

      this.grpcNewsLib.getNews2().then( p => {
        this.getNews3(v);
      });

    } else {
      this.getNews3(v);
    }
  }
  getNews3(v: MemberToken) {
    if (this.userLib.Data.news3Page?.id) {

      this.grpcNewsLib.getNews3().then( p => {
        this.getNews4(v);
      });

    } else {
      this.getNews4(v);
    }
  }
  getNews4(v: MemberToken) {
    if (this.userLib.Data.news4Page?.id) {

      this.grpcNewsLib.getNews4().then( p => {
        this.getNews5(v);
      });

    } else {
      this.getNews5(v);
    }
  }
  getNews5(v: MemberToken) {
    if (this.userLib.Data.news5Page?.id) {

      this.grpcNewsLib.getNews5().then( p => {
        this.getNews6(v);
      });

    } else {
      this.getNews6(v);
    }
  }
  getNews6(v: MemberToken) {
    if (this.userLib.Data.news6Page?.id) {

      this.grpcNewsLib.getNews6().then( p => {
        this.getNews7(v);
      });

    } else {
      this.getNews7(v);
    }
  }
  getNews7(v: MemberToken) {
    if (this.userLib.Data.news7Page?.id) {

      this.grpcNewsLib.getNews7().then( p => {
        this.getNews8(v);
      });

    } else {
      this.getNews8(v);
    }
  }
  getNews8(v: MemberToken) {
    if (this.userLib.Data.news8Page?.id) {

      this.grpcNewsLib.getNews8().then( p => {
        this.getNews9(v);
      });

    } else {
      this.getNews9(v);
    }
  }
  getNews9(v: MemberToken) {
    if (this.userLib.Data.news9Page?.id) {

      this.grpcNewsLib.getNews9().then( p => {
        this.getMembers(v);
      });

    } else {
      this.getMembers(v);
    }
  }

  getMembers(v: MemberToken) {
    if (this.userLib.Data.memberPage?.id) {

      this.grpcMemberLib.getMember().then( p => {
        this.userLib.afterLogin(v);
      });

    } else {
      this.userLib.afterLogin(v);
    }
  }

  login(){
    const thise = this;
    this.grpcAuthLib.signIn(this.loginForm.email, this.loginForm.password).then( v => {

      // keep user/pass
      this.userLib.setUser(this.loginForm.email, this.loginForm.password);

      // goto disclaimer?
      if (this.userLib.checkDisclaimer(v)) { return; }

      this.getProducts(v);

    }).catch( e => {
      thise.translateService.get([
        'Login.title',
        'Login.not_ok',
      ]).toPromise().then( t => {
        this.dialogLib.show(t['Login.not_ok'], t['Login.title']);
      });
    });
  }
  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  forgetPassword() {
    const thise = this;
    if (this.loginForm.email.indexOf('@') >= 0) {
      if (!this.validEmail()) {
        this.translateService.get('Login.forget_nouser').toPromise().then( t => {
          thise.dialogLib.show(t);
        });
        return;
      }
    } else if (!this.validUsername()) {
      this.translateService.get('Login.forget_invalid_user').toPromise().then( t => {
        thise.dialogLib.show(t);
      });
      return;
    }
    this.translateService.get([
      'Login.forget_sent',
      'Login.forget_error'
    ]).toPromise().then( t => {
      thise.grpcAuthLib.forgotPassword(thise.loginForm.email).then( v => {
        thise.dialogLib.show(t['Login.forget_sent']);
      }).catch( e => {
        thise.dialogLib.show(t['Login.forget_error']);
      });
    });
  }

  signUp() {
    this.route.navigateByUrl('/signup');
  }

  signinAuto(l: SignInMobileRequest) {
    const thise = this;

    this.grpcAuthLib.signIn(l.getUsername(), l.getPassword() ).then( v => {
      thise.userLib.afterLogin(v, true);

      thise.treatRediret();
    }).catch( e => {
      thise.customerLoading = false;
      thise.customer?.setAutoguest(false);
      // thise.appStopped = true;
      thise.hideLogin = false;
      thise.hideGroup = true;
      // thise.dialogLib.show( e.message || JSON.stringify(e));
    });

  }
  treatRediret() {
    this.grpcEventLib.getEvent({
      Offline: this.storeLib.cache.event || false,
    }).then( es => {
      this.customerLoading = false;
      const alink = this.storeLib.get('f-redirect') + '';

      if (alink) {
        this.storeLib.set('f-redirect', '');
        this.route.navigateByUrl(alink);
      } else {
        this.route.navigateByUrl('/home');
      }

    }).catch( (e: Error) => {
      this.customerLoading = false;
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      this.route.navigateByUrl('/home');
    });
  }

  signinGuest() {
    const thise = this;
    this.grpcAuthLib.signIn(this.customer.getGuestusername(), this.customer?.getGuestuserpwd()).then( v => {
      thise.userLib.afterLogin(v, true);

      if (thise.storeLib.get('table-id-1st-signin') === 'y' && thise.storeLib.get('table-id')) {
          thise.customerLoading = false;
          thise.route.navigateByUrl('/resto/' + thise.storeLib.get('table-id'));
          thise.storeLib.set('table-id-1st-signin', '');
          return;
      }
      thise.treatRediret();
    }).catch( e => {
      thise.customerLoading = false;
      thise.customer?.setAutoguest(false);
      // thise.appStopped = true;
      thise.hideLogin = false;
      thise.hideGroup = true;
      // thise.dialogLib.show( e.message || JSON.stringify(e));
    });
  }

  get diagnostic() {
    if (environment.production) { return; }
    return JSON.stringify(this.loginForm.value);
  }

  validUsername() {
    const a = /[^a-z0-9-]+/gi.exec(this.loginForm.email) || [];
    return (a.length === 0) && (this.loginForm.email.length >= 5);
  }
  validEmail() {
    const ar = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const a = ar.exec(this.loginForm.email) || [];
    return (a.length > 0) && (this.loginForm.email.length >= 5);
  }

  reload() {
    location.reload();
  }
  setCustomer(id: string) {
    const thise = this;
    this.grpcCusLib.getCustomer({
      call: {
        req: id
      }
    }).then( c => {
      if (c.getId()) {
        thise.userLib.Data.selectedCustomer = c;
        this.storeLib.set('s-cus', c.getId());
        thise.customer = c;
        thise.autoSigninOrShowLogin();
      }
    }).catch( e => {

    });
  }
  get groupLogo() {
    return this.userLib.Data.selectedGroup?.getLogo();
  }
  toGroup() {
    this.userLib.Data.selectedGroup = undefined;
    this.userLib.Data.selectedCustomer = undefined;
    this.storeLib.set('s-cus', '');
    this.userLib.clearGroup();
    location.reload();
  }

  get themeClass(): string {
    const c = this.customer;
    return c && c.getLoginforegroundcolor() === LoginTemplate.WHITE ? 'light-login' : 'dark-login';
  }

  get customerTextColor(): string {
    const c = this.userLib.Data.selectedGroup;
    return c && c.getCustomertextcolor() || 'white';
  }

  get showCustomerText() {
    const c = this.userLib.Data.selectedGroup;
    return c && c.getShowcustomername();
  }
}
