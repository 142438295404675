import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, timer, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FirebaseComponent } from './firebase/firebase.component';
import { LogLibService } from '../log/log-lib.service';
import { StorageLibService } from '../storage/storage-lib.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from './notification/notification.component';
import { GrpcAuthenLibService } from '../grpc/authen/grpc-authen-lib.service';
import { GrpcUserService } from '../grpc/user/grpc-user.service';
import { Platform } from '@angular/cdk/platform';
import { UserLibService } from '../user/user-lib.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  token: string;
  customerId: string;
  issubscribe: boolean;
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private bottomSheet: MatBottomSheet,
    private snackbar: MatSnackBar,
    private storeLib: StorageLibService,
    private userLib: UserLibService,
    private grpcLib: GrpcUserService,
    private platform: Platform
  ) {
      const thise = this;
      angularFireMessaging.tokenChanges.toPromise().then( (t) => {
        thise.updateToken(t);
      });
  }

  private updateToken(t: string) {
    this.token = t;
    if (this.userLib.Data.token?.getProfile().getFcmtoken() === this.token) { return; }

    const thise = this;
    this.grpcLib.updateFCMToken({
      call: {
        req: t
      }
    }).finally( () => {
      this.userLib.Data.token?.getProfile().setFcmtoken(t);
      this.userLib.updateUserData();
    });
  }

  subscribeMessage(): Promise<any>{
    const thise = this;
    return new Promise<any>( (ok, ko) => {
      thise.angularFireMessaging.onMessage( (m) => {
          this.showNotification(m);
      });
    });
  }

  private showNotification(m: any) {
    if (this.platform.IOS) { return; }

    this.snackbar.openFromComponent( NotificationComponent, {

      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: m
    });
  }

  requestToken() {
    const thise = this;
    this.angularFireMessaging.requestPermission.toPromise().then( v => {
      thise.angularFireMessaging.getToken.toPromise().then( t => {
        if (t) {
          thise.updateToken(t);
        } else {
          thise.showRequest();
        }
      });
    }).catch( e => {
      thise.showRequest();
    });
  }

  private showRequest(){
    if (this.platform.IOS) { return; }
    if ((this.storeLib.get('fcm') || '') !== '') { return; }

    const thise = this;
    timer(3000)
      .pipe(take(1)).toPromise().then( v => {
        this.bottomSheet.open(FirebaseComponent, {
          data: { }});
        timer(5000).pipe(take(1)).subscribe( () => {
          this.bottomSheet.dismiss();
        });
      });
  }
}
