import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

interface FCMMessage {
  collapse_key: string;
  data: any;
  from: string;
  notification: {
    body: string;
    tag: string;
    title: string;
    icon: string;
    image: string;
  }
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass']
})
export class NotificationComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: FCMMessage,
    private snackRef: MatSnackBarRef<NotificationComponent>,
  ) { }

  ngOnInit(): void {

  }

  close(){
    this.snackRef.dismiss();
  }
  reload() {
    location.reload();
  }
}
