<div class="box-alcohol">
  <div class="bg"></div>
  <div class="dlg">
    <div class="subtitle">
      <label>{{ 'alcohol.subtitle' | translate }}</label>
    </div>
    <div class="icon">-18</div>
      <div class="title">
        <label>{{ 'alcohol.title' | translate }}</label>
      </div>
      <p [innerHTML]="'alcohol.text' | translate">
      </p>
      <button (click)="close()" mat-raised-button>{{ 'alcohol.button' | translate }}</button>
      <div class="footer">
        <label>{{ 'alcohol.footer' | translate }}</label>
      </div>
  </div>
</div>

