import { Injectable } from '@angular/core';
import { BinaryWriter } from 'google-protobuf';
import { Stream } from '../../../libs/proto/commUnity_pb';

@Injectable({
  providedIn: 'root'
})
export class StreamConversionService {

  constructor() { }

  ToStorage(req: Stream) {
    const w = new BinaryWriter();
    Stream.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (Stream: Stream, errorConvert: any) => void) {
    try {
      callback( Stream.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }

  ToStorages(streams: Stream[]) {
    const rets = new Array<Uint8Array | number[]>();

    streams.forEach(element => {
      rets.push(
        this.ToStorage(element)
      );
    });

    return {data: rets};
  }

  FromStorages(data: any, callback: (streams: Stream[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const emps = new Array<Stream>();
    const empjs: {data: (Uint8Array)[]} = data;
    try {

      empjs.data.forEach(element => {
        const emp = Stream.deserializeBinary( element  );
        emps.push ( emp );
      });

      callback( emps, null);
    } catch (error) {
      callback( null, error);
    }
  }
}
