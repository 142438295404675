export class Loginform {
  email: string;
  password: string;

  get value(): {} {
    return {email: this.email || '',password:this.password || ''};
  }
  get valid(): boolean {
     return ((this.email || '').length > 0) && ((this.password || '').length > 0);
  }
}
