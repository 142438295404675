import { Pipe, PipeTransform } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Pipe({ name: 'pipeWebp' })
export class WebpPipe implements PipeTransform {
  constructor(
    private platform: Platform
  ) {}
  transform(value: string, type?: string): string {
    if (value && this.platform.IOS) {
      let newValue = value;
      const q1 = 'c-d=' + (new Date()).toDateString().replace(/ /g, '');
      if (newValue.indexOf('?') >= 0) { newValue += '&' + q1;  } else { newValue += '?' + q1; }

      return `${newValue}`;
    } else {
      return value;
    }
  }
}
