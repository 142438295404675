export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDC66_f9ZMBub_0d8LAwhvp32JT0t2RQ-0',
    authDomain: 'community-df8f5.firebaseapp.com',
    databaseURL: 'https://community-df8f5.firebaseio.com',
    projectId: 'community-df8f5',
    storageBucket: 'community-df8f5.appspot.com',
    messagingSenderId: '24488715767',
    appId: '1:24488715767:web:8bb74b0a7922f560fb7af2',
    measurementId: 'G-KQMSBJ2Q7G'
  }
};
