import { Injectable } from "@angular/core";
import { GrpcLibServiceOption } from "../../grpc/grpc-lib.service";
import { GrpcNewsLibService } from "../../grpc/news/grpc-news-lib.service";

@Injectable({
  providedIn: 'root'
})
export class NewsPathConverter {

  constructor(
    private grpcNewsLib: GrpcNewsLibService,
  ){}
  /**
   * get current news index
   */
   get newsIndex() {
    const rets = location.pathname.match(/news?([0-9]{0,})/) || [];
    return rets[1] || '';
  }

  /**
   * get news
   * @returns function to get news
   */
   fnGetNews(option?: GrpcLibServiceOption) {
    switch (this.newsIndex) {
      case '2':
        return this.grpcNewsLib.getNews2(option);
      case '3':
        return this.grpcNewsLib.getNews3(option);
      case '4':
        return this.grpcNewsLib.getNews4(option);
      case '5':
        return this.grpcNewsLib.getNews5(option);
      case '6':
        return this.grpcNewsLib.getNews6(option);
      case '7':
        return this.grpcNewsLib.getNews7(option);
      case '8':
        return this.grpcNewsLib.getNews8(option);
      case '9':
        return this.grpcNewsLib.getNews9(option);
      default:
        return this.grpcNewsLib.getNews(option);
    }
  }
}
