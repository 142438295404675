import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { Empty, Member, RequestID } from '../../../libs/proto/commUnity_pb';
import { EventTableReservation, EventTableRequest, Table } from 'src/app/libs/proto/restaurant_pb';

@Injectable({
    providedIn: 'root'
})
export class GrpcReservationLibService {

    constructor(
        private grpcLib: GrpcLibService,
        private userLib: UserLibService,
    ) { }

    availableTables(id: string, num: number): Promise<Table[]> {
        return new Observable<Table[]>(obs => {
            const opt = this.grpcLib.getOption({
                call: {
                    req: id
                }
            });
            opt.call.subscribe = obs;

            this._availableTables(opt, num);
        }).toPromise();
    }

    private _availableTables(option: GrpcLibServiceOption, num: number) {
        const req = new EventTableRequest;
        req.setEventid(option.call.req);
        req.setNumberofattendees(num);

        this.grpcLib.MobileClient.getAvailableTables(req, {
            token: this.userLib.Data.token?.getToken()
        })
            .on('data', (r: Table) => {
                option.call.data.push(r);
            })
            .on('error', (e: grpcWeb.RpcError) => {
                option.call.subscribe.error(e);
            })
            .on('end', () => {
                option.call.subscribe.next(option.call.data);
                option.call.subscribe.complete();
            });
    }

    availableMembers(eventId: string): Promise<Member[]> {
        return new Observable<Member[]>(obs => {
            const opt = this.grpcLib.getOption({
                call: {
                    req: eventId
                }
            });
            opt.call.subscribe = obs;

            this._availableMembers(opt);
        }).toPromise();
    }

    private _availableMembers(option: GrpcLibServiceOption) {
        const req = new RequestID;
        req.setId(option.call.req);

        this.grpcLib.MobileClient.getAvailableMembers(req, {
            token: this.userLib.Data.token?.getToken()
        })
            .on('data', (r: Member) => {
                option.call.data.push(r);
            })
            .on('error', (e: grpcWeb.RpcError) => {
                option.call.subscribe.error(e);
            })
            .on('end', () => {
                option.call.subscribe.next(option.call.data);
                option.call.subscribe.complete();
            });
    }

    addEventTableReservation(eventId: string, tableId: string, attendees: Member[]) {
        return new Observable<Empty>(obs => {
            const opt = this.grpcLib.getOption();
            opt.call.subscribe = obs;

            this._addEventTableReservation(opt, eventId, tableId, attendees);
        }).toPromise();
    }

    private _addEventTableReservation(option: GrpcLibServiceOption, eventId: string, tableId: string, attendees: Member[]) {
        const req = new EventTableReservation;
        req.setEventid(eventId);
        req.setTableid(tableId);

        const members = attendees.map((v) => { return v.getId() });
        req.setMembersList(members);

        this.grpcLib.MobileClient.addEventTableReservation(req, {
            token: this.userLib.Data.token?.getToken()
        }, (e, _) => {

            if (e != null) {
                this.grpcLib.handleError(e, () => {
                    this._addEventTableReservation(option, eventId, tableId, attendees);
                }, option);
            } else {
                option.call.subscribe.next(true);
                option.call.subscribe.complete();
            }
        });
    }

    cancelEventTableReservation(eventId: string) {
        return new Observable<Empty>(obs => {
            const opt = this.grpcLib.getOption({
                call: {
                    req: eventId
                }
            });
            opt.call.subscribe = obs;

            this._cancelEventTableReservation(opt, eventId);
        }).toPromise();
    }

    private _cancelEventTableReservation(option: GrpcLibServiceOption, eventId: string) {
        const req = new RequestID;
        req.setId(option.call.req);

        this.grpcLib.MobileClient.cancelEventTableReservation(req, {
            token: this.userLib.Data.token?.getToken()
        }, (e, _) => {

            if (e != null) {
                this.grpcLib.handleError(e, () => {
                    this._cancelEventTableReservation(option, eventId);
                }, option);
            } else {
                option.call.subscribe.next(true);
                option.call.subscribe.complete();
            }
        });
    }

    getEventTableReservation(eventId: string): Promise<EventTableReservation> {
        return new Observable<EventTableReservation>(obs => {
            const opt = this.grpcLib.getOption({
                call: {
                    req: eventId
                }
            });
            opt.call.subscribe = obs;

            this._getEventTableReservation(opt, eventId);
        }).toPromise();
    }

    private _getEventTableReservation(option: GrpcLibServiceOption, eventId: string) {
        const req = new RequestID;
        req.setId(option.call.req);

        this.grpcLib.MobileClient.getEventTableReservation(req, {
            token: this.userLib.Data.token?.getToken()
        }, (e, res) => {

            if (e != null) {
                this.grpcLib.handleError(e, () => {
                    this._getEventTableReservation(option, eventId);
                }, option);
            } else {
                option.call.subscribe.next(res);
                option.call.subscribe.complete();
            }
        });
    }
}
