import { Pipe, PipeTransform } from '@angular/core';
import { RestaurantProduct } from '../../../libs/proto/restaurant_pb';
@Pipe({
    name: 'MenusByCategory',
    pure: true
})
export class MenusByCategory implements PipeTransform {

    transform(collection: Array<RestaurantProduct>): Array<any> {

        if (!collection) {
            return null;
        }
        const groupedCollection = collection
          .reduce((previous, current) => {
                const t = current.getCategoryid();
                if (!previous[t]) {
                    previous[t] = {items: [current],
                                   id: current.getCategoryid(),
                                   name: current.getCategoryname()};
                }
                else {
                    previous[t].items.push(current);
                }

                return previous;
        }, {});

        return Object.keys(groupedCollection).map(key => ({
            label: groupedCollection[key].name,
            id: groupedCollection[key].id,
            items: groupedCollection[key].items
        }));
    }
}
