import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductOrderLine } from '../../libs/proto/shop_pb';
import { UserLibService } from '../../service/user/user-lib.service';
import { OrderForm } from '../shop/confirm/product-confirm.component';

@Component({
  selector: 'app-ctl-qty-dialog',
  templateUrl: './ctl-qty-dialog.component.html',
  styleUrls: ['./ctl-qty-dialog.component.sass']
})
export class CtlQtyDialogComponent implements OnInit {
  @Input() showQty?: OrderForm;
  @Output() okclick = new EventEmitter();

  constructor(
    private userLib: UserLibService,
  ) { }

  ngOnInit(): void {
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }
  qtyOK() {
    if (+this.showQty?.qtyEdit === 0) {return; }
    this.okclick.emit(this.showQty);
    this.showQty.show = false;
  }

  qtyClose() {
    this.showQty.show = false;
  }
}
