<mat-card class="gclass" [ngClass]="{'disabled': isPast(c)}">
  <div style="padding: 0px 0px 10px 10px;" [ngClass]="{'readonly': readonly}">
      <table style="width:100%"><tr>
        <td style="font-weight: 500;font-size: 20px;">{{ c.getName() }}</td>
        <td [ngStyle]="{'width': toolbar_width(c, readonly) + 'px'}">
          <button style="top:-7px" mat-icon-button *ngIf="has_ics(c) && readonly"
            (click)="downloadICS(c)">
            <mat-icon class="icon-color">perm_contact_calendar</mat-icon>
          </button>
          <mat-icon *ngIf="c.getColor() == 0" [ngStyle]="{'color':colorlib.colorhtml(c.getColor())}" class="icon-nocolor">circle</mat-icon>
          <mat-icon *ngIf="c.getColor() != 0" [ngStyle]="{'color':colorlib.colorhtml(c.getColor())}" class="icon-color">circle</mat-icon>
          
          <button style="top:-7px" mat-icon-button *ngIf="canCancel(c) && readonly"
            (click)="cancel(c)">
            <mat-icon class="icon-color">delete</mat-icon>
          </button>
        </td>
      </tr></table>

      <span>{{ c.getDate() | amParse: 'YYYYMMDD' | amDateFormat: dateFormat}}</span>  
      <span>&nbsp;{{ format_hour(c.getTimestart()) }} - {{ format_hour(c.getTimeend()) }}</span>  
      <span style="float:right;font-size: 24px;margin-right:5px"><span>{{ c.getPrice() ? c.getPrice() : ('golf_class.price_free' | translate)  }}</span><span *ngIf="c.getPrice()">&nbsp;{{ currencysymbol }} </span></span>
  </div>  
  <mat-card-content class="p0">
    <div style="display:flex;justify-content: space-between;">
    <div class="detail">
      <div>{{ 'golf_class.teacher' | translate }}: {{  teachername(c.getGolfteacherid(), dlgteachers) }}</div>
      <div *ngIf="c.getPlacename()">{{ c.getPlacename() ? ('golf_class.location' | translate) + ' ' + c.getPlacename() : '' }}</div>
      <div *ngIf="c.getQuotamin() || c.getQuotamax()">{{ ('golf_class.number_participants' | translate).replace('%s1',c.getNbattendees() ).replace('%s2',c.getQuotamax() ) }}</div>
    </div>
    <div (click)="openteacher(c.getGolfteacherid())" [ngStyle]="{'background-image':'url(' + teacherphoto(c.getGolfteacherid(), dlgteachers) + ')'}" class="img"></div>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="!readonly">
    <button mat-raised-button class="btn-attend"
      (click)="attend(c)"
      [disabled]="isattend(c, me.myclasses) || !canattend(c) || (c.getQuotamax() ? (c.getQuotamax() - c.getNbattendees()) <= 0 : false)"
      color="primary">{{'golf_class.button_attend' | translate }}</button>
  </mat-card-actions>  
</mat-card>

<div class="golf-teacher-dialog" *ngIf="dlgteacherDisplay == 'block'">
  <div class="bg"></div>
  <div class="dlg">
  <div class="top" [ngStyle]="{'background-color': backgroundColor}">
    <button [ngStyle]="{'color': foregroundColor}" mat-icon-button (click)="dlgteacherDisplay='none'"><mat-icon>close</mat-icon></button>
  </div>
  <div class="top-img" [ngStyle]="{'background-image': 'url(' + dlgteacher?.getPhoto() + ')'}"></div>
  <mat-card>
    <mat-card-content>
      <table>
        <tr>
          <td colspan="2">{{ [dlgteacher.getTitle(), dlgteacher.getFirstname(), dlgteacher.getName()].join(' ') }}</td>
        </tr>
        <tr *ngIf="dlgteacher.getDiploma()">
          <td>{{ 'golf_class.teacher_degree' | translate }}</td><td>{{ dlgteacher.getDiploma() }}</td>
        </tr>
        <tr *ngIf="dlgteacher.getIndex()">
          <td>{{ 'golf_class.teacher_index' | translate }}</td><td>{{ dlgteacher.getIndex().toFixed(1) }}</td>
        </tr>
        <tr *ngIf="dlgteacher.getMobile()">
          <td>{{ 'golf_class.teacher_mobile' | translate }}</td><td>{{ dlgteacher.getMobile() }}</td>
        </tr>
        <tr *ngIf="dlgteacher.getNote()">
          <td>{{ 'golf_class.teacher_note' | translate }}</td>
        </tr>
      </table>
      <div class="note" *ngIf="dlgteacher.getNote()">
        {{ dlgteacher.getNote() }}
      </div>
    </mat-card-content>
  </mat-card>
  </div>
</div>  