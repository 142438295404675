import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { BenefitConversionService } from '../../conversion/benefit/benefit-conversion.service';
import { Benefit, Empty } from 'src/app/libs/proto/commUnity_pb';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
@Injectable({
  providedIn: 'root'
})
export class GrpcBenefitLibService {
  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: BenefitConversionService,

  ){ }


  getBenefit(option?: GrpcLibServiceOption): Promise<Benefit[]>{
    return new Observable<Benefit[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getBenefitOffline(opt);
      } else {
        this._getBenefitOnline(opt);
      }
    }).toPromise();
  }

  private _getBenefitOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      this.storLib.get('benefit-s') || this.storLib.get('benefit'), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }
  private _getBenefitOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getBenefits(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getBenefitOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getBenefitOnlineStatus(s, option);
      })
      .on('data', (r: Benefit) => {
        this._getBenefitOnlineData(r, option);
      })
      .on('end', () => {
        this._getBenefitOnlineEnd(option);
      });
  }

  private _getBenefitOnlineData(ret: Benefit, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getBenefitOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getBenefitOnline(option);
    }, option);
  }

  private _getBenefitOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getBenefitOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.benefit = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'benefit', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getBenefitOnline(option);
      }, option);
    }
  }

}
