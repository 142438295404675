import { Component, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { Link } from '../../libs/proto/commUnity_pb';
import { GrpcLibService } from '../../service/grpc/grpc-lib.service';
import { Router } from '@angular/router';
import { UserLibService } from '../../service/user/user-lib.service';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { GrpcLinksLibService } from '../../service/grpc/links/grpc-links-lib.service';
import { desktopMode } from '../../config/type';
import { Platform } from '@angular/cdk/platform';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LinksPathConverter } from 'src/app/service/conversion/links/links-path-converter';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.sass']
})
export class LinksComponent implements OnInit {

  links$: Promise<Link[]>;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  constructor(
    private route: Router,
    private grpcLinksLib: GrpcLinksLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private detector: DeviceLibService,
    private linksLib: LinksPathConverter,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  /**
   * reset cache links
   */
     private resetCache() {
      switch (this.linksLib.linksIndex) {
        case '2':
          this.storeLib.cache.links2 = false;break;
        case '3':
          this.storeLib.cache.links3 = false;break;
        case '4':
          this.storeLib.cache.links4 = false;break;
        default:
          this.storeLib.cache.links = false;break;
      }
    }

    private get offlineLinks() {
      switch (this.linksLib.linksIndex) {
        case '2':
          return this.storeLib.cache.links2;
        case '3':
          return this.storeLib.cache.links3;
        case '4':
          return this.storeLib.cache.links4;
        default:
          return this.storeLib.cache.news;
      }
    }
  ngOnInit(): void {
    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });


    const linksk = 'links-s' + this.linksLib.linksIndex;
    if (this.storeLib.get(linksk)) {
      this.storeLib.set(linksk, null);
      this.resetCache();
    }

    this.links$ = this.linksLib.fnGetLinks({
      Offline: this.offlineLinks || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
  }
  get logo() {
    return this.userLib.designLogo;
  }
  get backgroundColor() {
    return this.userLib.designMainBackgroundColor;
  }
  get textColor() {
    return this.userLib.designMainTextColor;
  }
  get customer() {
    return this.userLib.Data.token?.getCustomer();
  }
  get customerName() {
    if (!(this.customer && this.customer.getShowcustomername()) || false) { return; }

    return this.userLib.Data.token?.getCustomer().getName();
  }

  get backgroundImage() {
    return this.userLib.designMainBg;
  }

  get backgroundSize() {
    if (!this.detector.isMobile()) {
      return (this.customer && this.customer.getBackgrounddesktopphotoskretch() || false) ? 'cover' : '';
    }

    return (this.customer && this.customer.getBackgroundphotoskretch() || false) ? 'cover' : '';
  }
  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
  open(id: string){
    this.route.navigate(['/link' + this.linksLib.linksIndex, id]);
  }
}
