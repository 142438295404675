<app-sidenav page="guest-reservation" menuType>

<div class="form-list" *ngIf="menuType!=2">
    <div class="card-main">
        <mat-card class="guest-card" [ngClass]="{tablet: menuType==2}">
            <mat-card-content>    
                <label [innerHTML]="('guest.reservation-detail-title' | translate).replace('%s', '' )"></label>
                <mat-toolbar>
                    {{ reservation?.getHotixreservation().getResaid() }}
                    
                    <span class="like-button">
                    <button (click)="gosatfiy()" *ngIf="showlike" mat-icon-button>
                        <img style="width: 40px;" src="assets/images/hotix/satisfaction.png"/>
                    </button>
                    <button (click)="gobill()" *ngIf="showbill" mat-icon-button>
                        <img style="width: 24px;" src="assets/images/hotix/Facture.png"/>
                    </button>
                    </span>
                </mat-toolbar>
                <mat-list class="w-100">

                <mat-list-item>
                    <label [innerHTML]="('guest.reservation-detail-arrive' | translate).replace('%1', reservationDetail?.getHotixreservationdetail().getArrivaldate() | pipeDatefmt2 | amDateFormat: 'DD/MM/YYYY' ).replace('%2', reservation?.getHotixreservation().getArrivaltime() | number: '2.'  )"></label>:00
                </mat-list-item>

                <mat-list-item>
                    <label [innerHTML]="('guest.reservation-detail-depart' | translate).replace('%1', reservationDetail?.getHotixreservationdetail().getDeparturedate() | pipeDatefmt2 | amDateFormat: 'DD/MM/YYYY' ).replace('%2', reservation?.getHotixreservation().getDeparturetime() | number: '2.'  )"></label>:00
                </mat-list-item>

                <mat-list-item>
                    <label>{{ lineHas }} </label>
                </mat-list-item>

                <mat-list-item>
                    <label [innerHTML]="('guest.reservation-detail-room' | translate).replace('%s', reservationDetail?.getHotixreservationdetail().getRoomnumber() )"></label>
                </mat-list-item>

                <mat-list-item>
                    <label [innerHTML]="('guest.reservation-detail-room-type' | translate).replace('%s', reservationDetail?.getHotixreservationdetail().getRoomtype() )"></label>
                </mat-list-item>

                <mat-list-item>
                    <label [innerHTML]="('guest.reservation-detail-price' | translate).replace('%s', reservationDetail?.getHotixreservationdetail().getRates() ) + ' ' + currencysymbol"></label>
                </mat-list-item>

                <mat-list-item>
                    <label [innerHTML]="('guest.reservation-detail-company' | translate).replace('%s', reservationDetail?.getHotixreservationdetail().getCompany() )"></label>
                </mat-list-item>

                <mat-list-item>
                    <label [innerHTML]="('guest.reservation-detail-hasdeposit' | translate).replace('%s', ('Dialog.' + (reservation?.getHotixreservation().getHavedeposit() ? 'Yes':'No')) | translate ) "></label>
                </mat-list-item>

                <mat-list-item *ngIf="reservation?.getHotixreservation().getHavedeposit()">
                    <label [innerHTML]="('guest.reservation-detail-deposit-receive' | translate).replace('%s', reservation?.getHotixreservation().getDepositreceived() ) + ' ' + currencysymbol"></label>
                </mat-list-item>

                <mat-list-item *ngIf="reservation?.getHotixreservation().getHavedeposit()">
                    <label [innerHTML]="('guest.reservation-detail-deposit' | translate).replace('%s', reservation?.getHotixreservation().getDeposit() ) + ' ' + currencysymbol"></label>
                </mat-list-item>

                <mat-list-item>
                    <label>{{ 'guest.reservation-precheck-button' | translate }} </label>
                </mat-list-item>
                
                <div class="t-center">
                    <button (click)="precheck()" mat-icon-button>
                        <img style="width: 48px;" src="assets/images/hotix/Details clients.png"/>
                    </button>
                </div>
            </mat-list>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="mode-tablet" *ngIf="menuType==2">
    <div class="left">
        <mat-card-header class="header">
          <mat-card-title [innerHTML]="('guest.reservation-detail-title' | translate).replace('%s', '' )"></mat-card-title>
          <mat-card-subtitle >{{ reservation?.getHotixreservation().getResaid() }}</mat-card-subtitle>

          <span class="like-button">
            <button (click)="gosatfiy()" *ngIf="showlike" mat-icon-button><mat-icon>thumb_up</mat-icon></button>
            <button (click)="gobill()" *ngIf="showbill" mat-icon-button>
                <img style="width: 24px;" src="assets/images/hotix/Facture.png"/>
            </button>
          </span>

        </mat-card-header>
        <mat-card-content>    
            <mat-list class="w-100">

            <mat-list-item>
                <label [innerHTML]="('guest.reservation-detail-arrive' | translate).replace('%1', reservationDetail?.getHotixreservationdetail().getArrivaldate() | pipeDatefmt2 | amDateFormat: 'DD/MM/YYYY' ).replace('%2', reservation?.getHotixreservation().getArrivaltime() | number: '2.'  )"></label>:00
            </mat-list-item>

            <mat-list-item>
                <label [innerHTML]="('guest.reservation-detail-depart' | translate).replace('%1', reservationDetail?.getHotixreservationdetail().getDeparturedate() | pipeDatefmt2 | amDateFormat: 'DD/MM/YYYY' ).replace('%2', reservation?.getHotixreservation().getDeparturetime() | number: '2.'  )"></label>:00
            </mat-list-item>
            </mat-list>
        </mat-card-content>
    </div>
      
      <div class="right">
        <div class="body">
            <mat-card class="guest-card" [ngClass]="{tablet: menuType==2}">
                <mat-card-content>    
                    <mat-list class="w-100">
    
                    <mat-list-item>
                        <label>{{ lineHas }} </label>
                    </mat-list-item>
    
                    <mat-list-item>
                        <label [innerHTML]="('guest.reservation-detail-room' | translate).replace('%s', reservationDetail?.getHotixreservationdetail().getRoomnumber() )"></label>
                    </mat-list-item>
    
                    <mat-list-item>
                        <label [innerHTML]="('guest.reservation-detail-room-type' | translate).replace('%s', reservationDetail?.getHotixreservationdetail().getRoomtype() )"></label>
                    </mat-list-item>
    
                    <mat-list-item>
                        <label [innerHTML]="('guest.reservation-detail-price' | translate).replace('%s', reservationDetail?.getHotixreservationdetail().getRates() ) + ' ' + currencysymbol"></label>
                    </mat-list-item>
    
                    <mat-list-item>
                        <label [innerHTML]="('guest.reservation-detail-hasdeposit' | translate).replace('%s', ('Dialog.' + (reservation?.getHotixreservation().getHavedeposit() ? 'Yes':'No')) | translate ) "></label>
                    </mat-list-item>
    
                    <mat-list-item *ngIf="reservation?.getHotixreservation().getHavedeposit()">
                        <label [innerHTML]="('guest.reservation-detail-deposit-receive' | translate).replace('%s', reservation?.getHotixreservation().getDepositreceived() ) + ' ' + currencysymbol"></label>
                    </mat-list-item>
    
                    <mat-list-item *ngIf="reservation?.getHotixreservation().getHavedeposit()">
                        <label [innerHTML]="('guest.reservation-detail-deposit' | translate).replace('%s', reservation?.getHotixreservation().getDeposit() ) + ' ' + currencysymbol"></label>
                    </mat-list-item>
    
                    <mat-list-item>
                        <label [innerHTML]="('guest.reservation-detail-company' | translate).replace('%s', reservationDetail?.getHotixreservationdetail().getCompany() )"></label>
                    </mat-list-item>
    
                    <mat-list-item>
                        <label>{{ 'guest.reservation-precheck-button' | translate }} </label>
                    </mat-list-item>
                    
                    <div class="t-center">
                        <button (click)="precheck()" mat-icon-button>
                            <img style="width: 48px;" src="assets/images/hotix/Details clients.png"/>
                        </button>
                        
                    </div>
                </mat-list>
                </mat-card-content>
            </mat-card>
        </div>
      </div>
</div>

</app-sidenav>