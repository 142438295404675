<div class="dialog-header">
    <h1>{{ 'reservation.title' | translate }}</h1>
    <button mat-mini-fab *ngIf="data?.event.getInforeservation().trim() != ''" color="primary" class="info-btn"
        aria-label="Information" (click)="info()">
        <mat-icon>info</mat-icon>
    </button>
    <button mat-mini-fab color="primary" class="close-btn" aria-label="Close" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-stepper [orientation]="menuType!=2 ? 'vertical' : 'horizontal'" [linear]="true" #stepper>
    <mat-step [stepControl]="numAttendeesFormGroup">
        <form [formGroup]="numAttendeesFormGroup">
            <ng-template matStepLabel>
                {{ 'reservation.number_places' | translate }}
                <br>
                <span *ngIf="stepper.selectedIndex > 0">{{
                    numAttendees?.value }} <span>{{ numAttendees?.value > 1 ? ('reservation.people' | translate) :
                        ('reservation.person' | translate) }}</span></span>
            </ng-template>
            <mat-form-field appearance="fill">
                <mat-label *ngIf="numAttendees?.value < 2">{{ 'reservation.person' | translate }}</mat-label>
                <mat-label *ngIf="numAttendees?.value > 1">{{ 'reservation.people' | translate }}</mat-label>
                <mat-select matInput formControlName="numAttendeesCtrl" required>
                    <mat-option
                        *ngFor="let n of (members?.length > maxAttendees ? maxAttendees : members?.length) | sequence"
                        [value]="n+1">
                        {{ n + 1 }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-button color="primary" (click)="goForwardAndGetTables(stepper)">{{ 'reservation.next_button'
                    | translate }}</button>
            </div>
        </form>
    </mat-step>
    <mat-step *ngIf="zones?.length > 1 || numAttendees?.value == 1 && tables?.length > 1" [stepControl]="zoneFormGroup">
        <form [formGroup]="zoneFormGroup">
            <ng-template matStepLabel>
                {{ 'reservation.choose_zone' | translate }}
                <br>
                <span *ngIf="stepper.selectedIndex > 1">{{ 'reservation.zone' | translate }} : {{
                    summary?.zone?.getName() }} <span *ngIf="numAttendees?.value == 1">{{ 'reservation.table' |
                        translate
                        }} : {{
                        summary?.table?.getCode() }}</span></span>
            </ng-template>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'reservation.zone' | translate }}</mat-label>
                <mat-select matInput formControlName="zoneCtrl" required>
                    <mat-option *ngFor="let zone of zones" [value]="zone.getId()">
                        {{ zone.getName() }}
                    </mat-option>
                </mat-select>
                <mat-error *ngFor="let err of zone?.errors | keyvalue">{{ err.key == 'required' ?
                    ('reservation.'+err.key | translate) : err.value }}</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="numAttendees?.value == 1 && tableSet?.length > 1" appearance="fill">
                <mat-label>{{ 'reservation.table' | translate }}</mat-label>
                <mat-select matInput formControlName="tableCtrl" required>
                    <mat-option *ngFor="let table of tableSet" [value]="table">
                        {{ table.getCode() }}
                    </mat-option>
                </mat-select>
                <mat-error *ngFor="let err of table?.errors | keyvalue">{{ err.key == 'required' ?
                    ('reservation.'+err.key | translate) : err.value }}</mat-error>
            </mat-form-field>
            <div>
                <button mat-button matStepperPrevious>{{ 'reservation.back_button' | translate }}</button>
                <button mat-button *ngIf="!zone?.errors && !table?.errors" color="primary" matStepperNext>{{
                    'reservation.next_button' | translate }}</button>
            </div>
        </form>
    </mat-step>
    <mat-step *ngIf="numAttendees?.value > 1" [stepControl]="attendeesFormGroup">
        <form [formGroup]="attendeesFormGroup">
            <ng-template matStepLabel>{{ 'reservation.choose_attendees' | translate }}</ng-template>
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>{{ 'reservation.people' | translate }}</mat-label>
                <mat-chip-list #attendeeList formControlName="attendeesCtrl" multiple required>
                    <mat-chip *ngFor="let attendee of attendees.value" [removable]="!isOptionDisabled(attendee)"
                        (removed)="onAttendeeRemoved(attendee)">
                        <span>{{ attendee.getFirstname() }} {{ attendee.getLastname() }}</span>
                        <mat-icon matChipRemove>close</mat-icon>
                    </mat-chip>
                    <input #memberAutocompleteInput matInput formControlName="memberCtrl"
                        [matChipInputFor]="attendeeList" [matAutocomplete]="auto">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn">
                    <mat-option *ngFor="let member of filteredMembers | async" [value]="member">
                        {{ member.getFirstname() }} {{ member.getLastname() }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-error *ngFor="let err of attendees?.errors | keyvalue">{{ err.key == 'required' || err.key ==
                'attendees_lt_num' || err.key == 'attendees_gt_num' ? ('reservation.'+err.key | translate) : err.value
                }}</mat-error>
            <div>
                <button mat-button matStepperPrevious>{{ 'reservation.back_button' | translate }}</button>
                <button mat-button *ngIf="!attendees?.errors" color="primary" matStepperNext>{{
                    'reservation.next_button' | translate }}</button>
            </div>
        </form>
    </mat-step>
    <mat-step [completed]="false">
        <ng-template matStepLabel>{{ 'reservation.summary' | translate }}</ng-template>
        <h2>{{ data.event?.getTitle() }}</h2>
        <h3>📍 {{ addressText }}</h3>
        <h4>🗓️ {{ data.event?.getDate() | amParse: 'YYYYMMDD' | amDateFormat: 'DD/MM/YYYY' }}</h4>
        <h4>⌚ {{ data.event?.getTimefrom() }} - {{ data.event?.getTimeto() }}</h4>
        <hr>
        <h2>{{ 'reservation.zone' | translate }}</h2>
        <p>{{ 'reservation.zone' | translate }} : {{ summary?.zone?.getName() }}</p>
        <p *ngIf="numAttendees?.value == 1">{{ 'reservation.table' | translate }} : {{ summary?.table?.getCode() }}</p>
        <hr>
        <h2 *ngIf="summary?.attendees.length < 2" style="margin-bottom: 0px;">{{ 'reservation.attendee' | translate }}
            ({{ summary?.attendees.length }}) :</h2>
        <h2 *ngIf="summary?.attendees.length > 1" style="margin-bottom: 0px;">{{ 'reservation.attendees' | translate }}
            ({{ summary?.attendees.length }}) :</h2>
        <mat-dialog-content>
            <ul>
                <li *ngFor="let attendee of summary?.attendees">{{ attendee.getFirstname() }} {{ attendee.getLastname()
                    }}</li>
            </ul>
        </mat-dialog-content>
        <div>
            <button mat-button matStepperPrevious>{{ 'reservation.back_button' | translate }}</button>
            <button mat-button color="primary" (click)="confirm()">{{ 'reservation.confirm' | translate }}</button>
        </div>
    </mat-step>
</mat-stepper>
