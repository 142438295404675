<app-sidenav page="documents" menuType>

<div
  (scroll)="clearScrollFlag()"
  class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="documents$ | async as documents">

  <mat-accordion
    [multi]="true">

    <mat-expansion-panel
    *ngFor="let category of documents | DocumentGroupByCategory"
      (afterExpand)="expanded(category.id, 1)"
      (afterCollapse)="expanded(category.id, 0)"
      [expanded]="!isCollapsed(category.id)"
      class="document-page"
      >
      <mat-expansion-panel-header
        class="document-category">
        <mat-panel-title>
          {{ category.label }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-card
        *ngFor="let document of category.items"
        [class]="'card-bottom'">
        <div
          [id]="document.getId()"
          (click)="open(document.getUrl())"  >
          <mat-card-header>
            <div mat-card-avatar class="item-img"
              [ngStyle]="{'background-image': 'url(' + (document.getImage() || '/assets/images/nophoto.png') + ')'}" >
            </div>
            <mat-card-title>{{ document.getTitle() }}</mat-card-title>
            <mat-card-subtitle>{{ document.getShortdescription() }}</mat-card-subtitle>
          </mat-card-header>
        </div>
      </mat-card>

    </mat-expansion-panel>

  </mat-accordion>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

</app-sidenav>