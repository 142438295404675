import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { Router } from '@angular/router';
import { GrpcAuthenLibService } from '../../service/grpc/authen/grpc-authen-lib.service';
import { SignUpMobileRequest } from '../../libs/proto/mobile_pb';
import { SignupForm } from '../../class/signup/signup';
import { DialogServiceService } from '../../service/dialog/dialog-service.service';
import { Customer } from '../../libs/proto/commUnity_pb';
import { GrpcCustomerLibService } from '../../service/grpc/customer/grpc-customer-lib.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

import * as jsonc from 'world-countries/countries.json';
export class EmailErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const emailok = (control.value || '').match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g)
    return !!(control && (control.invalid || !emailok) && (control.dirty || control.touched));
  }
}
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass']
})
export class SignupComponent implements OnInit {

  Loading: boolean;
  logoURL: string;
  bgURL: string;

  signupOK = false;
  signupForm: SignupForm;

  email_matcher = new EmailErrorMatcher();

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;
  country_list = [];
  hideSignupBirthday = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private grpcAuthLib: GrpcAuthenLibService,
    private grpcCusLib: GrpcCustomerLibService,
    private alertLib: DialogServiceService,
    private detector: DeviceLibService,
    
  ) {
    this.translate.use(this.detector.lang(location.href) || 'en');
    var clist = jsonc as [];
    
    for(var i=0;i<clist.length;i++) {
      if (!jsonc[i]['idd']) continue;

      this.country_list.push(
        {
          'name': jsonc[i]['name']['common'],
          'dial': jsonc[i]['idd']['root'] + (jsonc[i]['idd']['suffixes'].length > 1 ? '' : jsonc[i]['idd']['suffixes'][0]),
        }
      );
    }
    this.country_list = this.country_list.sort((a, b) => a['name'] > b['name'] ? 1 : -1);
    //console.log(clist);
  }

  validateCustomer(): Promise<Customer>{
    const thise = this;
    return new Promise<Customer>( (ok, ko) => {
      thise.grpcCusLib.getCustomer().then( c => {
        ok(c);
      }).catch( e => {
        ko(e);
      });

    });
  }

  ngOnInit(): void {
    const thise =  this;
    this.signupForm = new SignupForm();
    this.signupForm.country = this.country_list.filter((e) => e['name'] == 'France')[0];
    this.validateCustomer().then( c => {
      thise.hideSignupBirthday = c.getHidebirthdayatsignup();
      thise.signupForm.customerId = c.getId();

    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });
  }

  back() {
    this.router.navigateByUrl('/login');
  }

  save() {
    const thise = this;
    this.translate.get([
      'signup.signup_msg_title',
      'signup.signup_ok',
      'signup.signup_member_exist',
      'signup.signup_ko']).toPromise().then( t => {
        thise.grpcAuthLib.signUp(this.signupForm.toSignUpMobileRequest()).then( v => {

          thise.signupOK = true;

        }).catch( e => {
          if ((e.message || '') === 'member already exists in database') {
            thise.alertLib.show(t['signup.signup_member_exist'],
                  t['signup.signup_msg_title']);
          } else {
            thise.alertLib.show(t['signup.signup_ko'],
                  t['signup.signup_msg_title']);
          }
        });

    });
  }

  get validAccount(): boolean {
    return ((this.signupForm.firstName || '') !== '') &&
           ((this.signupForm.lastName || '') !== '') &&
           ((this.signupForm.email || '') !== '') &&
           ((this.signupForm.mobile || '') !== '');
  }
  close_signup() {
    this.signupOK = false;
    this.router.navigateByUrl('/login');
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }
  }
}
