import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';

import { UserLibService } from 'src/app/service/user/user-lib.service';
import { desktopMode } from 'src/app/config/type';
import { HotixBillLineResp, HotixReservation, HotixReservationDetail, HotixReservationDetailResp, HotixReservationResp, HotixReservationStatus, HotixRoomBillDetailResp, HotixStayReason } from 'src/app/libs/proto/hotix_pb';
import { GrpcGuestLibService } from 'src/app/service/grpc/guest/grpc-guest-lib.service';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { GuestConversionService } from 'src/app/service/conversion/guest/guest-conversion.service';
import { RequestID } from 'src/app/libs/proto/commUnity_pb';
@Component({
  selector: 'app-guest-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.sass']
})
export class GuestBillsComponent implements OnInit {

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  reservation_id = '';
  resaid = '';
  bill_total = 0;
  bill_cur = '';
  bill_id = '';

  tkeys = {};
  bills$: Promise<HotixBillLineResp[]>;
  err = '';
  waiting = true;
  nobill = false;

  constructor(
    private route: Router,
    private grpcLib: GrpcGuestLibService,
    private translate: TranslateService,
    private dateAdp: DateAdapter<Date>,
    private detector: DeviceLibService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private storeLib: StorageLibService,
    private convLib: GuestConversionService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  prepareTranslate(callback: ()=>void){
    this.translate.get([
      'guest.reservation-babys',
    ]).toPromise().then( v => {
      this.tkeys = v;
    }).finally( () => callback() );
  }

  ngOnInit(): void {
    this.dateAdp.setLocale(this.userLocale);

    const thise = this;
    this.actRoute.paramMap.subscribe(p => {
      this.reservation_id = p.get('id');
      this.prepareTranslate( () => {
        thise.grpcLib.getHotixReservation({
          Offline: true,
        }).then(ns => {
          const dd = ns.filter(n => n.getId() === thise.reservation_id);
          // not found any
          if (dd.length === 0) {
            thise.route.navigateByUrl('/guest/reservations');
            return;
          }
          thise.resaid = dd[0].getHotixreservation().getResaid();

          thise.getbill();            
        });
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  get userLocale(): string {
    return this.userLib.Data.lg || 'en';
  }

  getbill() {
    let req =  new RequestID();
    req.setId(this.reservation_id);

    this.bills$ = this.grpcLib.getHotixRoomBillDetails({
      call: {
        req: req
      }
    }).then((v) => {
      this.waiting = false;
      
      if ((v || []).length > 0) {
        
        //v[0].getLinesList()[0].getComment
        this.bill_id = v[0].getBillid();
        this.bill_cur = v[0].getCurrency() || this.userLib.Data.token?.getCustomer().getCurrencysymbol();
        this.bill_total = v[0].getTotalbill();

        return of(v[0].getLinesList()).toPromise();                
      }
      
      this.nobill = true;
      return of([]).toPromise();
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }      

      this.waiting = false;
      this.err = e.message;
      console.log('error getHotixRoomBillDetails:' + e);
      return of([]).toPromise();
    });
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }

  open(bill: guest_bill) {}
}

class guest_bill {
  num = 0;
  qty = 0;
  comment = '';
  description = '';
  date = 0;
  time = '';
  price = 0;  
}