<app-sidenav page="disclaimer" menuType>

<div class="form-list" [ngClass]="{tablet: menuType==2}">

<div class="msg">
  <mat-spinner *ngIf="disclaimLoading"
    diameter="24"
    class="wait"></mat-spinner>
  <p
  *ngIf="!disclaimLoading"
  [innerHTML] = "disclaim?.getText()"></p>
</div>

<div class="bottom" *ngIf="!disclaimLoading">
<button mat-flat-button
  (click)="accept()">
  {{ 'disclaimer.button' | translate }}
</button>
</div>

</div>

</app-sidenav>