import { Injectable } from '@angular/core';
import { GrpcLibService } from '../grpc-lib.service';
import { SignInMobileRequest, MemberToken, RequestPassword, SignUpMobileRequest } from '../../../libs/proto/mobile_pb';
import { MobileApp, Empty, SignUpToken, OS } from '../../../libs/proto/commUnity_pb';
import { communityAppType } from '../../../config/type';
import { UserLibService } from '../../user/user-lib.service';
import { LogLibService } from '../../log/log-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcAuthenLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storelib: StorageLibService
  ) { }

  signIn(user: string, password: string): Promise<MemberToken>{
    return this.grpcLib.signIn(user, password);
  }

  forgotPassword(user: string): Promise<Empty>{
    const req =  new RequestPassword();
    if ((user || '').indexOf('@') >= 0) {
      req.setEmail(user);
    } else {
      req.setUsername(user);
    }
    req.setMobileapp( this.userLib.userApp );
    req.setCustomerid( this.customerid );

    return new Promise<Empty>( (ok, ko) => {
      this.grpcLib.MobileClient.forgotPassword(req, {}, (err: any, resp: Empty) => {

        if (err != null) {
          ko(err);
        } else {
          ok(resp);
        }
      });
    });
  }

  private get customerid(): string {
    var cid = this.userLib.Data.selectedCustomer?.getId();
    if (!cid) {
      cid = this.storelib.get('s-cus');
    }
    return cid;
  }

  signUp(req: SignUpMobileRequest): Promise<SignUpToken>{
    req.setMobileapp( this.userLib.userApp );
    
    req.setCustomerid( this.customerid );
    return new Promise<SignUpToken>( (ok, ko) => {
      this.grpcLib.MobileClient.signUp(req, {}, (err: any, resp: SignUpToken) => {

        if (err != null) {
          ko(err);
        } else {
          ok(resp);
        }
      });
    });
  }
}
