import { Injectable } from '@angular/core';
import { BinaryWriter } from 'google-protobuf';
import { Guest } from '../../../libs/proto/commUnity_pb';
import { HotixPreCheckin, HotixReservation, HotixReservationDetail, HotixSettings } from 'src/app/libs/proto/hotix_pb';

@Injectable({
  providedIn: 'root'
})
export class GuestConversionService {
  constructor() { }

  ReservationToStorage(req: HotixReservation ){
    const w = new BinaryWriter();
    HotixReservation.serializeBinaryToWriter(req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  ReservationFromStorage(data: any, callback: (reservation: HotixReservation, errorConvert: any) => void) {
    try {
      callback( HotixReservation.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }

  ReservationToStorages(reservations: HotixReservation[]) {
    const rets = new Array<string>();

    reservations.forEach(element => {
      rets.push(
        btoa(String.fromCharCode.apply(null,this.ReservationToStorage(element)))
      );
    });

    return {data: rets};
  }

  ReservationFromStorages(data: any, callback: (news: HotixReservation[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const resvs = new Array<HotixReservation>();
    const resvjs: {data: string[]} = data;
    try {

      resvjs.data.forEach(element => {
        const resv = HotixReservation.deserializeBinary( new Uint8Array([...atob(element)].map(char => char.charCodeAt(0)))  );
        resvs.push ( resv );
      });

      callback( resvs, null);
    } catch (error) {
      callback( null, error);
    }
  }

  ReservationDetailToStorage(req: HotixReservationDetail ){
    const w = new BinaryWriter();
    HotixReservationDetail.serializeBinaryToWriter(req, w);

    const abuf = w.getResultBuffer();
    return  Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  ReservationDetailFromStorage(data: any, callback: (reservationdetail: HotixReservationDetail, errorConvert: any) => void) {
    try {
      callback( HotixReservationDetail.deserializeBinary(new Uint8Array([...atob(data)].map(char => char.charCodeAt(0)))), null);
    } catch (error) {
      callback( null, error);
    }
  }

  ReservationDetailToStorages(reservations: HotixReservationDetail[]) {
    const rets = new Array<string>();

    reservations.forEach(element => {
      rets.push(
        btoa(String.fromCharCode.apply(null,this.ReservationDetailToStorage(element)))
      );
    });

    return {data: rets};
  }

  ReservationDetailFromStorages(data: any, callback: (news: HotixReservationDetail[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const resvs = new Array<HotixReservationDetail>();
    const resvjs: {data: string[]} = data;
    try {

      resvjs.data.forEach(element => {
        const resv = HotixReservationDetail.deserializeBinary( new Uint8Array([...atob(element)].map(char => char.charCodeAt(0)))  );
        resvs.push ( resv );
      });

      callback( resvs, null);
    } catch (error) {
      callback( null, error);
    }
  }

  HotixSettingsToStorage(req: HotixSettings ){
    const w = new BinaryWriter();
    HotixSettings.serializeBinaryToWriter(req, w);

    const abuf = w.getResultBuffer();
    return btoa(String.fromCharCode.apply(null, Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v)));
  }

  HotixSettingsFromStorage(data: any, callback: (req: HotixSettings, errorConvert: any) => void) {
    try {
      callback( HotixSettings.deserializeBinary(new Uint8Array([...atob(data)].map(char => char.charCodeAt(0)))), null);
    } catch (error) {
      callback( null, error);
    }
  }

  PrecheckToStorage(req: HotixPreCheckin ){
    const w = new BinaryWriter();
    HotixPreCheckin.serializeBinaryToWriter(req, w);

    const abuf = w.getResultBuffer();
    return btoa(String.fromCharCode.apply(null, Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v)));
  }

  PrecheckFromStorage(data: any, callback: (req: HotixPreCheckin, errorConvert: any) => void) {
    try {
      callback( HotixPreCheckin.deserializeBinary(new Uint8Array([...atob(data)].map(char => char.charCodeAt(0)))), null);
    } catch (error) {
      callback( null, error);
    }
  }
}
