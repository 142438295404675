import * as olProj from 'ol/proj';
import LineString from 'ol/geom/LineString';

export interface LocationOK {
  location?: GeolocationCoordinates;
  ok?: boolean;
  err?: string;
  msg?: string;
}

interface LocationOption {
  forceLocation: boolean;
  positions: number[];
  radius: number;
}

export class LocationClass {
  private distanceBetweenPoints(pos: GeolocationCoordinates, option: LocationOption) {
    const c1 = olProj.fromLonLat([
      pos.longitude,
      pos.latitude,
    ]);
    const c2 = olProj.fromLonLat(option.positions);
    const l = new LineString([c1, c2]);
    return l.getLength();
  }

  private validLocation(pos: GeolocationCoordinates, option: LocationOption) {
    let locOK = false;
    const dis = this.distanceBetweenPoints(pos, option);

    if (dis <= option.radius) {
      locOK = true;
    } else {
      if (pos.accuracy > option.radius) {
        if (dis <= (pos.accuracy + option.radius)) {
          locOK = true;
        }
      }
    }
    const debug1 = '\n\ndistance between ' +
                  '\n current position (' + pos.longitude.toFixed(7) + ',' + pos.latitude.toFixed(7) + ')' +
                  '\n restaurant position (' + option.positions[0].toFixed(7) + ',' + option.positions[1].toFixed(7) +
                  ') \n= ' + dis.toFixed(7) +
                  '\n\n if distance <= radius (' + option.radius + ')' +
                  '\n = ' + (dis <= option.radius) +
                  '\n\n if dis <= accruacy (' + pos.accuracy + ') + radius (' + option.radius + ')' +
                  '\n = ' + (dis <= (pos.accuracy + option.radius)) +
                  '\n it is not in location';

    return locOK ? '' : debug1;
  }
  // err: no, empty, fail, invalid
  checkLocation(option: LocationOption): Promise<LocationOK> {
    // not force return no location
    if (!option.forceLocation) { return Promise.resolve({ok: true}); }

    if (!navigator.geolocation) {
      return Promise.resolve({ok: false, err: 'no'});
    }

    const thise = this;
    return new Promise<LocationOK>( ok => {
      navigator.geolocation.getCurrentPosition( p => {
        if (!p) {
          ok({ok: false, err: 'empty'});
        }
        const r = this.validLocation(p.coords, option);
        if (r === '') {
          ok({ok: true, location: p.coords});
        } else {
          ok({ok: false, err: 'invalid', msg: r});
        }
      }, (e) => {
        ok({ok: false, err: 'fail', msg: e.message});
      });
    });
  }
}
