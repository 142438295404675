import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { AlertConversionService } from '../../conversion/alert/alert-conversion.service';
import { Alert, Empty, RequestID } from '../../../libs/proto/commUnity_pb';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';

@Injectable({
  providedIn: 'root'
})
export class GrpcAlertLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: AlertConversionService,
  ) { }

  getAlert(option?: GrpcLibServiceOption): Promise<Alert[]>{
    return new Observable<Alert[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getAlertOffline(opt);
      } else {
        this._getAlertOnline(opt);
      }
    }).toPromise();
  }

  private _getAlertOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      this.storLib.get('alert'), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getAlertOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getAlerts(new Empty(), {
        token: this.userLib.Data.token?.getToken()
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getAlertOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getAlertOnlineStatus(s, option);
      })
      .on('data', (r: Alert) => {
        this._getAlertOnlineData(r, option);
      })
      .on('end', () => {
        this._getAlertOnlineEnd(option);
      });
  }

  private _getAlertOnlineData(ret: Alert, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getAlertOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getAlertOnline(option);
    }, option);
  }

  private _getAlertOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getAlertOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {

      if (option.KeepInCache || false) {
        this.storLib.set(
          'alert', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getAlertOnline(option);
      }, option);
    }
  }
}
