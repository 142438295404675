import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { Place, RequestID } from '../../../libs/proto/commUnity_pb';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { PlaceConversionService } from '../../conversion/place/place-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcPlaceLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: PlaceConversionService,
  ) { }

  getPlace(option?: GrpcLibServiceOption): Promise<Place[]>{
    return new Observable<Place[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getPlaceOffline(opt);
      } else {
        this._getPlaceOnline(opt);
      }

    }).toPromise();
  }

  private _getPlaceOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('places-s') || this.storLib.get('places')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getPlaceOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    // req.setId( option.call?.req);
    this.grpcLib.MobileClient.getPlaces(req, {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getPlaceOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getPlaceOnlineStatus(s, option);
      })
      .on('data', (r: Place) => {
        this._getPlaceOnlineData(r, option);
      })
      .on('end', () => {
        this._getPlaceOnlineEnd(option);
      });
  }

  private _getPlaceOnlineData(ret: Place, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getPlaceOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getPlaceOnline(option);
    }, option);
  }

  private _getPlaceOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getPlaceOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.places = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'places', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getPlaceOnline(option);
      }, option);
    }
  }
}
