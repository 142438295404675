import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Injectable } from '@angular/core';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { MemberConversionService } from '../../conversion/member/member-conversion.service';
import { Member, Empty, RequestMembers, MemberBusinessCard } from 'src/app/libs/proto/commUnity_pb';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { GetMemberBusinessCardRequest, UpdateMemberLanguageRequest } from 'src/app/libs/proto/mobile_pb';

@Injectable({
  providedIn: 'root'
})
export class GrpcMemberLibService {
  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: MemberConversionService,

  ){}

  getMember(option?: GrpcLibServiceOption): Promise<Member[]>{
    return new Observable<Member[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getMemberOffline(opt);
      } else {
        this._getMemberOnline(opt);
      }
    }).toPromise();
  }

  private _getMemberOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('member-s')  || this.storLib.get('member')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getMemberOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getMembers(new RequestMembers(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getMemberOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getMemberOnlineStatus(s, option);
      })
      .on('data', (r: Member) => {
        this._getMemberOnlineData(r, option);
      })
      .on('end', () => {
        this._getMemberOnlineEnd(option);
      });
  }

  private _getMemberOnlineData(ret: Member, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getMemberOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getMemberOnline(option);
    }, option);
  }

  private _getMemberOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getMemberOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.member = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'member', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getMemberOnline(option);
      }, option);
    }
  }

  getMemberBusinessCard(option?: GrpcLibServiceOption): Promise<MemberBusinessCard[]>{
    return new Observable<MemberBusinessCard[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      this._getMemberBusinessCardOnline(opt);
      
    }).toPromise();
  }
  private _getMemberBusinessCardOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();    
    this.grpcLib.MobileClient.getMemberBusinessCard(option.call.req, {
        token: t,
      }, ()=>{})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getMemberBusinessCardOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getMemberBusinessCardOnlineStatus(s, option);
      })
      .on('data', (r: MemberBusinessCard) => {
        this._getMemberBusinessCardOnlineData(r, option);
      })
      .on('end', () => {
        this._getMemberBusinessCardOnlineEnd(option);
      });
  }

  private _getMemberBusinessCardOnlineData(ret: MemberBusinessCard, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getMemberBusinessCardOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getMemberBusinessCardOnline(option);
    }, option);
  }

  private _getMemberBusinessCardOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getMemberBusinessCardOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getMemberBusinessCardOnline(option);
      }, option);
    }
  }

  scanMemberBusinessCard(option?: GrpcLibServiceOption): Promise<MemberBusinessCard[]>{
    return new Observable<MemberBusinessCard[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      this._scanMemberBusinessCardOnline(opt);
      
    }).toPromise();
  }
  private _scanMemberBusinessCardOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();    
    this.grpcLib.MobileClient.scanMemberBusinessCard(option.call.req, {
        token: t,
      }, ()=>{})
      .on('error', (e: grpcWeb.RpcError) => {
        this._scanMemberBusinessCardOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._scanMemberBusinessCardOnlineStatus(s, option);
      })
      .on('data', (r: MemberBusinessCard) => {
        this._scanMemberBusinessCardOnlineData(r, option);
      })
      .on('end', () => {
        this._scanMemberBusinessCardOnlineEnd(option);
      });
  }

  private _scanMemberBusinessCardOnlineData(ret: MemberBusinessCard, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _scanMemberBusinessCardOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._scanMemberBusinessCardOnline(option);
    }, option);
  }

  private _scanMemberBusinessCardOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _scanMemberBusinessCardOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._scanMemberBusinessCardOnline(option);
      }, option);
    }
  }
  
  updateMemberLanguage(lg: string){
    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: lg
        }
      });
      opt.call.subscribe = obs;

      this._updateMemberLanguage(opt);

    }).toPromise();
  }
  private _updateMemberLanguage(option: GrpcLibServiceOption){
    const req = new UpdateMemberLanguageRequest();
    req.setLang(option.call.req);

    this.grpcLib.MobileClient.updateMemberLanguage(req, {
      token: this.userLib.Data.token?.getToken()
    }, (e, _) => {

      if (e != null) {

        this.grpcLib.handleError(e, () => {
          this._updateMemberLanguage(option);
        }, option);

      } else {
        option.call.subscribe.next(true);
        option.call.subscribe.complete();
      }
    });
  }  
}
