import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { UserLibService } from '../../../service/user/user-lib.service';
import { GrpcOrderLibService } from '../../../service/grpc/order/grpc-order-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { GrpcLibService } from '../../../service/grpc/grpc-lib.service';
import { of } from 'rxjs';
import { ProductOrder, ProductOrderLine, PaymentStatus, OrderStatus, OrderInfo, DeliveryMethod } from '../../../libs/proto/shop_pb';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.sass']
})
export class OrderHistoryComponent implements OnInit {

  orders$: Promise<ProductOrder[]>;
  T: {k?: string, t?: string};
  paymentK: {v?: string, k?: string} = {};
  orderK: {v?: string, k?: string} = {};
  payLoading?: boolean;

  static canPay(o: ProductOrder) {
    return o?.getPaymenturl() && o?.getPaymentstatus() === PaymentStatus.UNPAID &&
           o?.getDeliverymethod() === DeliveryMethod.DELIVERY &&
           o?.getOrderstatus() !== OrderStatus.ORDERCANCELLED;
  }

  constructor(
    private route: Router,
    private storeLib: StorageLibService,
    private userLib: UserLibService,
    private grpcLib: GrpcOrderLibService,
    private translate: TranslateService,
  ) { }
  get currencysymbol() {
    return this.userLib.Data.token?.getCustomer().getCurrencysymbol();
  }
  ngOnInit(): void {
    const thise = this;

    const paymentT: string[] = [];
    for (const key of Object.keys(PaymentStatus)) {
      paymentT.push('payment_status.' + key);
      this.paymentK[ PaymentStatus[key]] = key;
    }

    const deliveryT: string[] = [];
    for (const key of Object.keys(OrderStatus)) {
      deliveryT.push('order_status.' + key);
      this.orderK[ OrderStatus[key]] = key;
    }

    this.translate.get(
      paymentT.concat( deliveryT )
    ).toPromise().then( t => {
      thise.T = t as {k?: string, t?: string};
    });

    this.orders$ = this.grpcLib.getProductOrders({
      Offline: this.storeLib.cache.product_order || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        thise.userLib.clear();
        thise.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
  }
  reOrder(id: string) {
    this.route.navigate(['/reorder', id]);
  }
  pay(e: MouseEvent, url: string) {
    e.stopPropagation();
    if ((url || '') === '') { return; }

    this.payLoading = true;
    window.location.href = url;
  }
  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  isCancel(o: ProductOrder): boolean {
    return o.getPaymentstatus() === PaymentStatus.CANCELLED;
  }
  textPaymentStatus(o: number) {
    return this.T['payment_status.' + this.paymentK[o]] || this.paymentK[o];
  }
  textOrderStatus(o: number) {
    return this.T['order_status.' + this.orderK[o]] || this.orderK[o];
  }
  showTracking(e: any, t: string){
    e.stopPropagation();
    if ((t || '') === '') { return; }

    window.open(t, '_blank');
  }
  canPay(o: ProductOrder) {
    return OrderHistoryComponent.canPay(o);
  }
}
