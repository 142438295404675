import { Injectable } from '@angular/core';
import { GolfTeacher } from '../../../libs/proto/commUnity_pb';
import { BinaryWriter } from 'google-protobuf';

@Injectable({
  providedIn: 'root'
})
export class GolfTeacherConversionService {

  constructor() { }

  ToStorage(req: GolfTeacher) {
    const w = new BinaryWriter();
    GolfTeacher.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (GolfTeacher: GolfTeacher, errorConvert: any) => void) {
    try {
      callback( GolfTeacher.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }
  ToStorages(news: GolfTeacher[]) {
    const rets = new Array<String>();

    news.forEach(element => {
      rets.push(
        btoa(String.fromCharCode.apply(null,this.ToStorage(element)))
      );
    });

    return {data: rets};
  }

  FromStorages(data: any, callback: (GolfTeacheres: GolfTeacher[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const golfTeacheres = new Array<GolfTeacher>();
    const GolfTeacherjs: {data: string[]} = data;
    try {

      GolfTeacherjs.data.forEach(element => {
        const golfTeacher = GolfTeacher.deserializeBinary( new Uint8Array([...atob(element)].map(char => char.charCodeAt(0)))  );
        golfTeacheres.push ( golfTeacher );
      });
      
      callback( golfTeacheres, null);
    } catch (error) {
      callback( null, error);
    }
  }
}
