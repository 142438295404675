import { Injectable } from '@angular/core';
import { GroupCustomers } from '../../../libs/proto/mobile_pb';
import { BinaryWriter } from 'google-protobuf';

@Injectable({
  providedIn: 'root'
})
export class GroupCustomerConversionService {

  ToStorage(req: GroupCustomers) {
    const w = new BinaryWriter();
    GroupCustomers.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (cus: GroupCustomers, errorConvert: any) => void) {
    try {
      callback( GroupCustomers.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }
}
