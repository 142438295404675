import { Component, OnInit, OnDestroy } from '@angular/core';
import { GrpcNewsLibService } from '../../service/grpc/news/grpc-news-lib.service';
import { News } from '../../libs/proto/commUnity_pb';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscribable, Subscription } from 'rxjs';
import { GrpcLibServiceOption } from '../../service/grpc/grpc-lib.service';
import { NewsPathConverter } from '../../service/conversion/news/news-path-converter';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { GrpcPlaceLibService } from 'src/app/service/grpc/place/grpc-place-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.sass']
})
export class NewComponent implements OnInit, OnDestroy {

  news: News;
  private actRouter$: Subscription;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;
  newindex = '';
  hasplace = false;

  constructor(
    private grpcNewsLib: GrpcNewsLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private grpcPlaceLib: GrpcPlaceLibService,
    private storeLib: StorageLibService,
    private actRoute: ActivatedRoute,
    private newsLib: NewsPathConverter,
    private route: Router,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

    private get offlineNews() {
    switch (this.newsLib.newsIndex) {
      case '2':
        return this.storeLib.cache.news2;
      case '3':
        return this.storeLib.cache.news3;
      case '4':
        return this.storeLib.cache.news4;
      case '5':
        return this.storeLib.cache.news5;
      case '6':
        return this.storeLib.cache.news6;
      case '7':
        return this.storeLib.cache.news7;
      case '8':
        return this.storeLib.cache.news8;
      case '9':
        return this.storeLib.cache.news9;
      default:
        return this.storeLib.cache.news;
    }
  }


  ngOnInit(): void {
    const thise = this;
    this.actRouter$ = this.actRoute.paramMap.subscribe( p => {
      thise.newsLib.fnGetNews({
        Offline: this.offlineNews || false,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        let nss = ns.filter( n =>  n.getId() === p.get('id'));        
        if (nss.length === 0) {
          thise.route.navigateByUrl('/news' + this.newsLib.newsIndex);
          return;
        }
        thise.news = nss[0];

        thise.grpcPlaceLib.getPlace({
          Offline: this.storeLib.cache.places || false,
          call: {
            req: nss[0].getPlaceid(),
          }
        }).then( ps => {
          // check if place exist
          const ps0 = ps?.filter( (e) => e.getId() == nss[0].getPlaceid()) || [];
          this.hasplace = ps0 && ps0.length == 1;
        });

        thise.newindex = thise.newsLib.newsIndex;
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });    
  }

  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
}
