import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pipeDescription' })
export class DescriptionPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      const newValue = value.replace(/\n/g, '<br>').replace(/\r/g, '');
      return `${newValue}`;
    }
  }
}
