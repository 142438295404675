import { Injectable } from '@angular/core';
import { Member } from 'src/app/libs/proto/commUnity_pb';
import { BinaryWriter } from 'google-protobuf';

@Injectable({
  providedIn: 'root'
})
export class MemberConversionService {
  constructor() {}

  ToStorage(req: Member) {
    const w = new BinaryWriter();
    Member.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (member: Member, errorConvert: any) => void) {
    try {
      callback( Member.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }

  ToStorages(member: Member[]) {
    const rets = new Array<Uint8Array | number[]>();

    member.forEach(element => {
      rets.push(
        this.ToStorage(element)
      );
    });

    return {data: rets};
  }

  FromStorages(data: any, callback: (member: Member[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const emps = new Array<Member>();
    const empjs: {data: (Uint8Array)[]} = data;
    try {

      empjs.data.forEach(element => {
        const emp = Member.deserializeBinary( element  );
        emps.push ( emp );
      });

      callback( emps, null);
    } catch (error) {
      callback( null, error);
    }
  }


}
