<app-sidenav page="forum" menuType>

<div class="page-forum" [ngClass]="{tablet: menuType==2}" *ngIf="topics$| async as topics">

<mat-accordion [multi]="true">

  <mat-expansion-panel
    *ngFor="let topic of topics"
    (afterExpand)="expanded(topic.getId(), 1)"
    (afterCollapse)="expanded(topic.getId(), 0)"
    [expanded]="!isCollapsed(topic.getId())"
    hideToggle="true"
    class="post-page">

    <mat-expansion-panel-header
      class="post-topic">
      <mat-panel-title class="topic-name">
        {{topic.getName()}}
      </mat-panel-title>
      <button mat-mini-fab class="add-button">
        <mat-icon class="add-icon" (click)="openNewPost(topic.getId(), $event)">add</mat-icon>
      </button>
    </mat-expansion-panel-header>

    <div (scroll)="clearScrollFlag()"
      class="form-list" *ngIf="posts$ | async as posts">

      <div *ngFor="let post of posts">
        <mat-card *ngIf="topic.getId()=== post.getTopicid()"
          [class]="'card-bottom'">
          <div [id]="post.getId()">
            <mat-card-header>
              <div mat-card-avatar class="item-img"
              [ngStyle]="{'background-image': 'url(' + post.getImage() + ')'}"></div>

              <mat-card-title>
                <label>{{post.getMembername()}}</label>
                <div *ngIf="hasOneReplyType(post)">

                  <a *ngIf="showUniqueReplyType(post) === 'call'" [href]="tellink('tel:' + post.getMemberphone())">
                    <mat-icon class="icon-one-reply">{{showUniqueReplyType(post)}}</mat-icon></a>
                  <a *ngIf="showUniqueReplyType(post) === 'sms'" href="sms:{{post.getMemberphone()}}">
                    <mat-icon class="icon-one-reply">{{showUniqueReplyType(post)}}</mat-icon></a>
                  <a *ngIf="showUniqueReplyType(post) === 'mail_outline'" href="mailto:{{post.getMemberemail()}}">
                    <mat-icon class="icon-one-reply">{{showUniqueReplyType(post)}}</mat-icon></a>

                </div>

                <a (click)="openDialog(post)" *ngIf="!hasOneReplyType(post)">
                  <mat-icon class="icon-reply">reply</mat-icon></a>
                </mat-card-title>

                <mat-card-subtitle>{{post.getMessage()}}</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content class="date">{{post.getPublishdate()+'' | amParse: 'YYYYMMDD' | amDateFormat: dateFormat }}</mat-card-content>

          </div>
        </mat-card>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<app-new-post *ngIf="showNew"
[topicId]="showTopic"
(closeMe)="closeNew($event)"></app-new-post>

<app-choose-reply *ngIf="showPost"
(closeMe)="closePost()"
[data]="showPost"></app-choose-reply>

</app-sidenav>