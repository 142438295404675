import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { UserLibService } from '../../service/user/user-lib.service';

@Injectable()
export class Authguard implements CanActivate{

  constructor(
    private route: Router,
    private userLib: UserLibService,
    private storageLib: StorageLibService) {}

  /**
   * validate exist user
   * @param route active route
   * @param state route state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if ((this.storageLib.get('user') || '') || (this.userLib.disclaimerToken?.getToken() || '')) {
      return true;
    }
    if ((route.url[0] || {}).path === 'resto') {
      // reset when scan new table
      if (route.paramMap.get('table')) {
        this.storageLib.set('table-id', route.paramMap.get('table'));
        this.storageLib.set('table-id-1st-signin', 'y');
      }
    }
    this.route.navigate(['/login'], {skipLocationChange: true});
    return false;
  }
}
