import { Component, OnInit } from '@angular/core';
import { DeliveryAddress } from '../../../libs/proto/shop_pb';
import * as moment from 'moment';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { GrpcLibService } from '../../../service/grpc/grpc-lib.service';
import { UserLibService } from '../../../service/user/user-lib.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderConversionService } from '../../../service/conversion/order/order-conversion.service';
import { AddressConversionService } from '../../../service/conversion/address/address-conversion.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { GrpcRestaurantLibService } from '../../../service/grpc/restaurant/grpc-resto-lib.service';
import { CheckOutMeal, Meal, RestaurantOrderInfo } from '../../../libs/proto/restaurant_pb';
import { LocationClass, LocationOK } from '../../../class/location/location';

import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';

interface OrderForm {
  type: string;
  delay?: boolean;
  date: string;
  dateV: moment.Moment;
  note?: string;

  pickupLoading?: boolean;
  pickupMsg?: string;

  deliveryLoading?: boolean;
  deliveryPrice?: number;

  addressLoading?: boolean;
  addressShow: boolean;
  addresses?: DeliveryAddress[];
  address?: DeliveryAddress;

  addressAdd?: boolean;
  addressForm: {
    name?: string;
    street?: string;
    city?: string;
    postcode?: string;
    phone?: string;
  };

  confirmLoading?: boolean;
}

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-meal-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class MealCheckoutComponent implements OnInit {

  private meal: Meal;
  orderForm: OrderForm;
  hasAlcohol = false;
  resClose = '';
  resName = '';
  mustPaid = false;
  forceLocation = false;
  forceRadius = 0;
  toSignup = '';
  askPay = false;
  payLoc?: LocationOK;
  showCheckout = false;
  activePayment = false;

  /**
   * lng, lat
   */
  forcePos: number[] = [0, 0];

  constructor(
    private route: Router,
    private grpcLib: GrpcRestaurantLibService,
    private storeLib: StorageLibService,
    private userLib: UserLibService,
    private decPipe: DecimalPipe,
    private dlgLib: DialogServiceService,
    private translate: TranslateService,
    private dateAdp: DateAdapter<Date>,
  ) { }
  get currencysymbol() {
    return this.userLib.Data.token?.getCustomer().getCurrencysymbol();
  }
  ngOnInit(): void {
    const thise = this;

    if (!this.validateLastPayment()) { return; }

    this.dateAdp.setLocale(this.userLib.Data.lg || 'en');
    if (this.userLib.Data.lg == 'fr') registerLocaleData(localeFr);
    if (this.userLib.Data.lg == 'nl') registerLocaleData(localeNl);
    if (this.userLib.Data.lg == 'es') registerLocaleData(localeEs);
    if (this.userLib.Data.lg == 'pt') registerLocaleData(localePt);

    thise.orderForm = {
      type: '',
      date: moment().format('DD.MM.YYYY'),
      dateV: moment(),
      deliveryLoading: true,
      addressLoading: true,
      addressShow: false,
      pickupLoading: false,
      addressForm: {}
    };

    this.grpcLib.getRestaurantSettings().then( s => {
      thise.resName = s.getName();
      thise.resClose = s.getServiceclosedtext();
      thise.mustPaid = s.getForcepayment();
      thise.forceLocation = s.getForcegeolocation();
      thise.forcePos[1] = s.getLatitude();
      thise.forcePos[0] = s.getLongitude();
      thise.forceRadius = s.getRadius();
      thise.showCheckout = s.getAllowtextatorder();
      thise.activePayment = s.getActivepayment();
      if (s.getProposeregister()) {
          thise.toSignup = s.getProposeregistertext();
      }
      thise.getMeal();
    });

  }
  private validateLastPayment() {

    if (this.storeLib.get('meal-pay-id')) {
       this.route.navigateByUrl('/meal-payment?orderid' + this.storeLib.get('meal-pay-id'));
       return;
    }

    this.storeLib.set('meal-pdf', '');
    this.storeLib.set('meal-img', '');
    return true;
  }
  private getMeal(){
    const thise = this;

    this.grpcLib.getMeal({
      Offline: this.storeLib.cache.restaurantMeal || false,
      call: { req: this.userLib.mealID }
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        thise.userLib.clear();
        thise.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    }).then( (c: Meal) => {
      thise.meal = c;
      if (c.getProductsList().length === 0) {
        thise.route.navigateByUrl('/resto');
        return;
      }
      if (!thise.showCheckout) { thise.checkOut(); }
    });
  }

  private get Total() {
    return this.meal?.getTotalprice();
  }
  get TotalText() {
    return (this.decPipe.transform( this.Total
                                  , '1.2-2'))?.replace(/,/g, ' ')
                                  .replace('.', ',');
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }
  private check_alcohol() {
    if (this.meal.getAlcoholyn()) {
      this.hasAlcohol = true;
      return true;
    }
    return false;
  }
  checkOut() {
    if (this.check_alcohol()) { return; }

    this.canCheckOut();
  }
  canCheckOut() {
    const loc = new LocationClass();
    loc.checkLocation({
      forceLocation: this.forceLocation,
      positions: this.forcePos,
      radius: this.forceRadius
    }).then( res => {
      if (!res.ok) {
        if (res.err === 'invalid'){
          this.translate.get([
            'meal-checkout.title-location',
            'meal-checkout.ko-location-out',
          ]).toPromise().then( t => {
            this.dlgLib.confirm(t['meal-checkout.ko-location-out'], r => {
              if (r.no) {
                this.storeLib.set('meal', null);
                this.storeLib.set('meal-badge', null);
                this.route.navigateByUrl('/resto');
                return;
              }

              // force paid
              this.mustPaid = true;
              this.ask_payment({});

            }, t['meal-checkout.title-location']);
          });
        } else {
          this.error_location();
        }
        return;
      }

      this.ask_payment(res);

    });
  }
  error_location() {
    this.translate.get([
      'meal-checkout.title',
      'meal-checkout.ko-location',
    ]).toPromise().then( t => {
      this.dlgLib.show(t['meal-checkout.ko-location'],
                        t['meal-checkout.title']);
    });
  }
  doCheckout(pos: LocationOK, payOnline: boolean){
    this.orderForm.confirmLoading = true;
    const req = new CheckOutMeal();
    req.setNote( this.orderForm.note );
    req.setId( this.userLib.mealID);
    req.setTableid( this.storeLib.get('table-id') || '' );

    if (pos.location) {
      req.setLatitude( pos.location.latitude );
      req.setLongitude( pos.location.longitude);
      req.setRadius( pos.location.accuracy );
    }
    req.setPayonline(payOnline);
    const retURL = window.location.href.replace('/meal-checkout', '/meal-payment');
    req.setUrlok( retURL );
    req.setUrlnotok( retURL );

    const thise = this;
    this.grpcLib.restaurantCheckOut(req).then( o => {
      thise.checkoutOK(o, payOnline);
    })
    .catch( (e: Error) => {
      let emsg = 'meal-checkout.ko';
      if (e.message === 'Restaurant not serving now') {
          emsg = this.resClose || 'meal-checkout-ko-no-serve';
      }
      thise.orderForm.confirmLoading = false;
      thise.translate.get([
        'meal-checkout.title',
        'meal-checkout.ko',
        'meal-checkout-ko-no-serve'
      ]).toPromise().then( t => {
        thise.dlgLib.show(t['meal-checkout.ko'] + e.message,
                          t[emsg]);
      });

    });
  }
  private ask_payment(ret: LocationOK){
    if (this.mustPaid || !this.activePayment) {
      this.doCheckout(ret, true);
      return;
    }
    this.askPay = true;
    this.payLoc = ret;
  }
  pay_waiter(){
    this.askPay = false;
    this.doCheckout(this.payLoc, false);
  }
  pay_online(){
    this.askPay = false;
    this.doCheckout(this.payLoc, true);
  }
  private checkoutOK(o: RestaurantOrderInfo, topay: boolean) {
    const thise = this;

    this.storeLib.set('meal', null);
    this.storeLib.set('meal-badge', null);
    this.storeLib.set('meal-pdf', o.getPdf_asB64());
    this.storeLib.set('meal-img', o.getWebp_asB64());

    if (!topay || !this.activePayment) {
      this.show_unpaid(o);
      return;
    }

    this.go_payment(o);
  }
  private go_payment(o: RestaurantOrderInfo) {
    if (o.getPaymenturl()) {
      this.showPayment(o);
      return true;
    }
    this.show_unpaid(o);
  }
  private show_unpaid(o: RestaurantOrderInfo){
    this.route.navigateByUrl('/meal-unpaid?orderid=' + o.getOrderid());
  }
  private show_signup() {
    if (!this.userLib.Data.token?.getProfile().getIsguest()) { return false; }
    if (this.toSignup === '') { return false; }

    this.dlgLib.confirm(this.toSignup, r => {

        if (r.yes) {
          this.route.navigateByUrl('/signup');
        } else {
          this.route.navigateByUrl('/resto');
        }
    }, this.resName);

    return true;
  }

  private showPayment(o: RestaurantOrderInfo) {
    this.storeLib.set('meal-pay-id', o.getOrderid());
    window.location.href = o.getPaymenturl();
  }

  closeAlcohol() {
    this.hasAlcohol = false;
    this.canCheckOut();
  }
}
