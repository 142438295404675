import { Injectable } from '@angular/core';
import { BinaryWriter } from 'google-protobuf';
import { Meal } from '../../../libs/proto/restaurant_pb';

@Injectable({
  providedIn: 'root'
})
export class RestaurantMealConversionService {

  constructor() { }

  ToStorage(req: Meal) {
    const w = new BinaryWriter();
    Meal.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (c: Meal, errorConvert: any) => void) {
    try {
      callback( Meal.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }
}
