<div class="mdc-layout-grid h-100">

<ng-container [ngTemplateOutlet]="loading" *ngIf="iswaitsignin">
</ng-container>   

<div class="mdc-layout-grid h-100" *ngIf="err_invalid_token">
    <div class="welcome-wrong-token-page" [ngStyle]="{'background-image': 'url('+ bgURL + ')'}">
        <div class="page-logo">
            <img class="login" src="{{ this.logoURL }}" alt="logo" title="logo">
            <br/>
            <div class="page-message">
              {{ 'guest.wrong-token-message' | translate}}
            </div>
            <br/>
            <div class="page-message">
               <button (click)="gotohome()" mat-raised-button color="primary">{{ 'guest.home-button' | translate}}</button>
            </div>
          </div>
    </div>
</div>

<div class="mdc-layout-grid h-100" *ngIf="showmessage">
  <div class="form-wait-bg"></div>
  <div class="page-logo">
          <input type="hidden" id="imgerr" value="{{ this.logoURL }}"/>
          <img class="login" onerror="this.src=document.getElementById('imgerr').value" src="{{ this.customerLogo }}" alt="logo" title="logo">
          <br/>
          <div class="page-message" [innerHTML]="checkmessage">
           
          </div>
          <br/>
          <div class="page-message">
             <button (click)="next()" mat-raised-button color="primary">{{ 'guest.next-button' | translate}}</button>
          </div>
        </div>
  </div>
</div>

  
<ng-template #loading>
    <div class="form-wait-bg"></div>
    <div class="form-wait">
      <div class="t-center">{{ 'guest.wait-signin' | translate }}</div>
      <br />
      <mat-spinner></mat-spinner>
    </div>
</ng-template>