import { Injectable } from "@angular/core";
import { GrpcLibServiceOption } from "../../grpc/grpc-lib.service";
import { GrpcStreamLibService } from "../../grpc/stream/grpc-stream-lib.service";

@Injectable({
  providedIn: 'root'
})
export class StreamPathConverter {

  constructor(
    private grpcStreamLib: GrpcStreamLibService,
  ){}
  /**
   * get current Stream index
   */
   get StreamIndex() {
    const rets = location.pathname.match(/streams?([0-9]{0,})/) || [];
    return rets[1] || '';
  }

  /**
   * get Stream
   * @returns function to get Stream
   */
   fnGetStream(option?: GrpcLibServiceOption) {
    switch (this.StreamIndex) {
      case '2':
        return this.grpcStreamLib.getStream2(option);
      case '3':
        return this.grpcStreamLib.getStream3(option);
      case '4':
        return this.grpcStreamLib.getStream4(option);
      default:
        return this.grpcStreamLib.getStream(option);
    }
  }
}
