import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { MobileApp, Group } from '../../../libs/proto/commUnity_pb';
import { MobApp } from '../../../libs/proto/mobile_pb';
import * as grpcWeb from 'grpc-web';
import { Observable } from 'rxjs';
import { GroupConversionService } from '../../conversion/group/group-conversion.service';
import { communityAppType } from '../../../config/type';

@Injectable({
  providedIn: 'root'
})
export class GrpcGroupLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: GroupConversionService
  ) { }

  getGroup(option?: GrpcLibServiceOption): Promise<Group>{
    return new Observable<Group>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getGroupOffline(opt);
      } else {
        this._getGroupOnline(opt);
      }

    }).toPromise();
  }

  private _getGroupOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorage(
      this.storLib.get('group'), (ns, e) => {
        option.call.subscribe.next(ns);
        option.call.subscribe.complete();
    });
  }

  private _getGroupOnline(option: GrpcLibServiceOption){
    const req = new MobApp();
    req.setMobileapp( this.userLib.userApp );
    this.grpcLib.MobileClient.getGroup(req, {}, () => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getGroupOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getGroupOnlineStatus(s, option);
      })
      .on('data', (r: Group) => {
        this._getGroupOnlineData(r, option);
      })
      .on('end', () => {
        this._getGroupOnlineEnd(option);
      });
  }

  private _getGroupOnlineData(ret: Group, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getGroupOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getGroupOnline(option);
    }, option);
  }

  private _getGroupOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getGroupOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.group = true;
      if (option.KeepInCache || false) {
        if (option.call.data?.length > 0) {
          this.storLib.set(
            'group', this.convLib.ToStorage(option.call.data[0])
          );
        }
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getGroupOnline(option);
      }, option);
    }
  }
}
