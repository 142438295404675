import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { Empty, GolfClass, GolfClassesQuery, Place, RequestID } from '../../../libs/proto/commUnity_pb';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { PlaceConversionService } from '../../conversion/place/place-conversion.service';
import { GolfClassConversionService } from '../../conversion/golf_class/golfclass-conversion.service';
import { ICS } from 'src/app/libs/proto/mobile_pb';

@Injectable({
  providedIn: 'root'
})
export class GrpcGolfClassLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: GolfClassConversionService,
  ) { }

  getGolfClasses(option?: GrpcLibServiceOption): Promise<GolfClass[]>{
    return new Observable<GolfClass[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getGolfClassesOffline(opt);
      } else {
        this._getGolfClassesOnline(opt);
      }

    }).toPromise();
  }

  private _getGolfClassesOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('g-class-s') || this.storLib.get('g-class')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getGolfClassesOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getGolfClasses(option.call?.req, {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getGolfClassesOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getGolfClassesOnlineStatus(s, option);
      })
      .on('data', (r: GolfClass) => {
        this._getGolfClassesOnlineData(r, option);
      })
      .on('end', () => {
        this._getGolfClassesOnlineEnd(option);
      });
  }

  private _getGolfClassesOnlineData(ret: GolfClass, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getGolfClassesOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getGolfClassesOnline(option);
    }, option);
  }

  private _getGolfClassesOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getGolfClassesOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.golfClass = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'g-class', ''
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getGolfClassesOnline(option);
      }, option);
    }
  }

  attendGolfClass(id: string){
    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: id
        }
      });
      opt.call.subscribe = obs;

      this._attendGolfClassOnline(opt);

    }).toPromise();
  }
  private _attendGolfClassOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);

    this.grpcLib.MobileClient.attendGolfClass(req, {
      token: this.userLib.Data.token?.getToken()
    }, (e, _) => {

      if (e != null) {

        this.grpcLib.handleError(e, () => {
          this._attendGolfClassOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(true);
        option.call.subscribe.complete();
      }
    });
  }

  getMyGolfClasses(option?: GrpcLibServiceOption): Promise<GolfClass[]>{
    return new Observable<GolfClass[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getMyGolfClassesOffline(opt);
      } else {
        this._getMyGolfClassesOnline(opt);
      }

    }).toPromise();
  }

  private _getMyGolfClassesOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('g-mclass-s') || this.storLib.get('g-mclass')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getMyGolfClassesOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getMyGolfClasses(new Empty(), {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getMyGolfClassesOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getMyGolfClassesOnlineStatus(s, option);
      })
      .on('data', (r: GolfClass) => {
        this._getMyGolfClassesOnlineData(r, option);
      })
      .on('end', () => {
        this._getMyGolfClassesOnlineEnd(option);
      });
  }

  private _getMyGolfClassesOnlineData(ret: GolfClass, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getMyGolfClassesOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getMyGolfClassesOnline(option);
    }, option);
  }

  private _getMyGolfClassesOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getMyGolfClassesOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.myGolfClass = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'g-mclass', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getGolfClassesOnline(option);
      }, option);
    }
  }

  cancelGolfClassReservation(id: string){
    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: id
        }
      });
      opt.call.subscribe = obs;

      this._cancelGolfClassReservationOnline(opt);

    }).toPromise();
  }
  private _cancelGolfClassReservationOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);

    this.grpcLib.MobileClient.cancelGolfClassReservation(req, {
      token: this.userLib.Data.token?.getToken()
    }, (e, _) => {

      if (e != null) {

        this.grpcLib.handleError(e, () => {
          this._cancelGolfClassReservationOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(true);
        option.call.subscribe.complete();
      }
    });
  }

  generateICSConfirmGolfClass(id: string):Promise<ICS[]>{
    return new Observable<[ICS]>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: id
        }
      });
      opt.call.subscribe = obs;

      this._generateICSConfirmGolfClassOnline(opt);

    }).toPromise();
  }
  private _generateICSConfirmGolfClassOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);

    this.grpcLib.MobileClient.generateICSConfirmGolfClass(req, {
      token: this.userLib.Data.token?.getToken()
    }, (e)=>{})
    .on('error', (e: grpcWeb.RpcError) => {
      this._generateICSConfirmGolfClassOnlineError(e, option);
    })
    .on('status', (s: grpcWeb.Status) => {
      this._generateICSConfirmGolfClassOnlineStatus(s, option);
    })
    .on('data', (r: ICS) => {
      this._generateICSConfirmGolfClassOnlineData(r, option);
    })
    .on('end', () => {
      this._generateICSConfirmGolfClassOnlineEnd(option);
    });
  }

  private _generateICSConfirmGolfClassOnlineData(ret: ICS, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _generateICSConfirmGolfClassOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._generateICSConfirmGolfClassOnline(option);
    }, option);
  }

  private _generateICSConfirmGolfClassOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _generateICSConfirmGolfClassOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._generateICSConfirmGolfClassOnline(option);
      }, option);
    }
  }
}
