import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Post } from 'src/app/libs/proto/forum_pb';
import { Router } from '@angular/router';
import { GrpcPostLibService } from 'src/app/service/grpc/post/grpc-post-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-choose-reply',
  templateUrl: './choose-reply.component.html',
  styleUrls: ['./choose-reply.component.sass']
})


export class ChooseReplyComponent implements OnInit {
  phoneNumber: string;
  emailAddress: string;

  @Input() data?: Post;
  @Output() closeMe = new EventEmitter();

  constructor(
    private route: Router,
    private grpcPostLib: GrpcPostLibService,
    private userLib: UserLibService,
    private storeLib: StorageLibService,
    private sfurl: DomSanitizer) {}

  ngOnInit(): void {
    this.phoneNumber = this.data.getMemberphone();
    this.emailAddress = this.data.getMemberemail();
  }
  cancel(){
    this.closeMe.emit();
  }
  tellink(tel: string) {
    return this.sfurl.bypassSecurityTrustUrl(tel);
  }
  get box_height() {
    let h = 68;
    h += (this.data?.getReplytypesList().length * 60);
    return h  + 'px';
  }
}
