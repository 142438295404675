<app-sidenav page="menu-confirm" data="{{ product?.getId() }}" menuType>

<div class="form-list">

<div class="product-page">
<mat-card class="product-card">
  <mat-card-header>
    <mat-card-title>{{ product?.getName() }}</mat-card-title>
  </mat-card-header>
  <mat-card-footer [class]="'product-price'">
    <span *ngIf="product_qty">{{ 'product.qty' | translate }} {{ product_qty }}</span>
    {{ ((product?.getPrice() | number:'1.2-2') || '').replace(',',' ').replace('.',',') }}{{ currencysymbol }}
  </mat-card-footer>
</mat-card>

<div class="content">
<div class="top-image"
    [ngStyle]="{'background-image': 'url(' + product?.getImagesList()[0] +')'}">
  </div>

<div #boxOption class="box-options">
  <div class="row">
    <div class="col1">{{ 'menu-confirm.quantity' | translate }}</div>
    <div class="col2">
      <mat-form-field>
        <mat-select [(ngModel)]="mealForm.qtySelect" >
          <mat-option (click)="updateOrder()"
            *ngFor="let s of '1,2,3,4,5,menu-confirm.Other'.split(',')"
            [value]="s">{{s | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row last" >
    <div class="col1">{{ 'menu-confirm.total' | translate }}</div>
    <div class="col2">
      <div class="total">
        {{ ((total | number:'1.2-2') || '').replace(',',' ').replace('.',',') }} {{ currencysymbol }}
      </div>
    </div>
  </div>
</div>
</div>
</div>
<div class="bottom">
  <button (click)="addProductToMeal()" mat-raised-button [class]="'button-confirm'">{{ 'menu-confirm.button' | translate }}</button>
</div>

</div>

<app-ctl-qty-dialog
  [showQty]="mealForm"
  (okclick)="qtyOK()"
  *ngIf="mealForm.show"></app-ctl-qty-dialog>

<app-related-product
[products]="related"
[product]="product"
(closeRelated)="closeRelated()"
*ngIf="showRelated"></app-related-product>

</app-sidenav>