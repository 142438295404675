import { Injectable } from '@angular/core';
import { MemberType } from 'src/app/libs/proto/commUnity_pb';
import { BinaryWriter } from 'google-protobuf';

@Injectable({
  providedIn: 'root'
})
export class MemberTypeConversionService {
  constructor() {}

  ToStorage(req: MemberType) {
    const w = new BinaryWriter();
    MemberType.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (MemberType: MemberType, errorConvert: any) => void) {
    try {
      callback( MemberType.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }

  ToStorages(MemberType: MemberType[]) {
    const rets = new Array<Uint8Array | number[]>();

    MemberType.forEach(element => {
      rets.push(
        this.ToStorage(element)
      );
    });

    return {data: rets};
  }

  FromStorages(data: any, callback: (MemberType: MemberType[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const emps = new Array<MemberType>();
    const empjs: {data: (Uint8Array)[]} = data;
    try {

      empjs.data.forEach(element => {
        const emp = MemberType.deserializeBinary( element  );
        emps.push ( emp );
      });

      callback( emps, null);
    } catch (error) {
      callback( null, error);
    }
  }


}
