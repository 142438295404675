import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Leaderboard } from 'src/app/libs/proto/commUnity_pb';
import { Router } from '@angular/router';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { GrpcLeaderboardLibService } from 'src/app/service/grpc/leaderboard/grpc-leaderboard-lib.service';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { of } from 'rxjs';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { reverse } from 'dns';
@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.sass']
})
export class LeaderboardComponent implements OnInit {
  columnsToDisplay = ['name', 'brut', 'net', 'sum'];

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;
  leaderboard = [];
  leaderboard0 = [];
  group = [];

  sortcol = 'brut';
  sortdirection = -1;
  gexpand = [];

  constructor(
    private route: Router,
    private grpcLeaderboardLib: GrpcLeaderboardLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  ngOnInit(): void {
    this.grpcLeaderboardLib.getLeaderboard({
      Offline: this.storeLib.cache.leaderboard || false,
    })
    .then((v) => {      
      this.leaderboard0 = v;
      this.leaderboard = this.todtsrc(v);      
    })
    .catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }

      // return to complete
      return of([]).toPromise();
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
  SortChange(c: string) { 
    if (this.sortcol == c) {
      this.sortdirection *=  -1;    
    } else {
      this.sortcol = c;
      this.sortdirection = 1;
    }
    this.leaderboard = this.todtsrc(this.sortdata());
  }
  todtsrc(v:Leaderboard[]) {
    var newv = {};
    this.group = [];
    // add sum
    for (var i=0;i<v.length;i++) {
      // test no group
      // v[i]?.setSerie('x');
      let g = v[i]?.getSerie() || '\t';
      if (!(g in newv)) {
        newv[g] = [];
      }
      newv[g].push({
        'name': [(v[i]?.getFirstname() || ''), (v[i]?.getLastname() || '')].join(' '),
        'brut': v[i]?.getBrut() || 0,
        'net':  v[i]?.getNet() || 0,
        'g': g,
        'sum':  (v[i]?.getBrut() || 0) + (v[i]?.getNet() || 0),
      })
      
      if (this.group.indexOf(g) == -1) {
        this.group.push( g );
      }
    }    

    if (this.group.length == 0) {
      this.group.push( '\t' );
    }

    // // sort group by name and put no group at end
    var newl = [];
    // var newg = this.group.sort();
    // var fsg = newg[0]
    // if (this.group.length > 1) {
    //   newg = newg.slice(1);
    //   newg.push(fsg);
    // }
    // this.group = newg;

    for (var i=0;i<this.group.length;i++) {
      for (var l=0;l<newv[this.group[i]].length;l++) {
        newl.push(newv[this.group[i]][l])
      }
    }
   
    return newl;
  }
  expandChange(g: String) {
    let gi = this.gexpand.indexOf(g);
    if (gi == -1) {
      this.gexpand.push(g);
    } else {
      this.gexpand.splice(gi, 1);
    }
  }
  filter_group(g: String) {
    if (this.group.length == 1) return this.leaderboard;
    if (this.gexpand.indexOf(g) == -1) return [];
    
    return this.leaderboard.filter( (e) => e['g'] == g);
  }

  sortdata() {        
    return this.leaderboard0.sort((a, b) => { 
      if (this.sortcol == 'brut') {
          return this.sortdirection * (a.getBrut() - b.getBrut());
      }else if (this.sortcol == 'net') {
        return this.sortdirection * (a.getNet() - b.getNet());
      }else if (this.sortcol == 'sum') {
        return this.sortdirection * ((a.getBrut() + a.getNet()) - (b.getBrut() + b.getNet()));
      }else if (this.sortcol == 'name') {      
        let na = [(a.getFirstname() || ''), (a.getLastname() || '')].join(' ')
        let nb = [(b.getFirstname() || ''), (b.getLastname() || '')].join(' ')
        return this.sortdirection * ( na.localeCompare(nb) );
      }
    });
  }
}
