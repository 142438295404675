import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { desktopMode } from 'src/app/config/type';
import { Language } from 'src/app/libs/proto/commUnity_pb';
import { HotixReservation } from 'src/app/libs/proto/hotix_pb';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { GrpcAuthenLibService } from 'src/app/service/grpc/authen/grpc-authen-lib.service';
import { GrpcGuestLibService } from 'src/app/service/grpc/guest/grpc-guest-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { UserLibService } from 'src/app/service/user/user-lib.service';

@Component({
  selector: 'app-guest-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.sass']
})
export class GuestWelcomeComponent implements OnInit {

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  err_invalid_token = false;
  iswaitsignin = false;
  showmessage = false;
  checkmessage = '';

  guest: userinfo1 = new userinfo1();
  step = 1;

  constructor(
    private router: Router,
    private userLib: UserLibService,
    private detector: DeviceLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private actRoute: ActivatedRoute,
    private grpcAuthLib: GrpcAuthenLibService,
    private grpcGuestLib: GrpcGuestLibService,
  ) {
    userLib.setPageLanguage(translate);
  }
  
  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
  getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }

  get logoURL() {
    return 'assets/icons/icon-128x128.png';
  }

  get bgURL() {
    return 'assets/images/login_bg.jpeg';
  }
  /**
   * goto login page
   */
  gotohome () {
    this.router.navigateByUrl('/login');
  }

  ngOnInit(): void {
    // reset
    this.userLib.clearTemporary();

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });

    // get token
    this.actRoute.paramMap.subscribe( p => {
      if (p.get('token')) {
        this.validate_token(p.get('token'));   
      } else {
        this.err_invalid_token = true;
      }
    });

  } 

  /**
   * validate token to get user/password for signin
   * @param t token from email link
   * @returns true/false
   */
  validate_token(t: string){    
    if (parseFloat(t) > 0) {
      this.err_invalid_token = true;
      return;
    }

    this.iswaitsignin = true;
    try {
      let userpass = atob(t).split('&');  
      var userpassk = {}
      for(var i=0;i<userpass.length;i++) {
        let es = userpass[i].split('=');
          if (es.length == 2) {
            userpassk[es[0]] = es[1];
          }
        }   
    } catch {
      this.iswaitsignin = false;
      this.err_invalid_token = true;
      return;
    }
    
    if (!userpassk['username']) {
      this.iswaitsignin = false;
      this.err_invalid_token = true;
      return;
    }
    if (!userpassk['password']) {
      this.iswaitsignin = false;
      this.err_invalid_token = true;
      return;
    }

    console.log(userpassk)
    // reset customerid
    this.userLib.Data.selectedCustomer = null;
    this.storeLib.set('s-cus', '');
    this.userLib.Data.noContinue = true;

    let thise = this;
    thise.grpcAuthLib.signIn(userpassk['username'], userpassk['password']).then( v => {
      thise.userLib.afterLogin(v, false);
      thise.iswaitsignin = false;
      
      
      thise.grpcGuestLib.getHotixSettings().then( (v) => {
          if (v.length > 0) {
            thise.showmessage = true;
            let lang = this.detector.lang(location.href);
            const ll = Object.keys(Language).filter( lg => lg.toLowerCase() == lang);
            if (ll && ll[0]) {
              thise.checkmessage = v[0].getPrecheckinmessageMap().get(Language[ll[0]]);
            }

            if (thise.checkmessage == '') {
              thise.next();
            }
          } else {
            thise.next();
          }
      })
    }).catch( e => {
      thise.err_invalid_token = true;
      this.iswaitsignin = false;
    });    
  }  
  get customerLogo() {
    return this.userLib.Data?.token?.getCustomer().getLogo();
  }
  getprecheck(n: number, es: HotixReservation[]) {
    let thise = this;
    if ((es || []).length == 0) {
        thise.router.navigate(['/guest/reservations']);
        return;
    }
    this.grpcGuestLib.getHotixPreCheckin({
      Offline: this.storeLib.cache.guestPrecheckin || false,
      call: {
        req: es[n].getId()
      }
    }).finally( () => {
      if ((n + 1) == es.length) {
        if (es.length > 1) {
          thise.router.navigate(['/guest/reservations']);
        } else if (es.length == 1) {
          thise.router.navigate(['/guest/precheckin/',es[0].getHotixreservation().getResaid()]);
        } else {
          thise.router.navigate(['/guest/reservations']);
        }
      } else {
        this.getprecheck(n + 1, es);
      }
    });
  }

  next() {
    let thise = this;
    const prevch = this.storeLib.cache.guestReservation || false;
    this.grpcGuestLib.getHotixReservation({
      Offline: prevch,
    }).then( es => {
      
      thise.grpcGuestLib.getHotixReservationDetail({
        Offline: prevch,
      }).then( dt => {

        thise.getprecheck(0, es);

      });

    }).catch( (e) => {
      this.router.navigate(['/guest/reservations']);
    });
  }
}

class userinfo1 {
  name = 'xxx';
  prename = '';
  title = '';
  telephone = '';
  email = 'a@aaa.com';
  reason = '';
  date_arrive = '20220201';
  date_depart = '20220211';
  type = 'test';
  number_person = 2; 
}