import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { StorageLibService } from '../../storage/storage-lib.service';
import { FirebaseService } from '../firebase.service';
import { UserLibService } from '../../user/user-lib.service';

@Component({
  selector: 'app-firebase',
  templateUrl: './firebase.component.html',
  styleUrls: ['./firebase.component.sass']
})
export class FirebaseComponent implements OnInit {

  constructor(
    //@Inject(MAT_BOTTOM_SHEET_DATA) public data: { callback?: any, service?: any },
    //private bottomSheetRef: MatBottomSheetRef<FirebaseComponent>,
    private storageLib: StorageLibService,
    private userLib: UserLibService
  ) { }

  ngOnInit(): void {
  }

  acceptFCM() {
    this.storageLib.set('fcm', 'y');
    //this.data.callback(this.data.service);
  }
  refuseFCM() {
    this.storageLib.set('fcm', 'n');
    //this.bottomSheetRef.dismiss();
  }
  get customerName() {
    return this.userLib.Data.token?.getCustomer().getName() || 'Light-PWA';
  }
}
