<app-toolbar 
[sidenav]="this"
[pageTitle]="pageTitle"
[data]="data"
[noBack]="noBack"
[source]="source"
[noCart]="noCart"
(evtsortgolf)="eventsortgolf($event)"
(closePayment)="closepaymenttoolbar()"
[show_burger_menu]="show_burger_menu"
(eventsType)="eventChange($event)"
[page]="page" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<mat-sidenav-container autosize>
    <mat-sidenav *ngIf="show_burger_menu"
      [mode]="isMobile ? 'over' : 'side'" [opened]="isMobile && canOpen ? 'false' : 'true'">
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/']">
          <span class="entry">
            <mat-icon>house</mat-icon>
            <span>{{ 'home.title' | translate}}</span>
          </span>
        </a>
        <app-menu-type-side [menu]="menu"></app-menu-type-side>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>