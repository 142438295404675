import { Pipe, PipeTransform } from '@angular/core';
import { SearchLib } from './search-lib';

@Pipe({
    name: 'GroupBySearchType',
    pure: true
})
export class GroupBySearchType implements PipeTransform {

    transform(collection: Array<any>): Array<any> {

        if (!collection) {
            return null;
        }
        const groupedCollection = collection
          .filter(item => SearchLib.getTypeValue(item))
          .reduce((previous, current) => {
                const t = SearchLib.getTypeValue(current);
                if (!previous[t]) {
                    previous[t] = [current];
                }
                else {
                    previous[t].push(current);
                }

                return previous;
        }, {});

        return Object.keys(groupedCollection).map(key => ({
            label: key,
            items: groupedCollection[key]
        }));
    }
}
