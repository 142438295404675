<div class="notification-head">
  <div
  class="notification-icon"
  [ngStyle]="{'background-image': 'url(' + ((data.notification?.icon || data.notification?.image) | pipeWebp) + ')'}">
  </div>
  <h2 class="notification-title">{{ data.notification?.title || 'Light PWA' }}</h2>
  <button class="float-right" (click)="close()" mat-icon-button aria-label="close">
    <mat-icon>close</mat-icon>
  </button>
</div>

<p class="notification-body" [innerHTML]="data.notification?.body | pipeDescription"></p>
