import { Component, OnInit, OnDestroy } from '@angular/core';
import { Member, Title, PoloSize, Country, MemberBusinessCard } from 'src/app/libs/proto/commUnity_pb';
import { Subscription } from 'rxjs';
import { GrpcMemberLibService } from 'src/app/service/grpc/member/grpc-member-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { GrpcUserService } from 'src/app/service/grpc/user/grpc-user.service';
import { desktopMode } from '../../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { GetMemberBusinessCardRequest, MemberToken } from 'src/app/libs/proto/mobile_pb';

export class MemberBusinessCardComponentBase {
  title = '';
  noback = '';

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;
  isflip = false;
  memberbusiness?: MemberBusinessCard;
  showqr = true;
  // true show membercard mode
  showvalid = false;
  // true when data > now
  valid = false;  
  // when get data
  datavalid = true;
  // loading
  loading = false;

  get canseerenew() : boolean {
    return this.memberbusiness?.getSettings().getShowrenewon();
  }
  get canseepaidok() : boolean {
    return this.memberbusiness?.getSettings().getShowpaidok();
  }

  get businessname() : string {
    var ret = [];
    if (this.memberbusiness?.getSettings().getShowfirstname()) {
      ret.push(this.memberbusiness?.getFirstname() ?? '')
    }
    if (this.memberbusiness?.getSettings().getShowlastname()) {
      ret.push(this.memberbusiness?.getLastname() ?? '')
    }
    var prefixspace = ''
    if (this.memberbusiness?.getSettings().getShowtitle()) {
      prefixspace = ' '
    }
    this.memberbusiness?.getQrcode
    return prefixspace + ret.join(' ');
  }

  businesstitle(title: Title) : string {
    var rettitle = '';
    Object.keys(Title).map( c => {
      if (Title[c] === title) { rettitle = c; }
    })
    return rettitle;
  }

  flip(){
    this.isflip = !this.isflip;
  }  

  sponsorslogo(num:number): string {
    let ls = this.memberbusiness?.getSettings()?.getSponsorimagesList() ?? [];
    if (num > ls.length) return '';

    return ls[num - 1];
  }

  get cover(): string {
    return this.memberbusiness?.getSettings()?.getFrontcardphoto();
  }
}
@Component({
  selector: 'app-member-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.sass']
})
export class MemberBusinessCardComponent extends MemberBusinessCardComponentBase implements OnInit {
   
  constructor(
    private grpcMemberLib: GrpcMemberLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private safurl: DomSanitizer,
    private detector: DeviceLibService,
  ) {
    super();    
    userLib.setPageLanguage( this.translate );
  }

  ngOnInit(): void {
    const thise = this;
    
    let req = new GetMemberBusinessCardRequest();
    req.setMenutype ( this.getmenuType());

    thise.grpcMemberLib.getMemberBusinessCard({
      Offline: false,
      call: {
        req: req,
      }
    }).then( ns => {
      if (ns && ns.length > 0) {
        thise.memberbusiness = ns[0];
      }
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });

  }


  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }

}
