import { Component, OnInit } from '@angular/core';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { DialogServiceService } from 'src/app/service/dialog/dialog-service.service';
import { FirebaseService } from 'src/app/service/firebase/firebase.service';
import { GrpcCustomerLibService } from 'src/app/service/grpc/customer/grpc-customer-lib.service';
import { GrpcShopLibService } from 'src/app/service/grpc/shop/grpc-shop-lib.service';
import { ShopSettings } from 'src/app/libs/proto/shop_pb';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { desktopMode } from 'src/app/config/type';
import { GrpcPlaceLibService } from 'src/app/service/grpc/place/grpc-place-lib.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.sass']
})
export class AboutUsComponent implements OnInit {

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  hasAboutUs: boolean;
  photo: string;
  title: string;
  shortDesc: string;
  desc: string;
  link: string;
  placeId: string;

  constructor(
    private route: Router,
    private storageLib: StorageLibService,
    private userLib: UserLibService,
    private alertLib: DialogServiceService,
    private translate: TranslateService,
    private fcmLib: FirebaseService,
    private detector: DeviceLibService,
    private grpcLib: GrpcShopLibService,
    private grpcPlaceLib: GrpcPlaceLibService,
  ) {
    userLib.setPageLanguage(translate);
  }
  ngOnInit(): void {
    const thise = this;
    this.grpcLib.getShopSettings({
      Offline: this.storageLib.cache.shopSetting || false,
    }).then( s => {
      this.hasAboutUs = s.hasAboutus();
      if (this.hasAboutUs){
        this.title = s.getAboutus().getTitle();
        this.photo = s.getAboutus().getPhoto();
        this.shortDesc = s.getAboutus().getShortdescription();
        this.desc = s.getAboutus().getDescription();
        
        thise.grpcPlaceLib.getPlace({
          Offline: this.storageLib.cache.places || false,
          call: {
            req: s.getAboutus().getPlaceid(),
          }
        }).then( ns => {
          // check if place exist
          const ns0 = ns.filter( (e) => e.getId() == s.getAboutus().getPlaceid());
          if (ns0 && ns0.length > 0){
            this.placeId = s.getAboutus().getPlaceid();         
          }
        });
      }
    });
    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });
  }
  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
  }
