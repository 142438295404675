import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Ad } from '../../libs/proto/commUnity_pb';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.sass']
})
export class AdsComponent {

  @Input() Ad?: Ad;
  @Output() evt = new EventEmitter();

  constructor(private router: Router) {}

  close() {
    this.evt.emit();
  }
  showAd() {
    window.open(this.Ad?.getUrl());
    this.evt.emit();
  }
}
