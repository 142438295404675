import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Empty, HomePageBanner } from '../../../libs/proto/commUnity_pb';
import * as grpcWeb from 'grpc-web';
import { Observable, Subscribable, Subscriber } from 'rxjs';
import { UserLibService } from '../../user/user-lib.service';

@Injectable({
  providedIn: 'root'
})

export class GrpcBannerLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
  ) { }

  getBanner(option?: GrpcLibServiceOption): Promise<HomePageBanner[]>{
    return new Observable<HomePageBanner[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        // TODO:
      } else {
        this._getBannerOnline(opt);
      }
    }).toPromise();
  }

  private _getBannerOnline(option: GrpcLibServiceOption) {

      this.grpcLib.MobileClient.getHomePageBanners(new Empty(), {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getBannerOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getBannerOnlineStatus(s, option);
      })
      .on('data', (a: HomePageBanner) => {
        this._getBannerOnlineData(a, option);
      })
      .on('end', () => {
        this._getBannerOnlineEnd(option);
      });
  }

  private _getBannerOnlineData(ret: HomePageBanner, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getBannerOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getBannerOnline(option);
    }, option);
  }

  private _getBannerOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getBannerOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      if (option.KeepInCache || false) { }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getBannerOnline(option);
      }, option);
    }
  }
}
