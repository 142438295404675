import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { Alert } from '../../libs/proto/commUnity_pb';
import { GrpcAlertLibService } from '../../service/grpc/alert/grpc-alert-lib.service';
import { of, Subscription } from 'rxjs';
import { GrpcLibService } from '../../service/grpc/grpc-lib.service';
import { Router } from '@angular/router';
import { SwiperOptions } from 'swiper/types/swiper-options';

import SwiperCore, { Navigation } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.sass']
})
export class AlertComponent implements OnInit {

  alert$: Promise<Alert[]>;
  eventI = 0;
  @Output() evt = new EventEmitter();
  config: SwiperOptions = {
    navigation: true,
  };
  constructor(
    private route: Router,
    private userLib: UserLibService,
    private grpcLib: GrpcAlertLibService
  ) { }

  ngOnInit(): void {
    this.alert$ = this.grpcLib.getAlert({

    }).then( als => {
      return of(als).toPromise();
    })
    .catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
  }
  get logo() {
    return this.userLib.designLogo;
  }
  close() {
    this.evt.emit();
  }
  get backgroundColor() {
    return this.userLib.Data.token?.getCustomer().getAlertbackgroundcolor() || 'white';
  }

  get textColor() {
    return this.userLib.Data.token?.getCustomer().getAlerttextforegroundcolor() || 'black';
  }
}
