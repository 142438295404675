<div class="box-related">
  <div class="bg"></div>
  <div class="form-list dlg">
    <button mat-icon-button (click)="close()" class="close-but"><mat-icon>cancel</mat-icon></button>
    <div class="title" [ngStyle]="{'background-color': backgroundColor, 'color': foregroundColor}">
      <div class="parent-product">
        <table><tr>
          <td style="font-size:2em;width: 100%;"><mat-label>{{ product.getName() }}</mat-label></td>
          <td><div mat-card-avatar class="item-img"
            [ngStyle]="{'background-image': 'url(' + (product.getImagesList()||[])[0] + ')'}" >
      </div></td>
        </tr>
      <tr><td colspan="2">
        <mat-label>{{ 'related.title' | translate }}</mat-label>
      </td></tr>
      </table>


      </div>
    </div>

    <mat-action-list [class]="'cart-list'">
      <mat-card class="card-bottom" *ngFor="let line of products; index as lineno">
        <div class="cart-table">
          <div class="cart-row">
            <div mat-card-avatar class="cart-col cart-col1 item-img"
              [ngStyle]="{'background-image': 'url(' + (line.getImagesList()||[])[0] + ')'}" >
            </div>
            <div class="cart-col cart-col2">
              <div class="cart-title"> {{ line.getName() }}</div>
              <div class="cart-price">
              <span #totalElem>
                {{ (lines[+lineno]?.getTotalprice()| number:'1.2-2')?.replace(',',' ').replace('.',',') }}
              </span> {{ currencysymbol }}</div>
            </div>
            <div class="cart-col cart-col3 right-box">
              <span class="qty">
                <button class=""
                  [matMenuTriggerFor]="qtyBox">
                  <span [ngClass]="{'fix-safari': isSafari}" #qtyElem>{{ qtys[lineno] }}</span>
                </button>
              </span>
              <mat-menu #empBox ></mat-menu>
              <mat-menu #qtyBox xPosition="before">
                <button (click)="addProductToQty(lineno, s, qtyElem, totalElem)"
                *ngFor="let s of '1,2,3,4,5,menu-confirm.Other'.split(',')"
                mat-menu-item>{{ s | translate }}</button>
              </mat-menu>

              <div class="delete">
                <button (click)="addProductToMeal(lines[+lineno], qtys[lineno], qtyElem, totalElem)">
                  <mat-icon>add_circle</mat-icon>
                </button>
              </div>

            </div>
          </div>
        </div>
      </mat-card>

    </mat-action-list>
  </div>
</div>

<app-ctl-qty-dialog
  [showQty]="showQty"
  (okclick)="qtyOK()"
  *ngIf="showQty"></app-ctl-qty-dialog>
