import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Observable, Subscriber } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { ProductConversionService } from '../../conversion/product/product-conversion-service';
import { Product } from '../../../libs/proto/shop_pb';
import { RequestID } from '../../../libs/proto/commUnity_pb';

@Injectable({
  providedIn: 'root'
})
export class GrpcProductLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: ProductConversionService,
  ) { }

  getProducts(option?: GrpcLibServiceOption): Promise<Product[]>{
    return new Observable<Product[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getProductsOffline(opt);
      } else {
        this._getProductsOnline(opt);
      }
    }).toPromise();
  }

  private _getProductsOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('product-s') || this.storLib.get('product')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getProductsOnline(option: GrpcLibServiceOption){
    const r = new RequestID();
    r.setId(option.call.req);
    this.grpcLib.MobileClient.getProducts(r, {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getProductsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getProductsOnlineStatus(s, option);
      })
      .on('data', (r: Product) => {
        this._getProductsOnlineData(r, option);
      })
      .on('end', () => {
        this._getProductsOnlineEnd(option);
      });
  }

  private _getProductsOnlineData(ret: Product, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getProductsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getProductsOnline(option);
    }, option);
  }

  private _getProductsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getProductsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.product = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'product', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getProductsOnline(option);
      }, option);
    }
  }
}
