import { Component, OnInit } from '@angular/core';
import { OrderInfo, ProductOrder, PaymentStatus } from '../../../libs/proto/shop_pb';
import { ActivatedRoute } from '@angular/router';
import { GrpcShopLibService } from '../../../service/grpc/shop/grpc-shop-lib.service';
import { GrpcOrderLibService } from '../../../service/grpc/order/grpc-order-lib.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserLibService } from '../../../service/user/user-lib.service';
import { StorageLibService } from '../../../service/storage/storage-lib.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class PaymentComponent implements OnInit {

  order: ProductOrder;

  constructor(
    private actRoute: ActivatedRoute,
    private grpcLib: GrpcOrderLibService,
    private userLib: UserLibService,
    private storeLib: StorageLibService
  ) { }

  ngOnInit(): void {
    const thise = this;
    this.actRoute.queryParamMap.subscribe( p => {
      thise.grpcLib.getProductOrders({
        call: {
          req: p.get('orderid'),
        }
      }).then( ns => {
        thise.userLib.clearLastPayment();
        thise.order = ns[0];
      });
    });
  }
  get messageTag(): string {

    switch (this.order?.getPaymentstatus()) {
      case PaymentStatus.PAID:
        return 'payment.msg_paid';
      case PaymentStatus.CANCELLED:
        return 'payment.msg_cancelled';
      default:
        return 'payment.msg_unpaid';
    }
  }
  get message(): string {
    return this.order?.getOrdernumber();
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }
}
