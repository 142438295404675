import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { desktopMode } from 'src/app/config/type';
import { HotixReservation, HotixReservationDetail, HotixReservationStatus } from 'src/app/libs/proto/hotix_pb';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { GrpcGuestLibService } from 'src/app/service/grpc/guest/grpc-guest-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { UserLibService } from 'src/app/service/user/user-lib.service';

@Component({
  selector: 'app-guest-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.sass']
})
export class GuestReservationComponent implements OnInit {

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  reservation: HotixReservation;
  reservationDetail: HotixReservationDetail;

  tkeys = {};

  constructor(
    private route: Router,
    private userLib: UserLibService,
    private detector: DeviceLibService,
    private translate: TranslateService,
    private actRoute: ActivatedRoute,
    private grpcLib: GrpcGuestLibService,
    private storeLib: StorageLibService,
  ) {
    
  }
  
  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
  getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4) {
        if (this.isLandscape) { return 2; }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }

  prepareTranslate(callback: ()=>void){
    this.translate.get([
      'guest.reservation-adult',
      'guest.reservation-adults',
      'guest.reservation-child',
      'guest.reservation-childs',
      'guest.reservation-night',
      'guest.reservation-nights',
      'guest.reservation-baby',
      'guest.reservation-babys',
    ]).toPromise().then( v => {
      this.tkeys = v;
    }).finally( () => callback() );
  }

  get currencysymbol() {
    return this.userLib.Data.token?.getCustomer().getCurrencysymbol();
  }

  ngOnInit(): void {
    const thise = this;
    this.actRoute.paramMap.subscribe(p => {
      this.prepareTranslate( () => {
      thise.grpcLib.getHotixReservation({
        Offline: true,
      }).then(ns => {
        const dd = ns.filter(n => n.getHotixreservation().getResaid().toString() === p.get('id'));
        // not found any
        if (dd.length === 0) {
          thise.route.navigateByUrl('/guest/reservations');
          return;
        }
        thise.reservation = dd[0];        

        thise.grpcLib.getHotixReservationDetail({
          Offline: true,
        }).then( dt => {
          const dd = dt.filter(n => n.getReservationid() === thise.reservation.getId());
          if (dd?.length > 0) {
            thise.reservationDetail = dd[0];
          }
        });
      });
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }
  get lineHas(): string {
    var s:string[] = [];

    let dd = moment.unix(this.reservationDetail?.getHotixreservationdetail().getDeparturedate()).format('YYYYMMDD');
    let da = moment.unix(this.reservationDetail?.getHotixreservationdetail().getArrivaldate()).format('YYYYMMDD');
    
    let nights = moment(dd,'YYYYMMDD').diff(moment(da, 'YYYYMMDD'),'days');    
    let lb1 = nights > 1 ? 's': '';
    s.push(this.tkeys['guest.reservation-night'+lb1]?.replace('%s', nights));

    if (this.reservationDetail?.getHotixreservationdetail().getNumadults() > 0){
      let lb = this.reservationDetail.getHotixreservationdetail().getNumadults() > 1 ? 's' : '';
      s.push(this.tkeys['guest.reservation-adult'+lb]?.replace('%s', this.reservationDetail.getHotixreservationdetail().getNumadults()));
    }
    if (this.reservationDetail?.getHotixreservationdetail().getNumkids() > 0){
      let lb = this.reservationDetail.getHotixreservationdetail().getNumkids() > 1 ? 's' : '';
      s.push(this.tkeys['guest.reservation-child'+lb]?.replace('%s', this.reservationDetail.getHotixreservationdetail().getNumkids()));
    }
    if (this.reservationDetail?.getHotixreservationdetail().getNumbabies() > 0){
      let lb = this.reservationDetail.getHotixreservationdetail().getNumbabies() > 1 ? 's' : '';
      s.push(this.tkeys['guest.reservation-baby'+lb]?.replace('%s', this.reservationDetail.getHotixreservationdetail().getNumbabies()));
    }

    return s.join(' ');
  }
  precheck() {
    this.route.navigate(['/guest/precheckin/', this.reservation.getHotixreservation().getResaid()]);
  }
  get showlike() {
    return moment(moment.unix(this.reservation?.getHotixreservation().getDeparturedate()).format('YYYYMMDD'),'YYYYMMDD') <= moment() && 
          (this.reservation?.getStatus() == HotixReservationStatus.HOTIXRESERVATIONSTATUS_CONFIRMED);
  }
  gosatfiy(){
    this.route.navigate(['/guest/satisfaction/', this.reservation.getId()]);
  }
  gobill(){
    this.route.navigate(['/guest/bill/', this.reservation.getId()]);
  }
  get showbill() {
    return this.reservation?.getShowbill() ?? false;
  }
}