<div class="mdc-layout-grid h-100">
  <div class="n-mobile-page" [ngStyle]="{'background-image': 'url('+ bgURL + ')'}">
      <div class="page-logo">
          <img class="login" src="{{ this.logoURL }}" alt="logo" title="logo">
          <br/>
          <div class="page-message">
            {{ 'not-mobile.message' | translate}}
          </div>

          <div class="page-message">
            <qrcode [qrdata]="url" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
          </div>

          <div class="page-message">
            {{ 'not-mobile.scan_message' | translate}}
          </div>
        </div>
  </div>
</div>
