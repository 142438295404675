import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { Member, Title, PoloSize, Country } from '../../libs/proto/commUnity_pb';
import * as moment from 'moment';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { GrpcUserService } from '../../service/grpc/user/grpc-user.service';
import { MemberProfileRequest } from '../../libs/proto/mobile_pb';
import { Subject } from 'rxjs';

import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { CropperComponent } from 'angular-cropperjs';
import { MatDatepicker, MatDatepickerInput } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { Router } from '@angular/router';

interface UserLine {
  type: 'title' | 'field';
  edit?: boolean;
  name: string;
  value?: string;
  mode?: 'text' | 'number' | 'email' | 'size' | 'title' | 'birthday' | 'country';
  setFunction?: (v: any) => void;
}
interface EditBox {
  Value?: string;
  Type?: string;
  Line?: UserLine;
  Title?: string;
  show?: boolean;
  error?: string;
  view?: boolean;
}
interface CameraBox {
  title?: string;
  showSelector?: boolean;
  showPreview?: boolean;
  showCrop?: boolean;
  showCropper?: boolean;
  showSave?: boolean;
  noCamera?: boolean;
  saving?: boolean;

  base64?: string;
  old64?: string;

  cropMode?: boolean;
  moveMode?: boolean;
  crop64?: string;
  cropped?: boolean;
  cropping?: boolean;
  cropConfig?: any;

  errors?: WebcamInitError[];
  saveError?: string[];

  option?: {
    width: number;
    facing: MediaTrackConstraints;
  };
  event?: {
    webcam: Subject<void>;
  };
}

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class AccountComponent implements OnInit {

  lines: UserLine[];
  editor: EditBox;
  sizeOption: string[] = [];
  titleOption: string[] = [];
  countryOption: string[] = [];
  cameraBox: CameraBox = {};
  newPhoto?: string;
  T = {};
  vbirthdaypicker?: Date;
  vbirthdayLine?: UserLine;

  @Output() pictureEvent = new EventEmitter<WebcamImage>();
  @ViewChild('photofile') photofile: ElementRef;
  @ViewChild('imgorg') imgorg: CropperComponent;
  @ViewChild('picker') pickdate: MatDatepicker<Date>;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private platform: Platform,
    private userLib: UserLibService,
    private decPipe: DecimalPipe,
    private grpcLib: GrpcUserService,
    private translate: TranslateService,
    private dateAdp: DateAdapter<any>,
    private detector: DeviceLibService,
    private route: Router,
  ) { }

  get birthdaypicker() {
    return this.vbirthdaypicker;
  }
  set birthdaypicker(v: Date) {
    this.vbirthdaypicker = v;
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  get foreColor() {
    return this.userLib.designToolbarTextColor;
  }

  ngOnInit(): void {
    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
    
    this.dateAdp.setLocale(this.userLib.Data.lg || 'en');
    if (this.userLib.Data.lg == 'fr') registerLocaleData(localeFr);
    if (this.userLib.Data.lg == 'nl') registerLocaleData(localeNl);
    if (this.userLib.Data.lg == 'es') registerLocaleData(localeEs);
    if (this.userLib.Data.lg == 'pt') registerLocaleData(localePt);

    this.lines = [];
    this.translate.get([
      'photo.camera',
      'member.invalid_birthday'
    ]).toPromise().then( t => {
      this.T = t;
    });
    // setup
    this.cameraBox.cropMode = true;
    this.cameraBox.cropConfig = {
      autoCrop: true,
      zoomable: true,
      dragMode: 'crop',
      aspectRatio: 1 / 1,
      movable: true,
      minCanvasWidth: window.innerWidth * 0.8,
      minContainerHeight: window.innerHeight * 0.8,
      crop: (e) => {
        this.cameraBox.cropping =
            (e.detail.width > 0 && e.detail.height > 0);
      }
    };
    this.cameraBox.option = {
      width: window.innerWidth,
      facing: {
        facingMode: 'environment'
      },
    };
    this.cameraBox.event = {
      webcam: new Subject<void>()
    };

    if (this.userLib.Data.profileSettings === undefined) {
      this.grpcLib.getMemberProfileSettingss().then( s => {
        this.userLib.Data.profileSettings = s;
        this.load();
      });
    }

    this.load();
    if (this.userLib.Data.token?.getProfile()) {
      this.newPhoto = this.userLib.Data.token?.getProfile().getPhoto();
    }
  }

  private closeCameraBox() {
    this.cameraBox.title = undefined;
    this.cameraBox.base64 = undefined;
    this.cameraBox.crop64 = undefined;
    this.cameraBox.cropped = undefined;
    this.cameraBox.errors = undefined;
    this.cameraBox.old64 = undefined;
    this.cameraBox.showCrop = undefined;
    this.cameraBox.showPreview = undefined;
    this.cameraBox.showSave = undefined;
    this.cameraBox.saveError = undefined;
    this.cameraBox.saving = undefined;
    this.cameraBox.showCropper = undefined;
    this.cameraBox.cropping = undefined;

    this.cameraBox.cropMode = true;
    this.cameraBox.moveMode = false;

  }

  private load(){

    this.createGeneral();

    if (this.userLib.Data.profileSettings) {
      if (this.userLib.Data.profileSettings?.getShowprofessionaladdress()) {
        this.createContactInfo();
      }
      if (this.userLib.Data.profileSettings?.getShowpersonaladdress()) {
        this.createPersonInfo();
      }
      if (this.userLib.Data.profileSettings?.getShowsocialnetwork()) {
        this.createSocialNetwork();
      }
    }
    this.createMemberInfo();
  }

  private createGeneral() {
    const u = this.userLib.Data.token?.getProfile();
    if (u === undefined) { return; }

    const thise = this;
    let uTitle = '';
    Object.keys(Title).map( c => {
      if (Title[c] === u.getTitle()) { uTitle = c; }
      this.titleOption.push( c );
    });

    if (this.userLib.Data.profileSettings?.getShowtitle() ||
        this.userLib.Data.profileSettings?.getShowcompany() ||
        this.userLib.Data.profileSettings?.getShowactivitysector() ||
        this.userLib.Data.profileSettings?.getShowshortdescription() ||
        this.userLib.Data.profileSettings?.getShowdescription() ||
        this.userLib.Data.profileSettings?.getShowurl() ||
        this.userLib.Data.profileSettings?.getShowbirthday() ||
        this.userLib.Data.profileSettings?.getShowinterest() || false) {
      this.lines.push({type: 'title', name: 'General'});
    }
    if (this.userLib.Data.profileSettings?.getShowprivate() || false) {

    this.lines.push({type: 'field', name: 'private', edit: false,
    value: '', mode: 'title'});
    }
    this.userLib.Data.profileSettings.setShowtitle(true)

    if (this.userLib.Data.profileSettings.getShowtitle() || false) {
      this.lines.push({type: 'field', name: 'title', edit: true,
        value: uTitle, mode: 'title',
        setFunction: (v: string) => { u.setTitle( Title[v]); } });
    }

    if (this.userLib.Data.profileSettings?.getShowcompany() || false) {
      this.lines.push({type: 'field', name: 'company', edit: true,
        value: u.getCompany(),
        setFunction: (v: string) => { u.setCompany(v); } });
      }
    if (this.userLib.Data.profileSettings?.getShowactivitysector() || false) {
      this.lines.push({type: 'field', name: 'activitySector', edit: true,
      value: u.getActivitysector() ,
      setFunction: (v: string) => { u.setActivitysector(v); } });
    }
    if (this.userLib.Data.profileSettings?.getShowshortdescription() || false) {
      this.lines.push({type: 'field', name: 'shortdescription', edit: true,
      value: u.getShortdescription() ,
      setFunction: (v: string) => { u.setShortdescription(v); } });
    }
    if (this.userLib.Data.profileSettings?.getShowdescription() || false) {
      this.lines.push({type: 'field', name: 'description', edit: true,
      value: u.getDescription() ,
      setFunction: (v: string) => { u.setDescription(v); } });
    }
    if (this.userLib.Data.profileSettings?.getShowurl() || false) {
      this.lines.push({type: 'field', name: 'URL', edit: true,
      value: u.getUrl() ,
      setFunction: (v: string) => { u.setUrl(v); } });
    }
    if (this.userLib.Data.profileSettings?.getShowbirthday() || false) {
      this.lines.push({type: 'field', name: 'birthday', edit: true,
      mode: 'birthday',
      value: moment(u.getBirthday(), 'YYYYMMDD').format('DD/MM/YYYY'),
      setFunction: (v: string) => { u.setBirthday( +moment(v, 'DD/MM/YYYY').format('YYYYMMDD')  ); } });
    }
    if (this.userLib.Data.profileSettings?.getShowinterest() || false) {
      this.lines.push({type: 'field', name: 'interest', edit: true,
      value: u.getInterestList().join(',') ,
      setFunction: (v: string) => { u.setInterestList( v.split(',') ); } });
    }
  }

  private _get_country(v: string) {
    const cc = Object.keys(Country).filter( c => c === v );
    if (cc && cc[0]) { return Country[cc[0]]; }

    return Country.OTHER_COUNTRY;
  }

  private _set_country(v: number) {
    const cc = Object.keys(Country).filter( c => Country[c] === +v );
    if (cc && cc[0]) { return cc[0]; }

    return 'OTHER_COUNTRY';
  }

  private createContactInfo() {
    const u = this.userLib.Data.token?.getProfile().getProfessionaladdress();
    if (u === undefined) { return; }

    this.lines.push({type: 'title', name: 'ContactInfo'});
    this.lines.push({type: 'field', name: 'street', edit: true,
      value: u.getStreet(),
      setFunction: (v: string) => { u.setStreet( v ); } });
    this.lines.push({type: 'field', name: 'street2', edit: true,
      value: u.getStreet2(),
      setFunction: (v: string) => { u.setStreet2( v ); } });
    this.lines.push({type: 'field', name: 'city', edit: true,
      value: u.getCity(),
      setFunction: (v: string) => { u.setCity( v ); } });
    this.lines.push({type: 'field', name: 'postCode', edit: true,
      value: u.getPostcode(),
      setFunction: (v: string) => { u.setPostcode( v ); } });
    this.lines.push({type: 'field', name: 'Country', edit: true, mode: 'country',
      value: this._set_country(u.getCountry()),
      setFunction: (v: string) => { u.setCountry( this._get_country(v) ); } });
    this.lines.push({type: 'field', name: 'phone', edit: true,
      value: u.getPhone(),
      setFunction: (v: string) => { u.setPhone( v ); } });
    this.lines.push({type: 'field', name: 'mobile', edit: true,
      value: u.getMobile(),
      setFunction: (v: string) => { u.setMobile( v ); } });
    this.lines.push({type: 'field', name: 'email', edit: true,
      value: u.getEmail(), mode: 'email',
      setFunction: (v: string) => { u.setEmail( v ); } });
  }

  private createPersonInfo() {
    const u = this.userLib.Data.token?.getProfile().getPersonaladdress();
    if (u === undefined) { return; }

    this.lines.push({type: 'title', name: 'PersonInfo'});
    this.lines.push({type: 'field', name: 'street', edit: true,
      value: u.getStreet(),
      setFunction: (v: string) => { u.setStreet( v ); } });
    this.lines.push({type: 'field', name: 'street2', edit: true,
      value: u.getStreet2(),
      setFunction: (v: string) => { u.setStreet2( v ); } });
    this.lines.push({type: 'field', name: 'city', edit: true,
      value: u.getCity(),
      setFunction: (v: string) => { u.setCity( v ); } });
    this.lines.push({type: 'field', name: 'postCode', edit: true,
      value: u.getPostcode(),
      setFunction: (v: string) => { u.setPostcode( v ); } });
    this.lines.push({type: 'field', name: 'Country', edit: true, mode: 'country',
      value: this._set_country(u.getCountry()),
      setFunction: (v: string) => { u.setCountry( this._get_country(v) ); } });
    this.lines.push({type: 'field', name: 'phone', edit: true,
      value: u.getPhone(),
      setFunction: (v: string) => { u.setPhone( v ); } });
    this.lines.push({type: 'field', name: 'mobile', edit: true,
      value: u.getMobile(),
      setFunction: (v: string) => { u.setMobile( v ); } });
    this.lines.push({type: 'field', name: 'email', edit: true,
      value: u.getEmail(), });
  }

  private createSocialNetwork() {
    const u = this.userLib.Data.token?.getProfile().getSocialnetwork();
    if (u === undefined) { return; }

    this.lines.push({type: 'title', name: 'SocialNetwork'});
    this.lines.push({type: 'field', name: 'facebook', edit: true,
      value: u.getFacebook(),
      setFunction: (v: string) => { u.setFacebook( v ); } });
    this.lines.push({type: 'field', name: 'twitter', edit: true,
      value: u.getTwitter(),
      setFunction: (v: string) => { u.setTwitter( v ); } });
    this.lines.push({type: 'field', name: 'linkedin', edit: true,
      value: u.getLinkedin(),
      setFunction: (v: string) => { u.setLinkedin( v ); } });
    this.lines.push({type: 'field', name: 'instagram', edit: true,
      value: u.getInstagram(),
      setFunction: (v: string) => { u.setInstagram( v ); } });
    this.lines.push({type: 'field', name: 'snapchat', edit: true,
      value: u.getSnapshat(),
      setFunction: (v: string) => { u.setSnapshat( v ); } });
    this.lines.push({type: 'field', name: 'whatsapp', edit: true,
      value: u.getWhatsapp(),
      setFunction: (v: string) => { u.setWhatsapp( v ); } });
  }

  private createMemberInfo() {
    const u = this.userLib.Data.token?.getProfile();
    if (u === undefined) { return; }

    let uSize = '';
    Object.keys(PoloSize).map( c => {
      if (PoloSize[c] === u.getPolosize()) { uSize = c; }
      if (PoloSize[c] !== PoloSize.OTHER_POLO_SIZE)  {
        this.sizeOption.push(
          c
        );
      }
    });

    Object.keys(Country).map( c => {
        this.countryOption.push(
          c
        );
    });

    const uIndex = this.decPipe.transform( u.getIndex(), '1.1-1')?.replace(/,/g, ' ').replace(/\./g, ',');

    if (this.userLib.Data.profileSettings?.getShowlicense() || false) {
      this.lines.push({type: 'field', name: 'license', edit: true,
        value: u.getLicense(),
        setFunction: (v: string) => { u.setLicense( v ); } });
    }
    if (this.userLib.Data.profileSettings?.getShowindex() || false) {
      this.lines.push({type: 'field', name: 'index', edit: true,
        value: uIndex, mode: 'number',
        setFunction: (v: string) => { u.setIndex( +(v.replace(',', '.'))); } });
    }
    if (this.userLib.Data.profileSettings?.getShowpolosize() || false) {
      this.lines.push({type: 'field', name: 'poloSize', edit: true,
        value: uSize, mode: 'size',
        setFunction: (v: string) => { u.setPolosize( PoloSize[v] ); } });
    }
    if (this.userLib.Data.profileSettings?.getShowmembersince() || false) {
      this.lines.push({type: 'field', name: 'memberSince',
        value: moment(u.getMembersince(), 'YYYYMMDD').format('DD/MM/YYYY') });
    }
    if (this.userLib.Data.profileSettings?.getShowrenewon() || false) {
      this.lines.push({type: 'field', name: 'renewOn',
      value: moment(u.getRenewon(), 'YYYYMMDD').format('DD/MM/YYYY') });
    }
  }

  get memberName() {
    const p = this.userLib.Data.token?.getProfile();
    return [
      p && p.getFirstname() || '',
      p && p.getLastname() || ''
    ].join(' ');
  }
  changeText() {
    this.editor.error = undefined;
  }
  preview(l: UserLine) {
    if (l.edit) { return; }

    if (this.editor === undefined) { this.editor = {}; }
    this.editor.Title = l.name;
    this.editor.Value = l.value;
    this.editor.Type = 'text';
    this.editor.view = true;
    this.editor.show = true;
  }
  edit(l: UserLine) {
    if (this.editor === undefined) { this.editor = {}; }
    this.editor.Title = l.name;
    this.editor.Line = l;
    this.editor.Value = l.value;
    if (l.mode === 'number') {
      this.editor.Value = (l.value || '').replace(',', '.');
    } else if (l.mode === 'birthday') {
      this.birthdaypicker = moment(l.value, 'DD/MM/YYYY').toDate();
    }

    this.editor.Type = l.mode;
    this.editor.show = true;
  }
  editOK() {
    if (this.editor.view) {
      this.editor = undefined;
      return;
    }
    this.editor.Line.value = this.editor.Value;
    if (this.editor.Type === 'number') {
      // display
      this.editor.Line.value = (this.decPipe.transform( this.editor.Value, '1.1-1') || '')
                                .replace(/,/g, ' ')
                                .replace(/\./g, ',');
    }

    if (this.editor.Type === 'birthday') {
      const mm = moment( this.birthdaypicker );
      if (mm.startOf('day') >= moment().startOf('day')) {
        this.editor.error = 'member.invalid_birthday';
        return;
      }
      this.editor.Line.value = mm.format('DD/MM/YYYY');
    }

    if (this.editor.Line?.setFunction) {
      this.editor.Line.setFunction( this.editor.Line.value );
      this.userLib.updateUserData();
      this.grpcLib.updateProfile(
        this.toMemberProfileRequest());
    }

    this.editor = undefined;
  }

  private toMemberProfileRequest(): MemberProfileRequest{
    const r = new MemberProfileRequest();
    const u = this.userLib.Data.token?.getProfile();

    r.setTitle( u.getTitle());
    r.setCompany( u.getCompany());
    r.setBirthday(u.getBirthday());
    r.setActivitysector( u.getActivitysector());
    r.setShortdescription( u.getShortdescription());
    r.setDescription( u.getDescription());
    r.setUrl( u.getUrl());

    r.setInterestList( u.getInterestList());
    r.setProfessionaladdress( u.getProfessionaladdress());
    r.setPersonaladdress( u.getPersonaladdress());
    r.setSocialnetwork( u.getSocialnetwork());
    r.setLicense( u.getLicense());
    r.setIndex( u.getIndex());
    r.setPolosize( u.getPolosize());
    r.setPrivate( u.getPrivate());
    r.setLicense( u.getLicense());

    if (this.cameraBox.base64) {
      r.setPhoto( this.cameraBox.base64.substr(15 + 'base64,'.length));
    }

    return r;
  }

  editClose(){
    this.editor = undefined;
  }
  get backgroundImage() {
    return this.userLib.Data.token?.getCustomer() && this.userLib.Data.token?.getCustomer().getBackgroundprofile();
  }
  get photo() {
    return this.newPhoto ||
      (this.userLib.Data.token?.getProfile() && this.userLib.Data.token?.getProfile().getPhoto());
  }
  // avoid photo cache
  get photo_suffix() {
    return '?ts=' + moment().unix();
  }
  openPhotoSelector() {
    this.photofile.nativeElement.click();
  }

  photoChange(e: any) {

    const targetSize = 320;
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (ev: any) => {
        const image = new Image();
        image.onload = () => {

          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          // calculated to max size
          // 320px
          let isizew = image.width;
          let isizeh = image.height;
          if (isizew > isizeh) {
            // landscape
            if (isizew > targetSize) {
              isizew = targetSize;
              isizeh = image.height / image.width * targetSize;
            }
          } else if (isizeh > isizew) {
            // portrait
            if (isizeh > targetSize) {
              isizeh = targetSize;
              isizew = image.width / image.height * targetSize;
            }
          }

          canvas.width = isizew;
          canvas.height = isizeh;
          context.drawImage(image,
              0,
              0,
              image.width,
              image.height,
              0,
              0,
              canvas.width,
              canvas.height
          );
          const c = canvas.toDataURL();
          if (c.length < 100) {
            return;
          }
          this.cameraBox.base64 = c;
          this.cameraBox.old64 = this.cameraBox.base64;
          this.cameraBox.showPreview = true;
          this.cameraBox.showCrop = true;

          this.photofile.nativeElement.value = '';
        };
        image.src = ev.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  cancelPhotoSelector() {
    this.closeCameraBox();
    this.cameraBox.showSelector = false;
  }

  openCamera() {
    this.cameraBox.showPreview = true;
    this.cameraBox.title = this.T['photo.camera'];
  }
  cancelCamera() {
    this.closeCameraBox();
  }

  handleWebcam(webcamImage: WebcamImage): void {
    this.pictureEvent.emit(webcamImage);
    this.cameraBox.showCrop = true;
    this.cameraBox.old64 = webcamImage.imageAsDataUrl;
    this.cameraBox.base64 = webcamImage.imageAsDataUrl;
  }

  handleWebcamError(error: WebcamInitError): void {
    if (this.cameraBox.errors === undefined) {
      this.cameraBox.errors = [];
    }

    if (error.message.indexOf('Requested device not found') >= 0) {
      this.cameraBox.noCamera = true;
    }
    this.cameraBox.errors.push(error);
  }

  resetImage() {
    this.cameraBox.cropped = false;
    this.cameraBox.base64 = this.cameraBox.old64;
    this.cameraBox.showCropper = false;
  }

  saveImage() {
    this.cameraBox.showSave = true;
    this.cameraBox.saving = true;

    this.doSaveImage();
  }
  private doSaveImage() {
    this.grpcLib.updateProfile(
      this.toMemberProfileRequest()
    ).then( m => {
      let uu = m.getPhoto().indexOf('?');
      if (uu < 0) { uu = undefined; }
      this.newPhoto = m.getPhoto().substring(0, uu);

      // update in user profile's cache
      this.userLib.Data.token?.getProfile().setPhoto(this.newPhoto);
      this.userLib.updateUserData();


      this.closeCameraBox();
      this.cameraBox.showSelector = false;
    }).catch( e => {
      const er = ['photo.save_error'];
      if (!environment.production && JSON.stringify(e) !== '{}') {
        er.push( JSON.stringify(e));
      }
      this.cameraBox.saving = false;
      this.cameraBox.saveError = er;
    }).finally( () => {
      this.cameraBox.saving = false;
    });
  }
  cancelPreview() {
    this.closeCameraBox();
  }
  okCrop() {
    this.cameraBox.base64 = this.imgorg.cropper.getCroppedCanvas().toDataURL();
    this.cameraBox.cropped = true;
    this.cameraBox.cropping = false;
    this.cameraBox.cropMode = false;
    this.cameraBox.moveMode = true;
    this.imgorg.cropper.clear();
    this.imgorg.cropperOptions.autoCrop = false;
  }
  closeSave() {
    this.cameraBox.saving = false;
    this.cameraBox.showSave = false;
  }
  cropImage() {
    this.imgorg.cropper.setDragMode('crop');
    this.cameraBox.cropMode = true;
    this.cameraBox.moveMode = false;
  }
  moveImage(){
    this.imgorg.cropper.setDragMode('move');
    this.cameraBox.moveMode = true;
    this.cameraBox.cropMode = false;
  }
  zoomIn() {
    this.imgorg.cropper.zoom(0.1);
  }
  zoomOut() {
    this.imgorg.cropper.zoom(-0.1);
  }
  rotateLeft() {
    this.imgorg.cropper.rotate(-90);
  }
  rotateRight() {
    this.imgorg.cropper.rotate(90);
  }

  cancelCrop() {
    this.imgorg.cropper.clear();
    this.cameraBox.cropping = false;
  }
  cancelError() {
    this.closeCameraBox();
  }
  get showCamera() {
    return this.platform.IOS;
  }
  get isPrivate() {
    return this.userLib.Data.token?.getProfile().getPrivate() || false;
  }
  set isPrivate(v: boolean) {
    // tslint:disable-next-line: no-non-null-assertion
    this.userLib.Data.token!.getProfile().setPrivate(v);
    this.userLib.updateUserData();
    this.grpcLib.updateProfile( this.toMemberProfileRequest() );
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4) {
        if (this.isLandscape) { return 2; }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }

  get showBusinessCard() : boolean {
    return this.userLib.Data.token?.getCustomer().getShowmemberbusinesscardpwa();
  }

  openBusinessCard() {
    this.route.navigateByUrl('/member-business-card');
  }
}
