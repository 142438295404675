<div *ngIf="data.mobileType === 'android'" color="primary" class="android-prompt">
  
  <div style="text-align: center">
    <img src="assets/icons/icon-96x96.png" />
  </div>
  <p>{{ 'pwa.android' | translate }}?</p>
  <button mat-raised-button color="primary" (click)="installPwa()">
    {{ 'Dialog.Yes' | translate }}
  </button>
  &nbsp;&nbsp;&nbsp;
  <button mat-raised-button class="float-right" (click)="close()">
    {{ 'Dialog.No' | translate }}
  </button>
</div>

<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
  <button style="margin-top: -18px;margin-right: -35px;"
    class="close-ios-inv float-right" mat-button color="white" (click)="close()"><mat-icon>close</mat-icon></button>
  <div *ngIf="!isChrome">
    <div [innerHTML]="'pwa.ios' | translate"></div>
    <div class="ios-buttons m-auto"><img src="/assets/images/ios-menu-btn.png"> <mat-icon>navigate_next</mat-icon> <img src="/assets/images/ios-add-btn.png"></div>
  </div>

  <div *ngIf="isChrome">
    <div>{{ 'pwa.ios-chrome' | translate }}</div>
  </div>

</div>

