import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { Post, Topic } from 'src/app/libs/proto/forum_pb';
import { Router } from '@angular/router';
import { GrpcPostLibService } from 'src/app/service/grpc/post/grpc-post-lib.service';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { of } from 'rxjs';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { GrpcTopicLibService } from 'src/app/service/grpc/topic/grpc-topic-lib.service';
import {MatDialog} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';


@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.sass']
})
export class ForumComponent implements OnInit, AfterContentChecked {

  posts$: Promise<Post[]>;
  topics$: Promise<Topic[]>;
  defaultCollapse: string;
  onceScroll = false;
  showNew = false;
  showTopic = '';
  showPost?: Post;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private route: Router,
    private grpcPostLib: GrpcPostLibService,
    private userLib: UserLibService,
    private storeLib: StorageLibService,
    private grpcTopicLib: GrpcTopicLibService,
    public dialog: MatDialog,
    private sfurl: DomSanitizer,
    private detector: DeviceLibService,
  ) { }

  isCollapsed(c: string) {
    if (this.defaultCollapse) { return this.defaultCollapse === 'y'; }
    return this.userLib.Data.postsPage?.collapsed?.indexOf(c) >= 0;
  }


  ngOnInit(): void {
    this.topics$ = this.grpcTopicLib.getTopic({
      Offline: this.storeLib.cache.topic || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });

    this.getPosts();

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  checkVisible(elm: HTMLElement) {
    if (!elm) { return true; }
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  private scrollToPrev() {
    const c = document.getElementById(this.userLib.Data.postsPage?.id);
    if (this.checkVisible(c)) { return; }

    c?.scrollIntoView();
  }
  ngAfterContentChecked() {
    if (this.onceScroll) { return; }
    this.scrollToPrev();
  }

  private getPosts(){
    this.posts$ = this.grpcPostLib.getPosts({
      Offline: this.storeLib.cache.post || false,
    }).then( pp => {
      if (this.defaultCollapse === 'y') {
        for (const p of pp) {
          this.expanded(p.getTopicid(), 2);
        }
        this.defaultCollapse = undefined;
      }
      return of(pp).toPromise();
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });

  }
  clearScrollFlag() {
    this.onceScroll = true;
  }

  expanded(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.postsPage === undefined) {
      this.userLib.Data.postsPage = {};
    }
    if (this.userLib.Data.postsPage.collapsed === undefined ){
      this.userLib.Data.postsPage.collapsed = [];
    }
    const ii = this.userLib.Data.postsPage?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.postsPage?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.postsPage?.collapsed.push(
        id
        );
      }
    }
  }
  get dateFormat(): string {
    return this.userLib.dateFormat;
  }
  hasOneReplyType(post: Post): boolean{
    if (post.getReplytypesList().length === 1){
      return true;
    }
    else {
      return false;
    }

  }
  showUniqueReplyType(post: Post){
    if (post.getReplytypesList()[0] === 0){
      return 'call';
    }
    if (post.getReplytypesList()[0] === 1){
      return 'sms';
    }
    if (post.getReplytypesList()[0] === 2){
      return 'mail_outline';
    }
  }

  openDialog(post: Post) {
    this.showPost = post;
  }
  closePost(){
    this.showPost = undefined;
  }
  openNewPost(topicId: string, e: MouseEvent){
    e.stopPropagation();

    if (this.userLib.Data.token?.getProfile().getIsguest()) {
      this.userLib.clear();
      this.userLib.Data.signOut = true;
      this.route.navigateByUrl('/login');
      return;
    }

    this.showTopic = topicId;
    this.showNew = true;
  }
  closeNew(ok?: string) {
    this.showNew = false;
    if (ok) {
      // reset cache
      this.storeLib.cache.post = false;
      this.ngOnInit();
    }
  }

  tellink(tel: string) {
    return this.sfurl.bypassSecurityTrustUrl(tel);
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
}
