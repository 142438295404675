<app-sidenav page="account" menuType>

<div class="form-list">

<mat-card class="p-0">
  <div
    class="top-image"
    [ngStyle]="{'background-image': 'url('+ (backgroundImage | pipeWebp) +')'}">
  </div>

  <div
    [ngStyle]="{'background-image': 'url(' + (newPhoto | pipeWebp) + photo_suffix + ')'}"
    class="top-avatar">
  </div>

  <div class="camera">
    <button (click)="openPhotoSelector()" mat-icon-button>
      <mat-icon >photo_camera</mat-icon>
    </button>
    <input #photofile hidden (change)="photoChange($event)" type="file" accept="image/*">
  </div>

  <div class="business-card" *ngIf="showBusinessCard">
    <button (click)="openBusinessCard()" mat-icon-button>
      <mat-icon>business</mat-icon>
    </button>
  </div>

  <mat-card-content>

    <mat-list class="member">

      <mat-list-item class="title">
        <h2>{{ memberName }}</h2>
      </mat-list-item>

      <div *ngFor="let l of lines">
        <mat-list-item
          *ngIf="l.type === 'title'"
          [ngStyle]="{'background-color': backgroundColor,
                      color: foreColor}"
          class="group">
          <span>{{ ('member.' + l.name) | translate }}</span>
        </mat-list-item>

        <mat-list-item
          *ngIf="l.type === 'field'"
          class="item">

          <div class="col1">
            <span>{{ ('member.' + l.name) | translate }}</span>
          </div>
          <div class="col2">
            <span *ngIf="['private'].indexOf(l.name || '') < 0 && ['country','title'].indexOf(l.mode || '') < 0"  (click)="preview(l)">{{ l.value }}</span>
            <span *ngIf="['private'].indexOf(l.name || '') < 0 && ['title'].indexOf(l.mode || '') >= 0"  (click)="preview(l)">{{ ('title.' + l.value) | translate }}</span>
            <span *ngIf="['private'].indexOf(l.name || '') < 0 && ['country'].indexOf(l.mode || '') >= 0"  (click)="preview(l)">{{ ('country.' + l.value) | translate }}</span>
            <mat-slide-toggle [(ngModel)]="isPrivate" *ngIf="['private'].indexOf(l.name || '') >= 0" ></mat-slide-toggle>
            <button
              (click)="edit(l)"
              *ngIf="l.edit"
              mat-icon-button>
              <mat-icon>edit</mat-icon>
            </button>
          </div>

        </mat-list-item>
      </div>

    </mat-list>

  </mat-card-content>
</mat-card>

</div>

<div class="box-edit" *ngIf="editor?.show">
  <div class="box-edit-bg"></div>
  <mat-card [class]="'box-edit-dlg'">
    <h2 [ngStyle]="{'background-color': backgroundColor,'color': foreColor}">
        {{ ('member.' + editor.Title) | translate }}
    </h2>
    <mat-card-content>
      <p class="preview-box" *ngIf="editor.view">
        {{ editor.Value }}
      </p>
      <mat-form-field *ngIf="!editor.view" class="w-100">
        <textarea
          *ngIf="(editor.Type || 'text') === 'text'"
          [(ngModel)]="editor.Value" matInput>
        </textarea>
        <input *ngIf="['number', 'email'].indexOf(editor.Type || 'text') >= 0"
          [type]="editor.Type || 'text'"
          autocomplete="off"
          (keyup)="changeText()"
          [(ngModel)]="editor.Value" matInput />
        <mat-select
          *ngIf="['size', 'title'].indexOf(editor.Type || 'text') >= 0"
          [(ngModel)]="editor.Value">
          <div *ngIf="editor.Type === 'title'">
            <mat-option
            *ngFor="let t of titleOption"
            [value]="t">{{ ('title.' + t) | translate }}</mat-option>
          </div>
          <div *ngIf="editor.Type === 'size'">
            <mat-option
            *ngFor="let s of sizeOption"
            [value]="s">{{ s }}</mat-option>
          </div>
        </mat-select>
        <mat-select
          *ngIf="['country'].indexOf(editor.Type || 'text') >= 0"
          [(ngModel)]="editor.Value">
          <div *ngIf="editor.Type === 'title'">
            <mat-option
            *ngFor="let t of titleOption"
            [value]="t">{{ t }}</mat-option>
          </div>
          <div *ngIf="editor.Type === 'country'">
            <mat-option
            *ngFor="let s of countryOption"
            [value]="s">{{ ('country.' + s) | translate }}</mat-option>
          </div>
        </mat-select>
        <input *ngIf="['birthday'].indexOf(editor.Type || 'text') >= 0"
          autocomplete="off"
          [matDatepicker]="picker"
          (change)="changeText()"
          [(ngModel)]="birthdaypicker" matInput />
        <mat-datepicker-toggle *ngIf="['birthday'].indexOf(editor.Type || 'text') >= 0" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </mat-card-content>
    <mat-card-footer>
      <span *ngIf="editor.error" class="text-danger">{{ editor.error | translate}}</span>
      <button
        *ngIf="editor.view"
        class="btn-readonly"
        [ngStyle]="{'background-color': backgroundColor,'color': foreColor}"
        (click)="editOK()" mat-raised-button>
        {{ 'Dialog.OK' | translate }}</button>
      <button
        *ngIf="!editor.view"
        [ngStyle]="{'background-color': backgroundColor,'color': foreColor}"
        (click)="editOK()" mat-raised-button>
        {{ 'Dialog.OK' | translate }}</button>
      <button
        *ngIf="!editor.view"
        [ngStyle]="{'background-color': backgroundColor,'color': foreColor}"
        (click)="editClose()" mat-raised-button>
        {{ 'Dialog.Cancel' | translate }}</button>
    </mat-card-footer>
  </mat-card>
</div>

<div
  class="modal-popup box-selection" *ngIf="cameraBox.showSelector">
  <div class="bg"></div>
  <div class="dlg-center">
    <div class="dlg">
      <h2 class="select-title">
        {{ 'photo.select' | translate}}
      </h2>

      <div class="button-item">
        <button
          (click)="photofile.click()"
          mat-flat-button>
          {{ 'photo.gallery' | translate}}
        </button>
      </div>
      <div
        class="button-item">
        <button
          [disabled]="cameraBox.noCamera"
          (click)="openCamera()"
          mat-flat-button>
          {{ 'photo.camera' | translate }}
        </button>
      </div>
      <div class="button-item">
        <button
          (click)="cancelPhotoSelector()"
          class="cancel"
          mat-flat-button>
          {{ 'Dialog.Cancel' | translate}}
        </button>
    </div>
    </div>
  </div>
</div>

<div #previewCamera class="modal-popup"
  *ngIf="cameraBox.showPreview">
  <div class="bg"></div>
  <div class="dlg-center">
    <div *ngIf="!cameraBox.showCrop" class="box-preview">
      <webcam
        [width]="cameraBox.option.width"
        [trigger]="cameraBox.event.webcam.asObservable()"
        (imageCapture)="handleWebcam($event)"
        [allowCameraSwitch]="false"
        [videoOptions]="cameraBox.option.facing"
        (initError)="handleWebcamError($event)">
      </webcam>

      <div class="buttons">
        <button color="primary"
        (click)="cameraBox.event.webcam.next()" mat-raised-button>📸</button>

        <button color="primary"
        (click)="cancelCamera()" mat-raised-button>
        {{ 'Dialog.Cancel' | translate }}</button>
      </div>
    </div>

    <div *ngIf="cameraBox.showCrop"
      class="box-crop">
      <mat-toolbar [color]="'primary'">
        <mat-toolbar-row class="crop-tool">
          <div class="tool-button">
            <button
                [disabled]="cameraBox.cropping"
                [ngClass]="{'active': cameraBox.moveMode}"
                (click)="moveImage()"
                mat-icon-button>
              <mat-icon>open_with</mat-icon>
            </button>
          </div>
          <div class="tool-button">
            <button
              [ngClass]="{'active': cameraBox.cropMode}"
              (click)="cropImage()"
              mat-icon-button>
              <mat-icon>crop</mat-icon>
            </button>
          </div>
          <div class="tool-button">
            <button
                (click)="zoomIn()"
                mat-icon-button>
              <mat-icon>zoom_in</mat-icon>
            </button>
          </div>
          <div class="tool-button">
            <button
                (click)="zoomOut()"
                mat-icon-button>
              <mat-icon>zoom_out</mat-icon>
            </button>
          </div>
          <div
            class="tool-button">
            <button
                (click)="rotateLeft()"
                mat-icon-button>
              <mat-icon>rotate_left</mat-icon>
            </button>
          </div>
          <div
            class="tool-button">
            <button
                (click)="rotateRight()"
                mat-icon-button>
              <mat-icon>rotate_right</mat-icon>
            </button>
          </div>
          <span class="space"></span>
          <div
            *ngIf="cameraBox.cropping"
            class="tool-button">
            <button
                (click)="cancelCrop()"
                mat-icon-button>
              <mat-icon>undo</mat-icon>
            </button>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <div
        class="crop-container">

      <angular-cropper
        class="img-origin box-crop"
        [cropperOptions]="cameraBox.cropConfig"
        [imageUrl]="cameraBox.base64"
        #imgorg></angular-cropper>
    </div>

    <div class="buttons">

      <button color="warn"
        (click)="cancelPreview()" mat-raised-button>
        {{ 'Dialog.Cancel' | translate }}</button>

      <button color="primary" *ngIf="cameraBox.cropping"
        (click)="okCrop()" mat-raised-button>
        {{ 'photo.crop' | translate }}</button>

      <button color="primary" *ngIf="cameraBox.cropped"
        (click)="saveImage()" mat-raised-button>
        {{ 'photo.save' | translate }}</button>
    </div>
</div>

<div class="modal-popup box-error"
    *ngIf="this.cameraBox.errors?.length > 0">
    <div class="bg"></div>
    <div class="dlg-center">
    <div class="dlg">
      <div class="head">
      <h2
        [ngStyle]="{'background-color': backgroundColor,
                  color: foreColor}">
        {{ cameraBox.title | translate }}</h2>
        <button mat-button
          [ngStyle]="{'color': foreColor}"
          (click)="cancelError()" >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div *ngFor="let error of this.cameraBox.errors">
        <label>{{ error.message | translate }}</label>
      </div>
    </div>
    </div>
  </div>
</div>
</div>

<div class="modal-popup box-save"
  *ngIf="cameraBox.showSave">
  <div class="bg"></div>
  <div class="dlg-center">
    <div class="dlg">
      <mat-spinner *ngIf="cameraBox.saving"></mat-spinner>
      <p class="text-error"
        *ngIf="cameraBox.saveError">
        {{ cameraBox.saveError[0] | translate }}
        <BR />
        {{ cameraBox.saveError[1] }}
      </p>
      <button class="text-center" color="primary"
      *ngIf="!cameraBox.saving"
      (click)="closeSave()" mat-raised-button>
      {{ 'Dialog.Close' | translate }}</button>
    </div>
  </div>
</div>

</app-sidenav>