
<!-- 
   auto generated by order_menu.py. DO NOT EDIT.
   source: menu_type_side.py

   sample command:

   python3 order_menu.py [3,5,1,2] side > menu_type_side.html
-->

    <a *ngIf="show_place" mat-list-item [ngStyle]="{'background-color': menu == 0 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/places']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getPlacephoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getPlace() ||  ('places.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_event" mat-list-item [ngStyle]="{'background-color': menu == 1 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/events']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getEventphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getEvent() ||  ('events.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_shop" mat-list-item [ngStyle]="{'background-color': menu == 2 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/shop']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getShopphoto() +')'}"></div>
      <span [class]="'shop-menu' + (cartBadge ? '' : ' x')">{{ menuAlias?.getShop() ||  ('shop.title' | translate) }}</span>
      <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ cartBadge }}" *ngIf="cartBadge">shopping_cart</mat-icon>
    </span>
    </a>
    <a *ngIf="show_stream" mat-list-item [ngStyle]="{'background-color': menu == 3 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/streams']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getStream() ||  ('streams.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_stream2" mat-list-item [ngStyle]="{'background-color': menu == 4 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/streams2']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto2() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getStream2() ||  ('streams.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_stream3" mat-list-item [ngStyle]="{'background-color': menu == 5 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/streams3']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto3() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getStream3() ||  ('streams.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_stream4" mat-list-item [ngStyle]="{'background-color': menu == 6 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/streams4']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto4() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getStream4() ||  ('streams.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_news" mat-list-item [ngStyle]="{'background-color': menu == 7 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/news']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNewsphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getNews() ||  ('news.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_news2" mat-list-item [ngStyle]="{'background-color': menu == 8 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/news2']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews2photo() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getNews2() ||  ('news.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_news3" mat-list-item [ngStyle]="{'background-color': menu == 9 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/news3']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews3photo() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getNews3() ||  ('news.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_news4" mat-list-item [ngStyle]="{'background-color': menu == 10 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/news4']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews4photo() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getNews4() ||  ('news.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_news5" mat-list-item [ngStyle]="{'background-color': menu == 30 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/news5']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews5photo() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getNews5() ||  ('news.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_news6" mat-list-item [ngStyle]="{'background-color': menu == 31 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/news6']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews6photo() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getNews6() ||  ('news.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_news7" mat-list-item [ngStyle]="{'background-color': menu == 32 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/news7']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews7photo() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getNews7() ||  ('news.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_news8" mat-list-item [ngStyle]="{'background-color': menu == 33 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/news8']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews8photo() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getNews8() ||  ('news.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_news9" mat-list-item [ngStyle]="{'background-color': menu == 34 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/news9']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews9photo() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getNews9() ||  ('news.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_links" mat-list-item [ngStyle]="{'background-color': menu == 23 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/links']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getLink() ||  ('links.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_links2" mat-list-item [ngStyle]="{'background-color': menu == 24 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/links2']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto2() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getLink2() ||  ('links.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_links3" mat-list-item [ngStyle]="{'background-color': menu == 25 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/links3']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto3() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getLink3() ||  ('links.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_links4" mat-list-item [ngStyle]="{'background-color': menu == 26 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/links4']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto4() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getLink4() ||  ('links.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_cpsurl" mat-list-item [ngStyle]="{'background-color': menu == 22 ? backgroundColor: '',
    'color': textColor}"  [href]="cpsurl" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getCpslinkphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getCpslink() ||  ('cpsurl.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_competition" mat-list-item [ngStyle]="{'background-color': menu == 11 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/competitions']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getCompetitionphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getCompetition() ||  ('competitions.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_leader" mat-list-item [ngStyle]="{'background-color': menu == 12 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/leaderboard']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLeaderboardphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getLeaderboard() ||  ('leaderboard.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_member" mat-list-item [ngStyle]="{'background-color': menu == 13 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/members']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getMemberphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getMember() ||  ('members.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_forum" mat-list-item [ngStyle]="{'background-color': menu == 14 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/forum']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getForumphoto() +')'}"></div>
      <span [class]="'forum-menu' + (forumBadge ? '' : ' x')">{{ menuAlias?.getForum() ||  ('forum.title' | translate) }}</span>
      <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ forumBadge }}" *ngIf="forumBadge">chat</mat-icon>
    </span>
    </a>
    <a *ngIf="show_restaurant" mat-list-item [ngStyle]="{'background-color': menu == 15 ? backgroundColor: '',
    'color': textColor}" (click)="open_restaurant()" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getRestaurantphoto() +')'}"></div>
      <span [class]="'restaurant-menu' + (mealBadge ? '' : ' x')">{{ menuAlias?.getRestaurant() ||  ('menus.title' | translate) }}</span>
      <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ mealBadge }}" *ngIf="mealBadge">restaurant</mat-icon>
    </span>
    </a>
    <a *ngIf="show_benefit" mat-list-item [ngStyle]="{'background-color': menu == 16 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/benefits']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getBenefitphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getBenefit() ||  ('benefits.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_album" mat-list-item [ngStyle]="{'background-color': menu == 17 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/albums']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getAlbumphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getAlbum() ||  ('albums.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_shopprofile" mat-list-item [ngStyle]="{'background-color': menu == 18 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/about']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getAboutusphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getAboutus() ||  ('about.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_document" mat-list-item [ngStyle]="{'background-color': menu == 20 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/documents']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getDocumentphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getDocument() ||  ('document.title' | translate) }}</span>
    </span>
    </a>
    <div *ngFor="let menu of menus$ | async menus">
      <a mat-list-item [ngStyle]="{'background-color': menu == 27 ? backgroundColor: '',
      'color': textColor}" target="_blank" [href]="menu.getUrl()">
        <span class="entry side-menu">
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menu.getPhoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">{{ menu.getName() }}</span>
        </span>
      </a>
     </div>
    <a *ngIf="show_golf_teaching_class" mat-list-item [ngStyle]="{'background-color': menu == 28 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/golf_class']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getGolfclasslinkphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getGolfclasslink() ||  ('golf_class.title' | translate) }}</span>
    </span>
    </a>
    <a *ngIf="show_room_reservation" mat-list-item [ngStyle]="{'background-color': menu == 29 ? backgroundColor: '',
    'color': textColor}" [routerLink]="['/guest/reservations']" routerLinkActive="router-link-active">
    <span class="entry side-menu">
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getRoomreservationphoto() +')'}"></div>
      <span [ngStyle]="{'color': textColor}">{{ menuAlias?.getRoomreservation() ||  ('guest.menu-reservation' | translate) }}</span>
    </span>
    </a>
