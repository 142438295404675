import { Component, OnInit, OnDestroy, SecurityContext } from '@angular/core';
import { GrpcNewsLibService } from '../../service/grpc/news/grpc-news-lib.service';
import { Link, News } from '../../libs/proto/commUnity_pb';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { ActivatedRoute } from '@angular/router';
import { Subscribable, Subscription } from 'rxjs';
import { GrpcLibServiceOption } from '../../service/grpc/grpc-lib.service';
import { NewsPathConverter } from '../../service/conversion/news/news-path-converter';
import { GrpcLinksLibService } from 'src/app/service/grpc/links/grpc-links-lib.service';
import { LinksPathConverter } from 'src/app/service/conversion/links/links-path-converter';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.sass']
})
export class LinkComponent implements OnInit, OnDestroy {

  private link: Link;
  private actRouter$: Subscription;

  constructor(
    private grpcLinksLib: GrpcLinksLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private LinksLib: LinksPathConverter,
    private domSan: DomSanitizer
  ) {
    userLib.setPageLanguage( this.translate );
  }

  get url() {
    return this.domSan.bypassSecurityTrustResourceUrl(this.domSan.sanitize(SecurityContext.URL, this.link?.getUrl()));
  }

  ngOnInit(): void {
    const thise = this;
    this.actRouter$ = this.actRoute.paramMap.subscribe( p => {
      thise.LinksLib.fnGetLinks({
        Offline: true,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        thise.link = ns[0];
      });
    });
  }

  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }
}
