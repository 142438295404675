import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generate sequence of numbers
 * 
 * @param value - upper bound
 */
@Pipe({
  name: 'sequence'
})
export class SequencePipe implements PipeTransform {

  transform(value: number, ...args: any[]): number[] {
    let res: number[] = [];

    for (let i = 0; i < value; i++) {
      res.push(i);
    }

    return res;
  }

}
