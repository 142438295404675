import { SearchResult } from '../../libs/proto/mobile_pb';

export class SearchLib {

  static newsIndex(item: SearchResult) {
    let suf = '';
    if (item.getNews().getNewsnb() > 1) { suf = item.getNews().getNewsnb() + ''; }
    return suf;
  }

  static getTypeValue(item: SearchResult): string {
    let tag = '';
    if (item.getAlbum()?.getId()) {
      tag = 'album';
    } else if (item.getBenefit()?.getId()) {
      tag = 'benefit';
    } else if (item.getMember()?.getId()) {
      tag = 'member';
    } else if (item.getNews()?.getId()) {
      const suf = SearchLib.newsIndex(item);
      tag = 'news' + suf;
    } else if (item.getPlace()?.getId()) {
      tag = 'place';
    } else if (item.getProduct()?.getId()) {
      tag = 'product';
    }
    return tag;
  }
}
