import { Component, OnInit } from '@angular/core';
import { OrderInfo, ProductOrder, PaymentStatus } from '../../../libs/proto/shop_pb';
import { ActivatedRoute, Router } from '@angular/router';
import { GrpcShopLibService } from '../../../service/grpc/shop/grpc-shop-lib.service';
import { GrpcOrderLibService } from '../../../service/grpc/order/grpc-order-lib.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserLibService } from '../../../service/user/user-lib.service';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { RestaurantOrder, RestaurantOrderInfo, RestaurantPaymentStatus } from '../../../libs/proto/restaurant_pb';
import { GrpcRestaurantLibService } from '../../../service/grpc/restaurant/grpc-resto-lib.service';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';

@Component({
  selector: 'app-meal-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class MealPaymentComponent implements OnInit {

  order: RestaurantOrder;
  pdfthumb = '';
  pdfurl = '';

  constructor(
    private actRoute: ActivatedRoute,
    private grpcLib: GrpcRestaurantLibService,
    private userLib: UserLibService,
    private route: Router,
    private storeLib: StorageLibService,
    private santi: DomSanitizer,
    private dlgLib: DialogServiceService) { }

  ngOnInit(): void {
    this.actRoute.queryParamMap.subscribe( p => {
      this.get_order(p.get('orderid'));
    });
  }
  private get_order(n: string) {
    const thise = this;
    thise.userLib.clearLastMealPayment();
    thise.grpcLib.getRestaurantOrder({
      call: {
        req: n,
      }
    }).then( ns => {
      thise.userLib.clearLastMealPayment();
      thise.order = ns[0];
    });
    this.download_pdf();
  }
  private download_pdf() {
    this.pdfthumb = this.storeLib.get('meal-img');
    this.pdfurl = this.storeLib.get('meal-pdf');
    if (this.pdfthumb) { this.pdfthumb = 'data:image/webp;base64,' + this.pdfthumb; }
    if (this.pdfurl) { this.pdfurl = 'data:application/octet-stream;base64,' + this.pdfurl; }
  }
  loadpdf() {
    const lnk = document.createElement('a');
    lnk.href =  this.pdfurl;
    lnk.download = this.order.getOrdernumber() + '.pdf';
    lnk.target = '_blank';
    lnk.click();
  }
  get messageTag(): string {

    switch (this.order?.getPaymentstatus()) {
      case RestaurantPaymentStatus.ALREADYPAID:
        return 'meal-payment.msg_paid';
      default:
        return 'meal-payment.msg_unpaid';
    }
  }
  get message(): string {
    return this.order?.getOrdernumber();
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }
  closeme() {
    this.grpcLib.getRestaurantSettings().then( s => {
      const resName = s.getName();
      let toSignup = '';
      if (s.getProposeregister()) {
          toSignup = s.getProposeregistertext();
      }

      this.show_signup(resName, toSignup);
    });
  }
  private show_signup(resName: string, toSignup: string) {
    if (!this.userLib.Data.token?.getProfile().getIsguest()) { return; }
    if (toSignup === '') { return; }

    this.dlgLib.confirm(toSignup, r => {
        if (!r) { return; }
        if (r.yes) {
          this.route.navigateByUrl('/signup');
        } else {
          this.route.navigateByUrl('/resto');
        }
    }, resName);

  }
}
