import { Injectable } from '@angular/core';
import { Alert } from '../../../libs/proto/commUnity_pb';
import { BinaryWriter } from 'google-protobuf';

@Injectable({
  providedIn: 'root'
})
export class AlertConversionService {

  constructor() { }

  ToStorage(req: Alert ){
    const w = new BinaryWriter();
    Alert.serializeBinaryToWriter(req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (Alert: Alert, errorConvert: any) => void) {
    try {
      callback( Alert.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }

  ToStorages(alerts: Alert[]) {
    const rets = new Array<Uint8Array | number[]>();

    alerts.forEach(element => {
      rets.push(
        this.ToStorage(element)
      );
    });

    return {data: rets};
  }

  FromStorages(data: any, callback: (alerts: Alert[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const alerts = new Array<Alert>();
    const alertjs: {data: (Uint8Array)[]} = data;
    try {

      alertjs.data.forEach(element => {
        const alert = Alert.deserializeBinary( element  );
        alerts.push ( alert );
      });

      callback( alerts, null);
    } catch (error) {
      callback( null, error);
    }
  }

}
