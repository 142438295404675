<!--
    // TODO: alert, add design
    // TODO: alert, add title, close box, translation, buttons
-->
<h4 [ngStyle]="{'background-color': backgroundColor, 'color': foregroundColor}">{{ data.title }}</h4>
<p [innerHTML]="data.message"></p>

<div *ngIf="data.showYesNo" class="button-yesno">
  <button color="primary" class="float-right" mat-raised-button (click)="onYes()" [innerHTML]="(data.yesText || 'Dialog.Yes') | translate"></button>
  &nbsp;
  <button mat-raised-button class="float-right mr-10p" (click)="onNo()" [innerHTML]="(data.noText || 'Dialog.No') | translate"></button>
</div>

<div *ngIf="!data.showYesNo" class="button-yesno">
  <button color="primary" mat-raised-button (click)="onClose()">{{ 'Dialog.Close' | translate }}</button>
</div>
