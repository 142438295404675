import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { SignInMobileRequest, MemberToken, RequestPassword, SignUpMobileRequest  } from '../../../libs/proto/mobile_pb';
import { MobileApp, Empty, SignUpToken, OS, RequestID } from '../../../libs/proto/commUnity_pb';
import { communityAppType } from '../../../config/type';
import { UserLibService } from '../../user/user-lib.service';
import { LogLibService } from '../../log/log-lib.service';
import { HotixPreCheckin, HotixReservation, HotixReservationDetail, HotixRoomBillDetailResp, HotixSatisfactionSurvey, HotixSettings } from 'src/app/libs/proto/hotix_pb';
import { Observable } from 'rxjs';
import { GuestConversionService } from '../../conversion/guest/guest-conversion.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import * as grpcWeb from 'grpc-web';

@Injectable({
  providedIn: 'root'
})
export class GrpcGuestLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private convLib: GuestConversionService,
    private storLib: StorageLibService,
  ) { }

  getHotixReservation(option?: GrpcLibServiceOption): Promise<HotixReservation[]>{
    return new Observable<HotixReservation[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getHotixReservationOffline(opt);
      } else {
        this._getHotixReservationOnline(opt);
      }
    }).toPromise();
  }

  private _getHotixReservationOffline(option: GrpcLibServiceOption){
    this.convLib.ReservationFromStorages(
      this.storLib.get('htr-s') || this.storLib.get('htr'), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }
  private _getHotixReservationOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getHotixReservations(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getHotixReservationOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getHotixReservationOnlineStatus(s, option);
      })
      .on('data', (r: HotixReservation) => {
        this._getHotixReservationOnlineData(r, option);
      })
      .on('end', () => {
        this._getHotixReservationOnlineEnd(option);
      });
  }

  private _getHotixReservationOnlineData(ret: HotixReservation, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getHotixReservationOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getHotixReservationOnline(option);
    }, option);
  }

  private _getHotixReservationOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getHotixReservationOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.guestReservation = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'htr', this.convLib.ReservationToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getHotixReservationOnline(option);
      }, option);
    }
  }

  getHotixReservationDetail(option?: GrpcLibServiceOption): Promise<[HotixReservationDetail]>{
    return new Observable<[HotixReservationDetail]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getHotixReservationDetailOffline(opt);
      } else {
        this._getHotixReservationDetailOnline(opt);
      }
    }).toPromise();
  }

  private _getHotixReservationDetailOffline(option: GrpcLibServiceOption){
    this.convLib.ReservationDetailFromStorages(
      this.storLib.get('htd'), (ns, e) => {
        if (e == null) {
            if (ns) {
              option.call.subscribe.next( ns );
              option.call.subscribe.complete();
              return;
            }
        }

        option.call.subscribe.next(ns ?? []);
        option.call.subscribe.complete();
    });
  }
  private _getHotixReservationDetailOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getHotixReservationDetails(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getHotixReservationDetailOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getHotixReservationDetailOnlineStatus(s, option);
      })
      .on('data', (r: HotixReservationDetail) => {
        this._getHotixReservationDetailOnlineData(r, option);
      })
      .on('end', () => {
        this._getHotixReservationDetailOnlineEnd(option);
      });
  }

  private _getHotixReservationDetailOnlineData(ret: HotixReservationDetail, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getHotixReservationDetailOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getHotixReservationDetailOnline(option);
    }, option);
  }

  private _getHotixReservationDetailOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getHotixReservationDetailOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      if (option.KeepInCache || false) {
        this.storLib.set(
          'htd', this.convLib.ReservationDetailToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getHotixReservationDetailOnline(option);
      }, option);
    }
  }

  getHotixSettings(option?: GrpcLibServiceOption): Promise<[HotixSettings]>{
    return new Observable<[HotixSettings]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getHotixSettingsOffline(opt);
      } else {
        this._getHotixSettingsOnline(opt);
      }
    }).toPromise();
  }

  private _getHotixSettingsOffline(option: GrpcLibServiceOption){
    this.convLib.HotixSettingsFromStorage(
      this.storLib.get('htm'), (ns, e) => {
        if (e == null) {
            if (ns) {
              option.call.subscribe.next( [ns] );
              option.call.subscribe.complete();
              return;
            }
        }

        option.call.subscribe.next(ns ? [ns] : []);
        option.call.subscribe.complete();
    });
  }
  private _getHotixSettingsOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getHotixSettings(new Empty(), {
        token: t,
      },() => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getHotixSettingsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getHotixSettingsOnlineStatus(s, option);
      })
      .on('data', (r: HotixSettings) => {
        this._getHotixSettingsOnlineData(r, option);
      })
      .on('end', () => {
        this._getHotixSettingsOnlineEnd(option);
      });
  }

  private _getHotixSettingsOnlineData(ret: HotixSettings, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getHotixSettingsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getHotixSettingsOnline(option);
    }, option);
  }

  private _getHotixSettingsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getHotixSettingsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.guestCheckinMessage = true;      
      if (option.KeepInCache || false) {
        this.storLib.set(
          'htm', this.convLib.HotixSettingsToStorage(option.call.data[0])
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getHotixSettingsOnline(option);
      }, option);
    }
  }

  submitHotixPreCheckin(req: HotixPreCheckin){
    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: req
        }
      });
      opt.call.subscribe = obs;

      this._submitHotixPreCheckinOnline(opt);

    }).toPromise();
  }
  private _submitHotixPreCheckinOnline(option: GrpcLibServiceOption){

    this.grpcLib.MobileClient.submitHotixPreCheckin(option.call.req, {
      token: this.userLib.Data.token?.getToken()
    }, (e, _) => {

      if (e != null) {

        this.grpcLib.handleError(e, () => {
          this._submitHotixPreCheckinOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(true);
        option.call.subscribe.complete();
      }
    });
  }

  getHotixPreCheckin(option?: GrpcLibServiceOption): Promise<[HotixPreCheckin]>{
    return new Observable<[HotixPreCheckin]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getHotixPreCheckinOffline(opt);
      } else {
        this._getHotixPreCheckinOnline(opt);
      }
    }).toPromise();
  }

  private _getHotixPreCheckinOffline(option: GrpcLibServiceOption){
    this.convLib.PrecheckFromStorage(
      this.storLib.get('htp-' + option.call.req), (ns, e) => {
        if (e == null) {
            if (ns) {
              option.call.subscribe.next( [ns] );
              option.call.subscribe.complete();
              return;
            }
        }

        option.call.subscribe.next(ns ? [ns] : []);
        option.call.subscribe.complete();
    });
  }
  private _getHotixPreCheckinOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    let req = new RequestID();
    req.setId(option.call.req);
    this.grpcLib.MobileClient.getHotixPreCheckin(req, {
        token: t,
      },() => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getHotixPreCheckinOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getHotixPreCheckinOnlineStatus(s, option);
      })
      .on('data', (r: HotixPreCheckin) => {
        this._getHotixPreCheckinOnlineData(r, option);
      })
      .on('end', () => {
        this._getHotixPreCheckinOnlineEnd(option);
      });
  }

  private _getHotixPreCheckinOnlineData(ret: HotixPreCheckin, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getHotixPreCheckinOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getHotixPreCheckinOnline(option);
    }, option);
  }

  private _getHotixPreCheckinOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getHotixPreCheckinOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.guestPrecheckin = true;      
      if (option.KeepInCache || false) {
        this.storLib.set(
          'htp-' + option.call.req, this.convLib.PrecheckToStorage(option.call.data[0])
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getHotixPreCheckinOnline(option);
      }, option);
    }
  }

  setHotixSatisfactionSurvey(req: HotixSatisfactionSurvey){
    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: req
        }
      });
      opt.call.subscribe = obs;

      this._setHotixSatisfactionSurveyOnline(opt);

    }).toPromise();
  }
  private _setHotixSatisfactionSurveyOnline(option: GrpcLibServiceOption){

    this.grpcLib.MobileClient.setHotixSatisfactionSurvey(option.call.req, {
      token: this.userLib.Data.token?.getToken()
    }, (e, _) => {

      if (e != null) {

        this.grpcLib.handleError(e, () => {
          this._setHotixSatisfactionSurveyOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(true);
        option.call.subscribe.complete();
      }
    });
  }

  getHotixRoomBillDetails(option?: GrpcLibServiceOption): Promise<HotixRoomBillDetailResp[]>{
    return new Observable<HotixRoomBillDetailResp[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      this._getHotixRoomBillDetailsOnline(opt);
    }).toPromise();
  }

  private _getHotixRoomBillDetailsOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getHotixRoomBillDetails(option.call.req, {
        token: t,
      }, () => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getHotixRoomBillDetailsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getHotixRoomBillDetailsOnlineStatus(s, option);
      })
      .on('data', (r: HotixRoomBillDetailResp) => {
        this._getHotixRoomBillDetailsOnlineData(r, option);
      })
      .on('end', () => {
        this._getHotixRoomBillDetailsOnlineEnd(option);
      });
  }

  private _getHotixRoomBillDetailsOnlineData(ret: HotixRoomBillDetailResp, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getHotixRoomBillDetailsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getHotixRoomBillDetailsOnline(option);
    }, option);
  }

  private _getHotixRoomBillDetailsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getHotixRoomBillDetailsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getHotixRoomBillDetailsOnline(option);
      }, option);
    }
  }
}
