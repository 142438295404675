export const communityConfig = {
  version: '1.0',
  grpc: {
    server: {
      url_dev: 'https://web.athora.me',
      url_production: 'https://web.alvoria.com',
      dev: false,
    }
  },
  log: {
    debug : true,
    warn: true,
    info: true,
    error: true,
  }
};
