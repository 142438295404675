import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventMobile } from '../../libs/proto/mobile_pb';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GrpcEventLibService } from '../../service/grpc/event/grpc-event-lib.service';
import { GrpcReservationLibService } from 'src/app/service/grpc/reservation/grpc-reservation-lib.service';
import { EventTableReservation } from 'src/app/libs/proto/restaurant_pb';

import { AddressConversionService, AddressFromNominate } from '../../service/conversion/address/address-conversion.service';
import * as grpcWeb from 'grpc-web';

import { DialogServiceService } from '../../service/dialog/dialog-service.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import * as moment from 'moment';
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import { nn } from 'date-fns/locale';

import { ReservationComponent } from '../reservation/reservation.component';
import { MatDialog } from '@angular/material/dialog';
import { GrpcPlaceLibService } from 'src/app/service/grpc/place/grpc-place-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';

// install Swiper modules
// SwiperCore.use([Navigation]);

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.sass']
})
export class EventComponent implements OnInit, OnDestroy, AfterViewInit {
  // Reference to swiper object
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  // Swiper settings
  configSwiper: SwiperOptions = {
    enabled: true,
    initialSlide: 0,
    direction: 'horizontal',
  };

  // done is true when all events have been loaded.
  // this is to ensure that swiper does not load the
  // config before everything has loaded.
  done: boolean = false;

  events: EventMobile[];
  eventI = 0;
  eventTableReservations: EventTableReservation[];

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  // Speed at which the swiper's slide is transitionning in ms.
  slideTransitionDuration: number = 300;

  private actRouter$: Subscription;
  hasplace = false;

  constructor(
    private grpcLib: GrpcEventLibService,
    private grpcReservationLib: GrpcReservationLibService,
    private grpcPlaceLib: GrpcPlaceLibService,
    private storeLib: StorageLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private route: Router,
    private addConvLib: AddressConversionService,
    private dlgLib: DialogServiceService,
    private detector: DeviceLibService,
    private dialog: MatDialog,
  ) {
    userLib.setPageLanguage(this.translate);
  }

  ngOnInit(): void {
    const thise = this;
    this.actRouter$ = this.actRoute.paramMap.subscribe(p => {
      thise.grpcLib.getEvent({
        Offline: this.storeLib.cache.event || false,
      }).then(ns => {
        const dd = ns.filter(n => n.getEvent().getId() === p.get('d'));
        // not found any
        if (dd.length === 0) {
          thise.route.navigateByUrl('/events');
          return;
        }

        thise.grpcPlaceLib.getPlace({
          Offline: this.storeLib.cache.places || false,
          call: {
            req: dd[0].getEvent()?.getPlaceid(),
          }
        }).then( ps => {
          // check if place exist
          const ps0 = ps.filter( (e) => e.getId() == dd[0].getEvent()?.getPlaceid());
          this.hasplace = ps0 && ps0.length == 1;
        });

        thise.events = ns.filter(n => n.getEvent().getDate() === dd[0].getEvent().getDate());

        thise.eventTableReservations = Array<EventTableReservation>(thise.events?.length).fill(new EventTableReservation);
        // Load table reservation information.
        thise.events.forEach((event, i) => {
          // There is no reservation for this event.
          if (!event?.getEvent().getHastablereservation() || event?.getAttending() != 1) {
            thise.eventTableReservations[i] = null;
            return;
          }

          // Fetch reservation information.
          this.grpcReservationLib.getEventTableReservation(
            event?.getEvent().getId()
          ).then((reservation: EventTableReservation) => {
            thise.eventTableReservations[i] = reservation;
          }).catch((err: grpcWeb.RpcError) => {
            console.error(err);
            thise.eventTableReservations[i] = null;
          });
        });

        // Find the index of the array of events which corresponds to the id from the url.
        let slideIndex = thise.events.findIndex((event) => event.getEvent().getId() === dd[0].getEvent().getId());
        thise.configSwiper.initialSlide = slideIndex;
        thise.done = true; // load swiper
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }
  ngAfterViewInit(): void {

  }
  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }

  // Transition to previous slide
  slidePrev() {
    this.swiper.swiperRef.slidePrev(this.slideTransitionDuration);
  }
  // Transition to next slide
  slideNext() {
    this.swiper.swiperRef.slideNext(this.slideTransitionDuration);
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  get addressText() {
    return this.addConvLib.toAddressText(this.events[this.eventI]?.getEvent().getAddress());
  }

  showTime(e: EventMobile) {
    return !(((e?.getEvent().getTimefrom() || '00:00') === '00:00') &&
      ((e?.getEvent().getTimeto() || '23:59') === '23:59'));
  }
  /**
   * check if guest?
   *
   * @returns true when guest
   */
  checkGuest() {
    if (this.userLib.Data.token?.getProfile().getIsguest()) {
      this.userLib.clear();
      this.userLib.Data.signOut = true;
      this.route.navigateByUrl('/login');
      return true;
    }

    return false;
  }
  attend(e: EventMobile) {
    if (e.getAttending() === 1) { return; }
    if (this.checkGuest()) { return; }

    this.grpcLib.eventAttend(e.getEvent().getId()).then(ok => {
      if (ok) {
        this.attendOK('-att');
      } else {
        this.attendFail();
      }

    }).catch(() => {
      this.attendFail();
    });
  }
  notAttend(e: EventMobile) {
    if (e.getAttending() === 2) { return; }
    if (this.checkGuest()) { return; }

    this.grpcLib.eventNotAttend(e.getEvent().getId()).then(ok => {
      if (ok) {
        this.attendOK('-unatt');
      } else {
        this.attendFail();
      }

    }).catch(() => {
      this.attendFail();
    });
  }
  private attendOK(type: string) {
    const thise = this;
    this.translate.get([
      'event.ok' + type,
      'event.title'
    ]).toPromise().then(t => {
      thise.dlgLib.show(t['event.ok' + type], t['event.title'], _ => {
        thise.grpcLib.getEvent({
          Offline: false
        }).finally(() => {
          thise.route.navigateByUrl('/events');
        });
      });
    });
  }
  private attendFail() {
    const thise = this;
    this.translate.get([
      'event.ko',
      'event.title'
    ]).toPromise().then(t => {
      thise.dlgLib.show(t['event.ko'], t['event.title']);
    });
  }
  isFuture(d: number) {
    return +moment().format('YYYYMMDD') < d;
  }

  allowAttends(em?: EventMobile) {
    if (!em) { return false; }
    // -1 unlimit
    if (em.getEvent().getQt() === -1) { return true; }

    // 0 not available
    // if attend, allow to see (so user can unattend)
    if (em.getEvent().getQt() === 0) { return em.getAttending() === 2; }

    return em.getEvent().getQt() > 0;
  }

  /**
   * is reservable if d is 0 or now() is smaller or equal to d
   * @param d Number epoch time
   * @returns boolean reservation reservable
   */
  canReserve(d: number): boolean {
    // Ignore, cancel datetime is null
    if (d == 0) { return true; }

    // Current datetime is same or before reservation datetime.
    return moment().isSameOrBefore(moment.unix(d));
  }

  /**
   * Open reservation wizard only if the user has not yet reserved
   * and the user is logged in.
   * @param e EventMobile event object
   * @returns void
   */
  reserve(e: EventMobile): void {
    // Check if user has already reserved.
    if (e.getAttending() === 1) { return; }
    // Block guest.
    if (this.checkGuest()) { return; }
    // Check if can make reservation.
    if (!this.canReserve(e?.getEvent().getCancanceleventuntil())) { return; }

    this.dialog.open(ReservationComponent, {
      data: { event: e?.getEvent() },
      autoFocus: 'dialog',
      disableClose: true
    });
  }

  /**
   * is cancellable if d is 0 or now() is smaller or equal to d
   * @param d Number epoch time
   * @returns boolean reservation cancellable
   */
  canCancel(d: number): boolean {
    // Ignore, cancel datetime is null
    if (d == 0) { return true; }

    // Current datetime is same or before cancel datetime.
    return moment().isSameOrBefore(moment.unix(d));
  }

  /**
   * Open dialog only if the user has reserved
   * and the user is logged in and it is cancellable.
   * @param e EventMobile event object
   * @returns void
   */
  cancel(e: EventMobile): void {
    // Check if user has not reserved.
    if (e.getAttending() !== 1) { return; }
    // Block guest.
    if (this.checkGuest()) { return; }
    // Check if reservation can be cancelled.
    if (!this.canCancel(e?.getEvent().getCancanceleventuntil())) { return; }

    // Get reservation
    let reservation = this.eventTableReservations.find((reservation) => reservation?.getEventid() === e.getEvent().getId());

    this.translate.get([
      'reservation.cancel_title',
      'reservation.cancel_msg',
      'reservation.cancel_msg_group'
    ]).toPromise().then(t => {
      let userId = this?.userLib.Data?.token.getProfile().getId();
      let createdBy = reservation?.getCreatedby();

      let msg = t['reservation.cancel_msg'];
      if (userId == createdBy) {
        msg = t['reservation.cancel_msg_group'];
      }

      this.dlgLib.confirm(
        msg,
        (res) => {
          if (res.no) { return; }

          this.grpcReservationLib.cancelEventTableReservation(e.getEvent().getId()).then(() => {
            this.grpcLib.getEvent({
              Offline: false
            }).finally(() => {
              this.route.navigateByUrl('/events');
            });
          }).catch((err: grpcWeb.RpcError) => {
            console.error(err);
            this.translate.get([
              'reservation.cancel_title',
              'reservation.cancel_err'
            ]).toPromise().then(t => {
              this.dlgLib.show(t['reservation.cancel_err'], t['reservation.cancel_title']);
            });
          });
        },
        t['reservation.cancel_title']
      );
    });
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
  getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4) {
        if (this.isLandscape) { return 2; }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
}
