import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { OrderConversionService } from '../../conversion/order/order-conversion.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { ProductOrder } from '../../../libs/proto/shop_pb';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { RequestID } from '../../../libs/proto/commUnity_pb';

@Injectable({
  providedIn: 'root'
})
export class GrpcOrderLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private convLib: OrderConversionService,
    private storeLib: StorageLibService
  ) { }

  getProductOrders(option?: GrpcLibServiceOption): Promise<ProductOrder[]>{
    return new Observable<ProductOrder[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getProductOrdersOffline(opt);
      } else {
        this._getProductOrdersOnline(opt);
      }
    }).toPromise();
  }

  private _getProductOrdersOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      this.storeLib.get('order'), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getProductOrdersOnline(option: GrpcLibServiceOption){
      const req = new RequestID();
      req.setId(option.call.req);
      this.grpcLib.MobileClient.getProductOrders(req, {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getProductOrdersError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getProductOrdersStatus(s, option);
      })
      .on('data', (r: ProductOrder) => {
        this._getProductOrdersData(r, option);
      })
      .on('end', () => {
        this._getProductOrdersEnd(option);
      });
  }

  private _getProductOrdersData(ret: ProductOrder, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getProductOrdersError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getProductOrdersOnline(option);
    }, option);
  }

  private _getProductOrdersEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getProductOrdersStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storeLib.cache.product_order = true;
      if (option.KeepInCache || false) {
        this.storeLib.set(
          'order', this.convLib.ToStorages(option.call.data)
        );
      }
      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getProductOrdersOnline(option);
      }, option);
    }
  }
}
