<div class="box-forum">
<div class="bg"></div>
<div class="body">
<div class="reply-types">
  <h2 class="new-post-title">{{ 'new-post.reply' | translate }}</h2>
  <mat-selection-list required="true" [(ngModel)]="newPostForm.replytypesList" (selectionChange)="hasReplyType()">
    <mat-list-option class="call" value='0' mat-list-item><mat-icon class="call">call</mat-icon></mat-list-option>
    <mat-list-option class="sms" value='1' mat-list-item><mat-icon class="sms">sms</mat-icon></mat-list-option>
    <mat-list-option class="sms" value='2' mat-list-item><mat-icon class="email">mail_outlined</mat-icon></mat-list-option>
  </mat-selection-list>
</div>
<div class="form">
  <mat-form-field appearance="outline">
    <textarea matInput id="message" required="true"
    [(ngModel)]="newPostForm.message" maxlength="140" rows="10" (input)="hasMessageLongEnough()">
    </textarea>
  </mat-form-field>
  <div>
    <label class="remaining"> {{ 'new-post.remain' | translate }} {{ charactersLeft - newPostForm.message.length}}/140</label>
  </div>
</div>
<div class="footer">
  <div class="buttons">
    <button class="cancel" (click)="cancel()" mat-flat-button>{{ 'new-post.cancel' | translate }}</button>
    <button mat-flat-button  class="send" (click)="tosave()" [disabled]="!canSend" >{{ 'new-post.send' | translate }}</button>
  </div>
</div>
</div>
</div>
