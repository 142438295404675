import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';

import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';

import { Empty, RequestID } from '../../../libs/proto/commUnity_pb';
import { CompetitionConversionService } from '../../conversion/competition/competition-conversion.service';
import { Event} from 'src/app/libs/proto/commUnity_pb';
@Injectable({
  providedIn: 'root'
})
export class GrpcCompetitionLibService{
  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: CompetitionConversionService,
  ) { }

  getCompetition(option?: GrpcLibServiceOption): Promise<Event[]>{
    return new Observable<Event[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getCompetitionOffline(opt);
      } else {
        this._getCompetitionOnline(opt);
      }
    }).toPromise();
  }

  private _getCompetitionOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      this.storLib.get('compet'), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                if ((option.call.req as string).length !== 8) {
                  return n.getId() === option.call.req;
                }
                return n.getDate() === +option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getCompetitionOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getCompetitions(new Empty(), {
        token: this.userLib.Data.token?.getToken()
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getCompetitionOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getCompetitionOnlineStatus(s, option);
      })
      .on('data', (r: Event) => {
        this._getCompetitionOnlineData(r, option);
      })
      .on('end', () => {
        this._getCompetitionOnlineEnd(option);
      });
  }

  private _getCompetitionOnlineData(ret: Event, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getCompetitionOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getCompetitionOnline(option);
    }, option);
  }

  private _getCompetitionOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }
  private _getCompetitionOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.compeitition = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'compet', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getCompetitionOnline(option);
      }, option);
    }
  }
  eventAttend(id: string){
    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: id
        }
      });
      opt.call.subscribe = obs;

      this._competitionAttendOnline(opt);

    }).toPromise();
  }

  private _competitionAttendOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);

    this.grpcLib.MobileClient.eventAttend(req, {
      token: this.userLib.Data.token?.getToken()
    }, (e, _) => {

      if (e != null) {

        this.grpcLib.handleError(e, () => {
          this._competitionAttendOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(true);
        option.call.subscribe.complete();
      }
    });
  }

  competitionNotAttend(id: string){
    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: id
        }
      });
      opt.call.subscribe = obs;

      this._competitionNotAttendOnline(opt);

    }).toPromise();
  }
  private _competitionNotAttendOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);

    this.grpcLib.MobileClient.eventNotAttend(req, {
      token: this.userLib.Data.token?.getToken()
    }, (e, _) => {

      if (e != null) {

        this.grpcLib.handleError(e, () => {
          this._competitionNotAttendOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(true);
        option.call.subscribe.complete();
      }
    });
  }


}
