import { Injectable } from "@angular/core";
import { GrpcLibServiceOption } from "../../grpc/grpc-lib.service";
import { GrpcLinksLibService } from "../../grpc/links/grpc-links-lib.service";

@Injectable({
  providedIn: 'root'
})
export class LinksPathConverter {

  constructor(
    private grpcLinksLib: GrpcLinksLibService,
  ){}
  /**
   * get current Links index
   */
   get linksIndex() {
    const rets = location.pathname.match(/links?([0-9]{0,})/) || [];
    return rets[1] || '';
  }

  /**
   * get Links
   * @returns function to get Links
   */
   fnGetLinks(option?: GrpcLibServiceOption) {
    switch (this.linksIndex) {
      case '2':
        return this.grpcLinksLib.getLinks2(option);
      case '3':
        return this.grpcLinksLib.getLinks3(option);
      case '4':
        return this.grpcLinksLib.getLinks4(option);
      default:
        return this.grpcLinksLib.getLinks(option);
    }
  }
}
