
<div class="mdc-layout-grid h-100">

  <mat-toolbar>
    <mat-toolbar-row [ngClass]="{tablet: menuType==2}">
      <mat-icon (click)="back()" aria-hidden="false" aria-label="menu">arrow_back_ios</mat-icon>
      <span class="m-auto">{{ 'signup.title' | translate }}</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-card class="signup-card" [ngClass]="{tablet: menuType==2}">
    <mat-card-content>

      <mat-list class="w-100">
        <mat-list-item>
          <mat-form-field class="w-100">
            <input matInput id="txt-firstname" required="true"
              placeholder="{{ 'signup.firstname' | translate }}" [(ngModel)]="signupForm.firstName"  [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field class="w-100">
            <input matInput id="txt-lastname" required="true"
              placeholder="{{ 'signup.lastname' | translate }}" [(ngModel)]="signupForm.lastName"  [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </mat-list-item>        
        <mat-list-item *ngIf="!hideSignupBirthday">
          <mat-form-field class="w-100">
            <input matInput id="txt-docdate" required="true"
                  autocomplete="off"                      
                  [matDatepicker]="pickerd"          
                  placeholder="{{ 'signup.birthday' | translate }}" [(ngModel)]="signupForm.birthday" [ngModelOptions]="{standalone: true}">
                  <mat-datepicker-toggle matSuffix [for]="pickerd"></mat-datepicker-toggle>
                  <mat-datepicker #pickerd></mat-datepicker>              
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field class="w-100">
            <input type="email" matInput id="txt-email" required="true"
              placeholder="{{ 'signup.email' | translate }}" [(ngModel)]="signupForm.email" [errorStateMatcher]="email_matcher" [ngModelOptions]="{standalone: true}">
            <mat-error>
              {{ 'signup.email_ko' | translate }}
            </mat-error>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field class="w-100">           
            <mat-label>{{'signup.country' | translate }}*</mat-label>
            <mat-select [(ngModel)]="signupForm.country">
              <mat-select-trigger>
                {{signupForm.country?.name}}
              </mat-select-trigger>
            <mat-option *ngFor="let c of country_list" [value]="c">
              <span style="margin-right: 10px;">{{ c.name }}</span><span style="color: gray;">{{ c.dial }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field class="w-100">           
            <mat-label>{{'signup.mobile' | translate }}</mat-label>
            <span matPrefix>{{ signupForm.country?.dial }} &nbsp;</span>
            <input type="text" matInput id="txt-mobile" required="true"
              placeholder="01 23 45 67 89" [(ngModel)]="signupForm.mobile"  [ngModelOptions]="{standalone: true}">
          </mat-form-field>
      </mat-list-item>

        <mat-list-item>
          <button [disabled]="!validAccount" mat-raised-button [color]="'primary'" (click)="save()" class="m-auto d-block">{{ 'signup.button' | translate }}</button>
          <button mat-raised-button [color]="'danger'" (click)="back()" class="m-auto d-block">{{ 'Dialog.Cancel' | translate }}</button>
        </mat-list-item>
      </mat-list>

    </mat-card-content>
  </mat-card>

</div>

<ng-template [ngIf]="Loading">
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div class="signup-ok" *ngIf="signupOK">
  <mat-card class="card">
    <h1>{{ 'signup.signup_msg_title' | translate }}</h1>

    <img src="assets/images/ok.png" />
    <p>
      {{ 'signup.signup_ok' | translate }}
    </p>

    <mat-card-footer>
      <div class="button-div">
        <button (click)="close_signup()" mat-button>{{ 'signup.home' | translate }}</button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
