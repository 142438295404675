<div class="box-reply">
  <div class="bg"></div>
  <div class="body" [ngStyle]="{'height': box_height}">
    <mat-card class="box-message">
      <h2 class="title">
        {{ 'forum.contact' | translate }}
      </h2>
      <mat-card-content>
        <div *ngFor="let reply of data?.getReplytypesList()">
          <a *ngIf="reply === 0" [href]="tellink('tel:' + phoneNumber)">
        <button mat-flat-button class="reply-button">
          {{ 'forum.call' | translate }}
        </button></a>
        <a *ngIf="reply === 1" href="sms:{{phoneNumber}}">
        <button mat-flat-button class="reply-button">
          {{ 'forum.sms' | translate }}
        </button></a>
        <a *ngIf="reply === 2" href="mailto:{{emailAddress}}">
        <button mat-flat-button class="reply-button" >
          {{ 'forum.email' | translate }}
        </button></a>
      </div>
      <mat-card-footer>
        <button mat-flat-button class="cancel-button" (click)="cancel()">
          {{ 'Dialog.Cancel' | translate }}
        </button>
      </mat-card-footer>
    </mat-card-content>
    </mat-card>
  </div>
</div>
