import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Observable, Subscriber } from 'rxjs';
import { CustomizedMenu, Empty, Link } from '../../../libs/proto/commUnity_pb';
import * as grpcWeb from 'grpc-web';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { LinksConversionService } from '../../conversion/links/links-conversion.service';
import { CustomizedMenusConversionService } from '../../conversion/customizemenu/customizemenu-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcLinksLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: LinksConversionService,
    private convCustomizedMenuLib: CustomizedMenusConversionService,
  ) { }

  getLinks(option?: GrpcLibServiceOption): Promise<Link[]>{
    return new Observable<Link[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getLinksOffline(opt);
      } else {
        this._getLinksOnline(opt);
      }
    }).toPromise();
  }

  private _getLinksOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('links-s') || this.storLib.get('links')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getLinksOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getLinks(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getLinksOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getLinksOnlineStatus(s, option);
      })
      .on('data', (r: Link) => {
        this._getLinksOnlineData(r, option);
      })
      .on('end', () => {
        this._getLinksOnlineEnd(option);
      });
  }

  private _getLinksOnlineData(ret: Link, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getLinksOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getLinksOnline(option);
    }, option);
  }

  private _getLinksOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getLinksOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.links = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'links', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getLinksOnline(option);
      }, option);
    }
  }

  getLinks2(option?: GrpcLibServiceOption): Promise<Link[]>{
    return new Observable<Link[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getLinks2Offline(opt);
      } else {
        this._getLinks2Online(opt);
      }
    }).toPromise();
  }

  private _getLinks2Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('links-s2') || this.storLib.get('links2')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getLinks2Online(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getLinks2(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getLinks2OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getLinks2OnlineStatus(s, option);
      })
      .on('data', (r: Link) => {
        this._getLinks2OnlineData(r, option);
      })
      .on('end', () => {
        this._getLinks2OnlineEnd(option);
      });
  }

  private _getLinks2OnlineData(ret: Link, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getLinks2OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getLinks2Online(option);
    }, option);
  }

  private _getLinks2OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getLinks2OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.links2 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'links2', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getLinks2Online(option);
      }, option);
    }
  }
  getLinks3(option?: GrpcLibServiceOption): Promise<Link[]>{
    return new Observable<Link[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getLinks3Offline(opt);
      } else {
        this._getLinks3Online(opt);
      }
    }).toPromise();
  }

  private _getLinks3Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('links-s3') || this.storLib.get('links3')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getLinks3Online(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getLinks3(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getLinks3OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getLinks3OnlineStatus(s, option);
      })
      .on('data', (r: Link) => {
        this._getLinks3OnlineData(r, option);
      })
      .on('end', () => {
        this._getLinks3OnlineEnd(option);
      });
  }

  private _getLinks3OnlineData(ret: Link, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getLinks3OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getLinks3Online(option);
    }, option);
  }

  private _getLinks3OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getLinks3OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.links3 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'links3', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getLinks3Online(option);
      }, option);
    }
  }
  getLinks4(option?: GrpcLibServiceOption): Promise<Link[]>{
    return new Observable<Link[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getLinks4Offline(opt);
      } else {
        this._getLinks4Online(opt);
      }
    }).toPromise();
  }

  private _getLinks4Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('links-s4') || this.storLib.get('links4')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getLinks4Online(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getLinks4(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getLinks4OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getLinks4OnlineStatus(s, option);
      })
      .on('data', (r: Link) => {
        this._getLinks4OnlineData(r, option);
      })
      .on('end', () => {
        this._getLinks4OnlineEnd(option);
      });
  }

  private _getLinks4OnlineData(ret: Link, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getLinks4OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getLinks4Online(option);
    }, option);
  }

  private _getLinks4OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getLinks4OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.links4 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'links4', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getLinks4Online(option);
      }, option);
    }
  }
}
