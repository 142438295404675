<mat-toolbar color="primary" [ngStyle]="{'background-color': backgroundColor}">
  <mat-toolbar-row>

    <a [ngStyle]="{'margin-left': ml}" *ngIf="showBack() && noBack != 'Y'" [routerLink]="[backURL()]">
      <mat-icon [ngStyle]="{'color': foregroundColor}" aria-hidden="false" aria-label="back">arrow_back_ios</mat-icon>
    </a>

    <button *ngIf="show_burger_menu" class="burger-menu" mat-icon-button [ngStyle]="{'background-color': 'transparent'}" (click)="toggle_sidenav()">
      <mat-icon [ngStyle]="{'color': foregroundColor}" aria-hidden="false">menu</mat-icon>
    </button>

    <a *ngIf="hasOrder" class="unpaid" [matBadge]="showUnpaid()?'1':''"
        matBadgeColor="warn" [routerLink]="['/order/history']" routerLinkActive="router-link-active">
      <img class="img-unpaid" src="/assets/images/order.png"/>
    </a>
    <span class="space"></span>
    <a [routerLink]="['/search']" routerLinkActive="router-link-active" *ngIf="show_search()">
      <mat-icon [ngStyle]="{'color': foregroundColor}" aria-hidden="false" aria-label="find">search</mat-icon>
    </a>
    <a matBadge="{{ cartBadge }}"
      matBadgeColor="warn" *ngIf="hasCart() && show_cart()" [routerLink]="['/cart']" routerLinkActive="router-link-active" >
      <mat-icon [ngStyle]="{'color': foregroundColor}" aria-hidden="false" aria-label="cart">shopping_cart</mat-icon>
    </a>
    <a matBadge="{{ mealBadge }}"
      matBadgeColor="warn" *ngIf="hasMeal() && show_meal()" [routerLink]="['/meal']" routerLinkActive="router-link-active" >
      <mat-icon [ngStyle]="{'color': foregroundColor}" aria-hidden="false" aria-label="meal">restaurant</mat-icon>
    </a>

    <a (click)="showMyClass()"  *ngIf="page === 'golf_class'">
      <mat-icon [ngStyle]="{'color': foregroundColor}" aria-hidden="false">class</mat-icon>
    </a>

    <a (click)="showEventCalendar()" *ngIf="(['events','guest-reservations'].indexOf(page) >= 0)  && showEventCalendarIcon()">
      <mat-icon [ngStyle]="{'color': foregroundColor}" aria-hidden="false">date_range</mat-icon>
    </a>
    <a (click)="showEventList()" *ngIf="(['events','guest-reservations'].indexOf(page) >= 0) && showEventListIcon()">
      <mat-icon [ngStyle]="{'color': foregroundColor}" aria-hidden="false">list</mat-icon>
    </a>
    <button mat-icon-button [ngStyle]="{'background-color': 'transparent'}" (click)="topayment()" *ngIf="page === 'meal-payment'">
      <mat-icon [ngStyle]="{'color': foregroundColor}" aria-hidden="false">done</mat-icon>
    </button>

    <button mat-icon-button [ngStyle]="{'background-color': 'transparent'}" (click)="sortgolfclass()" *ngIf="page === 'golf_classes'">
      <mat-icon [ngClass]="{'sort-golf-desc': sortgolf == 1}" [ngStyle]="{'color': foregroundColor}" aria-hidden="false">sort</mat-icon>
    </button>

    <div [ngStyle]="{'margin-right': mr}"></div>
  </mat-toolbar-row>
</mat-toolbar>

<span
  #titleContainer
  *ngIf="page!='cart'"
  [ngStyle]="{'color': foregroundColor}"
  class="title mat-title">
  <span #titleFront [ngClass]="{'title-scroll':isTitleOverflow}" (click)="showVersion()">{{ title | translate }}</span>
  <span #titleBack *ngIf="isTitleOverflow" class="title-scroll" (click)="showVersion()">{{ title | translate }}</span>
</span>
<span
  class="title"
  *ngIf="page ==='cart'">
  <img src="/assets/images/cart_emoji.png" />
</span>
