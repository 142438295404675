import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Empty, OS, MemberProfileSettings, Member } from '../../../libs/proto/commUnity_pb';
import { Observable } from 'rxjs';
import { FCMToken, MemberProfileRequest } from '../../../libs/proto/mobile_pb';
import { UserLibService } from '../../user/user-lib.service';
import * as grpcWeb from 'grpc-web';
import { StorageLibService } from '../../storage/storage-lib.service';
import { MemberProfileSettingConversionService } from '../../conversion/member/member-profile-setting-conversion.service';
import { MobileUpdateProfile } from '../../../libs/proto/mobile_pb_service';
import { ko } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class GrpcUserService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storeLib: StorageLibService,
    private convLib: MemberProfileSettingConversionService
  ) { }

  updateFCMToken(option?: GrpcLibServiceOption): Promise<Empty>{
    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      this._updateFCMTokenOnline(opt);

    }).toPromise();
  }

  private _updateFCMTokenOnline(option: GrpcLibServiceOption){
    const req = new FCMToken();
    req.setOsmobile( OS.OS_PWA );
    req.setToken(option.call.req);

    this.grpcLib.MobileClient.updateFCMToken(req, {
        token: this.userLib.Data.token?.getToken(),
      }, () => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._updateFCMTokenOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._updateFCMTokenOnlineStatus(s, option);
      })
      .on('data', (r: Empty) => {
        this._updateFCMTokenOnlineData(r, option);
      })
      .on('end', () => {
        this._updateFCMTokenOnlineEnd(option);
      });
  }

  private _updateFCMTokenOnlineData(ret: Empty, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _updateFCMTokenOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._updateFCMTokenOnline(option);
    }, option);
  }

  private _updateFCMTokenOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _updateFCMTokenOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._updateFCMTokenOnline(option);
      }, option);
    }
  }

  getMemberProfileSettingss(option?: GrpcLibServiceOption): Promise<MemberProfileSettings>{
    return new Observable<MemberProfileSettings>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getMemberProfileSettingssOffline(opt);
      } else {
        this._getMemberProfileSettingssOnline(opt);
      }
    }).toPromise();
  }

  private _getMemberProfileSettingssOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorage(
      this.storeLib.get('p.set'), (ns, e) => {
        option.call.subscribe.next(ns || null);
        option.call.subscribe.complete();
    });
  }

  private _getMemberProfileSettingssOnline(option: GrpcLibServiceOption){

    this.grpcLib.MobileClient.getMemberProfileSettings(new Empty(), {
        token: this.userLib.Data.token?.getToken(),
      }, (d, e) => {} )
      .on('error', (e: grpcWeb.RpcError) => {
        this._getMemberProfileSettingssOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getMemberProfileSettingssOnlineStatus(s, option);
      })
      .on('data', (r: MemberProfileSettings) => {
        this._getMemberProfileSettingssOnlineData(r, option);
      })
      .on('end', () => {
        this._getMemberProfileSettingssOnlineEnd(option);
      });
  }

  private _getMemberProfileSettingssOnlineData(ret: MemberProfileSettings, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getMemberProfileSettingssOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getMemberProfileSettingssOnline(option);
    }, option);
  }

  private _getMemberProfileSettingssOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getMemberProfileSettingssOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storeLib.cache.MemberProfileSettings = true;
      if (option.KeepInCache || false) {
        this.storeLib.set(
          'p.set', this.convLib.ToStorage(option.call.data[0])
        );
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getMemberProfileSettingssOnline(option);
      }, option);
    }
  }

  updateProfile(req: MemberProfileRequest): Promise<Member>{
    return new Observable<Member>(obs => {
      const opt = this.grpcLib.getOption();
      opt.call.subscribe = obs;
      opt.call.req = req;

      this._updateProfileOnline(opt);

    }).toPromise();
  }
  private _updateProfileOnline(option: GrpcLibServiceOption) {
    this.grpcLib.MobileClient.updateProfile(option.call.req, {
      token: this.userLib.Data.token?.getToken(),
    }, (e, m) => {
      if (e === null) {

        option.call.subscribe.next(m);
        option.call.subscribe.complete();

      } else {
        this.grpcLib.handleError(e, () => {
          this._updateProfileOnline(option);
        }, option);
      }
    });
  }

  getMemberSettings(option?: GrpcLibServiceOption): Promise<MemberProfileSettings>{
    return new Observable<MemberProfileSettings>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getMemberSettingsOffline(opt);
      } else {
        this._getMemberSettingsOnline(opt);
      }
    }).toPromise();
  }

  private _getMemberSettingsOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorage(
      this.storeLib.get('mem.set'), (ns, e) => {
        option.call.subscribe.next(ns || null);
        option.call.subscribe.complete();
    });
  }

  private _getMemberSettingsOnline(option: GrpcLibServiceOption){

    this.grpcLib.MobileClient.getMemberSettings(new Empty(), {
        token: this.userLib.Data.token?.getToken(),
      }, (d, e) => {} )
      .on('error', (e: grpcWeb.RpcError) => {
        this._getMemberSettingsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getMemberSettingsOnlineStatus(s, option);
      })
      .on('data', (r: MemberProfileSettings) => {
        this._getMemberSettingsOnlineData(r, option);
      })
      .on('end', () => {
        this._getMemberSettingsOnlineEnd(option);
      });
  }

  private _getMemberSettingsOnlineData(ret: MemberProfileSettings, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getMemberSettingsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getMemberSettingsOnline(option);
    }, option);
  }

  private _getMemberSettingsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getMemberSettingsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storeLib.cache.MemberSettings = true;
      if (option.KeepInCache || false) {
        this.storeLib.set(
          'mem.set', this.convLib.ToStorage(option.call.data[0])
        );
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getMemberSettingsOnline(option);
      }, option);
    }
  }
}
