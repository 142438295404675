import { Injectable } from '@angular/core';
import { Color } from 'src/app/libs/proto/commUnity_pb';

@Injectable({
  providedIn: 'root'
})
export class ColorConversionService {

  colorhtml(c: number): string {
    switch (c) {
      case Color.COLOR_BLACK:
        return 'black';
      case Color.COLOR_BLUE:
        return '#33FFF0';
      case Color.COLOR_BROWN:
        return 'brown';
      case Color.COLOR_CYAN:
        return 'cyan';
      case Color.COLOR_GOLD:
        return '#F77006';
      case Color.COLOR_GREEN:
        return '#29C311';
      case Color.COLOR_GREY:
        return 'grey';
      case Color.COLOR_MAGENTA:
        return 'magenta';
      case Color.COLOR_ORANGE:
        return '#E3560A';
      case Color.COLOR_PINK:
        return '#F7067E';
      case Color.COLOR_PURPLE:
        return '#7C0AD5';
      case Color.COLOR_RED:
        return '#E30A14';
      case Color.COLOR_SILVER:
        return 'silver';
      case Color.COLOR_TURQUOISE:
        return 'turquoise';
      case Color.COLOR_VIOLET:
        return 'violet';
      case Color.COLOR_WHITE:
        return 'white';
      case Color.COLOR_YELLOW:
        return 'yellow';
      case Color.COLOR_NO:
      default:
        return 'transparent';
    }
  }
}
