import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { SearchRequest, SearchResult } from '../../../libs/proto/mobile_pb';
import * as grpcWeb from 'grpc-web';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GrpcSearchService {

  current?: grpcWeb.ClientReadableStream<SearchResult | unknown>;

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
  ) { }

  search(option?: GrpcLibServiceOption): Promise<SearchResult[]>{
    return new Observable<SearchResult[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      this._searchOnline(opt);

    }).toPromise();
  }

  cancel() {
    if (this.current == null) { return; }

    this.current?.cancel();
  }

  private _searchOnline(option: GrpcLibServiceOption){
    const req = new SearchRequest();
    req.setText(option.call.req);

    this.current = this.grpcLib.MobileClient.search(req, {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._searchOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._searchOnlineStatus(s, option);
      })
      .on('data', (r: SearchResult) => {
        this._searchOnlineData(r, option);
      })
      .on('end', () => {
        this._searchOnlineEnd(option);
      });
  }

  private _searchOnlineData(ret: SearchResult, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _searchOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._searchOnline(option);
    }, option);
  }

  private _searchOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _searchOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._searchOnline(option);
      }, option);
    }
  }
}
