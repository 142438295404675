import { Injectable, isDevMode } from '@angular/core';
import { ConfigLibService } from '../config/config-lib.service';

@Injectable({
  providedIn: 'root'
})
export class LogLibService {

    constructor(private configLib: ConfigLibService) { }

    /**
     * log debug to console (when config.debug)
     * @param context page/function short description
     * @param message message to log
     * @param optionalParams extra message to log
     */
    debug(context: string, message?: any, ...optionalParams: any[]): void {
      if (!this.configLib.settings.log.debug) { return; }
      this._log(context, 'debug', message, ...optionalParams);
    }

    /**
     * log info to console
     * @param context page/function short description
     * @param message message to log
     * @param optionalParams extra message to log
     */
    info(context: string, message?: any, ...optionalParams: any[]): void {
      if (!this.configLib.settings.log.info) { return; }
      this._log(context, 'info', message, ...optionalParams);
    }

    /**
     * log warn to console (when config.debug)
     * @param context page/function short description
     * @param message message to log
     * @param optionalParams extra message to log
     */
    warn(context: string, message?: any, ...optionalParams: any[]): void {
      if (!this.configLib.settings.log.warn) { return; }
      this._log(context, 'warn', message, ...optionalParams);
    }
    /**
     * log error to console
     * @param context page/function short description
     * @param message message to log
     * @param optionalParams extra message to log
     */
    error(context: string, message?: any, ...optionalParams: any[]): void {
      if (!this.configLib.settings.log.error) { return; }
      this._log(context, 'error', message, ...optionalParams);
    }
    /**
     * log to console
     * @param context page/function short description
     * @param type debug, error, warn, ...
     * @param message message to log
     * @param optionalParams extra message to log
     */
    private _log(context: string, type: string, message?: any, ...optionalParams: any[]): void {
      if (!isDevMode() && type === 'debug') { return; }

      switch (type) {
        // tslint:disable-next-line: no-console
        case 'debug': console.debug('[', context, ']', message, ...optionalParams); break;
        case 'error': console.error('[', context, ']', message, ...optionalParams); break;
        case 'warn': console.warn('[', context, ']', message, ...optionalParams); break;
        // tslint:disable-next-line: no-console
        default : console.info('[', context, ']', message, ...optionalParams); break;
      }
    }
  }
