import { Injectable } from '@angular/core';
import { communityConfig } from '../../config/app';
import { MobApp } from '../../libs/proto/mobile_pb';
import { MobileApp } from '../../libs/proto/commUnity_pb';
import { communityAppType } from '../../config/type';

@Injectable({
  providedIn: 'root'
})
export class ConfigLibService {

  private mobileApps: { key: string, value: number }[];

  constructor() {
    this.mobileApps = Object.keys(MobileApp).map( (k, v) => {
      return { key: k, value: v };
    });
  }

  /**
   * return config
   */
  get settings() {
    return communityConfig;
  }

  get envoyURL() {
    return (this.settings.grpc.server.dev ? this.settings.grpc.server.url_dev : this.settings.grpc.server.url_production);
  }

  get MobileAppKey(): string {
    for (const m of this.mobileApps) {
      if (location.href.match( '(' + m.key + ')' )) { return m.key; }
    }
    return '';
  }
}
