import { Injectable } from '@angular/core';
import { GolfClassLevel, Place } from '../../../libs/proto/commUnity_pb';
import { BinaryWriter } from 'google-protobuf';

@Injectable({
  providedIn: 'root'
})
export class GolfClassLevelConversionService {

  constructor() { }

  ToStorage(req: GolfClassLevel) {
    const w = new BinaryWriter();
    GolfClassLevel.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (GolfClassLevel: GolfClassLevel, errorConvert: any) => void) {
    try {
      callback( GolfClassLevel.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }
  ToStorages(news: GolfClassLevel[]) {
    const rets = new Array<string>();

    news.forEach(element => {
      rets.push(
        btoa(String.fromCharCode.apply(null,this.ToStorage(element)))
      );
    });

    return {data: rets};
  }

  FromStorages(data: any, callback: (GolfClassLeveles: GolfClassLevel[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const golfClassLeveles = new Array<GolfClassLevel>();
    const golfClassLeveljs: {data: string[]} = data;
    try {

      golfClassLeveljs.data.forEach(element => {
        const golfClassLevel = GolfClassLevel.deserializeBinary( new Uint8Array([...atob(element)].map(char => char.charCodeAt(0)))  );
        golfClassLeveles.push ( golfClassLevel );
      });

      callback( golfClassLeveles, null);
    } catch (error) {
      callback( null, error);
    }
  }
}
