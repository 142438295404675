import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { Empty, GolfClassType,  Place, RequestID } from '../../../libs/proto/commUnity_pb';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { GolfClassTypeConversionService } from '../../conversion/golf_class/golfclasstype-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcGolfClassTypeLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: GolfClassTypeConversionService,
  ) { }

  getGolfClassTypees(option?: GrpcLibServiceOption): Promise<GolfClassType[]>{
    return new Observable<GolfClassType[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getGolfClassTypeesOffline(opt);
      } else {
        this._getGolfClassTypeesOnline(opt);
      }

    }).toPromise();
  }

  private _getGolfClassTypeesOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('g-classt-s') || this.storLib.get('g-classt')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getGolfClassTypeesOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getGolfClassTypes(new Empty(), {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getGolfClassTypeesOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getGolfClassTypeesOnlineStatus(s, option);
      })
      .on('data', (r: GolfClassType) => {
        this._getGolfClassTypeesOnlineData(r, option);
      })
      .on('end', () => {
        this._getGolfClassTypeesOnlineEnd(option);
      });
  }

  private _getGolfClassTypeesOnlineData(ret: GolfClassType, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getGolfClassTypeesOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getGolfClassTypeesOnline(option);
    }, option);
  }

  private _getGolfClassTypeesOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getGolfClassTypeesOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.golfClassType = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'g-classt', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getGolfClassTypeesOnline(option);
      }, option);
    }
  }
}
