<app-sidenav page="benefit" menuType>

<div class="form-list" *ngIf="menuType!=2">

  <mat-card class="benefit-card">
    <div class="top-image"
    [ngStyle]="{'background-image': 'url('+ benefit?.getImage() +')'}">
  </div>

  <mat-card-header class="header">
    <mat-card-title>{{ benefit?.getTitle() }}</mat-card-title>
    <mat-card-subtitle class="benefit-date">{{ benefit?.getPublishdate()+'' | amParse: 'YYYYMMDD' | amDateFormat: dateFormat }}</mat-card-subtitle>

  </mat-card-header>
  </mat-card>
  <div class="body">
    <p [innerHTML]="benefit?.getDescription() | pipeDescription">

    </p>
    <a class="benefit-link" mat-flat-button target="_blank" *ngIf="benefit?.getUrl()" href="{{ benefit?.getUrl()}}">
      <mat-icon>language</mat-icon>
      {{'benefit.more_info'| translate }}
    </a>
  </div>

</div>

<div class="mode-tablet" *ngIf="menuType==2">
  <div class="left">
    <mat-card class="benefit-card">
      <div class="top-image"
      [ngStyle]="{'background-image': 'url('+ benefit?.getImage() +')'}">
    </div>
  
    <mat-card-header class="header">
      <mat-card-title>{{ benefit?.getTitle() }}</mat-card-title>
      <mat-card-subtitle class="benefit-date">{{ benefit?.getPublishdate()+'' | amParse: 'YYYYMMDD' | amDateFormat: dateFormat }}</mat-card-subtitle>
  
    </mat-card-header>
    </mat-card>
  </div>
  
  <div class="right">
    <div class="body">
      <p [innerHTML]="benefit?.getDescription() | pipeDescription">
  
      </p>
      <a class="benefit-link" mat-flat-button target="_blank" *ngIf="benefit?.getUrl()" href="{{ benefit?.getUrl()}}">
        <mat-icon>language</mat-icon>
        {{'benefit.more_info'| translate }}
      </a>
    </div>
  </div>
</div>

</app-sidenav>