import { Component, OnInit, OnDestroy } from '@angular/core';
import { PhotoAlbumsComponent } from '../photo-albums.component';
import { Album } from 'src/app/libs/proto/commUnity_pb';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GrpcAlbumLibService } from 'src/app/service/grpc/album/grpc-album-lib-service';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { ActivatedRoute, Router } from '@angular/router';
import { desktopMode } from '../../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';

declare let Splide: any;
@Component({
  selector: 'app-photo-album',
  templateUrl: './photo-album.component.html',
  styleUrls: ['./photo-album.component.sass']
})
export class PhotoAlbumComponent implements OnInit, OnDestroy {
  album: Album;
  actRouter$: Subscription;
  imageObject: Array<object> = [];
  imageSize: object = {};
  showpreview = false;
  photopreview = '';
  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;
  private newsplide: any;

  constructor(
    private grpcAlbumLib: GrpcAlbumLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private detector: DeviceLibService,
    private storeLib: StorageLibService,
    private route: Router,
  ) {
    userLib.setPageLanguage( this.translate );

    this.imageSize = {
      width: '100%',
    };
   }

   ngOnInit(): void {
    const thise = this;
    this.actRouter$ = this.actRoute.paramMap.subscribe( p => {
      thise.grpcAlbumLib.getAlbum({
        Offline: this.storeLib.cache.album || false,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        let nss = ns.filter( n =>  n.getId() === p.get('id'));        
        if (nss.length === 0) {
          thise.route.navigateByUrl('/albums');
          return;
        }
        thise.album = nss[0];
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }


  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }
  get dateFormat(): string {
    return this.userLib.dateFormat;
  }
  previewme(p: string, ind: number) {
    if (this.newsplide === undefined) {
      this.newsplide = new Splide('.image-slider-container', {
        width: '100vw',
        height: '100vh',
        pagination : false,
        start: ind,
      }).mount();
    }
    this.newsplide.go('>' + ind);

    this.showpreview = true;
    this.photopreview = p;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
}
