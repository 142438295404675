import { CustomizedMenu, MenuName } from 'src/app/libs/proto/commUnity_pb';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { Router } from '@angular/router';

/**
 * base menu template component
 */
export class menu_type_def {
  MenuFromGRPC = MenuName;
  
  get hasLink () {
    return this.userLib.Data.menu_data?.hasLink;
  }
  get hasLink2 () {
    return this.userLib.Data.menu_data?.hasLink2;
  }
  get hasLink3 () {
    return this.userLib.Data.menu_data?.hasLink3;
  }
  get hasLink4 () {
    return this.userLib.Data.menu_data?.hasLink4;
  }

  get hasStream  () {
    return this.userLib.Data.menu_data?.hasStream;
  }
  get hasStream2  () {
    return this.userLib.Data.menu_data?.hasStream2;
  }
  get hasStream3 () {
    return this.userLib.Data.menu_data?.hasStream3;
  }
  get hasStream4 () {
    return this.userLib.Data.menu_data?.hasStream4;
  }
  get hasCPSURL () {
    return this.userLib.Data.menu_data?.hasCPSURL;
  }
  get show_member () {
    return this.userLib.Data.menu_data?.show_member;
  }

  get cpsurl () {
    return this.userLib.Data.menu_data?.cpsurl;
  }
  get cpsref () {
    return this.userLib.Data.menu_data?.cpsref;
  }
  get cpsttl () {
    return this.userLib.Data.menu_data?.cpsttl;
  }

  get menus$ () {
    return this.userLib.Data.menu_data?.menus$;
  }

  constructor(
    private userLib: UserLibService,    
    private storageLib: StorageLibService,
    private route: Router,
  ){}

  get forumBadge() {
    return this.storageLib.get('forum-badge');
  }
  get cartBadge() {
    return this.storageLib.get('cart-badge');
  }
  get mealBadge() {
    return '';
  }
  get backgroundColor() {
    return this.userLib.designMainBackgroundColor;
  }
  get textColor() {
    return this.userLib.designMainTextColor;
  }
  get menuAlias() {
    return this.userLib.Data.menuAlias;
  }  

  open_restaurant() {
    this.storageLib.set('table-id', '');
    this.route.navigateByUrl('/resto');
  }

  get show_event() {
    return this.userLib.Data.token?.getCustomer().getShowevent() ?? false;
  }
  get show_news() {
    return this.userLib.Data.token?.getCustomer().getShownews() ?? false;
  }
  get show_shop() {
    return this.userLib.Data.token?.getCustomer().getShowshoppwa() ?? false;
  }
  get show_shopprofile() {
    return this.userLib.Data.token?.getCustomer().getShowshopprofile() ?? false;
  }
  get show_benefit() {
    return this.userLib.Data.token?.getCustomer().getShowbenefit() ?? false;
  }
  get show_album() {
    return this.userLib.Data.token?.getCustomer().getShowalbum() ?? false;
  }
  get show_leader() {
    return this.userLib.Data.token?.getCustomer().getShowleaderboard() ?? false;
  }
  get show_competition() {
    return this.userLib.Data.token?.getCustomer().getShowcompetition() ?? false;
  }
  get show_place() {
    return this.userLib.Data.token?.getCustomer().getShowplacemenu() ?? false;
  }
  get show_forum() {
    return this.userLib.Data.token?.getCustomer().getShowforum() ?? false;
  }
  get show_golf_teaching_class() {
    return this.userLib.Data.token?.getCustomer().getShowgolfteachingclass() ?? false;
  }
  get show_room_reservation() {
    return this.userLib.Data.token?.getCustomer().getShowhotix() ?? false;
  }  
  get show_news2() {
    return this.userLib.Data.token?.getCustomer().getShownews2() ?? false;
  }
  get show_news3() {
    return this.userLib.Data.token?.getCustomer().getShownews3() ?? false;
  }
  get show_news4() {
    return this.userLib.Data.token?.getCustomer().getShownews4() ?? false;
  }
  get show_news5() {
    return this.userLib.Data.token?.getCustomer().getShownews5() ?? false;
  }
  get show_news6() {
    return this.userLib.Data.token?.getCustomer().getShownews6() ?? false;
  }
  get show_news7() {
    return this.userLib.Data.token?.getCustomer().getShownews7() ?? false;
  }
  get show_news8() {
    return this.userLib.Data.token?.getCustomer().getShownews8() ?? false;
  }
  get show_news9() {
    return this.userLib.Data.token?.getCustomer().getShownews9() ?? false;
  }
  get show_links2() {
    return this.hasLink2;
  }
  get show_links3() {
    return this.hasLink3;
  }
  get show_links4() {
    return this.hasLink4;
  }
  get show_stream() {
    return this.hasStream;
  }
  get show_stream2() {
    return this.hasStream2;
  }
  get show_stream3() {
    return this.hasStream3;
  }
  get show_stream4() {
    return this.hasStream4;
  }
  get show_cpsurl() {
    return this.hasCPSURL;
  }
  get show_document() {
    return this.userLib.Data.token?.getCustomer().getShowdocumentpwa() ?? false;
  }  
  get show_restaurant() {
    return this.userLib.Data.token?.getCustomer().getShowrestaurant() ?? false;
  }
  get show_links() {
    return this.hasLink;
  }
}

/** parsing menu options to each template */
export class menu_type_data {
  hasLink = false;
  hasLink2 = false;
  hasLink3 = false;
  hasLink4 = false;

  hasStream = false;
  hasStream2 = false;
  hasStream3 = false;
  hasStream4 = false;
 
  show_member = false;

  hasCPSURL = false;
  cpsurl = '';
  cpsref = 0;
  cpsttl = 0;

  menus$: Promise<CustomizedMenu[]>;
}