import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Ad, Empty } from '../../../libs/proto/commUnity_pb';
import { Status } from '../../../libs/proto/mobile_pb_service';
import * as grpcWeb from 'grpc-web';
import { Observable, Subscribable, Subscriber } from 'rxjs';
import { UserLibService } from '../../user/user-lib.service';

@Injectable({
  providedIn: 'root'
})

export class GrpcAdsLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
  ) { }

  getAds(option?: GrpcLibServiceOption): Promise<Ad[]>{
    return new Observable<Ad[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        // TODO:
      } else {
        this._getAdsOnline(opt);
      }
    }).toPromise();
  }

  private _getAdsOnline(option: GrpcLibServiceOption) {

      this.grpcLib.MobileClient.getAds(new Empty(), {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getAdsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getAdsOnlineStatus(s, option);
      })
      .on('data', (a: Ad) => {
        this._getAdsOnlineData(a, option);
      })
      .on('end', () => {
        this._getAdsOnlineEnd(option);
      });
  }

  private _getAdsOnlineData(ret: Ad, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getAdsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getAdsOnline(option);
    }, option);
  }

  private _getAdsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getAdsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      if (option.KeepInCache || false) { }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getAdsOnline(option);
      }, option);
    }
  }
}
