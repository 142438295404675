<app-sidenav page="albums" menuType>

<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="albums$ | async as albums ">
  <mat-action-list *ngFor="let album of albums">

    <mat-card>
      <div (click)="open(album.getId())"  >
        <mat-card-header class="card">
          <div mat-card-avatar class="img"  [ngStyle]="{'background-image': 'url(' + album.getImagesList()[0] + ')'}" ></div>
          <mat-card-title> {{ album.getTitle() }}</mat-card-title>
          <mat-card-subtitle>{{ album.getShortdescription() }}
          <div class="footer">
          <div class="date">{{ album.getPublishdate() | amParse: 'YYYYMMDD' | amDateFormat: dateFormat}}</div>
          <div *ngIf="album.getYoutube() != ''" (click)="stop($event)">
            <a href="{{album.getYoutube()}}">
            <img class="youtube-logo" src="assets/images/youtube-logo.png">
          </a>
        </div>
        </div>
        </mat-card-subtitle>

        </mat-card-header>
      </div>
    </mat-card>

  </mat-action-list>
</div>

</app-sidenav>