import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { CheckOut, OrderInfo, ProductOrderLine, Cart, ShopSettings, DeliveryAddress } from '../../../libs/proto/shop_pb';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { UserLibService } from '../../user/user-lib.service';
import { Empty, RequestID } from '../../../libs/proto/commUnity_pb';
import { CartConversionService } from '../../conversion/cart/cart-conversion.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { DeliveryCost, ProductToRemove } from '../../../libs/proto/mobile_pb';
import { ShopSettingsConversionService } from '../../conversion/shop-settings/shop-settings-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcShopLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private convCartLib: CartConversionService,
    private convSetLib: ShopSettingsConversionService,
    private storeLib: StorageLibService
  ) { }

  addProductToCart(req: ProductOrderLine): Promise<Cart>{
    return new Observable<Cart>(obs => {
      const opt = this.grpcLib.getOption({});
      opt.call.subscribe = obs;
      opt.call.req = req;

      this._addProductToCart(opt);

    }).toPromise();
  }

  private _addProductToCart(option: GrpcLibServiceOption){
      this.grpcLib.MobileClient.addProductToCart(option.call.req, {
        token: this.userLib.Data.token?.getToken(),
      }, (err: any, resp: Cart) => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._addProductToCartError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._addProductToCartStatus(s, option);
      })
      .on('data', (r: Cart) => {
        this._addProductToCartData(r, option);
      })
      .on('end', () => {
        this._addProductToCartEnd(option);
      });
  }

  private _addProductToCartData(ret: Cart, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _addProductToCartError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._addProductToCart(option);
    }, option);
  }

  private _addProductToCartEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _addProductToCartStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._addProductToCart(option);
      }, option);
    }
  }

  getCart(option?: GrpcLibServiceOption): Promise<Cart>{
    return new Observable<Cart>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getCartOffline(opt);
      } else {
        this._getCartOnline(opt);
      }
    }).toPromise();
  }

  private _getCartOffline(option: GrpcLibServiceOption){
    this.convCartLib.FromStorage(
      this.storeLib.get('cart'), (ns, e) => {
        option.call.subscribe.next(ns);
        option.call.subscribe.complete();
    });
  }

  private _getCartOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getCart(new Empty(), {
        token: this.userLib.Data.token?.getToken(),
      }, (e, r) => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getCartOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getCartOnlineStatus(s, option);
      })
      .on('data', (r: Cart) => {
        this._getCartOnlineData(r, option);
      })
      .on('end', () => {
        this._getCartOnlineEnd(option);
      });
  }

  private _getCartOnlineData(ret: Cart, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getCartOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getCartOnline(option);
    }, option);
  }

  private _getCartOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getCartOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storeLib.cache.cart = true;
      if (option.KeepInCache || false) {
        this.storeLib.set(
          'cart', this.convCartLib.ToStorage(option.call.data[0])
        );
        this.storeLib.set('cart-badge',
            (option.call.data[0] as Cart).getNbitems());
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getCartOnline(option);
      }, option);
    }
  }

  checkOutCart(req: CheckOut): Promise<OrderInfo>{

    return new Observable<OrderInfo>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req
        }
      });
      opt.call.subscribe = obs;

      this._checkOutCartOnline(opt);

    }).toPromise();
  }

  private _checkOutCartOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.checkOutCart(option.call.req, {
      token: this.userLib.Data.token?.getToken(),
    }, (err: any, resp: OrderInfo) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._checkOutCartOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }

  dropProductFromCart(req: ProductToRemove): Promise<Cart>{
    return new Observable<Cart>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req
        }
      });
      opt.call.subscribe = obs;

      this._dropProductFromCartOnline(opt);

    }).toPromise();
  }

  private _dropProductFromCartOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.dropProductFromCart(option.call.req, {
      token: this.userLib.Data.token?.getToken(),
    }, (err: any, resp: Cart) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._dropProductFromCartOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }

  getShopSettings(option?: GrpcLibServiceOption): Promise<ShopSettings>{
    return new Observable<ShopSettings>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getShopSettingsOffline(opt);
      } else {
        this._getShopSettingsOnline(opt);
      }
    }).toPromise();
  }

  private _getShopSettingsOffline(option: GrpcLibServiceOption){
    this.convSetLib.FromStorage(
      this.storeLib.get('shop-setting'), (ns, e) => {
        option.call.subscribe.next(ns);
        option.call.subscribe.complete();
    });
  }

  private _getShopSettingsOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getShopSettings(new Empty(), {
        token: this.userLib.Data.token?.getToken(),
      }, (e, r) => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getShopSettingsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getShopSettingsOnlineStatus(s, option);
      })
      .on('data', (r: ShopSettings) => {
        this._getShopSettingsOnlineData(r, option);
      })
      .on('end', () => {
        this._getShopSettingsOnlineEnd(option);
      });
  }

  private _getShopSettingsOnlineData(ret: ShopSettings, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getShopSettingsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getShopSettingsOnline(option);
    }, option);
  }

  private _getShopSettingsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getShopSettingsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storeLib.cache.shopSetting = true;
      if (option.KeepInCache || false) {
        this.storeLib.set(
          'shop-setting', this.convSetLib.ToStorage(option.call.data[0])
        );
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {

      this.grpcLib.treatStatus(s, () => {
        this._getShopSettingsOnline(option);
      }, option);
    }
  }

  getDeliveryCost(): Promise<DeliveryCost>{

    return new Observable<DeliveryCost>(obs => {
      const opt = this.grpcLib.getOption();
      opt.call.subscribe = obs;

      this._getDeliveryCostOnline(opt);

    }).toPromise();
  }

  private _getDeliveryCostOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getDeliveryCost(new Empty(), {
      token: this.userLib.Data.token?.getToken(),
    }, (err: any, resp: DeliveryCost) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._getDeliveryCostOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }

  getDeliveryAddresses(): Promise<DeliveryAddress[]>{
    return new Observable<DeliveryAddress[]>(obs => {
      const option = this.grpcLib.getOption();
      option.call.subscribe = obs;

      this._getDeliveryAddressesOnline(option);
    }).toPromise();
  }

  private _getDeliveryAddressesOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getDeliveryAddresses(new RequestID(), {
      token: this.userLib.Data.token?.getToken(),
    })
    .on('error', (e: grpcWeb.RpcError) => {
      this._getDeliveryAddressesError(e, option);
    })
    .on('status', (s: grpcWeb.Status) => {
      this._getDeliveryAddressesStatus(s, option);
    })
    .on('data', (r: DeliveryAddress) => {
      this._getDeliveryAddressesData(r, option);
    })
    .on('end', () => {
      this._getDeliveryAddressesEnd(option);
    });
  }

  private _getDeliveryAddressesData(ret: DeliveryAddress, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getDeliveryAddressesError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    this.grpcLib.handleError(e, () => {
      this._getDeliveryAddressesOnline(option);
    }, option);
  }

  private _getDeliveryAddressesEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getDeliveryAddressesStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getDeliveryAddressesOnline(option);
      }, option);
    }
  }

  dropDeliveryAddress(id: string): Promise<Empty>{

    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: id
        }
      });
      opt.call.subscribe = obs;

      this._dropDeliveryAddressOnline(opt);

    }).toPromise();
  }
  private _dropDeliveryAddressOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);

    this.grpcLib.MobileClient.dropDeliveryAddress(req, {
      token: this.userLib.Data.token?.getToken(),
    }, (err: any, resp: Empty) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._dropDeliveryAddressOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }
  addDeliveryAddress(req: DeliveryAddress): Promise<Empty>{

    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req
        }
      });
      opt.call.subscribe = obs;

      this._addDeliveryAddressOnline(opt);

    }).toPromise();
  }

  private _addDeliveryAddressOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.addDeliveryAddress(option.call.req, {
      token: this.userLib.Data.token?.getToken(),
    }, (err: any, resp: Empty) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._addDeliveryAddressOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }

  resetCart(id: string): Promise<Cart>{
    return new Observable<Cart>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: id
        }
      });
      opt.call.subscribe = obs;

      this._resetCartOnline(opt);

    }).toPromise();
  }
  private _resetCartOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);

    this.grpcLib.MobileClient.resetCart(req, {
      token: this.userLib.Data.token?.getToken(),
    }, (err: any, resp: Cart) => {

      if (err != null) {

        this.grpcLib.handleError(err, () => {
          this._resetCartOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(resp);
        option.call.subscribe.complete();
      }
    });
  }
}
