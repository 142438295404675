import { communityAppType } from 'src/app/config/type';
import { SignUpMobileRequest } from '../../libs/proto/mobile_pb';

export class SignupForm {

  email?: string;
  firstName?: string;
  lastName?: string;
  mobile?: string;
  country?: any;
  birthday?: any;
  customerId: string;

  toSignUpMobileRequest(): SignUpMobileRequest {
    const req = new SignUpMobileRequest();
    req.setEmail(this.email);
    req.setFirstname(this.firstName);
    req.setLastname(this.lastName);
    var bd = 0;
    try {
      bd = parseFloat(this.birthday.format('YYYYMMDD'));
      if (isNaN(bd)) bd = 0;
    }catch(e) {
      //
    }
    req.setBirthday( bd );    
    let mb = this.mobile;
    // remove +33
    mb = mb.replace(/^\+33/, '')
    mb = mb.replace(/\+/g, '')

    req.setMobile((this.country?.dial || '') + mb);
    req.setCustomerid(this.customerId);
    req.setMobileapp(communityAppType);
    return req;
  }
}

