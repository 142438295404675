import { Injectable } from '@angular/core';
import { MemberProfileSettings } from '../../../libs/proto/commUnity_pb';
import { BinaryWriter } from 'google-protobuf';

@Injectable({
  providedIn: 'root'
})
export class MemberProfileSettingConversionService {

  constructor() { }

  ToStorage(req: MemberProfileSettings) {
    const w = new BinaryWriter();
    MemberProfileSettings.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (c: MemberProfileSettings, errorConvert: any) => void) {
    if (data === null) {
      callback( null, null);
      return;
    }
    try {
      callback( MemberProfileSettings.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }
}
