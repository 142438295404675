// source: restaurant.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var commUnity_pb = require('./commUnity_pb.js');
goog.object.extend(proto, commUnity_pb);
goog.exportSymbol('proto.commUnity.Attendance', null, global);
goog.exportSymbol('proto.commUnity.AttendanceAction', null, global);
goog.exportSymbol('proto.commUnity.AttendanceRequest', null, global);
goog.exportSymbol('proto.commUnity.CallFromTable', null, global);
goog.exportSymbol('proto.commUnity.CheckOutMeal', null, global);
goog.exportSymbol('proto.commUnity.CheckResult', null, global);
goog.exportSymbol('proto.commUnity.EventTableRequest', null, global);
goog.exportSymbol('proto.commUnity.EventTableReservation', null, global);
goog.exportSymbol('proto.commUnity.Meal', null, global);
goog.exportSymbol('proto.commUnity.Nothing', null, global);
goog.exportSymbol('proto.commUnity.OrderUpdate', null, global);
goog.exportSymbol('proto.commUnity.PaymentUpdate', null, global);
goog.exportSymbol('proto.commUnity.Position', null, global);
goog.exportSymbol('proto.commUnity.RequestTicket', null, global);
goog.exportSymbol('proto.commUnity.RequestTicket.LastCase', null, global);
goog.exportSymbol('proto.commUnity.Reservation', null, global);
goog.exportSymbol('proto.commUnity.ReservationStatus', null, global);
goog.exportSymbol('proto.commUnity.RestaurantOrder', null, global);
goog.exportSymbol('proto.commUnity.RestaurantOrderInfo', null, global);
goog.exportSymbol('proto.commUnity.RestaurantOrderLine', null, global);
goog.exportSymbol('proto.commUnity.RestaurantOrderStatus', null, global);
goog.exportSymbol('proto.commUnity.RestaurantPaymentStatus', null, global);
goog.exportSymbol('proto.commUnity.RestaurantProduct', null, global);
goog.exportSymbol('proto.commUnity.RestaurantProductCategory', null, global);
goog.exportSymbol('proto.commUnity.RestaurantSettings', null, global);
goog.exportSymbol('proto.commUnity.Service', null, global);
goog.exportSymbol('proto.commUnity.Table', null, global);
goog.exportSymbol('proto.commUnity.Ticket', null, global);
goog.exportSymbol('proto.commUnity.TlgUser', null, global);
goog.exportSymbol('proto.commUnity.Waiter', null, global);
goog.exportSymbol('proto.commUnity.Zone', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Nothing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Nothing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Nothing.displayName = 'proto.commUnity.Nothing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.OrderUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.OrderUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.OrderUpdate.displayName = 'proto.commUnity.OrderUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.PaymentUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.PaymentUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.PaymentUpdate.displayName = 'proto.commUnity.PaymentUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RequestTicket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.commUnity.RequestTicket.oneofGroups_);
};
goog.inherits(proto.commUnity.RequestTicket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RequestTicket.displayName = 'proto.commUnity.RequestTicket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Attendance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Attendance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Attendance.displayName = 'proto.commUnity.Attendance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.AttendanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.AttendanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.AttendanceRequest.displayName = 'proto.commUnity.AttendanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Reservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Reservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Reservation.displayName = 'proto.commUnity.Reservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Ticket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Ticket.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Ticket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Ticket.displayName = 'proto.commUnity.Ticket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.CallFromTable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.CallFromTable.repeatedFields_, null);
};
goog.inherits(proto.commUnity.CallFromTable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.CallFromTable.displayName = 'proto.commUnity.CallFromTable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.TlgUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.TlgUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.TlgUser.displayName = 'proto.commUnity.TlgUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.CheckResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.CheckResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.CheckResult.displayName = 'proto.commUnity.CheckResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RestaurantProductCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.RestaurantProductCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RestaurantProductCategory.displayName = 'proto.commUnity.RestaurantProductCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RestaurantProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.RestaurantProduct.repeatedFields_, null);
};
goog.inherits(proto.commUnity.RestaurantProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RestaurantProduct.displayName = 'proto.commUnity.RestaurantProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Waiter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Waiter.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Waiter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Waiter.displayName = 'proto.commUnity.Waiter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Zone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Zone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Zone.displayName = 'proto.commUnity.Zone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RestaurantSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.RestaurantSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RestaurantSettings.displayName = 'proto.commUnity.RestaurantSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Table.displayName = 'proto.commUnity.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RestaurantOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.RestaurantOrder.repeatedFields_, null);
};
goog.inherits(proto.commUnity.RestaurantOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RestaurantOrder.displayName = 'proto.commUnity.RestaurantOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RestaurantOrderLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.RestaurantOrderLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RestaurantOrderLine.displayName = 'proto.commUnity.RestaurantOrderLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Meal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Meal.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Meal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Meal.displayName = 'proto.commUnity.Meal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.CheckOutMeal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.CheckOutMeal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.CheckOutMeal.displayName = 'proto.commUnity.CheckOutMeal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RestaurantOrderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.RestaurantOrderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RestaurantOrderInfo.displayName = 'proto.commUnity.RestaurantOrderInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.EventTableReservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.EventTableReservation.repeatedFields_, null);
};
goog.inherits(proto.commUnity.EventTableReservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.EventTableReservation.displayName = 'proto.commUnity.EventTableReservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.EventTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.EventTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.EventTableRequest.displayName = 'proto.commUnity.EventTableRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Nothing.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Nothing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Nothing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Nothing.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Nothing}
 */
proto.commUnity.Nothing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Nothing;
  return proto.commUnity.Nothing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Nothing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Nothing}
 */
proto.commUnity.Nothing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Nothing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Nothing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Nothing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Nothing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.OrderUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.OrderUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.OrderUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.OrderUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    telegramid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    status: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.OrderUpdate}
 */
proto.commUnity.OrderUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.OrderUpdate;
  return proto.commUnity.OrderUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.OrderUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.OrderUpdate}
 */
proto.commUnity.OrderUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTelegramid(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.RestaurantOrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.OrderUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.OrderUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.OrderUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.OrderUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTelegramid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
};


/**
 * optional string OrderID = 1;
 * @return {string}
 */
proto.commUnity.OrderUpdate.prototype.getOrderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.OrderUpdate} returns this
 */
proto.commUnity.OrderUpdate.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 TelegramID = 10;
 * @return {number}
 */
proto.commUnity.OrderUpdate.prototype.getTelegramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.OrderUpdate} returns this
 */
proto.commUnity.OrderUpdate.prototype.setTelegramid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional RestaurantOrderStatus Status = 20;
 * @return {!proto.commUnity.RestaurantOrderStatus}
 */
proto.commUnity.OrderUpdate.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.RestaurantOrderStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.RestaurantOrderStatus} value
 * @return {!proto.commUnity.OrderUpdate} returns this
 */
proto.commUnity.OrderUpdate.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.PaymentUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.PaymentUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.PaymentUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.PaymentUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    telegramid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    status: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.PaymentUpdate}
 */
proto.commUnity.PaymentUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.PaymentUpdate;
  return proto.commUnity.PaymentUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.PaymentUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.PaymentUpdate}
 */
proto.commUnity.PaymentUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTelegramid(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.RestaurantPaymentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.PaymentUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.PaymentUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.PaymentUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.PaymentUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTelegramid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
};


/**
 * optional string OrderID = 1;
 * @return {string}
 */
proto.commUnity.PaymentUpdate.prototype.getOrderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.PaymentUpdate} returns this
 */
proto.commUnity.PaymentUpdate.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 TelegramID = 10;
 * @return {number}
 */
proto.commUnity.PaymentUpdate.prototype.getTelegramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.PaymentUpdate} returns this
 */
proto.commUnity.PaymentUpdate.prototype.setTelegramid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional RestaurantPaymentStatus Status = 20;
 * @return {!proto.commUnity.RestaurantPaymentStatus}
 */
proto.commUnity.PaymentUpdate.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.RestaurantPaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.RestaurantPaymentStatus} value
 * @return {!proto.commUnity.PaymentUpdate} returns this
 */
proto.commUnity.PaymentUpdate.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.commUnity.RequestTicket.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.commUnity.RequestTicket.LastCase = {
  LAST_NOT_SET: 0,
  LASTORDERID: 2,
  LASTTIME: 3
};

/**
 * @return {proto.commUnity.RequestTicket.LastCase}
 */
proto.commUnity.RequestTicket.prototype.getLastCase = function() {
  return /** @type {proto.commUnity.RequestTicket.LastCase} */(jspb.Message.computeOneofCase(this, proto.commUnity.RequestTicket.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RequestTicket.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RequestTicket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RequestTicket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestTicket.toObject = function(includeInstance, msg) {
  var f, obj = {
    api: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastorderid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lasttime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderstatus: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RequestTicket}
 */
proto.commUnity.RequestTicket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RequestTicket;
  return proto.commUnity.RequestTicket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RequestTicket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RequestTicket}
 */
proto.commUnity.RequestTicket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastorderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLasttime(value);
      break;
    case 5:
      var value = /** @type {!proto.commUnity.RestaurantOrderStatus} */ (reader.readEnum());
      msg.setOrderstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RequestTicket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RequestTicket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RequestTicket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestTicket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApi();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOrderstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string API = 1;
 * @return {string}
 */
proto.commUnity.RequestTicket.prototype.getApi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestTicket} returns this
 */
proto.commUnity.RequestTicket.prototype.setApi = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string LastOrderID = 2;
 * @return {string}
 */
proto.commUnity.RequestTicket.prototype.getLastorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestTicket} returns this
 */
proto.commUnity.RequestTicket.prototype.setLastorderid = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.commUnity.RequestTicket.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.commUnity.RequestTicket} returns this
 */
proto.commUnity.RequestTicket.prototype.clearLastorderid = function() {
  return jspb.Message.setOneofField(this, 2, proto.commUnity.RequestTicket.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.RequestTicket.prototype.hasLastorderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 LastTime = 3;
 * @return {number}
 */
proto.commUnity.RequestTicket.prototype.getLasttime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RequestTicket} returns this
 */
proto.commUnity.RequestTicket.prototype.setLasttime = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.commUnity.RequestTicket.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.commUnity.RequestTicket} returns this
 */
proto.commUnity.RequestTicket.prototype.clearLasttime = function() {
  return jspb.Message.setOneofField(this, 3, proto.commUnity.RequestTicket.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.RequestTicket.prototype.hasLasttime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RestaurantOrderStatus OrderStatus = 5;
 * @return {!proto.commUnity.RestaurantOrderStatus}
 */
proto.commUnity.RequestTicket.prototype.getOrderstatus = function() {
  return /** @type {!proto.commUnity.RestaurantOrderStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.commUnity.RestaurantOrderStatus} value
 * @return {!proto.commUnity.RequestTicket} returns this
 */
proto.commUnity.RequestTicket.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Attendance.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Attendance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Attendance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Attendance.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    staffid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    staffname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    locationname: jspb.Message.getFieldWithDefault(msg, 9, ""),
    action: jspb.Message.getFieldWithDefault(msg, 10, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 20, 0),
    loc: (f = msg.getLoc()) && commUnity_pb.Loc.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Attendance}
 */
proto.commUnity.Attendance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Attendance;
  return proto.commUnity.Attendance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Attendance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Attendance}
 */
proto.commUnity.Attendance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStaffid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStaffname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationname(value);
      break;
    case 10:
      var value = /** @type {!proto.commUnity.AttendanceAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 30:
      var value = new commUnity_pb.Loc;
      reader.readMessage(value,commUnity_pb.Loc.deserializeBinaryFromReader);
      msg.setLoc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Attendance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Attendance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Attendance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Attendance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStaffid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStaffname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLocationname();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getLoc();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      commUnity_pb.Loc.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Attendance.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Attendance} returns this
 */
proto.commUnity.Attendance.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string StaffID = 5;
 * @return {string}
 */
proto.commUnity.Attendance.prototype.getStaffid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Attendance} returns this
 */
proto.commUnity.Attendance.prototype.setStaffid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string StaffName = 8;
 * @return {string}
 */
proto.commUnity.Attendance.prototype.getStaffname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Attendance} returns this
 */
proto.commUnity.Attendance.prototype.setStaffname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string LocationName = 9;
 * @return {string}
 */
proto.commUnity.Attendance.prototype.getLocationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Attendance} returns this
 */
proto.commUnity.Attendance.prototype.setLocationname = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional AttendanceAction Action = 10;
 * @return {!proto.commUnity.AttendanceAction}
 */
proto.commUnity.Attendance.prototype.getAction = function() {
  return /** @type {!proto.commUnity.AttendanceAction} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.commUnity.AttendanceAction} value
 * @return {!proto.commUnity.Attendance} returns this
 */
proto.commUnity.Attendance.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional int64 TimeStamp = 20;
 * @return {number}
 */
proto.commUnity.Attendance.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Attendance} returns this
 */
proto.commUnity.Attendance.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional Loc loc = 30;
 * @return {?proto.commUnity.Loc}
 */
proto.commUnity.Attendance.prototype.getLoc = function() {
  return /** @type{?proto.commUnity.Loc} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Loc, 30));
};


/**
 * @param {?proto.commUnity.Loc|undefined} value
 * @return {!proto.commUnity.Attendance} returns this
*/
proto.commUnity.Attendance.prototype.setLoc = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Attendance} returns this
 */
proto.commUnity.Attendance.prototype.clearLoc = function() {
  return this.setLoc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Attendance.prototype.hasLoc = function() {
  return jspb.Message.getField(this, 30) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.AttendanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.AttendanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.AttendanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.AttendanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    staffid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    from: jspb.Message.getFieldWithDefault(msg, 10, 0),
    to: jspb.Message.getFieldWithDefault(msg, 20, 0),
    xlsx: jspb.Message.getBooleanFieldWithDefault(msg, 30, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.AttendanceRequest}
 */
proto.commUnity.AttendanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.AttendanceRequest;
  return proto.commUnity.AttendanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.AttendanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.AttendanceRequest}
 */
proto.commUnity.AttendanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStaffid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setXlsx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.AttendanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.AttendanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.AttendanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.AttendanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStaffid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getXlsx();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.AttendanceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AttendanceRequest} returns this
 */
proto.commUnity.AttendanceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string StaffID = 5;
 * @return {string}
 */
proto.commUnity.AttendanceRequest.prototype.getStaffid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AttendanceRequest} returns this
 */
proto.commUnity.AttendanceRequest.prototype.setStaffid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 From = 10;
 * @return {number}
 */
proto.commUnity.AttendanceRequest.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.AttendanceRequest} returns this
 */
proto.commUnity.AttendanceRequest.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 To = 20;
 * @return {number}
 */
proto.commUnity.AttendanceRequest.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.AttendanceRequest} returns this
 */
proto.commUnity.AttendanceRequest.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional bool XLSX = 30;
 * @return {boolean}
 */
proto.commUnity.AttendanceRequest.prototype.getXlsx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.AttendanceRequest} returns this
 */
proto.commUnity.AttendanceRequest.prototype.setXlsx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Reservation.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Reservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Reservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Reservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tile: jspb.Message.getFieldWithDefault(msg, 8, 0),
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    email: jspb.Message.getFieldWithDefault(msg, 15, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 18, ""),
    status: jspb.Message.getFieldWithDefault(msg, 20, 0),
    zoneid: jspb.Message.getFieldWithDefault(msg, 24, ""),
    tableid: jspb.Message.getFieldWithDefault(msg, 25, ""),
    madeon: jspb.Message.getFieldWithDefault(msg, 30, 0),
    date: jspb.Message.getFieldWithDefault(msg, 40, 0),
    service: jspb.Message.getFieldWithDefault(msg, 45, 0),
    canceltimestamp: jspb.Message.getFieldWithDefault(msg, 100, 0),
    cancelreason: jspb.Message.getFieldWithDefault(msg, 101, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Reservation}
 */
proto.commUnity.Reservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Reservation;
  return proto.commUnity.Reservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Reservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Reservation}
 */
proto.commUnity.Reservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 8:
      var value = /** @type {!proto.commUnity.Title} */ (reader.readEnum());
      msg.setTile(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.ReservationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableid(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMadeon(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 45:
      var value = /** @type {!proto.commUnity.Service} */ (reader.readEnum());
      msg.setService(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCanceltimestamp(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setCancelreason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Reservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Reservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Reservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Reservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTile();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getZoneid();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTableid();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getMadeon();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = message.getService();
  if (f !== 0.0) {
    writer.writeEnum(
      45,
      f
    );
  }
  f = message.getCanceltimestamp();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
  f = message.getCancelreason();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Number = 2;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string MemberID = 3;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Title Tile = 8;
 * @return {!proto.commUnity.Title}
 */
proto.commUnity.Reservation.prototype.getTile = function() {
  return /** @type {!proto.commUnity.Title} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.commUnity.Title} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setTile = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string Name = 10;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string FirstName = 11;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string Email = 15;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string Mobile = 18;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional ReservationStatus Status = 20;
 * @return {!proto.commUnity.ReservationStatus}
 */
proto.commUnity.Reservation.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.ReservationStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.ReservationStatus} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional string ZoneID = 24;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getZoneid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setZoneid = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string TableID = 25;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getTableid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setTableid = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int64 MadeOn = 30;
 * @return {number}
 */
proto.commUnity.Reservation.prototype.getMadeon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setMadeon = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int64 Date = 40;
 * @return {number}
 */
proto.commUnity.Reservation.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional Service Service = 45;
 * @return {!proto.commUnity.Service}
 */
proto.commUnity.Reservation.prototype.getService = function() {
  return /** @type {!proto.commUnity.Service} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {!proto.commUnity.Service} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setService = function(value) {
  return jspb.Message.setProto3EnumField(this, 45, value);
};


/**
 * optional int64 CancelTimeStamp = 100;
 * @return {number}
 */
proto.commUnity.Reservation.prototype.getCanceltimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setCanceltimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional string CancelReason = 101;
 * @return {string}
 */
proto.commUnity.Reservation.prototype.getCancelreason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Reservation} returns this
 */
proto.commUnity.Reservation.prototype.setCancelreason = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Ticket.repeatedFields_ = [30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Ticket.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Ticket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Ticket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Ticket.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tablecode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 15, 0),
    lastupdate: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pdf: msg.getPdf_asB64(),
    jpg: msg.getJpg_asB64(),
    webp: msg.getWebp_asB64(),
    html: msg.getHtml_asB64(),
    telegramidsList: (f = jspb.Message.getRepeatedField(msg, 30)) == null ? undefined : f,
    orderstatus: jspb.Message.getFieldWithDefault(msg, 40, 0),
    paymentstatus: jspb.Message.getFieldWithDefault(msg, 50, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Ticket}
 */
proto.commUnity.Ticket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Ticket;
  return proto.commUnity.Ticket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Ticket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Ticket}
 */
proto.commUnity.Ticket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTablecode(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastupdate(value);
      break;
    case 20:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPdf(value);
      break;
    case 22:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setJpg(value);
      break;
    case 24:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWebp(value);
      break;
    case 26:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHtml(value);
      break;
    case 30:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTelegramids(values[i]);
      }
      break;
    case 40:
      var value = /** @type {!proto.commUnity.RestaurantOrderStatus} */ (reader.readEnum());
      msg.setOrderstatus(value);
      break;
    case 50:
      var value = /** @type {!proto.commUnity.RestaurantPaymentStatus} */ (reader.readEnum());
      msg.setPaymentstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Ticket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Ticket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Ticket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Ticket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTablecode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getLastupdate();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getPdf_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      20,
      f
    );
  }
  f = message.getJpg_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      22,
      f
    );
  }
  f = message.getWebp_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      24,
      f
    );
  }
  f = message.getHtml_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      26,
      f
    );
  }
  f = message.getTelegramidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      30,
      f
    );
  }
  f = message.getOrderstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      40,
      f
    );
  }
  f = message.getPaymentstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
};


/**
 * optional string OrderID = 1;
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getOrderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrderNumber = 10;
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string TableCode = 12;
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getTablecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setTablecode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 Timestamp = 15;
 * @return {number}
 */
proto.commUnity.Ticket.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 LastUpdate = 16;
 * @return {number}
 */
proto.commUnity.Ticket.prototype.getLastupdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setLastupdate = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bytes PDF = 20;
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getPdf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * optional bytes PDF = 20;
 * This is a type-conversion wrapper around `getPdf()`
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getPdf_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPdf()));
};


/**
 * optional bytes PDF = 20;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPdf()`
 * @return {!Uint8Array}
 */
proto.commUnity.Ticket.prototype.getPdf_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPdf()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setPdf = function(value) {
  return jspb.Message.setProto3BytesField(this, 20, value);
};


/**
 * optional bytes JPG = 22;
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getJpg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * optional bytes JPG = 22;
 * This is a type-conversion wrapper around `getJpg()`
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getJpg_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getJpg()));
};


/**
 * optional bytes JPG = 22;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getJpg()`
 * @return {!Uint8Array}
 */
proto.commUnity.Ticket.prototype.getJpg_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getJpg()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setJpg = function(value) {
  return jspb.Message.setProto3BytesField(this, 22, value);
};


/**
 * optional bytes WEBP = 24;
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getWebp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * optional bytes WEBP = 24;
 * This is a type-conversion wrapper around `getWebp()`
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getWebp_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWebp()));
};


/**
 * optional bytes WEBP = 24;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWebp()`
 * @return {!Uint8Array}
 */
proto.commUnity.Ticket.prototype.getWebp_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWebp()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setWebp = function(value) {
  return jspb.Message.setProto3BytesField(this, 24, value);
};


/**
 * optional bytes HTML = 26;
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getHtml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * optional bytes HTML = 26;
 * This is a type-conversion wrapper around `getHtml()`
 * @return {string}
 */
proto.commUnity.Ticket.prototype.getHtml_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHtml()));
};


/**
 * optional bytes HTML = 26;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHtml()`
 * @return {!Uint8Array}
 */
proto.commUnity.Ticket.prototype.getHtml_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHtml()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setHtml = function(value) {
  return jspb.Message.setProto3BytesField(this, 26, value);
};


/**
 * repeated int64 TelegramIDs = 30;
 * @return {!Array<number>}
 */
proto.commUnity.Ticket.prototype.getTelegramidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 30));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setTelegramidsList = function(value) {
  return jspb.Message.setField(this, 30, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.addTelegramids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 30, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.clearTelegramidsList = function() {
  return this.setTelegramidsList([]);
};


/**
 * optional RestaurantOrderStatus OrderStatus = 40;
 * @return {!proto.commUnity.RestaurantOrderStatus}
 */
proto.commUnity.Ticket.prototype.getOrderstatus = function() {
  return /** @type {!proto.commUnity.RestaurantOrderStatus} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {!proto.commUnity.RestaurantOrderStatus} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 40, value);
};


/**
 * optional RestaurantPaymentStatus PaymentStatus = 50;
 * @return {!proto.commUnity.RestaurantPaymentStatus}
 */
proto.commUnity.Ticket.prototype.getPaymentstatus = function() {
  return /** @type {!proto.commUnity.RestaurantPaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.commUnity.RestaurantPaymentStatus} value
 * @return {!proto.commUnity.Ticket} returns this
 */
proto.commUnity.Ticket.prototype.setPaymentstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.CallFromTable.repeatedFields_ = [30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.CallFromTable.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.CallFromTable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.CallFromTable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CallFromTable.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tablecode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 15, 0),
    telegramidsList: (f = jspb.Message.getRepeatedField(msg, 30)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.CallFromTable}
 */
proto.commUnity.CallFromTable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.CallFromTable;
  return proto.commUnity.CallFromTable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.CallFromTable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.CallFromTable}
 */
proto.commUnity.CallFromTable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTablecode(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 30:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTelegramids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.CallFromTable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.CallFromTable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.CallFromTable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CallFromTable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTablecode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getTelegramidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      30,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.CallFromTable.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CallFromTable} returns this
 */
proto.commUnity.CallFromTable.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TableCode = 12;
 * @return {string}
 */
proto.commUnity.CallFromTable.prototype.getTablecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CallFromTable} returns this
 */
proto.commUnity.CallFromTable.prototype.setTablecode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 Timestamp = 15;
 * @return {number}
 */
proto.commUnity.CallFromTable.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.CallFromTable} returns this
 */
proto.commUnity.CallFromTable.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated int64 TelegramIDs = 30;
 * @return {!Array<number>}
 */
proto.commUnity.CallFromTable.prototype.getTelegramidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 30));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.commUnity.CallFromTable} returns this
 */
proto.commUnity.CallFromTable.prototype.setTelegramidsList = function(value) {
  return jspb.Message.setField(this, 30, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.CallFromTable} returns this
 */
proto.commUnity.CallFromTable.prototype.addTelegramids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 30, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.CallFromTable} returns this
 */
proto.commUnity.CallFromTable.prototype.clearTelegramidsList = function() {
  return this.setTelegramidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.TlgUser.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.TlgUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.TlgUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.TlgUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    telegramid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.TlgUser}
 */
proto.commUnity.TlgUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.TlgUser;
  return proto.commUnity.TlgUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.TlgUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.TlgUser}
 */
proto.commUnity.TlgUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTelegramid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.TlgUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.TlgUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.TlgUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.TlgUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTelegramid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 TelegramID = 1;
 * @return {number}
 */
proto.commUnity.TlgUser.prototype.getTelegramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.TlgUser} returns this
 */
proto.commUnity.TlgUser.prototype.setTelegramid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.CheckResult.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.CheckResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.CheckResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CheckResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    restaurant: jspb.Message.getFieldWithDefault(msg, 2, ""),
    open: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    position: jspb.Message.getFieldWithDefault(msg, 20, 0),
    attendanceid: jspb.Message.getFieldWithDefault(msg, 30, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.CheckResult}
 */
proto.commUnity.CheckResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.CheckResult;
  return proto.commUnity.CheckResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.CheckResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.CheckResult}
 */
proto.commUnity.CheckResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRestaurant(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpen(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.Position} */ (reader.readEnum());
      msg.setPosition(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttendanceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.CheckResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.CheckResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.CheckResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CheckResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRestaurant();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOpen();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getAttendanceid();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.commUnity.CheckResult.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckResult} returns this
 */
proto.commUnity.CheckResult.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Restaurant = 2;
 * @return {string}
 */
proto.commUnity.CheckResult.prototype.getRestaurant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckResult} returns this
 */
proto.commUnity.CheckResult.prototype.setRestaurant = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool Open = 10;
 * @return {boolean}
 */
proto.commUnity.CheckResult.prototype.getOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.CheckResult} returns this
 */
proto.commUnity.CheckResult.prototype.setOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional Position Position = 20;
 * @return {!proto.commUnity.Position}
 */
proto.commUnity.CheckResult.prototype.getPosition = function() {
  return /** @type {!proto.commUnity.Position} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.Position} value
 * @return {!proto.commUnity.CheckResult} returns this
 */
proto.commUnity.CheckResult.prototype.setPosition = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional string AttendanceID = 30;
 * @return {string}
 */
proto.commUnity.CheckResult.prototype.getAttendanceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckResult} returns this
 */
proto.commUnity.CheckResult.prototype.setAttendanceid = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RestaurantProductCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RestaurantProductCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RestaurantProductCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantProductCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RestaurantProductCategory}
 */
proto.commUnity.RestaurantProductCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RestaurantProductCategory;
  return proto.commUnity.RestaurantProductCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RestaurantProductCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RestaurantProductCategory}
 */
proto.commUnity.RestaurantProductCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RestaurantProductCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RestaurantProductCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RestaurantProductCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantProductCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.RestaurantProductCategory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProductCategory} returns this
 */
proto.commUnity.RestaurantProductCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.commUnity.RestaurantProductCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProductCategory} returns this
 */
proto.commUnity.RestaurantProductCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.commUnity.RestaurantProductCategory.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProductCategory} returns this
 */
proto.commUnity.RestaurantProductCategory.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 Order = 5;
 * @return {number}
 */
proto.commUnity.RestaurantProductCategory.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantProductCategory} returns this
 */
proto.commUnity.RestaurantProductCategory.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.RestaurantProduct.repeatedFields_ = [10,50];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RestaurantProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RestaurantProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RestaurantProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    shortdescription: jspb.Message.getFieldWithDefault(msg, 8, ""),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    categoryname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    sku: jspb.Message.getFieldWithDefault(msg, 14, ""),
    qt: jspb.Message.getFieldWithDefault(msg, 16, 0),
    qtyn: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    alcoholyn: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    categoryorder: jspb.Message.getFieldWithDefault(msg, 30, 0),
    relatedproductsList: (f = jspb.Message.getRepeatedField(msg, 50)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RestaurantProduct}
 */
proto.commUnity.RestaurantProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RestaurantProduct;
  return proto.commUnity.RestaurantProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RestaurantProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RestaurantProduct}
 */
proto.commUnity.RestaurantProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryname(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQt(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQtyn(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlcoholyn(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCategoryorder(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.addRelatedproducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RestaurantProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RestaurantProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RestaurantProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getCategoryname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getQt();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getQtyn();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getAlcoholyn();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getCategoryorder();
  if (f !== 0) {
    writer.writeUint32(
      30,
      f
    );
  }
  f = message.getRelatedproductsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      50,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.RestaurantProduct.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.RestaurantProduct.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CategoryID = 3;
 * @return {string}
 */
proto.commUnity.RestaurantProduct.prototype.getCategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double Price = 6;
 * @return {number}
 */
proto.commUnity.RestaurantProduct.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string Description = 7;
 * @return {string}
 */
proto.commUnity.RestaurantProduct.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string ShortDescription = 8;
 * @return {string}
 */
proto.commUnity.RestaurantProduct.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string Images = 10;
 * @return {!Array<string>}
 */
proto.commUnity.RestaurantProduct.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string CategoryName = 11;
 * @return {string}
 */
proto.commUnity.RestaurantProduct.prototype.getCategoryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setCategoryname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool IsAvailable = 13;
 * @return {boolean}
 */
proto.commUnity.RestaurantProduct.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string SKU = 14;
 * @return {string}
 */
proto.commUnity.RestaurantProduct.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 QT = 16;
 * @return {number}
 */
proto.commUnity.RestaurantProduct.prototype.getQt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setQt = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bool QTYN = 17;
 * @return {boolean}
 */
proto.commUnity.RestaurantProduct.prototype.getQtyn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setQtyn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool AlcoholYN = 18;
 * @return {boolean}
 */
proto.commUnity.RestaurantProduct.prototype.getAlcoholyn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setAlcoholyn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional uint32 CategoryOrder = 30;
 * @return {number}
 */
proto.commUnity.RestaurantProduct.prototype.getCategoryorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setCategoryorder = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * repeated string RelatedProducts = 50;
 * @return {!Array<string>}
 */
proto.commUnity.RestaurantProduct.prototype.getRelatedproductsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 50));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.setRelatedproductsList = function(value) {
  return jspb.Message.setField(this, 50, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.addRelatedproducts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 50, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.RestaurantProduct} returns this
 */
proto.commUnity.RestaurantProduct.prototype.clearRelatedproductsList = function() {
  return this.setRelatedproductsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Waiter.repeatedFields_ = [30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Waiter.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Waiter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Waiter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Waiter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    code: jspb.Message.getFieldWithDefault(msg, 15, ""),
    telegramid: jspb.Message.getFieldWithDefault(msg, 20, 0),
    position: jspb.Message.getFieldWithDefault(msg, 25, 0),
    zoneidsList: (f = jspb.Message.getRepeatedField(msg, 30)) == null ? undefined : f,
    onduty: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 45, false),
    geolocationrequired: jspb.Message.getBooleanFieldWithDefault(msg, 50, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Waiter}
 */
proto.commUnity.Waiter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Waiter;
  return proto.commUnity.Waiter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Waiter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Waiter}
 */
proto.commUnity.Waiter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTelegramid(value);
      break;
    case 25:
      var value = /** @type {!proto.commUnity.Position} */ (reader.readEnum());
      msg.setPosition(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.addZoneids(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnduty(value);
      break;
    case 45:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 50:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGeolocationrequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Waiter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Waiter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Waiter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Waiter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTelegramid();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getZoneidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      30,
      f
    );
  }
  f = message.getOnduty();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      45,
      f
    );
  }
  f = message.getGeolocationrequired();
  if (f) {
    writer.writeBool(
      50,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Waiter.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FirstName = 10;
 * @return {string}
 */
proto.commUnity.Waiter.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string LastName = 12;
 * @return {string}
 */
proto.commUnity.Waiter.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string Code = 15;
 * @return {string}
 */
proto.commUnity.Waiter.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 TelegramID = 20;
 * @return {number}
 */
proto.commUnity.Waiter.prototype.getTelegramid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setTelegramid = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional Position Position = 25;
 * @return {!proto.commUnity.Position}
 */
proto.commUnity.Waiter.prototype.getPosition = function() {
  return /** @type {!proto.commUnity.Position} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.commUnity.Position} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setPosition = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * repeated string ZoneIDs = 30;
 * @return {!Array<string>}
 */
proto.commUnity.Waiter.prototype.getZoneidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 30));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setZoneidsList = function(value) {
  return jspb.Message.setField(this, 30, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.addZoneids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 30, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.clearZoneidsList = function() {
  return this.setZoneidsList([]);
};


/**
 * optional bool OnDuty = 40;
 * @return {boolean}
 */
proto.commUnity.Waiter.prototype.getOnduty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setOnduty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool Active = 45;
 * @return {boolean}
 */
proto.commUnity.Waiter.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 45, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 45, value);
};


/**
 * optional bool GeoLocationRequired = 50;
 * @return {boolean}
 */
proto.commUnity.Waiter.prototype.getGeolocationrequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 50, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Waiter} returns this
 */
proto.commUnity.Waiter.prototype.setGeolocationrequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 50, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Zone.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Zone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Zone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Zone.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Zone}
 */
proto.commUnity.Zone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Zone;
  return proto.commUnity.Zone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Zone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Zone}
 */
proto.commUnity.Zone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Zone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Zone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Zone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Zone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Zone.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Zone} returns this
 */
proto.commUnity.Zone.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 10;
 * @return {string}
 */
proto.commUnity.Zone.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Zone} returns this
 */
proto.commUnity.Zone.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool Active = 15;
 * @return {boolean}
 */
proto.commUnity.Zone.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Zone} returns this
 */
proto.commUnity.Zone.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RestaurantSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RestaurantSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RestaurantSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    apikey: jspb.Message.getFieldWithDefault(msg, 10, ""),
    acceptorder: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    proposeregister: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    activepayment: jspb.Message.getBooleanFieldWithDefault(msg, 45, false),
    forcepayment: jspb.Message.getBooleanFieldWithDefault(msg, 50, false),
    allowtextatorder: jspb.Message.getBooleanFieldWithDefault(msg, 52, false),
    allowtextatreservation: jspb.Message.getBooleanFieldWithDefault(msg, 53, false),
    forcegeolocation: jspb.Message.getBooleanFieldWithDefault(msg, 55, false),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 60, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 65, 0.0),
    radius: jspb.Message.getFieldWithDefault(msg, 70, 0),
    serviceclosedtext: jspb.Message.getFieldWithDefault(msg, 80, ""),
    proposeregistertext: jspb.Message.getFieldWithDefault(msg, 85, ""),
    receiptfooter: jspb.Message.getFieldWithDefault(msg, 90, ""),
    callwaitertext: jspb.Message.getFieldWithDefault(msg, 95, ""),
    defaultcollapse: jspb.Message.getBooleanFieldWithDefault(msg, 33, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RestaurantSettings}
 */
proto.commUnity.RestaurantSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RestaurantSettings;
  return proto.commUnity.RestaurantSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RestaurantSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RestaurantSettings}
 */
proto.commUnity.RestaurantSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setApikey(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAcceptorder(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProposeregister(value);
      break;
    case 45:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivepayment(value);
      break;
    case 50:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForcepayment(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowtextatorder(value);
      break;
    case 53:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowtextatreservation(value);
      break;
    case 55:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForcegeolocation(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRadius(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceclosedtext(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setProposeregistertext(value);
      break;
    case 90:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptfooter(value);
      break;
    case 95:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallwaitertext(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultcollapse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RestaurantSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RestaurantSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RestaurantSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApikey();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAcceptorder();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getProposeregister();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getActivepayment();
  if (f) {
    writer.writeBool(
      45,
      f
    );
  }
  f = message.getForcepayment();
  if (f) {
    writer.writeBool(
      50,
      f
    );
  }
  f = message.getAllowtextatorder();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
  f = message.getAllowtextatreservation();
  if (f) {
    writer.writeBool(
      53,
      f
    );
  }
  f = message.getForcegeolocation();
  if (f) {
    writer.writeBool(
      55,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      60,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      65,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0) {
    writer.writeInt32(
      70,
      f
    );
  }
  f = message.getServiceclosedtext();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getProposeregistertext();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getReceiptfooter();
  if (f.length > 0) {
    writer.writeString(
      90,
      f
    );
  }
  f = message.getCallwaitertext();
  if (f.length > 0) {
    writer.writeString(
      95,
      f
    );
  }
  f = message.getDefaultcollapse();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.RestaurantSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.commUnity.RestaurantSettings.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string APIKey = 10;
 * @return {string}
 */
proto.commUnity.RestaurantSettings.prototype.getApikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setApikey = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool AcceptOrder = 40;
 * @return {boolean}
 */
proto.commUnity.RestaurantSettings.prototype.getAcceptorder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setAcceptorder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool ProposeRegister = 41;
 * @return {boolean}
 */
proto.commUnity.RestaurantSettings.prototype.getProposeregister = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setProposeregister = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool ActivePayment = 45;
 * @return {boolean}
 */
proto.commUnity.RestaurantSettings.prototype.getActivepayment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 45, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setActivepayment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 45, value);
};


/**
 * optional bool ForcePayment = 50;
 * @return {boolean}
 */
proto.commUnity.RestaurantSettings.prototype.getForcepayment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 50, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setForcepayment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 50, value);
};


/**
 * optional bool AllowTextAtOrder = 52;
 * @return {boolean}
 */
proto.commUnity.RestaurantSettings.prototype.getAllowtextatorder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setAllowtextatorder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};


/**
 * optional bool AllowTextAtReservation = 53;
 * @return {boolean}
 */
proto.commUnity.RestaurantSettings.prototype.getAllowtextatreservation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 53, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setAllowtextatreservation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 53, value);
};


/**
 * optional bool ForceGeoLocation = 55;
 * @return {boolean}
 */
proto.commUnity.RestaurantSettings.prototype.getForcegeolocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 55, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setForcegeolocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 55, value);
};


/**
 * optional double Latitude = 60;
 * @return {number}
 */
proto.commUnity.RestaurantSettings.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 60, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 60, value);
};


/**
 * optional double Longitude = 65;
 * @return {number}
 */
proto.commUnity.RestaurantSettings.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 65, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 65, value);
};


/**
 * optional int32 Radius = 70;
 * @return {number}
 */
proto.commUnity.RestaurantSettings.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 70, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setRadius = function(value) {
  return jspb.Message.setProto3IntField(this, 70, value);
};


/**
 * optional string ServiceClosedText = 80;
 * @return {string}
 */
proto.commUnity.RestaurantSettings.prototype.getServiceclosedtext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setServiceclosedtext = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional string ProposeRegisterText = 85;
 * @return {string}
 */
proto.commUnity.RestaurantSettings.prototype.getProposeregistertext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setProposeregistertext = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional string ReceiptFooter = 90;
 * @return {string}
 */
proto.commUnity.RestaurantSettings.prototype.getReceiptfooter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 90, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setReceiptfooter = function(value) {
  return jspb.Message.setProto3StringField(this, 90, value);
};


/**
 * optional string CallWaiterText = 95;
 * @return {string}
 */
proto.commUnity.RestaurantSettings.prototype.getCallwaitertext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 95, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setCallwaitertext = function(value) {
  return jspb.Message.setProto3StringField(this, 95, value);
};


/**
 * optional bool DefaultCollapse = 33;
 * @return {boolean}
 */
proto.commUnity.RestaurantSettings.prototype.getDefaultcollapse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantSettings} returns this
 */
proto.commUnity.RestaurantSettings.prototype.setDefaultcollapse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    zoneid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    zonename: jspb.Message.getFieldWithDefault(msg, 8, ""),
    qrcode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    seats: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Table}
 */
proto.commUnity.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Table;
  return proto.commUnity.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Table}
 */
proto.commUnity.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setZonename(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQrcode(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getZoneid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getZonename();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQrcode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSeats();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Table.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Table} returns this
 */
proto.commUnity.Table.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Code = 2;
 * @return {string}
 */
proto.commUnity.Table.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Table} returns this
 */
proto.commUnity.Table.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ZoneID = 5;
 * @return {string}
 */
proto.commUnity.Table.prototype.getZoneid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Table} returns this
 */
proto.commUnity.Table.prototype.setZoneid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ZoneName = 8;
 * @return {string}
 */
proto.commUnity.Table.prototype.getZonename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Table} returns this
 */
proto.commUnity.Table.prototype.setZonename = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string QRCode = 10;
 * @return {string}
 */
proto.commUnity.Table.prototype.getQrcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Table} returns this
 */
proto.commUnity.Table.prototype.setQrcode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 Seats = 20;
 * @return {number}
 */
proto.commUnity.Table.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Table} returns this
 */
proto.commUnity.Table.prototype.setSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.RestaurantOrder.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RestaurantOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RestaurantOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RestaurantOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    membername: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orderdatetime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tableid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tablecode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customerid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.commUnity.RestaurantOrderLine.toObject, includeInstance),
    orderstatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    paymentstatus: jspb.Message.getFieldWithDefault(msg, 12, 0),
    paymenttimestamp: jspb.Message.getFieldWithDefault(msg, 14, 0),
    totalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    nbitems: jspb.Message.getFieldWithDefault(msg, 24, 0),
    paymentref: jspb.Message.getFieldWithDefault(msg, 25, ""),
    paymenturl: jspb.Message.getFieldWithDefault(msg, 27, ""),
    note: jspb.Message.getFieldWithDefault(msg, 31, ""),
    lastupdate: jspb.Message.getFieldWithDefault(msg, 50, 0),
    canceltimestamp: jspb.Message.getFieldWithDefault(msg, 100, 0),
    cancelreason: jspb.Message.getFieldWithDefault(msg, 101, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RestaurantOrder}
 */
proto.commUnity.RestaurantOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RestaurantOrder;
  return proto.commUnity.RestaurantOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RestaurantOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RestaurantOrder}
 */
proto.commUnity.RestaurantOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembername(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderdatetime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTablecode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 10:
      var value = new proto.commUnity.RestaurantOrderLine;
      reader.readMessage(value,proto.commUnity.RestaurantOrderLine.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 11:
      var value = /** @type {!proto.commUnity.RestaurantOrderStatus} */ (reader.readEnum());
      msg.setOrderstatus(value);
      break;
    case 12:
      var value = /** @type {!proto.commUnity.RestaurantPaymentStatus} */ (reader.readEnum());
      msg.setPaymentstatus(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaymenttimestamp(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalprice(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNbitems(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentref(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymenturl(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastupdate(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCanceltimestamp(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setCancelreason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RestaurantOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RestaurantOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RestaurantOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMembername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderdatetime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTableid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTablecode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.commUnity.RestaurantOrderLine.serializeBinaryToWriter
    );
  }
  f = message.getOrderstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getPaymentstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPaymenttimestamp();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getTotalprice();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getNbitems();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getPaymentref();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getPaymenturl();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getLastupdate();
  if (f !== 0) {
    writer.writeInt64(
      50,
      f
    );
  }
  f = message.getCanceltimestamp();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
  f = message.getCancelreason();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrderNumber = 2;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string MemberID = 3;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string MemberName = 4;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getMembername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setMembername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 OrderDateTime = 5;
 * @return {number}
 */
proto.commUnity.RestaurantOrder.prototype.getOrderdatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setOrderdatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string TableID = 6;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getTableid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setTableid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string TableCode = 7;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getTablecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setTablecode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string CustomerID = 8;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated RestaurantOrderLine Products = 10;
 * @return {!Array<!proto.commUnity.RestaurantOrderLine>}
 */
proto.commUnity.RestaurantOrder.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.commUnity.RestaurantOrderLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commUnity.RestaurantOrderLine, 10));
};


/**
 * @param {!Array<!proto.commUnity.RestaurantOrderLine>} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
*/
proto.commUnity.RestaurantOrder.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.commUnity.RestaurantOrderLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commUnity.RestaurantOrderLine}
 */
proto.commUnity.RestaurantOrder.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.commUnity.RestaurantOrderLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional RestaurantOrderStatus OrderStatus = 11;
 * @return {!proto.commUnity.RestaurantOrderStatus}
 */
proto.commUnity.RestaurantOrder.prototype.getOrderstatus = function() {
  return /** @type {!proto.commUnity.RestaurantOrderStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.commUnity.RestaurantOrderStatus} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional RestaurantPaymentStatus PaymentStatus = 12;
 * @return {!proto.commUnity.RestaurantPaymentStatus}
 */
proto.commUnity.RestaurantOrder.prototype.getPaymentstatus = function() {
  return /** @type {!proto.commUnity.RestaurantPaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.commUnity.RestaurantPaymentStatus} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setPaymentstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional int64 PaymentTimeStamp = 14;
 * @return {number}
 */
proto.commUnity.RestaurantOrder.prototype.getPaymenttimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setPaymenttimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional double TotalPrice = 22;
 * @return {number}
 */
proto.commUnity.RestaurantOrder.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setTotalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional uint32 NbItems = 24;
 * @return {number}
 */
proto.commUnity.RestaurantOrder.prototype.getNbitems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setNbitems = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string PaymentRef = 25;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getPaymentref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setPaymentref = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string PaymentURL = 27;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getPaymenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setPaymenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string Note = 31;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional int64 LastUpdate = 50;
 * @return {number}
 */
proto.commUnity.RestaurantOrder.prototype.getLastupdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setLastupdate = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional int64 CancelTimeStamp = 100;
 * @return {number}
 */
proto.commUnity.RestaurantOrder.prototype.getCanceltimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setCanceltimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional string CancelReason = 101;
 * @return {string}
 */
proto.commUnity.RestaurantOrder.prototype.getCancelreason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrder} returns this
 */
proto.commUnity.RestaurantOrder.prototype.setCancelreason = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RestaurantOrderLine.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RestaurantOrderLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RestaurantOrderLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantOrderLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    mealid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    relatedproductid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    alcoholyn: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    productname: jspb.Message.getFieldWithDefault(msg, 10, ""),
    relatedproductname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    sku: jspb.Message.getFieldWithDefault(msg, 14, ""),
    photo: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RestaurantOrderLine}
 */
proto.commUnity.RestaurantOrderLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RestaurantOrderLine;
  return proto.commUnity.RestaurantOrderLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RestaurantOrderLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RestaurantOrderLine}
 */
proto.commUnity.RestaurantOrderLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMealid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelatedproductid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalprice(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlcoholyn(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductname(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelatedproductname(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RestaurantOrderLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RestaurantOrderLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RestaurantOrderLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantOrderLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMealid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRelatedproductid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAlcoholyn();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getProductname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRelatedproductname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string MealID = 1;
 * @return {string}
 */
proto.commUnity.RestaurantOrderLine.prototype.getMealid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setMealid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ProductID = 2;
 * @return {string}
 */
proto.commUnity.RestaurantOrderLine.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string RelatedProductID = 3;
 * @return {string}
 */
proto.commUnity.RestaurantOrderLine.prototype.getRelatedproductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setRelatedproductid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 Quantity = 4;
 * @return {number}
 */
proto.commUnity.RestaurantOrderLine.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double TotalPrice = 5;
 * @return {number}
 */
proto.commUnity.RestaurantOrderLine.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setTotalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool AlcoholYN = 6;
 * @return {boolean}
 */
proto.commUnity.RestaurantOrderLine.prototype.getAlcoholyn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setAlcoholyn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string ProductName = 10;
 * @return {string}
 */
proto.commUnity.RestaurantOrderLine.prototype.getProductname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setProductname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string RelatedProductName = 11;
 * @return {string}
 */
proto.commUnity.RestaurantOrderLine.prototype.getRelatedproductname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setRelatedproductname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string SKU = 14;
 * @return {string}
 */
proto.commUnity.RestaurantOrderLine.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string Photo = 20;
 * @return {string}
 */
proto.commUnity.RestaurantOrderLine.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderLine} returns this
 */
proto.commUnity.RestaurantOrderLine.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Meal.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Meal.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Meal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Meal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Meal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.commUnity.RestaurantOrderLine.toObject, includeInstance),
    totalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    nbitems: jspb.Message.getFieldWithDefault(msg, 24, 0),
    alcoholyn: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    note: jspb.Message.getFieldWithDefault(msg, 31, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Meal}
 */
proto.commUnity.Meal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Meal;
  return proto.commUnity.Meal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Meal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Meal}
 */
proto.commUnity.Meal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = new proto.commUnity.RestaurantOrderLine;
      reader.readMessage(value,proto.commUnity.RestaurantOrderLine.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalprice(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNbitems(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlcoholyn(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Meal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Meal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Meal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Meal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.commUnity.RestaurantOrderLine.serializeBinaryToWriter
    );
  }
  f = message.getTotalprice();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getNbitems();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getAlcoholyn();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Meal.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Meal} returns this
 */
proto.commUnity.Meal.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RestaurantOrderLine Products = 10;
 * @return {!Array<!proto.commUnity.RestaurantOrderLine>}
 */
proto.commUnity.Meal.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.commUnity.RestaurantOrderLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commUnity.RestaurantOrderLine, 10));
};


/**
 * @param {!Array<!proto.commUnity.RestaurantOrderLine>} value
 * @return {!proto.commUnity.Meal} returns this
*/
proto.commUnity.Meal.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.commUnity.RestaurantOrderLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commUnity.RestaurantOrderLine}
 */
proto.commUnity.Meal.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.commUnity.RestaurantOrderLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Meal} returns this
 */
proto.commUnity.Meal.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional double TotalPrice = 22;
 * @return {number}
 */
proto.commUnity.Meal.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Meal} returns this
 */
proto.commUnity.Meal.prototype.setTotalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional uint32 NbItems = 24;
 * @return {number}
 */
proto.commUnity.Meal.prototype.getNbitems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Meal} returns this
 */
proto.commUnity.Meal.prototype.setNbitems = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional bool AlcoholYN = 26;
 * @return {boolean}
 */
proto.commUnity.Meal.prototype.getAlcoholyn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Meal} returns this
 */
proto.commUnity.Meal.prototype.setAlcoholyn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional string Note = 31;
 * @return {string}
 */
proto.commUnity.Meal.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Meal} returns this
 */
proto.commUnity.Meal.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.CheckOutMeal.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.CheckOutMeal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.CheckOutMeal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CheckOutMeal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    payonline: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    radius: jspb.Message.getFieldWithDefault(msg, 30, 0),
    note: jspb.Message.getFieldWithDefault(msg, 31, ""),
    urlok: jspb.Message.getFieldWithDefault(msg, 50, ""),
    urlnotok: jspb.Message.getFieldWithDefault(msg, 51, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.CheckOutMeal}
 */
proto.commUnity.CheckOutMeal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.CheckOutMeal;
  return proto.commUnity.CheckOutMeal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.CheckOutMeal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.CheckOutMeal}
 */
proto.commUnity.CheckOutMeal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPayonline(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRadius(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlok(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlnotok(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.CheckOutMeal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.CheckOutMeal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.CheckOutMeal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CheckOutMeal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPayonline();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getUrlok();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getUrlnotok();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.CheckOutMeal.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckOutMeal} returns this
 */
proto.commUnity.CheckOutMeal.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TableID = 2;
 * @return {string}
 */
proto.commUnity.CheckOutMeal.prototype.getTableid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckOutMeal} returns this
 */
proto.commUnity.CheckOutMeal.prototype.setTableid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool PayOnline = 5;
 * @return {boolean}
 */
proto.commUnity.CheckOutMeal.prototype.getPayonline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.CheckOutMeal} returns this
 */
proto.commUnity.CheckOutMeal.prototype.setPayonline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional double Latitude = 20;
 * @return {number}
 */
proto.commUnity.CheckOutMeal.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.CheckOutMeal} returns this
 */
proto.commUnity.CheckOutMeal.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double Longitude = 25;
 * @return {number}
 */
proto.commUnity.CheckOutMeal.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.CheckOutMeal} returns this
 */
proto.commUnity.CheckOutMeal.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional int32 Radius = 30;
 * @return {number}
 */
proto.commUnity.CheckOutMeal.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.CheckOutMeal} returns this
 */
proto.commUnity.CheckOutMeal.prototype.setRadius = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional string Note = 31;
 * @return {string}
 */
proto.commUnity.CheckOutMeal.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckOutMeal} returns this
 */
proto.commUnity.CheckOutMeal.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string URLOK = 50;
 * @return {string}
 */
proto.commUnity.CheckOutMeal.prototype.getUrlok = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckOutMeal} returns this
 */
proto.commUnity.CheckOutMeal.prototype.setUrlok = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string URLNOTOK = 51;
 * @return {string}
 */
proto.commUnity.CheckOutMeal.prototype.getUrlnotok = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckOutMeal} returns this
 */
proto.commUnity.CheckOutMeal.prototype.setUrlnotok = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RestaurantOrderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RestaurantOrderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RestaurantOrderInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantOrderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ordernumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paymenturl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pdf: msg.getPdf_asB64(),
    webp: msg.getWebp_asB64(),
    jpg: msg.getJpg_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RestaurantOrderInfo}
 */
proto.commUnity.RestaurantOrderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RestaurantOrderInfo;
  return proto.commUnity.RestaurantOrderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RestaurantOrderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RestaurantOrderInfo}
 */
proto.commUnity.RestaurantOrderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymenturl(value);
      break;
    case 11:
      var value = /** @type {!proto.commUnity.RestaurantOrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 20:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPdf(value);
      break;
    case 25:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWebp(value);
      break;
    case 30:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setJpg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RestaurantOrderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RestaurantOrderInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RestaurantOrderInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RestaurantOrderInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaymenturl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getPdf_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      20,
      f
    );
  }
  f = message.getWebp_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      25,
      f
    );
  }
  f = message.getJpg_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      30,
      f
    );
  }
};


/**
 * optional string OrderID = 1;
 * @return {string}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getOrderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderInfo} returns this
 */
proto.commUnity.RestaurantOrderInfo.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrderNumber = 2;
 * @return {string}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderInfo} returns this
 */
proto.commUnity.RestaurantOrderInfo.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string PaymentURL = 3;
 * @return {string}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getPaymenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RestaurantOrderInfo} returns this
 */
proto.commUnity.RestaurantOrderInfo.prototype.setPaymenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RestaurantOrderStatus Status = 11;
 * @return {!proto.commUnity.RestaurantOrderStatus}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.RestaurantOrderStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.commUnity.RestaurantOrderStatus} value
 * @return {!proto.commUnity.RestaurantOrderInfo} returns this
 */
proto.commUnity.RestaurantOrderInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional bytes PDF = 20;
 * @return {string}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getPdf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * optional bytes PDF = 20;
 * This is a type-conversion wrapper around `getPdf()`
 * @return {string}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getPdf_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPdf()));
};


/**
 * optional bytes PDF = 20;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPdf()`
 * @return {!Uint8Array}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getPdf_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPdf()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.commUnity.RestaurantOrderInfo} returns this
 */
proto.commUnity.RestaurantOrderInfo.prototype.setPdf = function(value) {
  return jspb.Message.setProto3BytesField(this, 20, value);
};


/**
 * optional bytes WEBP = 25;
 * @return {string}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getWebp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * optional bytes WEBP = 25;
 * This is a type-conversion wrapper around `getWebp()`
 * @return {string}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getWebp_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWebp()));
};


/**
 * optional bytes WEBP = 25;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWebp()`
 * @return {!Uint8Array}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getWebp_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWebp()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.commUnity.RestaurantOrderInfo} returns this
 */
proto.commUnity.RestaurantOrderInfo.prototype.setWebp = function(value) {
  return jspb.Message.setProto3BytesField(this, 25, value);
};


/**
 * optional bytes JPG = 30;
 * @return {string}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getJpg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * optional bytes JPG = 30;
 * This is a type-conversion wrapper around `getJpg()`
 * @return {string}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getJpg_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getJpg()));
};


/**
 * optional bytes JPG = 30;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getJpg()`
 * @return {!Uint8Array}
 */
proto.commUnity.RestaurantOrderInfo.prototype.getJpg_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getJpg()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.commUnity.RestaurantOrderInfo} returns this
 */
proto.commUnity.RestaurantOrderInfo.prototype.setJpg = function(value) {
  return jspb.Message.setProto3BytesField(this, 30, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.EventTableReservation.repeatedFields_ = [30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.EventTableReservation.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.EventTableReservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.EventTableReservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EventTableReservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdon: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdby: jspb.Message.getFieldWithDefault(msg, 5, ""),
    eventid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tableid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    membersList: (f = jspb.Message.getRepeatedField(msg, 30)) == null ? undefined : f,
    zonename: jspb.Message.getFieldWithDefault(msg, 40, ""),
    zoneid: jspb.Message.getFieldWithDefault(msg, 42, ""),
    tablecode: jspb.Message.getFieldWithDefault(msg, 50, ""),
    date: jspb.Message.getFieldWithDefault(msg, 60, 0),
    membernames: jspb.Message.getFieldWithDefault(msg, 90, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.EventTableReservation}
 */
proto.commUnity.EventTableReservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.EventTableReservation;
  return proto.commUnity.EventTableReservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.EventTableReservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.EventTableReservation}
 */
proto.commUnity.EventTableReservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedon(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedby(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventid(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableid(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembers(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setZonename(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneid(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setTablecode(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDate(value);
      break;
    case 90:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembernames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.EventTableReservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.EventTableReservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.EventTableReservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EventTableReservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedon();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreatedby();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTableid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      30,
      f
    );
  }
  f = message.getZonename();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getZoneid();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getTablecode();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeUint32(
      60,
      f
    );
  }
  f = message.getMembernames();
  if (f.length > 0) {
    writer.writeString(
      90,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.EventTableReservation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedOn = 2;
 * @return {number}
 */
proto.commUnity.EventTableReservation.prototype.getCreatedon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setCreatedon = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string CreatedBy = 5;
 * @return {string}
 */
proto.commUnity.EventTableReservation.prototype.getCreatedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setCreatedby = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string EventID = 10;
 * @return {string}
 */
proto.commUnity.EventTableReservation.prototype.getEventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setEventid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string TableID = 20;
 * @return {string}
 */
proto.commUnity.EventTableReservation.prototype.getTableid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setTableid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated string Members = 30;
 * @return {!Array<string>}
 */
proto.commUnity.EventTableReservation.prototype.getMembersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 30));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setMembersList = function(value) {
  return jspb.Message.setField(this, 30, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.addMembers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 30, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * optional string ZoneName = 40;
 * @return {string}
 */
proto.commUnity.EventTableReservation.prototype.getZonename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setZonename = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string ZoneID = 42;
 * @return {string}
 */
proto.commUnity.EventTableReservation.prototype.getZoneid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setZoneid = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string TableCode = 50;
 * @return {string}
 */
proto.commUnity.EventTableReservation.prototype.getTablecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setTablecode = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional uint32 Date = 60;
 * @return {number}
 */
proto.commUnity.EventTableReservation.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional string MemberNames = 90;
 * @return {string}
 */
proto.commUnity.EventTableReservation.prototype.getMembernames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 90, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableReservation} returns this
 */
proto.commUnity.EventTableReservation.prototype.setMembernames = function(value) {
  return jspb.Message.setProto3StringField(this, 90, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.EventTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.EventTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.EventTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EventTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    zoneid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    numberofattendees: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.EventTableRequest}
 */
proto.commUnity.EventTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.EventTableRequest;
  return proto.commUnity.EventTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.EventTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.EventTableRequest}
 */
proto.commUnity.EventTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneid(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberofattendees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.EventTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.EventTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.EventTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EventTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getZoneid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNumberofattendees();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
};


/**
 * optional string EventID = 1;
 * @return {string}
 */
proto.commUnity.EventTableRequest.prototype.getEventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableRequest} returns this
 */
proto.commUnity.EventTableRequest.prototype.setEventid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ZoneID = 10;
 * @return {string}
 */
proto.commUnity.EventTableRequest.prototype.getZoneid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventTableRequest} returns this
 */
proto.commUnity.EventTableRequest.prototype.setZoneid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 NumberOfAttendees = 20;
 * @return {number}
 */
proto.commUnity.EventTableRequest.prototype.getNumberofattendees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.EventTableRequest} returns this
 */
proto.commUnity.EventTableRequest.prototype.setNumberofattendees = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * @enum {number}
 */
proto.commUnity.AttendanceAction = {
  IN: 0,
  OUT: 1
};

/**
 * @enum {number}
 */
proto.commUnity.Service = {
  ANYSERVICE: 0,
  BREAKFAST: 1,
  BRUNCH: 2,
  LUNCH: 3,
  DINNER: 4
};

/**
 * @enum {number}
 */
proto.commUnity.ReservationStatus = {
  ALLRESERVATIONSTATUS: 0,
  NEWRESERVATION: 1,
  EMAILCONFIRMED: 2,
  PHONECONFIRMED: 3,
  CANCELLEDRESERVATION: 4,
  ARRIVED: 5,
  NOSHOW: 6
};

/**
 * @enum {number}
 */
proto.commUnity.Position = {
  SERVER: 0,
  BAR: 1,
  KITCHEN: 2
};

/**
 * @enum {number}
 */
proto.commUnity.RestaurantOrderStatus = {
  ALLORDERSTATUS: 0,
  NEWORDER: 1,
  READY: 2,
  SERVED: 3,
  ABORTED: 4,
  NOTYETSERVED: 5
};

/**
 * @enum {number}
 */
proto.commUnity.RestaurantPaymentStatus = {
  ALLPAYMENTSTATUS: 0,
  NOTPAID: 1,
  ALREADYPAID: 2
};

goog.object.extend(exports, proto.commUnity);
