import { Component, OnInit } from '@angular/core';
import { ProductOrderLine, ProductOrder, PaymentStatus } from '../../../libs/proto/shop_pb';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLibService } from '../../../service/user/user-lib.service';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { GrpcOrderLibService } from '../../../service/grpc/order/grpc-order-lib.service';
import { Color } from '../../../libs/proto/commUnity_pb';
import { ProductToRemove, ProductToRemoveFromMeal } from '../../../libs/proto/mobile_pb';
import { Platform } from '@angular/cdk/platform';
import { MealForm } from '../confirm/menu-confirm.component';
import { RestaurantMealConversionService } from '../../../service/conversion/restaurant/resto-meal-conversion.service';
import { Meal, RestaurantOrderLine } from '../../../libs/proto/restaurant_pb';
import { GrpcRestaurantLibService } from '../../../service/grpc/restaurant/grpc-resto-lib.service';
import { GrpcLibService } from '../../../service/grpc/grpc-lib.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-meal',
  templateUrl: './meal.component.html',
  styleUrls: ['./meal.component.sass']
})
export class MealComponent implements OnInit {

  meal: Meal;
  showQty?: MealForm;
  reOrder?: boolean;
  reOrderId?: string;
  rePaymentURL?: string;
  payLoading?: boolean;
  colors: {k?: string, v?: string} = {};
  T = {};
  tLists: string[] = [];

  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private storeLib: StorageLibService,
    private userLib: UserLibService,
    private convLib: RestaurantMealConversionService,
    private grpcOrerLib: GrpcOrderLibService,
    private grpcLib: GrpcRestaurantLibService,
    private dlgLib: DialogServiceService,
    private translate: TranslateService,
    private decPipe: DecimalPipe,
    private platform: Platform
  ) { }
  get currencysymbol() {
    return this.userLib.Data.token?.getCustomer().getCurrencysymbol();
  }
  ngOnInit(): void {
    const thise = this;
    Object.keys(Color).map( c => {
      this.colors[Color[c]] = c;
      this.tLists.push('colors.' + c);
    });

    this.actRoute.paramMap.subscribe( p => {
      if (p.get('id')) {
          thise.loadReOrder(p.get('id'));
      } else {
          thise.loadMeal();
      }
    });
    this.showQty = {};
  }

  private loadMeal() {
    const thise = this;
    this.grpcLib.getMeal({
      Offline: false,
      call: {
        req: this.userLib.mealID,
      }
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        thise.userLib.clear();
        thise.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    }).then( (c: Meal) => {
      thise.meal = c;

      // translate
      this.translate.get(this.tLists).toPromise().then( t => {
      this.T = t;
      });

    });
  }

  private loadReOrder(id: string) {
    this.grpcOrerLib.getProductOrders({
      Offline: true,
      call: {
        req: id,
      }
    }).then( ns => {
      if (ns.length === 0) {
        this.loadMeal();
      } else {
        this.copyMeal(ns[0]);
      }
    }).catch( e => {
      this.loadMeal();
    });

  }

  private copyMeal(o: ProductOrder) {
    // this.reOrder = true;
    // this.reOrderId = o.getId();
    // this.Meal = new Meal();
    // this.Meal.setProductsList( o.getProductsList() );
    // this.Meal.setTotalprice( o.getTotalprice() );
    // this.Meal.setNbitems( o.getNbitems() );
    // if (o.getPaymentstatus() === PaymentStatus.UNPAID) {
    // this.rePaymentURL = o.getPaymenturl();
    // }
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  get MealItems(): number {
    return this.meal.getNbitems();
  }

  qtyOK() {
    if (+this.showQty?.qtyEdit === 0) {return; }
    this.addProductToMeal(
        this.showQty?.lValue,
        this.showQty?.qtyEdit,
        this.showQty?.qElem,
        this.showQty?.tElem);
  }

  addProductToMeal(line: RestaurantOrderLine, q: string,
                   qtyElem: HTMLSpanElement,
                   totalElem: HTMLSpanElement) {
    if (q === 'product-confirm.Other') {
      this.showQty.lValue = line;
      this.showQty.qtyEdit = line.getQuantity() + '';
      this.showQty.qElem = qtyElem;
      this.showQty.tElem =  totalElem;
      this.showQty.show = true;

      return;
    }

    const thise = this;
    line.setQuantity( +( +q > 0 ? q : '0') - line.getQuantity());

    this.grpcLib.addProductToMeal(line).then( c => {

      thise.keepMeal(c);
      thise.meal = c;
      thise.addProductToMealOK(c, line, qtyElem, totalElem);

    }).catch( e => {

      thise.addProductToMealError(e);
    });
  }

  private addProductToMealOK(c: Meal,
                             l: RestaurantOrderLine,
                             qtyElem: HTMLSpanElement,
                             totalElem: HTMLSpanElement) {
          this.ngOnInit();

  }

  private addProductToMealError(e: Error) {
    const thise = this;
    this.translate.get([
      'meal.add_to_Meal_failed',
      'meal.title'
    ]).toPromise().then( t => {
      thise.dlgLib.show(t['meal.add_to_Meal_failed'] +
                        ':' + JSON.stringify(e),
                        t['meal.title']);
    });
  }

  deleteItem(line: RestaurantOrderLine) {
    const thise = this;
    this.translate.get([
      'meal.title',
      'meal.delete',
      'meal.delete_ko'
    ]).toPromise().then( t => {

      thise.dlgLib.confirm(t['meal.delete'], r => {

        if (r.no) { return; }
        const req = new ProductToRemoveFromMeal();
        req.setProductid(line.getProductid());
        req.setMealid( this.userLib.mealID);

        thise.grpcLib.dropProductFromMeal(req).then( c => {

          thise.keepMeal(c);
          if (c.getNbitems() === 0) {
              thise.route.navigateByUrl('/resto');
              return;
           }
          thise.ngOnInit();

        }).catch( e => {
          thise.dlgLib.show(t['meal.delete_ko'], t['meal.title']);
        });

      }, t['meal.title']);
    });
  }

  private keepMeal(c: Meal) {
    this.storeLib.set('meal-badge', c.getNbitems());
    this.storeLib.set('meal',
      this.convLib.ToStorage( c ));
  }

  clickButton() {
    if (this.rePaymentURL) {
      this.payLoading = true;
      window.location.href = this.rePaymentURL;
      return;
    }

    if (this.reOrder) {
      this.doReOrder();
      return;
    }

    this.route.navigateByUrl('/meal-checkout');
  }

  private doReOrder() {
    // const thise = this;
    // this.grpcLib.resetMeal(this.reOrderId).then( c => {

    //   thise.keepMeal( c );
    //   thise.route.navigateByUrl('/shop');

    // }).catch( e => {
    //   this.translate.get([
    //     'meal.title',
    //     'order-history.reset_Meal_failed',
    //   ]).toPromise().then( t => {

    //     this.dlgLib.show(t['order-history.reset_Meal_failed'],
    //                      t['meal.title']);
    //   });
    // });
  }
  get butttonTag(): string {
    if (this.rePaymentURL) { return 'order-history.order_pay'; }
    if (this.reOrder) { return 'order-history.reorder'; }

    return 'meal.checkout';
  }

  ColorText(c: number) {
    return this.T['colors.' + this.colors[c]];
  }
  get isSafari() {
    return this.platform.SAFARI;
  }
}
