<app-sidenav class="hide-toolbar" [noBack]="noback" page="member-business-card-page"  menuType>

<div class="business-card">
  <div class="card" [ngClass]="{'flipped':!isflip}">
    <div class="front side" [ngStyle]="{'background-image': 'url(' + cover + ')'}">
      <div class="loading-card" *ngIf="loading">
        <mat-icon class="icon">refresh</mat-icon>
      </div>    
    </div>
    
    <div *ngIf="!loading" class="back side">
      <div [ngClass]="{'blur': !datavalid}">
        <div class="c-row">
          <div class="c-col">
            <div class="c-col1">
              <div class="spons" [ngStyle]="{'background-image': 'url(' + sponsorslogo(1) + ')' }"></div>
              <div class="spons" [ngStyle]="{'background-image': 'url(' + sponsorslogo(2) + ')' }"></div>
              <div class="spons" [ngStyle]="{'background-image': 'url(' + sponsorslogo(3) + ')' }"></div>
            </div>
            <div class="c-col2">
              <h3 class="name">{{ ('title.' + (memberbusiness?.getSettings().getShowtitle() ? businesstitle(this.memberbusiness?.getTitle()) : '')) | translate }}{{ businessname }}</h3>
              <div class="info" *ngIf="canseepaidok || canseerenew">
                <p *ngIf="canseerenew"><span class="property">{{ 'member-business-card-page.field-renewOn' | translate }} </span>{{memberbusiness?.getRenewon()| pipeDatefmt}}</p>
                <p *ngIf="canseepaidok"><span class="property">{{ 'member-business-card-page.field-paidok' | translate }}</span>{{ ('Dialog.' + (memberbusiness?.getPaidok() ? 'Yes' :'No')) | translate  }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="spon-bottom">
          <div class="spons" [ngStyle]="{'background-image': 'url(' + sponsorslogo(4) + ')' }"></div>
          <div class="spons" [ngStyle]="{'background-image': 'url(' + sponsorslogo(5) + ')' }"></div>
          <div class="spons" [ngStyle]="{'background-image': 'url(' + sponsorslogo(6) + ')' }"></div>
          <div class="spons" [ngStyle]="{'background-image': 'url(' + sponsorslogo(7) + ')' }"></div>
        </div>
    </div>
      <a target="_blank" [href]="memberbusiness?.getQrcode()">      
      <qrcode *ngIf="showqr"      
      [qrdata]="memberbusiness?.getQrcode()" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
      </a>
      <div *ngIf="showvalid" class="stamp" [ngClass]="{'is-approved': valid,'is-nope': !valid}">{{ ('member-business-card-page.field-valid' + (valid ? '' : '-in')) | translate }}</div>
    </div>
    
  </div>

</div>
<button *ngIf="!loading" mat-fab color="primary" class="flip-button" (click)="flip()">
<mat-icon>refresh</mat-icon></button>

</app-sidenav>