<div class="box-qty" *ngIf="showQty.show">
  <div class="box-qty-bg"></div>
  <mat-card [class]="'box-qty-dlg'">
    <h2 [ngStyle]="{'background-color': backgroundColor,'color': foregroundColor}">
        {{ 'product-confirm.quantity' | translate }}
    </h2>
    <mat-card-content>
      <mat-form-field class="w-100">
        <input id="f1"
          onKeyPress="if(this.value.length==2) return false;"
          autocomplete="off"
          [(ngModel)]="showQty.qtyEdit" matInput type="number" />
      </mat-form-field>
    </mat-card-content>
    <mat-card-footer>
      <button
        [ngStyle]="{'background-color': ((showQty.qtyEdit || '0') + '')  === '0' ? '#eee': backgroundColor,
                    'color': foregroundColor}"
        (click)="qtyOK()" mat-raised-button>{{ 'Dialog.OK' | translate }}</button>
      <button [ngStyle]="{'background-color': backgroundColor,'color': foregroundColor}" (click)="qtyClose()" mat-raised-button>{{ 'Dialog.Cancel' | translate }}</button>
    </mat-card-footer>
  </mat-card>
</div>
