<div class="modal-popup alert-box">
  <div class="bg"></div>
  <div class="dlg-center">

    <mat-card
      [ngStyle]="{'background-color': backgroundColor}"
      class="dlg">
      <button
        (click)="close()"
        class="alert-close"
        mat-icon-button>
        <mat-icon
          [ngStyle]="{'color': textColor}">highlight_off</mat-icon>
      </button>

      <div
        [ngStyle]="{'background-image': 'url(' + (logo | pipeWebp) +')'}"
        class="logo"></div>

      <div *ngIf="alert$ | async as alerts">
      <swiper [navigation]="true">

        <ng-template *ngFor="let al of alerts" swiperSlide>
          <div>
          <mat-card-title
                class="alert-title"
                [ngStyle]="{'color': textColor}">
                {{ al.getTitle() }}
              </mat-card-title>

              <mat-card-content
              [ngStyle]="{'color': textColor}"
                class="body">
                  <p
                  [innerHTML]="al.getMessage() | pipeDescription"
                  class="">
                  </p>
              </mat-card-content>
          </div>
        </ng-template>
      </swiper>
      </div>

    </mat-card>

  </div>
</div>
