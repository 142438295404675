import { Injectable } from '@angular/core';
import { GolfClassType, Place } from '../../../libs/proto/commUnity_pb';
import { BinaryWriter } from 'google-protobuf';

@Injectable({
  providedIn: 'root'
})
export class GolfClassTypeConversionService {

  constructor() { }

  ToStorage(req: GolfClassType) {
    const w = new BinaryWriter();
    GolfClassType.serializeBinaryToWriter( req, w);

    const abuf = w.getResultBuffer();
    return Array.from // if available
              ? Array.from(abuf) // use Array#from
              : abuf.map(v => v);
  }

  FromStorage(data: any, callback: (GolfClassType: GolfClassType, errorConvert: any) => void) {
    try {
      callback( GolfClassType.deserializeBinary(data), null);
    } catch (error) {
      callback( null, error);
    }
  }
  ToStorages(news: GolfClassType[]) {
    const rets = new Array<string>();

    news.forEach(element => {
      rets.push(
        btoa(String.fromCharCode.apply(null,this.ToStorage(element)))
      );
    });

    return {data: rets};
  }

  FromStorages(data: any, callback: (GolfClassTypees: GolfClassType[], errorConvert: any) => void) {
    if (data == null) {
      callback(null, Error('empty'));
      return;
    }
    const golfClassTypees = new Array<GolfClassType>();
    const golfClassTypejs: {data: string[]} = data;
    try {

      golfClassTypejs.data.forEach(element => {
        const golfClassType = GolfClassType.deserializeBinary( new Uint8Array([...atob(element)].map(char => char.charCodeAt(0)))  );
        golfClassTypees.push ( golfClassType );
      });

      callback( golfClassTypees, null);
    } catch (error) {
      callback( null, error);
    }
  }
}
