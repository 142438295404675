<app-sidenav page="link" menuType>

<div class="form-list link">

  <video controls #videoPlayer>
    <source [src]="url" type="video/mp4" />
    Browser not supported
</video>
</div>

</app-sidenav>