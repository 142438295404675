import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-not-mobile',
  templateUrl: './not-mobile.component.html',
  styleUrls: ['./not-mobile.component.sass']
})
export class NotMobileComponent implements OnInit {

  logoURL: string;
  bgURL: string;
  url = '';

  constructor(
    private translate: TranslateService,
    private device: DeviceLibService) { }

  ngOnInit(): void {
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.device.lang(location.href) || 'en');

    this.logoURL = 'assets/icons/icon-128x128.png';
    this.bgURL = 'assets/images/login_bg.jpeg';

    this.url = location.href;
  }
}
