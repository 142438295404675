<app-sidenav page="guest-satisfaction" [data]="resaid" menuType>

<div class="form-list" *ngIf="menuType!=2">    
    <mat-card class="guest-card" [ngClass]="{tablet: menuType==2}">
        <mat-card-content>    
            <mat-list class="w-100 t-center">
                {{ 'guest.satisfaction-note' | translate}}
            </mat-list>

            <mat-list class="w-100 t-center">
              <div class="choice">
                <button *ngFor="let c of satisfys" (click)="choose(c[1])" [ngClass]="{active: satisfy==c[1]}"  mat-icon-button><mat-icon>{{ c[0] }}</mat-icon></button>
              </div> 
              <br/>
              <label *ngIf="satisfy != -1">{{ ('guest.satisfaction-' + satisfy) | translate }}</label>
            </mat-list>

            <mat-list class="w-100 t-center">
              <br/>
              <mat-form-field class="w-100">
                <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="10"
                  placeholder="{{ 'guest.satisfaction-comment' | translate }}" [(ngModel)]="comment" [ngModelOptions]="{standalone: true}"
                ></textarea>
              </mat-form-field>
            </mat-list>
    </mat-card-content>
    </mat-card>
    <div class="guest-buttons">
      <button mat-raised-button [disabled]="!cansend" (click)="send()" color="primary">{{ 'guest.submit-satis-button' | translate }}</button>
    </div>
</div>

<div class="mode-tablet" *ngIf="menuType==2">
  <div class="left">
    <mat-card>
    <mat-card-header class="header">
      <mat-card-title [innerHTML]="('guest.reservation-detail-title' | translate).replace('%s', '' )"></mat-card-title>
      <mat-card-subtitle ><BR/>{{ resaid}}</mat-card-subtitle>
    </mat-card-header>
    </mat-card>
  </div>
  
  <div class="right">
    <div class="body">
      <mat-list class="w-100 t-center">
        <div>
          {{ 'guest.satisfaction-note' | translate }}
        </div>
        <div class="choice">
          <button *ngFor="let c of satisfys" (click)="choose(c[1])" [ngClass]="{active: satisfy==c[1]}"  mat-icon-button><mat-icon>{{ c[0] }}</mat-icon></button>
        </div> 
        <br/>
        <label *ngIf="satisfy != -1">{{ ('guest.satisfaction-' + satisfy) | translate }}</label>
      </mat-list>

      <mat-list class="w-100 t-center">
        <br/>
        <mat-form-field class="w-100">
          <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
            placeholder="{{ 'guest.satisfaction-comment' | translate }}" [(ngModel)]="comment" [ngModelOptions]="{standalone: true}"
          ></textarea>
        </mat-form-field>
      </mat-list>
      <div class="guest-buttons">
      <button mat-raised-button [disabled]="!cansend" (click)="send()" color="primary">{{ 'guest.submit-satis-button' | translate }}</button>
    </div>
    </div>
  </div>
</div>

<div class="modal-popup" *ngIf="waitsave">
  <div class="bg"></div>
  <div class="dlg-center">
    <div class="form-wait-bg"></div>
    <div class="form-wait">
      <mat-spinner></mat-spinner>
    </div>
  </div>  
</div>

</app-sidenav>