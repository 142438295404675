import { Injectable } from '@angular/core';
import { Address } from '../../../libs/proto/commUnity_pb';
import { CountryConversionService } from '../country/country-conversion.service';
import { HttpClient } from '@angular/common/http';

export interface AddressFromNominate {
  lat: string;
  lon: string;
}

@Injectable({
  providedIn: 'root'
})
export class AddressConversionService {

  constructor(
    private countryConvLib: CountryConversionService,
    private httpClient: HttpClient
  ) { }

  toAddressText(address: Address): string {
    return this.toAddressArray(address).join(',');
  }

  private toAddressArray(address: Address): string[] {
    if ((address || null) == null) { return []; }

    const r: string[] = [];

    if (address?.getStreet()) {
      r.push( address.getStreet() );
    }
    if (address?.getCity()) {
      r.push( address.getCity() );
    }
    if (address?.getPostcode()) {
      r.push( address.getPostcode() );
    }

    r.push( this.countryConvLib.getName(address.getCountry()) );

    return r;
  }

  fromAddressText(address: Address): Promise<any> {
    return this.httpClient.get('https://nominatim.openstreetmap.org/search?q=' +
        encodeURIComponent(this.toAddressArray(address).join(',')) +
        '&format=json&polygon=1&addressdetails=1')
    .toPromise();
  }
}
