<app-sidenav page="competition" menuType>

<div class="form-list" *ngIf="menuType!=2">

  <mat-card class="competition-card">
    <div class="top-image"
      [ngStyle]="{'background-image': 'url(' + competition?.getImage() +')'}">
    </div>

    <mat-card-header class="header">
      <mat-card-title>{{ competition?.getTitle() }}</mat-card-title>
      <mat-card-subtitle class="competition-date">{{ competition?.getDate()+'' | amParse: 'YYYYMMDD' | amDateFormat: dateFormat }}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <div class="body">
    <p [innerHTML]="competition?.getDescription() | pipeDescription">
    </p>

    <a target="_blank" class="results-link" *ngIf="competition?.getResulturl()" href="{{ competition?.getResulturl()}}">
      {{ 'competition.results' | translate }}
    </a>
    <BR />
    <a target="_blank" class="results-link" *ngIf="competition?.getResultpdf()" href="{{ competition?.getResultpdf()}}">
      {{ 'competition.resultspdf' | translate }}
    </a>
    <BR />
    <BR />
    <BR />
    <BR />
  </div>

  </div>

<div class="mode-tablet" *ngIf="menuType==2">
  <div class="left">
    <mat-card class="competition-card">
      <div class="top-image"
        [ngStyle]="{'background-image': 'url(' + competition?.getImage() +')'}">
      </div>
  
      <mat-card-header class="header">
        <mat-card-title>{{ competition?.getTitle() }}</mat-card-title>
        <mat-card-subtitle class="competition-date">{{ competition?.getDate()+'' | amParse: 'YYYYMMDD' | amDateFormat: dateFormat }}</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>
  
  <div class="right">
    <div class="body">
      <p [innerHTML]="competition?.getDescription() | pipeDescription">
      </p>
  
      <a target="_blank" class="results-link" *ngIf="competition?.getResulturl()" href="{{ competition?.getResulturl()}}">
        {{ 'competition.results' | translate }}
      </a>
      <BR />
      <a target="_blank" class="results-link" *ngIf="competition?.getResultpdf()" href="{{ competition?.getResultpdf()}}">
        {{ 'competition.resultspdf' | translate }}
      </a>
    </div>
  </div>
</div>

</app-sidenav>