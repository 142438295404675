import { Component, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { Stream } from '../../libs/proto/commUnity_pb';
import { GrpcLibService } from '../../service/grpc/grpc-lib.service';
import { Router } from '@angular/router';
import { UserLibService } from '../../service/user/user-lib.service';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { GrpcStreamLibService } from '../../service/grpc/stream/grpc-stream-lib.service';
import { desktopMode } from '../../config/type';
import { Platform } from '@angular/cdk/platform';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StreamPathConverter } from 'src/app/service/conversion/stream/stream-path-converter';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-everstreams',
  templateUrl: './everstreams.component.html',
  styleUrls: ['./everstreams.component.sass']
})
export class EverstreamsComponent implements OnInit {

  Everstreams$: Promise<Stream[]>;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  constructor(
    private route: Router,
    private grpcEverstreamsLib: GrpcStreamLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private detector: DeviceLibService,
    private EverstreamsLib: StreamPathConverter,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  /**
   * reset cache Everstreams
   */
     private resetCache() {
      switch (this.EverstreamsLib.StreamIndex) {
        case '2':
          this.storeLib.cache.stream2 = false; break;
        case '3':
          this.storeLib.cache.stream3 = false; break;
        case '4':
          this.storeLib.cache.stream4 = false; break;
        default:
          this.storeLib.cache.stream = false; break;
      }
    }

    private get offlineEverstreams() {
      switch (this.EverstreamsLib.StreamIndex) {
        case '2':
          return this.storeLib.cache.stream2;
        case '3':
          return this.storeLib.cache.stream3;
        case '4':
          return this.storeLib.cache.stream4;
        default:
          return this.storeLib.cache.news;
      }
    }
  ngOnInit(): void {
    const Everstreamsk = 'Everstreams-s' + this.EverstreamsLib.StreamIndex;

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });

    if (this.storeLib.get(Everstreamsk)) {
      this.storeLib.set(Everstreamsk, null);
      this.resetCache();
    }

    this.Everstreams$ = this.EverstreamsLib.fnGetStream({
      Offline: this.offlineEverstreams || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
  }
  get logo() {
    return this.userLib.designLogo;
  }
  get backgroundColor() {
    return this.userLib.designMainBackgroundColor;
  }
  get textColor() {
    return this.userLib.designMainTextColor;
  }
  get customer() {
    return this.userLib.Data.token?.getCustomer();
  }
  get customerName() {
    if (!(this.customer && this.customer.getShowcustomername()) || false) { return; }

    return this.userLib.Data.token?.getCustomer().getName();
  }

  get backgroundImage() {
    return this.userLib.designMainBg;
  }

  get backgroundSize() {
    if (!this.detector.isMobile()) {
      return (this.customer && this.customer.getBackgrounddesktopphotoskretch() || false) ? 'cover' : '';
    }

    return (this.customer && this.customer.getBackgroundphotoskretch() || false) ? 'cover' : '';
  }
  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
  open(id: string){
    this.route.navigate(['/stream' + this.EverstreamsLib.StreamIndex, id]);
  }
  get isChrome() {
    return navigator.userAgent.indexOf('CriOS/') >= 0;
  }
  canOpenInside(s: Stream) {
    return s.getOpeninside();
  }
}
