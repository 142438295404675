import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Observable, Subscriber } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { DocumentCategory, RequestID } from '../../../libs/proto/commUnity_pb';
import { Document as PbDocument } from 'src/app/libs/proto/commUnity_pb';
import { DocumentConversionService } from '../../conversion/document/document-conversion-service';
import { DocumentCategConversionService } from '../../conversion/document/document-cate-conversion-service';

@Injectable({
  providedIn: 'root'
})
export class GrpcDocumentLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: DocumentConversionService,
    private convcatLib: DocumentCategConversionService,
  ) { }

  getDocuments(option?: GrpcLibServiceOption): Promise<PbDocument[]>{
    return new Observable<PbDocument[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getDocumentsOffline(opt);
      } else {
        this._getDocumentsOnline(opt);
      }
    }).toPromise();
  }

  private _getDocumentsOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('document-s') || this.storLib.get('document')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getDocumentsOnline(option: GrpcLibServiceOption){
    const r = new RequestID();
    r.setId(option.call.req);
    this.grpcLib.MobileClient.getDocuments(r, {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getDocumentsOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getDocumentsOnlineStatus(s, option);
      })
      .on('data', (r: PbDocument) => {
        this._getDocumentsOnlineData(r, option);
      })
      .on('end', () => {
        this._getDocumentsOnlineEnd(option);
      });
  }

  private _getDocumentsOnlineData(ret: PbDocument, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getDocumentsOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getDocumentsOnline(option);
    }, option);
  }

  private _getDocumentsOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getDocumentsOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.document = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'document', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getDocumentsOnline(option);
      }, option);
    }
  }

  getDocumentCategories(option?: GrpcLibServiceOption): Promise<DocumentCategory[]>{
    return new Observable<DocumentCategory[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getDocumentCategoriesOffline(opt);
      } else {
        this._getDocumentCategoriesOnline(opt);
      }
    }).toPromise();
  }

  private _getDocumentCategoriesOffline(option: GrpcLibServiceOption){
    this.convcatLib.FromStorages(
      (this.storLib.get('documentc-s') || this.storLib.get('documentc')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getDocumentCategoriesOnline(option: GrpcLibServiceOption){
    const r = new RequestID();
    r.setId(option.call.req);
    this.grpcLib.MobileClient.getDocumentCategories(r, {
        token: this.userLib.Data.token?.getToken(),
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getDocumentCategoriesOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getDocumentCategoriesOnlineStatus(s, option);
      })
      .on('data', (r: DocumentCategory) => {
        this._getDocumentCategoriesOnlineData(r, option);
      })
      .on('end', () => {
        this._getDocumentCategoriesOnlineEnd(option);
      });
  }

  private _getDocumentCategoriesOnlineData(ret: DocumentCategory, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getDocumentCategoriesOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getDocumentCategoriesOnline(option);
    }, option);
  }

  private _getDocumentCategoriesOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getDocumentCategoriesOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.documentcat = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'documentc', this.convcatLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getDocumentCategoriesOnline(option);
      }, option);
    }
  }
}
