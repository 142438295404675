import { Component, OnInit } from '@angular/core';
import { Benefit } from 'src/app/libs/proto/commUnity_pb';
import { Router } from '@angular/router';
import { GrpcNewsLibService } from 'src/app/service/grpc/news/grpc-news-lib.service';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { GrpcBenefitLibService } from 'src/app/service/grpc/benefit/grpc-benefit-lib.service';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.sass']
})
export class BenefitsComponent implements OnInit {
  benefits$: Promise<Benefit[]>;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private route: Router,
    private grpcBenefitLib: GrpcBenefitLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  ngOnInit(): void {
    this.benefits$ = this.grpcBenefitLib.getBenefit({
      Offline: this.storeLib.cache.benefit || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });
  }
  open(id: string){
    this.route.navigate(['/benefit', id]);
  }
  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }

}
