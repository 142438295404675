import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GrpcPostLibService } from 'src/app/service/grpc/post/grpc-post-lib.service';
import { NewPostForm } from 'src/app/class/post/new-post-form';
import { NewPostConversionService } from 'src/app/service/conversion/new-post/new-post-conversion-service';
import { NewPost } from 'src/app/libs/proto/forum_pb';
@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.sass']
})
export class NewPostComponent implements OnInit {
  newPostForm: NewPostForm;
  messageLongEnough = false;
  replyType = false;
  canSend = false;
  charactersLeft = 140 ;

  @Input() topicId = '';
  @Output() closeMe = new EventEmitter();

  constructor(
    private newPostConvert: NewPostConversionService,
    private grpcPostLib: GrpcPostLibService){
      this.newPostForm = new NewPostForm();
      this.newPostForm.message = '';
      this.newPostForm.topicid = this.topicId;
      this.newPostForm.replytypesList = [];
     }

  ngOnInit(): void {
    const req = new NewPost();
    req.setTopicid(this.topicId);
  }

  save(){
    this.tosave();
  }
  tosave(){
    const req = new NewPost();
    req.setTopicid(this.topicId);
    req.setReplytypesList(this.newPostForm.replytypesList);
    req.setMessage(this.newPostForm.message);

    this.grpcPostLib.addPost(req).toPromise().then(() => {
      this.closeMe.emit('y');
    });

  }
  cancel(){
      this.closeMe.emit();
  }

  hasMessageLongEnough(){
    if ( this.newPostForm.message.length > 5){
      this.messageLongEnough = true;
      if (this.replyType ){
        this.canSend = true;
      }
    }
    else {
      this.canSend = false;
    }
  }
  hasReplyType(){
    if (this.newPostForm.replytypesList.length > 0){
      this.replyType = true;
      if (this.messageLongEnough){
        this.canSend = true;
      }
    }
    else {
      this.canSend = false;
    }
  }
}
