import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { Observable, Subscriber } from 'rxjs';
import { CustomizedMenu, Empty, Stream } from '../../../libs/proto/commUnity_pb';
import * as grpcWeb from 'grpc-web';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { StreamConversionService } from '../../conversion/stream/stream-conversion.service';
import { CustomizedMenusConversionService } from '../../conversion/customizemenu/customizemenu-conversion.service';

@Injectable({
  providedIn: 'root'
})
export class GrpcStreamLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: StreamConversionService,
    private convCustomizedMenuLib: CustomizedMenusConversionService,
  ) { }

  getStream(option?: GrpcLibServiceOption): Promise<Stream[]>{
    return new Observable<Stream[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getStreamOffline(opt);
      } else {
        this._getStreamOnline(opt);
      }
    }).toPromise();
  }

  private _getStreamOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('Stream-s') || this.storLib.get('Stream')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getStreamOnline(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getStreams(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getStreamOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getStreamOnlineStatus(s, option);
      })
      .on('data', (r: Stream) => {
        this._getStreamOnlineData(r, option);
      })
      .on('end', () => {
        this._getStreamOnlineEnd(option);
      });
  }

  private _getStreamOnlineData(ret: Stream, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getStreamOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getStreamOnline(option);
    }, option);
  }

  private _getStreamOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getStreamOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.stream = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'Stream', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getStreamOnline(option);
      }, option);
    }
  }

  getStream2(option?: GrpcLibServiceOption): Promise<Stream[]>{
    return new Observable<Stream[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getStream2Offline(opt);
      } else {
        this._getStream2Online(opt);
      }
    }).toPromise();
  }

  private _getStream2Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('Stream-s2') || this.storLib.get('Stream2')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getStream2Online(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getStreams2(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getStream2OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getStream2OnlineStatus(s, option);
      })
      .on('data', (r: Stream) => {
        this._getStream2OnlineData(r, option);
      })
      .on('end', () => {
        this._getStream2OnlineEnd(option);
      });
  }

  private _getStream2OnlineData(ret: Stream, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getStream2OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getStream2Online(option);
    }, option);
  }

  private _getStream2OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getStream2OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.stream2 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'Stream2', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getStream2Online(option);
      }, option);
    }
  }
  getStream3(option?: GrpcLibServiceOption): Promise<Stream[]>{
    return new Observable<Stream[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getStream3Offline(opt);
      } else {
        this._getStream3Online(opt);
      }
    }).toPromise();
  }

  private _getStream3Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('Stream-s3') || this.storLib.get('Stream3')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getStream3Online(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getStreams3(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getStream3OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getStream3OnlineStatus(s, option);
      })
      .on('data', (r: Stream) => {
        this._getStream3OnlineData(r, option);
      })
      .on('end', () => {
        this._getStream3OnlineEnd(option);
      });
  }

  private _getStream3OnlineData(ret: Stream, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getStream3OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getStream3Online(option);
    }, option);
  }

  private _getStream3OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getStream3OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.stream3 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'Stream3', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getStream3Online(option);
      }, option);
    }
  }
  getStream4(option?: GrpcLibServiceOption): Promise<Stream[]>{
    return new Observable<Stream[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getStream4Offline(opt);
      } else {
        this._getStream4Online(opt);
      }
    }).toPromise();
  }

  private _getStream4Offline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      (this.storLib.get('Stream-s4') || this.storLib.get('Stream4')), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                return n.getId() === option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getStream4Online(option: GrpcLibServiceOption){
    const t = this.userLib.Data.token?.getToken();
    this.grpcLib.MobileClient.getStreams4(new Empty(), {
        token: t,
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getStream4OnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getStream4OnlineStatus(s, option);
      })
      .on('data', (r: Stream) => {
        this._getStream4OnlineData(r, option);
      })
      .on('end', () => {
        this._getStream4OnlineEnd(option);
      });
  }

  private _getStream4OnlineData(ret: Stream, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getStream4OnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getStream4Online(option);
    }, option);
  }

  private _getStream4OnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getStream4OnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.stream4 = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'Stream4', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getStream4Online(option);
      }, option);
    }
  }
}
