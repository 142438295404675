<div class="mdc-layout-grid h-100">
  <div *ngIf="!hideLogin" class="login-page" [ngClass]="{'not-mobile': !ismobile}" [ngStyle]="{'background-image': 'url('+ (bgURL | pipeWebp: 'jpeg') + ')','background-size': bgSize}">
      <div
        class="login-logo-img default">
      </div>
      <div
        class="login-logo-img logo"
        [ngStyle]="{'background-image': 'url(' + (this.logoURL | pipeWebp: 'jpeg') +')'}">
      </div>

      <div
      (click)="toGroup()"
      *ngIf="groupLogo"
      class="group-logo">
      <div class="logo"
        [ngStyle]="{'background-image': 'url(' + (groupLogo | pipeWebp) +')'}"></div>
      </div>

      <div class="login-box" *ngIf="!customerLoading">
          <!-- /.login-logo -->
          <form>
          <div
              [class]="themeClass"
              class="login-box-body">
              <div class="form-group has-feedback">
                  <mat-form-field
                    type="email" class="w-100 field field-login">
                    <input
                      matInput id="txt-email" spellcheck="false" autocorrect="off" autocapitalize="off" autocomplete="username" [ngModelOptions]="{standalone: true}" [(ngModel)]="loginForm.email" placeholder="{{ 'Login.email' | translate }}" />
                  </mat-form-field>
              </div>

              <div class="form-group has-feedback">
                  <mat-form-field type="password"
                    class="w-100 field field-login">
                    <input matInput autocomplete="current-password" id="txt-password" placeholder="{{ 'Login.password' | translate }}" [type]="showPassword ? 'text' : 'password'" [ngModelOptions]="{standalone: true}" [(ngModel)]="loginForm.password">
                    <mat-icon
                      class="eye"
                      matSuffix (click)="togglePassword()">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </mat-form-field>
              </div>

              <div class="login-bottom" *ngIf="!waiting">
                <div class="login">
                    <button mat-raised-button
                      [class]="'login-button'"
                      [color]="'primary'"
                      [disabled]="!loginForm.valid || loginForm.value['password']==''" (click)="login()" class="w-80 m-auto d-block">{{ 'Login.button' | translate }}</button>
                </div>
                <div *ngIf="waiting" class="spin-wait">
                  <mat-spinner></mat-spinner>
                </div>
                <div class="signin-guest"
                  *ngIf="showSignInGuest">
                  <button mat-raised-button
                      [color]="'primary'" (click)="signinGuest()"
                      class="w-80 m-auto d-block signup-button">{{ 'Login.guest' | translate }}</button>
                </div>
                <div class="forget">
                    <button mat-button (click)="forgetPassword()"
                    class="w-80 m-auto d-block">{{ 'Login.forget_password' | translate }}</button>
                </div>
              </div>
          </div>
          <div class="signup-cont">
          <div class="signup"
              *ngIf="canSignUp">
              <button mat-stroked-button
                (click)="signUp()" class="signup-button d-block m-auto w-40">{{ 'Login.signup' | translate }}</button>
          </div>
        </div>
          </form>
      </div>
      <BR />
      <BR />
      <div class="diagnostic">
        {{diagnostic}}
      </div>
  </div>
</div>

<div class="customers-pane" *ngIf="!hideGroup && !appStopped">

  <div
    [ngStyle]="{'background-image': 'url(' + (customerGroup?.getBackgroundphoto() | pipeWebp) +')'}"
    class="logo-container">
    <div
      *ngIf="customerGroup?.getLogo()"
      [ngStyle]="{'background-image': 'url(' + (customerGroup?.getLogo() | pipeWebp) +')'}"
      class="logo-group"></div>
    <div class="logos">
      <div (click)="setCustomer(c?.getId())" class="logo"
      *ngFor="let c of customers">
        <div class="no-img"></div>
        <div class="img" mat-card-md-image
          [ngStyle]="{'background-image': 'url(' + (c?.getLogo() | pipeWebp) + ')'}"
        ></div>
        <label *ngIf="showCustomerText" [ngStyle]="{'color': customerTextColor}">{{ c?.getName() }}</label>
      </div>
    </div>
  </div>
</div>

<div class="error-failed" *ngIf="appStopped">
  <div class="form-wait-bg"></div>
  <mat-card class="err-box">
    <img mat-card-image src="{{ logoURL }}" />
    <mat-card-content>
    {{ 'stopped.msg' | translate }}
    </mat-card-content>
    <mat-card-footer>
      <button (click)="reload()"
      mat-raised-button [color]="'primary'">{{ 'stopped.button' | translate }}</button>
    </mat-card-footer>
  </mat-card>
</div>

<ng-template [ngIf]="customerLoading">
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
