export enum URLHelperClassType {
    address,
    phone,
    mobile,
    facebook,
    twitter,
    pinterest,
    linkedin,
    website,
    youtube,
    instagram,
    mail,
    menu
}

export class URLHelperClass {

    private static get_link_prefix(type:URLHelperClassType, value: string): string {
        if (value.substring(0, 4) == "http") return value;

        var prefix = "";
        switch (type) {
            case URLHelperClassType.facebook:
                prefix ='https://www.facebook.com/'
                break;
            case URLHelperClassType.twitter:
                prefix = "https://www.twitter.com/"
                break;
            case URLHelperClassType.pinterest:
                prefix = "https://www.pinterest.com/pin/"
                break;
            case URLHelperClassType.linkedin:
                prefix = "https://in.linkedin.com/in/"
                break;
            case URLHelperClassType.youtube:
                prefix = "https://www.youtube.com/"
                break;
            case URLHelperClassType.instagram:                
                prefix = "https://www.instagram.com/"
                break;
        }
        return prefix + value
    }

    static open_link(type :URLHelperClassType, value: string="", coordinates: number[] = null, target: string = ""){
        const toopen = URLHelperClass.get_link(type, value, coordinates);
        if (toopen == "") return;

        window.open(toopen, target)        
    }

    static get_link(type :URLHelperClassType, value: string="", coordinates: number[] = null): string {
        switch (type) {
            case URLHelperClassType.address:
                if (coordinates.length != 2) return undefined;
                
                return 'https://www.google.com/maps/@' + coordinates[1] + ',' +
                coordinates[0] + ',15z'
                
            case URLHelperClassType.phone:
            case URLHelperClassType.mobile:
                return 'tel:' + value
            case URLHelperClassType.facebook:
            case URLHelperClassType.twitter:
            case URLHelperClassType.pinterest:
            case URLHelperClassType.linkedin:
            case URLHelperClassType.youtube:
            case URLHelperClassType.instagram:                
                return URLHelperClass.get_link_prefix(type, value)
            case URLHelperClassType.website:
            case URLHelperClassType.menu:                
                return value
            case URLHelperClassType.mail:
                return 'mailto:'+ value
        }

        return undefined;
    }
}