import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { NewsConversionService } from '../../conversion/news/news-conversion.service';
import { Customer, MobileApp, RequestID } from '../../../libs/proto/commUnity_pb';
import { MobApp, GroupCustomers, RequestCustomer } from '../../../libs/proto/mobile_pb';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { CustomerConversionService } from '../../conversion/customer/customer-conversion.service';
import { GroupCustomerConversionService } from '../../conversion/customer/group-customer-conversion.service';
import { communityAppType } from '../../../config/type';

@Injectable({
  providedIn: 'root'
})
export class GrpcCustomerLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: CustomerConversionService,
    private grpConvLib: GroupCustomerConversionService
  ) { }

  getCustomer(option?: GrpcLibServiceOption): Promise<Customer>{
    return new Observable<Customer>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getCustomerOffline(opt);
      } else {
        this._getCustomerOnline(opt);
      }

    }).toPromise();
  }

  private _getCustomerOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorage(
      this.storLib.get('customer'), (ns, e) => {
        option.call.subscribe.next(ns);
        option.call.subscribe.complete();
    });
  }

  private _getCustomerOnline(option: GrpcLibServiceOption){
    const req = new RequestCustomer();
    if (option.call.req) {
      req.setId(option.call.req);
    } else {
      req.setMobileapp( this.userLib.userApp );
    }
    this.grpcLib.MobileClient.getCustomerInfo(req, {}, () => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getCustomerOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getCustomerOnlineStatus(s, option);
      })
      .on('data', (r: Customer) => {
        this._getCustomerOnlineData(r, option);
      })
      .on('end', () => {
        this._getCustomerOnlineEnd(option);
      });
  }

  private _getCustomerOnlineData(ret: Customer, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getCustomerOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getCustomerOnline(option);
    }, option);
  }

  private _getCustomerOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getCustomerOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      if (option.KeepInCache || false) {
        if (option.call.data?.length > 0) {
          this.storLib.set(
            'customer', this.convLib.ToStorage(option.call.data[0])
          );
        }
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getCustomerOnline(option);
      }, option);
    }

  }

  getCustomers(option?: GrpcLibServiceOption): Promise<GroupCustomers>{
    return new Observable<GroupCustomers>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getCustomersOffline(opt);
      } else {
        this._getCustomersOnline(opt);
      }

    }).toPromise();
  }

  private _getCustomersOffline(option: GrpcLibServiceOption){
    this.grpConvLib.FromStorage(
      this.storLib.get('customers'), (ns, e) => {
        option.call.subscribe.next(ns);
        option.call.subscribe.complete();
    });
  }

  private _getCustomersOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId( option.call.req );
    this.grpcLib.MobileClient.getCustomers(req, {}, () => {})
      .on('error', (e: grpcWeb.RpcError) => {
        this._getCustomersOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getCustomersOnlineStatus(s, option);
      })
      .on('data', (r: GroupCustomers) => {
        this._getCustomersOnlineData(r, option);
      })
      .on('end', () => {
        this._getCustomersOnlineEnd(option);
      });
  }

  private _getCustomersOnlineData(ret: GroupCustomers, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getCustomersOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getCustomersOnline(option);
    }, option);
  }

  private _getCustomersOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getCustomersOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.customer = true;
      if (option.KeepInCache || false) {
        if (option.call.data?.length > 0) {
          this.storLib.set(
            'customers', this.grpConvLib.ToStorage(option.call.data[0])
          );
        }
      }

      option.call.subscribe.next(option.call.data[0]);
      if (option?.callback) { option?.callback(option.call.data[0]); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getCustomersOnline(option);
      }, option);
    }
  }
}
